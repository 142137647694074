.task-responsability {
	.title {
		font-size: 18px;
	}
	.subtitle {
		font-size: 16px;
	}

	.responsability-action-take,
	.responsability-action-pass {
		// margin-top: 10px;
	}
	.actions {
		display: flex;
	}
}
