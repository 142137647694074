@import 'animations';
@import 'mediaQueries';
.graphic-line {
	@media #{$mobile_portrait_landscape} {
		flex-direction: column;
		padding: 10px;
	}
}
.dashboard-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(200px, auto));
	grid-auto-rows: minmax(100px, auto);
	grid-gap: 10px;
	padding: 20px;
	align-content: start;
	.box {
		// box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
		// border: 1px dashed #ddd;
		border-radius: 10px;
		padding: 20px;
		font-size: 12;
		max-height: 300px;
		overflow-y: scroll;

		.title {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 15px;
		}
		.items {
			// display: grid;
			// grid-template-columns: repeat(auto-fit, minmax(100px, auto));
			// grid-gap: 10px;
			// align-content: start;
			display: flex;
			align-items: stretch;
			align-content: flex-start;
			flex-wrap: wrap;
			.MuiMenuItem-root,
			.item {
				// background: #eee;
				margin-right: 4px;
				border: 1px dashed #ddd;
				border-bottom: 1px dashed #ddd;
				min-width: 32%;
				border-radius: 0;
				margin-bottom: 2px;
				padding: 10px;
				cursor: pointer;
				font-size: 12px;
				border-left: 10px solid #00d0ff;
				&:hover {
					background: #eef;
				}

				&.urgent {
					border-left-color: crimson !important;
				}
			}
		}
	}
}
header {
	h3 {
		font-size: 24px;
		color: #048a47;
		svg {
			margin-right: 10px;
			width: 30px;
		}
	}
}
h3 {
	font-size: 20px;
	color: #048a47;
	svg {
		margin-right: 10px;
	}
}
.column {
	&.tasks {
		height: 300px;
		overflow-y: scroll;
		margin-right: 10px;
		margin-left: 10px;
		padding: 30px;
		li {
			margin-bottom: 3px;
			padding: 5px 10px;
			&.approval {
				border-left: 10px solid #fff069;
				border-left-color: #ffeb3b !important;
			}
			&.documentInput {
				border-left: 10px solid #00bcd4;
				border-left-color: #00bcd4 !important;
			}
			&.formInput {
				border-left: 10px solid #039be5;
				border-left-color: #039be5 !important;
			}
			&.documentOutput {
				border-left: 10px solid #77d2ff;
				border-left-color: #77d2ff !important;
			}
			&.notificationEmail {
				border-left: 10px solid #04c614;
				border-left-color: #04c614 !important;
			}
			&.phaseJump {
				border-left: 10px solid #ffc507;
				border-left-color: #ffc507 !important;
			}
			&.urgent {
				border-left-color: crimson !important;
			}
		}
	}
}
.apexcharts-legend-series {
	word-break: break-word !important;
	max-width: 100px !important;
}
.workflow-search-container {
	padding: 20px;
}
.workflow-card-container {
	display: flex !important;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	padding: 0 !important;
	.workflow-action-card {
		width: calc(100% - 0px) !important;
		flex: 1 !important;
		.content {
			max-width: calc(100% - 0px) !important;
		}
	}
}
