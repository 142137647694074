$sidebar-width: 40px;
#sidebar {
	position: fixed;
	top: $ultra-bar-height;
	bottom: 0;
	left: 0;
	width: $sidebar-width;
	.items {
	}
}
