.card {
	background-color: #f1f1f1;
	width: 100%;
	display: flex;
	gap: 0.5rem;
	border-radius: 8px;
	position: relative;
	box-shadow: 2px 2px 4px #00000034;
}
.card * {
	margin: 0;
}
.areaBanner {
	width: 250px;
	height: 150px;
	border-radius: 8px;

	display: flex;
	align-items: center;
	justify-content: center;
}
.areaBanner img {
	width: 100%;
	height: 150px;
	object-fit: cover;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}
.areaText {
	display: flex;
	flex-direction: column;
	gap: 0.3rem;
	flex: 1;
	padding: 0.5rem;
}
.areaText p {
	max-height: calc(1.2em * 3);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.header section,
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 0.5rem;
}
.header section p {
	font-size: 12px;
}
.areaButtons {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #00000041;

	display: none;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	border-radius: 8px;
}
.card:hover .areaButtons {
	display: flex;
}
@media screen and (max-width: 650px) {
	.card {
		flex-direction: column;
	}
	.areaBanner {
		width: 100%;
		min-width: 100%;
	}
	.areaBanner img {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border-bottom-left-radius: 0px;
	}
}
@media screen and (max-width: 380px) {
	.areaBanner {
		min-width: 100px;
	}
}
