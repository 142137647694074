.central-full-logo {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	position: fixed;
	background: $background;
	opacity: 0;
	animation: showLoadingContainer 2s 1 linear forwards;
	z-index: 1000000000000;
	&.not-spinning {
		z-index: 10001;
	}
	&.instant {
		animation: none;
		opacity: 1;
		.app-logo {
			// animation: showLoadingContainer 200ms 1 linear forwards;
		}
	}
	.app-logo {
		position: relative;
		background: $primary-color;
		padding: 35px;
		max-width: 400px;
		max-height: 400px;
		width: 30vh;
		height: 30vh;
		border-radius: 50%;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		img {
			max-width: 340px;
			width: 24vh;
			// margin-left: 3vh;
			height: auto;
		}
		z-index: 10000000000000;
	}
	&.spinning {
		.app-logo {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				background: transparent;
				padding: 35px;
				min-width: 100%;
				min-height: 100%;
				width: 30vh;
				height: 30vh;
				border-radius: 50%;
				border-top: 4px solid #b8bcc2;
				border-left: 4px solid #b8bcc2;
				border-right: 4px solid #b8bcc2;
				border-bottom: 4px solid #5e69df;
				animation: spin 500ms linear infinite;
				box-sizing: border-box;
				z-index: 10000000000000;
			}
		}
	}
	.loading.icon {
		display: none;
		position: absolute;
		background: transparent;
		padding: 35px;
		max-width: 400px;
		max-height: 400px;
		left: auto;
		width: 30vh;
		height: 30vh;
		border-radius: 50%;
		border-top: 4px solid $black;
		border-left: 4px solid $black;
		border-right: 4px solid $black;
		border-bottom: 4px solid $primary-color;
		animation: spin 500ms linear infinite;

		svg {
			display: none;
		}
		z-index: 10000000000000;
	}
	.content-container {
		max-width: 50vw;
		max-height: 80vh;
		overflow-y: auto;
		margin-left: 15px;
	}
}
