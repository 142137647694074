#chatbar {
	position: fixed;
	top: 0;
	left: auto;
	right: 0;
	bottom: 0;
	width: 300px;
	height: 100vh;
	background: $chatbar-background;
	color: $chatbar-color;
	box-shadow: 10px 10px 40px $shadow-b;
	z-index: 10000000000000;
	transition: transform 400ms ease;
	transform: translate3d(400px, 0, 0);
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: flex-start;

	&.visible {
		display: block;
		transform: translate3d(0, 0, 0);
	}
	.header-line {
		height: 40px;
		border-bottom: 1px solid $chatbar-border-color;
		padding: 0 10px;
		display: flex;
		color: inherit;
		align-items: center;
		align-content: center;
		.title {
			color: inherit;
			flex: 1;
			margin-bottom: 0;
			padding: 0;
			font-size: 16px;
		}
		.action {
			cursor: pointer;
			svg {
				font-size: 20px;
				color: inherit;
			}
		}
	}

	.form-control-input {
		width: calc(100% - 1px);
		box-sizing: border-box;
		color: inherit;
		input {
			border: 1px solid $chatbar-border-color;
			color: inherit;
		}
		.svg {
			color: inherit;
			opacity: 0.9;
		}
	}

	.chats {
		flex: 1;
		border-bottom: 1px solid $chatbar-border-color;
		overflow: auto;
		max-height: calc(50% - 50px);
		.chat {
			min-height: 20px;
			font-size: 12px;
			color: inherit;
			border-bottom: 1px solid $chatbar-border-color;
			// box-sizing: border-box;
			// white-space: nowrap;
			padding: 10px 15px;
			display: flex;
			align-items: center;
			align-content: flex-start;
			cursor: pointer;
			&:hover {
				background: $chatbar-background-hover;
			}
			.icon {
				height: 24px;
				width: 24px;
				margin-right: 10px;
				border-radius: 12px;
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				background: $chatbar-avatar-background;
				color: $chatbar-avatar-color;
				font-size: 12px;
			}
		}
	}
}
.chat-panels {
	position: fixed;
	top: auto;
	bottom: 0;
	left: auto;
	right: 20px;
	display: flex;
	align-items: flex-end;
	align-content: flex-end;
	z-index: 100000;
	pointer-events: none;

	.chat-panel {
		pointer-events: all;
		max-width: 200px;
		min-width: 40px;
		height: 400px;
		width: 30px;
		margin-left: 10px;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		box-shadow: 0 0 10px 2px $shadow-a;
		border-radius: 3px;
		background: $background;
		box-sizing: border-box;
		transition: transform 100ms ease;
		transform: translate3d(0, 370px, 0);
		z-index: 1000;
		&:hover {
			z-index: 10000;
		}
		&.open {
			transform: translate3d(0, 0, 0);
			width: 300px;
			& > .title {
				padding: 5px 20px 5px 10px !important;
				font-size: 12px;
				white-space: normal;
				.text {
					display: block;
				}
			}
			&.maximized {
				height: calc(100%);
				width: 100vw !important;
				max-width: none;
				max-height: calc(100vh - 40px);
				bottom: 0px !important;
				left: 0 !important;
				right: 0 !important;
				margin: 0 !important;
				position: fixed;
				.conversation-panel {
					& > .message-container {
						.content-editable {
							margin-left: 80px;
						}
					}
				}
			}
		}
		& > .title {
			padding: 0px 10px 0px 10px !important;
			height: 30px;
			font-size: 12px;
			display: flex;
			align-items: center;
			margin-bottom: 0;
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			cursor: pointer;
			&::after {
				right: 0;
				top: -70px;
				width: 100px;
			}
			.text {
				flex: 1;
				display: none;
			}
			.icon {
				font-size: 16px;
				margin-left: 0px;
				margin-right: 8px;
				color: $title-color;
			}
			.badge {
				position: absolute;
				top: -20px;
				right: 10px;
				background: $green7;
				color: $greenB;
				border-radius: 50%;
				padding: 5px;
				font-size: 10px;
				line-height: 10px;
				min-width: 10px;
				min-height: 10px;
				text-align: center;
			}
			.close,
			.maximize {
				margin: 0 5px 0 5px;
				height: 20px;
				width: 20px;
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				font-size: 12px;
				cursor: pointer;
				border-radius: 4px;
				&:hover {
					background: $blue7;
					color: $blueD;
				}
			}
		}
		.conversation-panel {
			display: flex;
			align-items: stretch;
			flex-direction: column;
			background: $white;
			border-radius: 5px;
			// box-shadow: 10px 10px 50px $shadow-a;
			max-height: calc(100% - 50px);
			flex: 1;

			& > .conversations {
				flex: 1;
				overflow: scroll;
				max-height: calc(100% - 0px);
				display: flex;
				flex-direction: column-reverse;
				align-items: flex-start;
				align-content: flex-start;
				box-sizing: border-box;
				border-top: 1px solid $shadow-a;
				padding: 0 20px;
				box-shadow: inset 0 0 20px lighten($shadow-a, 9%);
				.message {
					padding: 10px;
					font-size: 12px;
					color: $blue9;
					display: inline-block;
					border-top: 0;

					margin-bottom: 10px;
					background: lighten($shadow-a, 12%);
					background: $white;
					box-shadow: 5px 5px 20px lighten($shadow-a, 9%);
					box-sizing: border-box;
					border-radius: 4px;
					position: relative;
					z-index: 1;
					white-space: pre-wrap;
					&.receiver {
						border-right: 5px solid $orange;
						align-self: flex-start;
						margin-left: -15px;
					}
					&.sender {
						border-left: 5px solid $blue9;
						align-self: flex-end;
						margin-right: -15px;
					}

					&:last-child {
						// scroll-snap-align: end;
					}
					.header {
						display: flex;
						align-items: center;
						margin-bottom: 10px;
						.user {
							color: $black;
							margin-right: 10px;
						}
					}
					.content {
						white-space: pre-wrap;
					}
				}
			}

			& > .message-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 5px 10px 0 5px;
				border-top: 1px solid $shadow-a;

				textarea {
					flex: 1;
					border: 0;
					box-shadow: inset 0 0 10px $shadow-a;
					border-radius: 5px;
					margin: 0 10px 0 0;
					border: 0;
					padding: 10px;
					line-height: 16px;
				}
				.content-editable {
					flex: 1;
					border: 1px solid $grayM;
					min-height: 40px;
					margin-right: 10px;
					border-radius: 3px;
					box-shadow: inset 0 0 10px $shadow-a;
					padding: 10px;
					line-height: 16px;
					font-size: 12px;
					font-weight: auto;
				}

				.send {
					display: flex;
					flex-direction: row;
					align-items: center;
					align-content: center;
					justify-content: center;
					background: $primary-color;
					width: 40px;
					height: 40px;
					border-radius: 20px;
					color: $white;
					cursor: pointer;
					&:hover {
						background: darken($primary-color, 20%);
					}
				}
			}
		}
	}
}
