@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

@include keyframes(showLoadingContainer) {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@include keyframes(hideLoadingContainer) {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@include keyframes(loading) {
	0% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: rotate3d(0, 0, 1, 360deg);
	}
}

@include keyframes(callAttention) {
	0% {
		z-index: 0;
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		opacity: 0;
		z-index: 0;
		transform: scale3d(1.5, 1.5, 1.5);
	}
}

@include keyframes(showTooltip) {
	0% {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@include keyframes(showItem) {
	0% {
		opacity: 0;
		transform: translate3d(0, 10px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

@include keyframes(scaleUp) {
	0% {
		transform: scale(10);
	}
	50% {
		transform: scale(0.2);
	}
	70% {
		transform: scale(1.2);
	}
	90% {
		transform: scale(0.7);
	}
	100% {
		transform: scale(1);
	}
}
