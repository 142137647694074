@mixin disable-selection {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}

@mixin enable-selection {
	-webkit-touch-callout: initial; /* iOS Safari */
	-webkit-user-select: initial; /* Safari */
	-khtml-user-select: initial; /* Konqueror HTML */
	-moz-user-select: initial; /* Firefox */
	-ms-user-select: initial; /* Internet Explorer/Edge */
	user-select: initial; /* Non-prefixed version, currently supported by Chrome and Opera */
}
@mixin call-attention {
	z-index: 0;
	position: relative;
	&::before {
		content: '';
		display: block;
		background: inherit;
		border-radius: inherit;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: calc(100% - 0px);
		height: calc(100% - 0px);
		z-index: -1;
	}
	&::after {
		content: '';
		display: block;
		background: #ffb700;
		border-radius: inherit;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: calc(100% - 0px);
		height: calc(100% - 0px);
		z-index: -2;
		// animation: callAttention 500ms infinite;
		animation-name: callAttention;
		animation-fill-mode: forwards;
		animation-timing-function: ease-out;
		animation-duration: 1000ms;
		animation-delay: 30ms;
		animation-iteration-count: infinite;
		animation-play-state: running;
	}
}
.call-attention {
	@include call-attention();
}

@function colour-difference($foreground, $background) {
	$r: (max(red($foreground), red($background))) - (min(red($foreground), red($background)));
	$g: (max(green($foreground), green($background))) - (min(green($foreground), green($background)));
	$b: (max(blue($foreground), blue($background))) - (min(blue($foreground), blue($background)));
	$sum-rgb: $r + $g + $b;

	@if $sum-rgb < 500 {
		@return #fff;
	} @else {
		@return #333;
	}
}
