$green: #34b10a;
$green0: #030a01;
$green1: #061401;
$green2: #0a2202;
$green3: #0c2903;
$green4: #0d2b03;
$green5: #133d04;
$green6: #174d06;
$green7: #206409;
$green8: #2b8a0c;
$green9: #349e11;
$greenA: #40cc12;
$greenB: #58c534;
$greenC: #7ad65b;
$greenD: #b0f09b;
$greenE: #c6fcb4;
$greenF: #d2f7c5;
$red: #d01235;
$red0: #200709;
$red1: #2e080b;
$red2: #3d090d;
$red3: #500c10;
$red4: #610c12;
$red5: #700f16;
$red6: #831018;
$red7: #98131c;
$red8: #ae1520;
$red9: #c61924;
$redA: #cc315a;
$redB: #db305e;
$redC: #ce6f89;
$redC: #df9bad;
$redE: #e9c6d0;
$redF: #fcdfe8;
$orange: #fc8b00;
$orange0: #211404;
$orange1: #3a2204;
$orange2: #4d2b03;
$orange3: #713e00;
$orange4: #824700;
$orange5: #935100;
$orange6: #ac5f00;
$orange7: #c66d00;
$orange8: #d57500;
$orange9: #e87f00;
$orangeA: #fc8b00;
$orangeB: #fc9d2a;
$orangeC: #f5b76b;
$orangeD: #fcd09c;
$orangeE: #fce0be;
$orangeF: #ffefdc;
$yellow: #fcfc00;
$yellow0: #202000;
$yellow1: #3b3b01;
$yellow2: #474700;
$yellow3: #646401;
$yellow4: #979703;
$yellow5: #a0a001;
$yellow6: #b1b103;
$yellow7: #d3d301;
$yellow8: #e2e204;
$yellow9: #eeee03;
$yellowA: #fcfc00;
$yellowB: #fafa38;
$yellowC: #f7f77a;
$yellowD: #fafaae;
$yellowE: #f8f8cd;
$yellowF: #f7f7e1;
$brownA: #8a4e32;
$brownB: #9b6952;
$brownC: #b3826b;
$brownD: #c28e76;
$brownE: #d8a289;
$brownF: #f1bfa8;
$teal: #205861;
$teal0: #0b1e21;
$teal1: #0f2c30;
$teal2: #12373c;
$teal3: #154950;
$teal4: #175d66;
$teal5: #176d79;
$teal6: #1992a2;
$teal7: #188391;
$teal8: #1896a7;
$teal9: #18a2b5;
$tealA: #17b6cb;
$tealB: #14c7df;
$tealC: #54e5f9;
$tealD: #72edfe;
$tealE: #98f3ff;
$tealF: #bff8ff;
$tealG: #d7faff;
$light-teal: #0192a2;
$blue: #127ed0;
$blue0: #030e16;
$blue1: #061520;
$blue2: #071724;
$blue3: #081f31;
$blue4: #0b2c46;
$blue5: #133650;
$blue6: #12466e;
$blue7: #0c558d;
$blue8: #0d60a0;
$blue9: #127ed0;
$blueA: #549ee2;
$blueB: #89c5fc;
$blueC: #a9d6ff;
$blueD: #c3e1fe;
$blueE: #daecfc;
$blueF: #eef7ff;
$purple0: #04070b;
$purple1: #070b10;
$purple2: #080e16;
$purple3: #0c1522;
$purple4: #0d1829;
$purple5: #0f1d34;
$purple6: #132441;
$purple7: #193059;
$purple8: #1e3763;
$purple9: #2b4d86;
$purpleA: #3c65ac;
$purpleB: #507ac2;
$purpleC: #6b8cc7;
$purpleD: #92addb;
$purpleE: #bacff4;
$purpleF: #c9dbfa;
