.zero-floating-message {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	z-index: 10000000;
	transition: opacity 500ms ease;
	opacity: 0;
	&.redux-message {
		z-index: 1000000000;
	}
	&.visible {
		opacity: 1;
		pointer-events: all;

		.content {
			transform: translate3d(0, 0, 0);
		}
	}

	& > .content {
		background: $white;
		padding: 0;
		border-radius: 10px;
		border-radius: 0;
		box-shadow: $shadow;
		box-shadow: 10px 10px 50px #3c4785aa;
		transform: translate3d(0, 2000px, 0);
		transition: transform 600ms ease-out;
		min-width: 300px;
		max-width: 50vw;
		max-height: 85vh;
		box-sizing: border-box;
		padding-bottom: 60px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: stretch;

		@media #{$mobile_portrait_landscape} {
			width: 98vw;
			height: 98vh;
			max-width: 100vw;
			box-sizing: border-box;
		}

		& > .header {
			// display: none;
			// padding: 20px;
			height: auto;
			margin-bottom: 10px;
			margin-bottom: 0;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			align-content: center;
			background: #89d2f0;
			background: $white;
			padding: 10px;
			box-shadow: inset 0 0 10px $shadow-a;
			// border-top-left-radius: 10px;
			// border-top-right-radius: 10px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			.close {
				// display: none;
				cursor: pointer;
				svg {
					color: $white;
					color: #1a1c37;
					color: #6d70a4;
					font-size: 30px;
				}
				&:hover {
					svg {
						color: #363855;
					}
				}
				@media #{$mobile_portrait_landscape} {
					display: block;
					height: 40px;
					width: 40px;
					min-width: 40px;
					// background: #ffeb3b;
					// color: #222;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 10px;
				}
			}
			.title {
				// font-size: 20px;
				font-size: 16px;
				font-weight: normal;
				text-transform: none;
				font-family: Roboto;
				flex: 1;
				margin-right: 15px;
				margin-bottom: 0;
				color: $white;
				color: #1a1c37;
			}
		}
		& > .description {
			padding: 20px;
			font-size: 16px;
			font-weight: lighter;
			margin-bottom: 15px;
			margin-bottom: 0;
			// max-height: 60vh;
			// max-height: calc(60% - 0px);
			// height: calc(60% - 0px);
			overflow: auto;
			.buttons {
				position: absolute;
				top: auto;
				bottom: 10px;
			}
		}

		.buttons {
			display: flex;
			align-items: center;
			align-content: flex-end;
			justify-content: flex-end;
			z-index: 1000;
			position: absolute;
			bottom: 10px;
			left: 10px;
			right: 10px;
		}
	}
}
