@import "../base/animations.scss";
@import "../utils/mixins";
@import "../utils/breakpoints";


$button-colors: (
  google: #ff7043,
  facebook: #3f5cac,
  linkedin: #0173b2,
  save: #009688,
  success: #33cc33,
  clear: #ff7043,
  delete: #ff7043,
  reject: #ff7043,
  info: #26c6da,
  cancel: #f92323,
  gold: #946a00,
);

.Auth,
.PrivacyPolicy,
.TermsAndConditions {
  .SimpleButton {
    display: flex;
    align-items: center;
    align-content: center;
    box-sizing: border-box;

    position: relative;
    height: 40px;
    width: fit-content;

    padding: 10px 15px;

    border-radius: 4px;
    border-width: 0;

    background: var(--button-color);
    color: var(--text-button-color);

    text-transform: uppercase;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

    cursor: pointer;

    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
    transition: box-shadow 400ms ease, transform 400ms ease;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);

    animation: showItem 400ms 1;

    @include disable-selection;

    @media #{$mobile-portrait-landscape} {
      font-size: 12px;
      height: auto;
    }

    svg {
      margin-right: 8px;
    }

    &:hover {
      box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
      transform: translate3d(-3px, -3px, 0) scale3d(1, 1, 1);
    }

    &[disabled="true"],
    &[disabled] {
      background: #c9c9c9;
      color: #fff;
      cursor: not-allowed;
    }

    &--outlined {
      background-color: transparent;
      border: 1px solid var(--primary-color);

      &[disabled="true"],
      &[disabled] {
        background: transparent;
        color: inherit;
        opacity: 0.5;
      }
    }

    @each $variant, $color in $button-colors {
      &--#{$variant} {
        background-color: $color;
        color: #fff;

        svg {
          color: #fff;
        }
      }

      &--outlined.SimpleButton--#{$variant} {
        background-color: transparent;
        border-color: $color;
        color: $color;

        svg {
          color: $color;
        }
      }
    }

    &--iconed {
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      width: 45px;

      svg {
        margin-right: 0;
      }
    }

    &--round {
      border-radius: 50%;

      &.SimpleButton--iconed {
        width: auto;
        padding: 12px;
      }
    }

    &--fullWidth {
      width: 100%;
    }

    &--mouseDown {
      &::after {
        content: "";
        display: block;
        background: inherit;
        border-radius: inherit;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        animation: callAttention 500ms 1;
      }
    }
  }
}