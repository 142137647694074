@import '../base/animations.scss';
@import '../utils/mixins';
@import '../utils/breakpoints';
@import '../utils/colors';
@import '../utils/themeApp';

.Account {
	@extend .themeApp;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 16px;
	height: 100%;
	padding: 16px;
	background: var(--primary-color);
	color: var(--text-color);
	overflow-x: auto;

	&[data-app='collectame'] {
		background: var(--secondary-color);
	}

	b {
		font-weight: bold;
	}

	&-tabs {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		gap: 8px;
		width: 100%;
		height: 50px;
		padding: 0;
		border-radius: 0;
		text-transform: capitalize;

		&-item {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 16px;
			padding: 8px 16px !important;

			&-label {
				@media (max-width: 520px) {
					display: none;
				}
			}

			&[data-selected='false'] {
				background-color: transparent;
				color: var(--title-color);
			}

			&[data-selected='true'] {
				&,
				&:hover {
					background-color: var(--highlight-color) !important;
					color: var(--text-button-color) !important;
				}
			}

			&:hover {
				opacity: 0.5;
			}
		}

		&-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			width: 100%;
			height: 100%;
			box-sizing: border-box;
			@include show-item-animation;
		}
	}

	&-title {
		color: var(--title-color);
		font-weight: 600;

		&:not(:first-child) {
			margin-top: 15px;
		}
	}

	&-subtitle {
		font-weight: bold;
		color: var(--text-color);
	}

	&-text {
		margin: 0;
	}

	&-icon {
		border-radius: 50%;

		svg {
			font-size: 32px;
		}

		&-almostReachingLimit {
			svg {
				color: #da0c20;
			}
		}

		&-underLimit {
			svg {
				color: #54ae4a;
			}
		}
	}

	&-profile,
	&-security,
	&-certificates {
		p {
			margin: 0;
		}

		hr {
			margin: 16px 0;
		}

		ul {
			font-size: 16px;

			li {
				margin-bottom: 8px;
			}
		}

		&,
		&-rows {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 8px;

			&[data-fullwidth='true'] {
				width: 100%;
			}

			&[data-fullheight='true'] {
				height: 100%;
			}

			&[data-align-x='center'] {
				align-items: center;
			}

			&[data-align-y='center'] {
				justify-content: center;
			}
		}

		&-columns {
			display: flex;
			flex-direction: row;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 8px;

			&[data-fullwidth='true'] {
				width: 100%;
			}

			&[data-fullheight='true'] {
				height: 100%;
			}

			&[data-align-x='center'] {
				justify-content: center;
			}

			&[data-align-y='center'] {
				align-items: center;
			}

			@media (max-width: 520px) {
				flex-direction: column;
			}
		}

		.MuiFormControlLabel-root {
			margin-left: 0 !important;
		}
	}

	&-profile,
	&-security,
	&-certificates,
	&-charge {
		padding: 32px;
		width: calc(100% - 2 * 32px) !important;
		height: 100%;
	}

	&-profile,
	&-security {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 24px;

		&-section {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 8px;
			border-radius: 16px;
			border: 1px solid var(--text-color);
			padding: 32px;
			width: calc(100% - 2 * 32px) !important;
		}
	}

	&-charge {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		gap: 8px;
		width: 100%;
		height: 100%;

		&-left,
		&-right {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			flex: 1;
			height: 100%;
		}

		&-right {
			justify-content: space-between;
		}

		&-container,
		&-warning {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-direction: column;
			margin: 8px 0;
			width: 100%;
		}

		&-warning {
			align-items: center;
			justify-content: center;
			gap: 16px;
			padding: 48px;
			background-color: var(--secondary-color);
			color: var(--tertiary-color);
			border-radius: 8px;
			text-align: center;
			width: fit-content;
			margin: 0 auto;

			* {
				margin: 0;
			}
		}
	}

	&-profile,
	&-security {
		&-input {
			flex: 1;

			@media (max-width: 520px) {
				width: 100%;
			}
		}

		&-photo {
			width: 80px !important;
			height: 80px !important;
		}

		&-loadPhoto,
		&-saveChanges {
			background-color: var(--button-color) !important;
			color: var(--text-button-color) !important;
		}

		&-resetPhoto,
		&-resetChanges {
			background-color: transparent !important;
			color: var(--text-color) !important;
			border: 1px solid var(--text-color) !important;
		}

		&-deleteAccount {
			background-color: $l-red !important;
			color: var(--text-button-color) !important;
		}

		&-actions {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			width: 100%;
		}
	}

	&-security {
	}

	&-certificates {
		&-header {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
			width: 100%;
		}

		&-list {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: 16px;
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
		}

		&-loading {
			height: 500px;
		}

		&-card {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			gap: 0;
			border-left: var(--button-color) solid 8px;
			border-radius: 8px;
			background-color: #fff;
			box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
			width: calc(100% - 2 * 16px);
			padding: 8px 16px;
			@include show-item-animation;

			&[data-disabled='true'] {
				border-left-color: #cdcdcc !important;
				background-color: #e3e2e2;
			}

			&[data-decrypted='false'] {
				border-left-color: #ee9800 !important;
				background-color: #e3e2e2;
			}

			&[data-deleted='true'] {
				opacity: 0.5;
			}

			&-certificateImage {
				width: 80px;
			}

			&-info {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				gap: 0;
				flex: 1;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			&-name,
			&-field {
				font-weight: 700;
				color: var(--title-color);
				font-size: 16px;
			}

			&-description,
			&-value {
				font-weight: 400;
				color: var(--text-color);
				font-size: 16px;
				margin-left: 8px !important;
			}

			&-separator {
				background-color: var(--button-color);
				border-radius: 8px;
				width: 6px;
				height: calc(100% - 2 * 16px);
				margin: 16px 16px;
			}

			&[data-disabled='true'] &-separator {
				background-color: #cdcdcc;
			}

			&-actions {
				flex: 1;
				align-self: flex-end;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-end;
				gap: 8px;
				margin: 0;
				padding: 0;
				height: 100%;
			}

			&-action {
				img {
					width: 28px;
					height: 28px;
				}
			}
		}

		&-enableCloudCertificates {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			gap: 8px;
			background-color: #fff;
			padding: 8px;

			.MuiFormControlLabel-root {
				margin-right: 0 !important;
			}
		}

		&-cloudCertificatesDescription,
		&-taugorWebPkiDescription {
			padding: 8px;
			text-align: center;
			border-radius: 8px;
			padding: 16px;
			opacity: 0.5;
			transition: opacity 0.5s ease-in-out;

			&[data-selected='true'] {
				opacity: 1;
			}
		}

		&-cloudCertificatesDescription {
			background-color: $l-yellow;
			color: #fff;
		}

		&-taugorWebPkiDescription {
			background-color: $grayN;
			color: #605c77;
		}

		&-uploadNewCertificate {
			text-transform: none !important;
			background-color: var(--button-color) !important;
			color: var(--text-button-color) !important;
			padding: 8px 24px !important;
			height: fit-content !important;

			&-icon {
				border-radius: 50%;
				width: 32px;
				height: 32px;
				background-color: rgba(0, 0, 0, 0.3);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}

	&-progressContainer {
		width: 100%;
		margin: 8px 0;
	}

	&-button {
		padding: 16px;
		border-radius: 8px;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
		outline: none;
		border: none;
		cursor: pointer;
	}

	&-moreStorageButton,
	&-upgradePlanButton {
		background-color: #54ae4a;
	}

	&-cancelPlanButton {
		background-color: #da0c20;
	}

	&-buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 8px;
		width: 100%;
	}
}
