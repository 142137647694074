@import 'animations';
@import 'mediaQueries';
.social-shares {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 0 16px;
	.social-share-brand {
		background: #fff;
		// box-shadow: 1px 1px 5px #5a5a5a;
		margin-right: 8px;
		margin-bottom: 8px;
		transition: transform 300ms ease, box-shadow 300ms ease;
		cursor: pointer;
		border-radius: 8px;
		&:hover {
			transform: translate3d(-2px, -2px, -2px);
			box-shadow: 5px 5px 5px #99b6a2;
		}
		.brand-name {
			font-size: 14px;
			display: none;
		}
		.brand-icon {
			img {
				width: 50px;
				height: 50px;
			}
		}
	}
}
