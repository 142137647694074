.hidden-on-mobile {
	@media #{$mobile_portrait_landscape} {
		display: none !important;
	}
}

// #root {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: stretch;
// 	align-content: stretch;
// 	height: 100vh;
// 	width: 100vw;
// }

// #container {
// 	display: flex;
// 	flex-direction: row;
// 	align-items: stretch;
// 	align-content: stretch;
// 	flex: 1;
// 	height: 100vh;
// 	width: 100vw;
// }
// main {
// 	flex: 1;
// 	display: flex;
// 	flex-direction: column;
// 	align-items: stretch;
// 	align-content: stretch;
// 	flex: 1;
// 	width: 0;
// }
// #spinner-container {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	background-color: rgba(0, 0, 0, 0.1);
// 	backdrop-filter: blur(5px);
// 	transition: backdrop-filter 1000ms ease;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	opacity: 0;
// 	animation: showLoadingContainer 2s 1 linear forwards;
// 	z-index: 1000000000000;
// 	svg {
// 		font-size: 100px;
// 		animation: loading 500ms infinite linear;
// 		color: rgba($green, 0.5);
// 	}
// }
// * {
// 	&::-webkit-scrollbar {
// 		position: absolute;
// 		background: transparent;
// 		width: 10px;
// 		height: 10px;
// 		border: 0;
// 		transition: background-color 300ms ease-out;
// 	}
// 	&::-webkit-scrollbar-track {
// 		background: transparent;
// 		background-color: transparent;
// 		background-color: $scroll-bar-background;
// 		background-color: inherit;
// 		width: 0;
// 		border: 0;
// 	}
// 	&::-webkit-scrollbar-thumb {
// 		// background-color: transparent;
// 		// outline: 0 solid slategrey;
// 		background-color: $scroll-bar-background;
// 		border-radius: 3px;
// 		outline: 0 solid slategrey;
// 	}
// 	&:hover {
// 		&::-webkit-scrollbar {
// 			position: absolute;
// 			background: transparent;
// 			width: 10px;
// 			height: 10px;
// 			border: 0;
// 		}
// 		&::-webkit-scrollbar-track {
// 			background: transparent;
// 			background-color: transparent;
// 			background-color: $scroll-thumb-background;
// 			background-color: inherit;
// 			width: 0;
// 			border: 0;
// 		}
// 		&::-webkit-scrollbar-thumb {
// 			// background-color: transparent;
// 			// outline: 0 solid slategrey;
// 			background-color: $scroll-thumb-background;
// 			border-radius: 3px;
// 			outline: 0 solid slategrey;
// 		}
// 	}
// }

// $content-top-left-border-radius: 0;
// h1,
// h2,
// h3,
// h4,
// h5,
// h6 {
// 	font-weight: 400;
// 	margin-top: 0;
// 	margin-bottom: 15px;
// 	display: flex;
// 	align-items: center;
// 	.left-icon {
// 		font-size: 20px;
// 		margin-right: 15px;
// 		&.green,
// 		&[green] {
// 			color: $green;
// 		}
// 		&.red,
// 		&[red] {
// 			color: $red;
// 		}
// 		&.orange,
// 		&[orange] {
// 			color: $orange;
// 		}
// 		&.blue,
// 		&[blue] {
// 			color: $blue;
// 		}
// 		&.teal,
// 		&[teal] {
// 			color: $teal;
// 		}
// 	}
// 	.right-icon {
// 		margin-left: 15px;
// 		font-size: 20px;
// 		height: 30px;
// 		width: 30px;
// 		box-sizing: border-box;
// 		border-radius: 15px;
// 		background: $blueIcon;
// 		color: $white;
// 		display: flex;
// 		align-items: center;
// 		align-content: center;
// 		justify-content: center;
// 		cursor: pointer;
// 		box-shadow: 0 0 0 transparent;
// 		transition: transform 300ms ease-out, background-color 300ms ease, box-shadow 300ms;
// 		&:hover {
// 			transform: translate3d(-3px, -3px, -3px);
// 			box-shadow: 3px 3px 5px $shadow-b;
// 			background: darken($blueIcon, 5%);
// 			color: $white;
// 		}
// 		&.green,
// 		&[green] {
// 			background: $green;
// 			color: $white;
// 		}
// 		&.red,
// 		&[red] {
// 			background: $red;
// 			color: $white;
// 		}
// 		&.orange,
// 		&[orange] {
// 			background: $orange;
// 			color: $white;
// 		}
// 		&.blue,
// 		&[blue] {
// 			background: $blue;
// 			color: $white;
// 		}
// 		&.teal,
// 		&[teal] {
// 			background: $teal;
// 			color: $white;
// 		}
// 		&.with-text {
// 			width: auto;
// 			padding: 0 10px;
// 			font-size: 14px;
// 			line-height: 14px;
// 			svg {
// 				margin-right: 6px;
// 			}
// 		}
// 		svg {
// 			font-size: 20px;
// 		}
// 	}
// }

// #page-header {
// 	& > h3 {
// 		color: $light-teal;
// 		font-size: 24px;
// 		font-weight: lighter;
// 		svg {
// 			font-size: 24px;
// 			margin-right: 10px;
// 		}
// 	}
// }
// .entity-management {
// 	display: flex;
// 	flex-wrap: wrap;
// 	flex-direction: row;
// 	align-content: stretch;
// 	align-items: stretch;
// 	flex: 1;

// 	@media #{$mobile_portrait_landscape} {
// 		flex-direction: column;
// 	}

// 	#list-panel {
// 		// flex-basis: 70%;
// 		flex: 1;
// 		overflow-y: scroll;
// 		@media #{$mobile_portrait_landscape} {
// 			max-height: 1000vh !important;
// 			max-width: 100vw;
// 			flex: none;
// 		}
// 	}
// 	#form-panel {
// 		flex: 1;
// 		box-sizing: border-box;
// 		padding: 15px;
// 		border-left: 1px solid $grayM;
// 		box-sizing: border-box;
// 		transition: transform 400ms ease;
// 		overflow-y: scroll;
// 		@media #{$mobile_portrait_landscape} {
// 			transform: translate3d(0, 120vh, 0);
// 			pointer-events: none;
// 			position: fixed;
// 			&.visible {
// 				position: fixed;
// 				top: 0;
// 				left: 0;
// 				right: 0;
// 				bottom: 0;
// 				transform: translate3d(0, 0, 0);
// 				max-height: 1000vh !important;
// 				z-index: 10000;
// 				background: $white;
// 				overflow-y: scroll;
// 				pointer-events: all;
// 				// border-top: 1px solid $grayM;
// 				header.form-panel-header {
// 					box-shadow: none;
// 				}
// 				.form-panel-header {
// 					display: flex !important;
// 					flex-direction: row;
// 					align-items: center;
// 					h1,
// 					h2,
// 					h3,
// 					h4,
// 					h5 {
// 						flex: 1;
// 					}
// 					.header-button {
// 						height: 50px;
// 						width: 50px;
// 						background: $yellow;
// 						color: $grayB;
// 						border-radius: 30px;
// 						display: flex;
// 						justify-content: center;
// 						align-items: center;
// 						margin-left: 15px;
// 						font-size: 25px;
// 						min-width: 50px;
// 						transition: transform 500ms ease, box-shadow 500ms ease;
// 						&.save,
// 						&[save] {
// 							background: $teal;
// 							color: $white;
// 						}
// 					}
// 				}
// 			}
// 		}
// 		.form-panel-header {
// 			display: none;
// 		}
// 		.fab-button {
// 			position: fixed;
// 			top: auto;
// 			left: auto;
// 			right: 20px;
// 			bottom: 20px;
// 			height: 60px;
// 			width: 60px;
// 			background: $blueIcon;
// 			color: $white;
// 			border-radius: 30px;
// 			display: flex;
// 			justify-content: center;
// 			align-items: center;
// 			margin-left: 15px;
// 			transition: transform 500ms ease, box-shadow 500ms ease;

// 			&:hover {
// 				box-shadow: $shadow;
// 			}
// 		}
// 	}
// }

// .form-control-input {
// 	display: block;
// 	margin-bottom: 15px;
// 	position: relative;
// 	pointer-events: none;
// 	&.file-type {
// 		cursor: pointer;
// 		.file {
// 			position: absolute;
// 			top: 0;
// 			left: 50px;
// 			right: 0;
// 			padding: 10px;
// 			display: flex;
// 			align-items: center;
// 			cursor: pointer;
// 			background: $white;
// 			border: 1px solid $grayK;
// 			border: 0;
// 			border-radius: 4px;
// 			box-shadow: 10px 10px 30px $shadow-a;
// 			svg {
// 				margin-left: -45px;
// 				font-size: 24px;
// 				margin-right: 30px;
// 				color: $blueA;
// 				color: $grayE;
// 			}
// 		}
// 		input,
// 		label,
// 		button {
// 			cursor: pointer !important;
// 		}
// 	}
// 	&.label-from-left {
// 		border: 1px dashed transparent;
// 		margin-bottom: 0;
// 		cursor: pointer;
// 		border-radius: 30px;
// 		background: transparent;
// 		border-bottom: 1px dashed transparent;

// 		@media #{$mobile_portrait_landscape} {
// 			.content {
// 				label {
// 					top: -10px;
// 					position: absolute !important;
// 				}
// 				input {
// 					margin-top: 5px;
// 					margin-bottom: 0;
// 					height: 30px;
// 					margin-top: 15px;
// 				}
// 			}
// 		}
// 		&:hover {
// 			cursor: pointer;
// 			border: 1px dashed $grayM;
// 		}
// 		&.multiline {
// 			padding-bottom: 25px;
// 			.content {
// 				label {
// 					position: absolute;
// 					top: -10px;
// 				}
// 			}
// 			.message {
// 				top: 90px;
// 			}
// 		}
// 		.content {
// 			display: flex;
// 			align-content: flex-start;
// 			flex-direction: row-reverse;
// 			textarea,
// 			input {
// 				background: transparent;
// 				border-radius: 0;
// 				color: $grayC;
// 				border: 0 solid $blueC;
// 				border-bottom: 0 !important;
// 				font-size: 14px;
// 				color: $blueA;
// 				cursor: pointer;
// 			}
// 			textarea {
// 				padding: 10px 10px 10px 10px !important;
// 				margin-left: 55px;
// 				margin-top: 28px;
// 				// min-height: 60px;
// 				// height: 60px;
// 				line-height: 20px;
// 				background: $white;
// 				margin-right: 10px;
// 				border-radius: 10px;
// 				min-height: 60px;
// 				max-height: 60px;
// 			}

// 			label {
// 				font-size: 14px;
// 				color: $grayG;
// 				opacity: 1;
// 				position: relative;
// 				word-wrap: none;
// 				white-space: nowrap;
// 				margin-right: 10px;
// 				pointer-events: all;
// 				&::after {
// 					content: ':';
// 				}
// 			}
// 			.icon {
// 				svg {
// 					color: $blueIcon;
// 					border: 1px solid $blueIcon;
// 					padding: 10px;
// 					border-radius: 25px;
// 					font-size: 20px;
// 					height: 20px;
// 					width: 20px;
// 				}
// 			}
// 		}
// 		.message {
// 			background: transparent;
// 			position: absolute;
// 			top: 30px;
// 			left: 55px;
// 			color: $grayE;
// 		}
// 	}
// 	&.outlined {
// 		.content {
// 			label {
// 				left: 10px;
// 			}
// 			textarea,
// 			input {
// 				border: 1px solid $grayM;
// 				border-radius: 4px;
// 				line-height: 20px;
// 				padding-left: 10px;
// 				background: $white;
// 				border-bottom: 1px solid $grayM;
// 			}
// 		}
// 		&.top-label {
// 			.content {
// 				input {
// 					border-bottom: 1px solid $grayM;
// 				}
// 			}
// 		}
// 	}
// 	&.filled {
// 		label {
// 			left: 10px !important;
// 			color: $grayH;
// 		}
// 		textarea,
// 		input {
// 			border: 1px solid $grayN !important;
// 			background: $grayO;
// 			line-height: 20px;
// 			border-radius: 4px;
// 			padding-left: 10px !important;
// 			box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.05);
// 			// border-bottom: 0 !important;
// 		}
// 		border-bottom: 0;
// 	}
// 	&.has-left-icon {
// 		.content {
// 			label {
// 				left: 60px;
// 			}

// 			textarea,
// 			input {
// 				line-height: 20px;
// 				padding-left: 60px;
// 			}
// 		}
// 	}
// 	&.has-right-icon {
// 		textarea,
// 		input {
// 			padding-right: 60px;
// 			line-height: 20px;
// 		}
// 	}

// 	.icon {
// 		position: absolute;
// 		top: 0;
// 		left: -1px;
// 		height: 50px;
// 		width: 50px;
// 		// background: $grayM;
// 		color: $grayC;
// 		border-radius: 25px;
// 		display: flex;
// 		align-items: center;
// 		align-content: center;
// 		justify-content: center;
// 		z-index: 100;
// 		cursor: pointer;
// 		pointer-events: all;
// 		&.right-icon {
// 			left: auto;
// 			right: -1px;
// 		}
// 		svg {
// 			font-size: 24px;
// 			padding: 0;
// 			margin: 0;
// 			width: 24px;
// 			height: 24px;
// 		}
// 	}

// 	label {
// 		height: 50px;
// 		line-height: 50px;
// 		position: absolute;
// 		transition: opacity 500ms ease, transform 400ms ease, font-size 400ms ease;
// 		left: 20px;
// 		z-index: 1;
// 		font-size: 10px;
// 		opacity: 0;
// 	}
// 	textarea,
// 	input {
// 		position: relative;
// 		height: 50px;
// 		line-height: 50px;
// 		box-sizing: border-box;
// 		padding: 0 20px;
// 		width: 100%;
// 		z-index: 0;
// 		pointer-events: auto;
// 		outline: none;
// 		border: 0;
// 		background: transparent;
// 		border-bottom: 1px solid $grayM;
// 		border-radius: 0;
// 		font-size: 20px;
// 		color: $black;

// 		&:placeholder-shown ~ label {
// 			font-size: 16px;
// 			opacity: 1;
// 		}
// 		&:focus ~ label {
// 			transform: translate3d(0, 0, 0);
// 			font-size: 10px;
// 			opacity: 0;
// 			font-weight: 700;
// 		}
// 		&[type='file'] {
// 			opacity: 0;
// 		}
// 	}
// 	textarea {
// 		box-sizing: border-box;
// 		padding: 25px 10px 5px 10px;
// 		line-height: 20px;
// 		min-height: 100px;
// 		max-height: 500px;
// 		pointer-events: all;
// 		height: auto;
// 	}
// 	.message {
// 		padding: 5px;
// 		font-size: 12px;
// 		background: $grayN;
// 		margin-top: -5px;
// 		padding-top: 10px;
// 		border-radius: 3px;
// 	}
// 	&.top-label {
// 		&.outlined {
// 			.content {
// 				textarea,
// 				input {
// 					padding-left: 10px;
// 				}
// 				&.always-shown {
// 					& textarea:focus ~ label,
// 					& textarea:placeholder-shown:focus ~ label,
// 					& input:focus ~ label,
// 					& input:placeholder-shown:focus ~ label,
// 					label {
// 						transform: translate3d(10px, -14px, 0);
// 					}
// 					& textarea:placeholder-shown ~ label,
// 					& input:placeholder-shown ~ label {
// 						transform: translate3d(10px, -14px, 0);
// 					}
// 				}

// 				& textarea:focus ~ label,
// 				& textarea:placeholder-shown:focus ~ label,
// 				& input:focus ~ label,
// 				& input:placeholder-shown:focus ~ label,
// 				label {
// 					transform: translate3d(10px, -14px, 0);
// 				}
// 				& textarea:placeholder-shown ~ label,
// 				& input:placeholder-shown ~ label {
// 					transform: translate3d(10px, 0, 0);
// 				}
// 			}
// 		}
// 		.content {
// 			textarea,
// 			input {
// 				// background: $grayM;
// 				font-size: 14px;
// 				padding-top: 15px;
// 				line-height: 14px;
// 				padding-left: 0px;
// 				border-bottom: 1px solid $grayC;
// 			}
// 			textarea {
// 				padding-top: 25px;
// 			}
// 			&.always-shown {
// 				& textarea:focus ~ label,
// 				& textarea:placeholder-shown:focus ~ label,
// 				& input:focus ~ label,
// 				& input:placeholder-shown:focus ~ label,
// 				label {
// 					transform: translate3d(0, -14px, 0);
// 					font-size: 12px;
// 					opacity: 1;
// 					left: 0px;
// 					font-weight: normal;
// 				}
// 				& textarea:placeholder-shown ~ label,
// 				& input:placeholder-shown ~ label {
// 					transform: translate3d(0, -14px, 0);
// 				}
// 			}

// 			& textarea:focus ~ label,
// 			& textarea:placeholder-shown:focus ~ label,
// 			& input:focus ~ label,
// 			& input:placeholder-shown:focus ~ label,
// 			label {
// 				transform: translate3d(0, -14px, 0);
// 				font-size: 12px;
// 				opacity: 1;
// 				left: 0px;
// 				font-weight: normal;
// 			}
// 			& textarea:placeholder-shown ~ label,
// 			& input:placeholder-shown ~ label {
// 				transform: translate3d(0, 0, 0);
// 			}
// 		}
// 	}
// }

// .content,
// content {
// 	.MuiTextField-root {
// 		display: block;
// 		margin-bottom: 15px;
// 		.MuiInputBase-root {
// 			background: $white;
// 			display: block;
// 			width: auto;
// 			box-sizing: border-box;
// 			.MuiSelect-root,
// 			.MuiInputBase-input {
// 				width: auto;
// 			}
// 		}
// 	}
// }

// content {
// 	display: flex;
// 	flex-direction: column;
// 	align-items: stretch;
// 	align-content: stretch;
// 	flex: 1;
// 	width: 100%;
// 	background: $background;
// 	border-top-left-radius: $content-top-left-border-radius;
// 	max-height: calc(100% - 0px);
// 	overflow-y: scroll;
// 	box-sizing: border-box;
// 	&.relative {
// 		position: relative;
// 	}
// 	& > header {
// 		padding: 3vh;
// 		display: flex;
// 		flex-direction: row;
// 		align-items: flex-start;
// 		@media #{$mobile_portrait_landscape} {
// 			flex-direction: column;
// 			align-items: stretch;
// 			z-index: 10;
// 			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
// 			h3 {
// 				font-size: 25px !important;
// 			}
// 		}
// 		.header-buttons {
// 			display: flex;
// 			min-width: 300px;
// 			justify-content: flex-end;
// 			@media #{$mobile_portrait_landscape} {
// 				padding: 0 15px;
// 				box-sizing: border-box;
// 				margin-top: 15px;
// 			}

// 			.header-button {
// 				cursor: pointer;
// 				height: 50px;
// 				width: 50px;
// 				background: $yellow;
// 				color: $grayB;
// 				border-radius: 30px;
// 				display: flex;
// 				justify-content: center;
// 				align-items: center;
// 				margin-left: 15px;
// 				transition: transform 500ms ease, box-shadow 500ms ease;
// 				&:hover {
// 					box-shadow: 10px 10px 10px $shadow-a;
// 					transform: scale3d(1.2, 1.2, 1.2);
// 				}
// 				&.save,
// 				&[save] {
// 					background: $teal;
// 					color: $white;
// 				}
// 				&.delete,
// 				&[delete] {
// 					background: $redB;
// 					color: $white;
// 				}
// 				&.with-text {
// 					min-width: 50px;
// 					width: auto;
// 					padding: 0 15px;
// 					svg {
// 						margin-right: 5px;
// 					}
// 				}
// 				@media #{$mobile_portrait_landscape} {
// 					height: 50px;
// 					width: 50px;
// 					border-radius: 25px;
// 					font-size: 15px;
// 					// background: $white;
// 				}

// 				svg {
// 					font-size: 24px;
// 				}
// 			}
// 		}
// 	}
// }

// .profile-button {
// 	background-color: $blueA;
// 	padding: 10px;
// 	border-radius: 5px;
// 	cursor: pointer;
// 	box-shadow: 0 0 0 transparent;
// 	transition: background-color 400ms ease, box-shadow 400ms ease, transform 400ms ease;
// 	margin-left: 5px;
// 	&:hover {
// 		// background-color: rgba(0, 0, 0, 0.3);
// 		box-shadow: 10px 10px 10px $shadow-a;
// 		transform: translate3d(-2px, -2px, -2px);
// 	}
// 	span {
// 		color: $white;
// 		margin-right: 15px;
// 		top: -1px;
// 		display: inline-block;
// 		position: relative;
// 	}
// 	span,
// 	div,
// 	p,
// 	svg {
// 		color: $white !important;
// 	}
// }
// .floating-message {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	pointer-events: none;
// 	background: rgba(0, 0, 0, 0.3);
// 	display: flex;
// 	align-items: center;
// 	align-content: center;
// 	justify-content: center;
// 	z-index: 10000000;
// 	transition: opacity 500ms ease;
// 	opacity: 0;
// 	&.redux-message {
// 		z-index: 1000000000;
// 	}
// 	&.visible {
// 		opacity: 1;
// 		pointer-events: all;

// 		.content {
// 			transform: translate3d(0, 0, 0);
// 		}
// 	}

// 	& > .content {
// 		background: $white;
// 		padding: 0;
// 		border-radius: 10px;
// 		border-radius: 0;
// 		box-shadow: $shadow;
// 		box-shadow: 10px 10px 50px $shadow-a;
// 		transform: translate3d(0, 2000px, 0);
// 		transition: transform 600ms ease-out;
// 		min-width: 300px;
// 		max-width: 50vw;
// 		max-height: 85vh;
// 		box-sizing: border-box;
// 		padding-bottom: 60px;
// 		position: relative;
// 		display: flex;
// 		flex-direction: column;
// 		align-items: stretch;

// 		@media #{$mobile_portrait_landscape} {
// 			width: 98vw;
// 			height: 98vh;
// 			max-width: 100vw;
// 			box-sizing: border-box;
// 		}

// 		& > .header {
// 			// display: none;
// 			// padding: 20px;
// 			height: auto;
// 			margin-bottom: 10px;
// 			margin-bottom: 0;
// 			display: flex;
// 			justify-content: flex-start;
// 			align-items: center;
// 			align-content: center;
// 			background: $white;
// 			padding: 10px;
// 			box-shadow: inset 0 0 10px $shadow-a;
// 			// border-top-left-radius: 10px;
// 			// border-top-right-radius: 10px;
// 			border-top-left-radius: 0;
// 			border-top-right-radius: 0;
// 			.close {
// 				// display: none;
// 				cursor: pointer;
// 				svg {
// 					color: $white;
// 					color: $light-teal;
// 					font-size: 30px;
// 				}
// 				&:hover {
// 					svg {
// 						color: $teal;
// 					}
// 				}
// 				@media #{$mobile_portrait_landscape} {
// 					display: block;
// 					height: 40px;
// 					width: 40px;
// 					min-width: 40px;
// 					// background: $yellow;
// 					// color: $grayB;
// 					border-radius: 20px;
// 					display: flex;
// 					justify-content: center;
// 					align-items: center;
// 					margin-left: 10px;
// 				}
// 			}
// 			.title {
// 				// font-size: 20px;
// 				font-size: 16px;
// 				font-weight: normal;
// 				text-transform: none;
// 				font-family: Roboto;
// 				flex: 1;
// 				margin-right: 15px;
// 				margin-bottom: 0;
// 				color: $white;
// 				color: $teal;
// 			}
// 		}
// 		& > .description {
// 			padding: 20px;
// 			font-size: 16px;
// 			font-weight: lighter;
// 			margin-bottom: 15px;
// 			margin-bottom: 0;
// 			// max-height: 60vh;
// 			// max-height: calc(60% - 0px);
// 			// height: calc(60% - 0px);
// 			overflow: auto;
// 			.buttons {
// 				position: absolute;
// 				top: auto;
// 				bottom: 10px;
// 			}
// 		}

// 		.buttons {
// 			display: flex;
// 			align-items: center;
// 			align-content: flex-end;
// 			justify-content: flex-end;
// 			z-index: 1000;
// 			position: absolute;
// 			bottom: 10px;
// 			left: 10px;
// 			right: 10px;
// 		}
// 	}
// }
// .hidden-on-mobile {
// 	@media #{$mobile_portrait_landscape} {
// 		display: none !important;
// 	}
// }

// // No selectable element
// .no-selectable {
// 	@include disable-selection;
// }
// .info-field {
// 	padding: 15px;
// 	display: flex;
// 	flex-direction: column;
// 	label {
// 		font-size: 14px;
// 		color: $grayF;
// 		margin-bottom: 5px;
// 	}
// }

// .columns {
// 	display: flex;
// 	flex-direction: row;
// 	align-items: stretch;
// 	align-content: center;
// 	.column {
// 		flex: 1;
// 	}
// }

// .simple-filled-button {
// 	background-color: $grayN !important;
// 	&:hover {
// 		background-color: $grayM !important;
// 	}
// }
// .simple-red-button {
// 	background-color: $red !important;
// 	color: $white !important;
// 	&:hover {
// 		background-color: $redB !important;
// 		color: $white !important;
// 	}
// }
// .simple-green-button {
// 	background-color: $greenC !important;
// 	color: $white !important;
// 	&:hover {
// 		background-color: $green !important;
// 		color: $white !important;
// 	}
// }

// simple-list-item,
// .simple-list-item {
// 	padding: 10px;
// 	border-bottom: 1px solid $grayM;
// 	color: $grayE;
// 	&.selected {
// 		background: $yellow;
// 	}
// 	&.clickable {
// 		cursor: pointer;
// 	}
// 	&.with-columns {
// 		display: flex;
// 		flex-direction: row;
// 		align-items: center;
// 	}
// 	.icon {
// 		&.green {
// 			color: $green;
// 		}
// 		&.red {
// 			color: $red;
// 		}
// 		svg {
// 			font-size: 20px;
// 		}
// 	}
// 	.right-icon {
// 		margin-left: 10px;
// 	}
// 	.left-icon {
// 		margin-right: 10px;
// 	}
// 	.line {
// 		font-size: 14px;
// 		padding: 2px 0;
// 		&.bold {
// 			font-weight: bolder;
// 		}
// 		&.small {
// 			font-size: 12px;
// 		}
// 		&.title {
// 			font-size: 18px;
// 		}
// 		&.gray {
// 			color: $grayF;
// 		}
// 	}
// }

// .MuiPopover-root {
// 	z-index: 1000000000 !important;
// }

// .simple-button,
// simple-button {
// 	display: flex;
// 	align-items: center;
// 	align-content: center;
// 	padding: 10px 15px;
// 	height: 40px;
// 	box-sizing: border-box;
// 	margin-top: 3px;
// 	border-radius: 4px;
// 	background: $simple-button-default-background;
// 	color: $simple-button-default-color;
// 	text-transform: uppercase;
// 	font-size: 14px;
// 	font-weight: bold;
// 	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
// 	cursor: pointer;
// 	margin-right: 10px;
// 	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
// 	transition: box-shadow 400ms ease, transform 400ms ease;
// 	transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
// 	position: relative;
// 	animation: showItem 400ms 1;
// 	@include disable-selection;
// 	@media #{$mobile_portrait_landscape} {
// 		font-size: 12px;
// 		height: auto;
// 	}
// 	&:hover {
// 		box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
// 		transform: translate3d(-3px, -3px, 0) scale3d(1, 1, 1);
// 	}
// 	&[save] {
// 		background: $teal;
// 		color: $white;
// 	}
// 	&[clear] {
// 		background: $red;
// 		color: $white;
// 	}
// 	&[iconed] {
// 		width: 45px;
// 		display: flex;
// 		align-items: center;
// 		align-content: center;
// 		justify-content: center;
// 		svg {
// 			margin-right: 0;
// 		}
// 	}
// 	&[tooltip] {
// 		&::after {
// 			top: auto;
// 			bottom: calc(100% + 5px);
// 		}
// 	}

// 	&.mouse-down {
// 		&::after {
// 			content: '';
// 			display: block;
// 			background: inherit;
// 			border-radius: inherit;
// 			position: absolute;
// 			// width: inherit;
// 			// height: inherit;
// 			top: 0;
// 			left: 0;
// 			right: 0;
// 			bottom: 0;
// 			z-index: -1;
// 			animation: callAttention 500ms 1;
// 		}
// 	}
// 	svg {
// 		margin-right: 8px;
// 	}
// }

main {
	margin-top: $ultra-bar-height;
	box-sizing: border-box;
}

// #app-bar {
// 	background: $white;
// 	background: transparent;
// 	box-shadow: inset 0 -10px 20px rgba(0, 0, 0, 0.03);
// 	transition: transform 400ms ease, left 50ms ease;
// 	left: 66px;
// 	right: 0;
// 	width: auto;
// 	z-index: 100;
// 	&.drawer-opened {
// 		left: 250px;
// 	}
// 	@media #{$mobile_portrait_landscape} {
// 		left: 0;
// 	}
// 	.MuiPopover-paper {
// 		top: 20px !important;
// 	}
// }
// #app-bar-toolbar {
// 	background: $white;
// 	border-top-left-radius: 10px;
// 	box-shadow: inset 0 -10px 20px rgba(0, 0, 0, 0.03);
// 	h1,
// 	h2,
// 	h3,
// 	h4,
// 	h5,
// 	h6,
// 	h7,
// 	span,
// 	div {
// 		color: $grayC;
// 	}
// 	#drawer-toggler-button {
// 		display: none;
// 	}
// 	@media #{$mobile_portrait_landscape} {
// 		#drawer-toggler-button {
// 			display: block;
// 		}
// 	}
// }
#app-drawer {
	transition: width 50ms ease;
	overflow: hidden;
	border-right: 0;
	z-index: 10000;
	margin-top: 40px;
	&.closed {
		width: 65px;
		.MuiPaper-root {
			width: 65px;
		}
		.MuiListItemText-root {
			width: 0;
			overflow: hidden;
			min-height: 20px;
			height: auto;
		}
	}
	.MuiListItemText-root {
		min-height: 20px;
		height: auto;
		span {
			font-size: 14px;
			white-space: nowrap;
		}
	}
	.language-selection {
		display: none;
		margin: 10px;
		.MuiFormControl-root {
			width: 100%;
		}
	}
	&.opened {
		width: 250px;
		.language-selection {
			display: block;
		}
		.menu-logo {
			&.custom-logo {
				height: 150px;
				width: 150px;
				margin: 10px 10px 10px 50px;
				border-radius: 90px;
				background: $white;
				img {
					margin-top: 0;
					margin-bottom: 0;
					margin-left: 0;
					height: 180px;
				}
			}
			img {
				height: 200px;
				margin-top: -20px;
				margin-bottom: -50px;
				margin-left: -25px;
				opacity: 1;
				&:hover {
					opacity: 1;
				}
			}
		}
		.MuiPaper-root {
			width: 250px;
		}
		[tooltip]::after {
			display: none;
		}
	}
	@media #{$mobile_portrait_landscape} {
		&.closed {
			width: 0;
			.MuiPaper-root {
				width: 0;
			}
		}
	}
	.menu-logo {
		&.custom-logo {
			width: 56px;
			height: 56px;
			margin-top: 5px;
			margin-left: 5px;
			overflow: hidden;
			border-radius: 30px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			background: $white;
			img {
				opacity: 1;
				margin-left: 0;
				height: 60px;
				filter: none;
				&:hover {
					filter: none;
				}
			}
		}
		img {
			height: 80px;
			margin-left: -7px;
			opacity: 0.5;
			cursor: pointer;
			outline: none;
			transition: opacity 500ms ease-out, height 300ms ease-out, width 300ms ease-out, margin 300ms ease-out, filter 500ms ease-out;
			&:focus {
				outline: none;
			}
			&:hover {
				opacity: 1;
				filter: drop-shadow(4px 4px 2px $shadow-b);
			}
		}
	}

	.MuiListItem-root {
		position: relative;
		overflow-y: auto;
		overflow-x: hidden;
		padding-left: 20px !important;
		color: inherit;
		&.current-item {
			.MuiListItemIcon-root svg {
				color: inherit;
			}
		}
		&::after {
			margin-top: 0px;
			left: 50px;
		}
		&:hover .MuiListItemIcon-root svg {
			color: inherit;
			transform: translate3d(-2px, -2px, -2px);
			filter: drop-shadow(2px 2px 3px $grayC);
		}
		.MuiListItemIcon-root {
			color: inherit;
			svg {
				width: 20px;
				height: auto;
				color: inherit;
				transition: transform 300ms ease, filter 300ms ease;
			}
		}
	}
	span.MuiBadge-badge {
		background: $yellow;
		color: $black !important;
		top: 4px;
		border: 0;
		right: -8px;
	}
	.MuiPaper-root {
		background: transparent;
		background-color: inherit;
		transition: width 50ms ease;
		border-right: 0;
		width: 250px;
		color: inherit;
		top: $ultra-bar-height;
		@media #{$mobile_portrait_landscape} {
			background-color: inherit;
		}
		span,
		div,
		svg,
		p {
			color: inherit;
		}
	}

	@media #{$mobile_portrait_landscape} {
		width: 0;
		position: fixed;
		z-index: 10000;
		.drawer-content {
			width: 0;
		}
	}
}
// .tips {
// 	display: flex;
// 	flex-direction: column;
// 	.tip {
// 		font-size: 12px;
// 		margin-bottom: 5px;
// 		span {
// 			padding: 2px;
// 			background: $blueA;
// 			color: $grayB;
// 			border-radius: 4px;
// 		}
// 	}
// }

// [urlTooltip],
// [tooltip] {
// 	&::after {
// 		content: attr(tooltip);
// 		position: fixed;
// 		margin-top: 10px;
// 		// position: absolute;
// 		// top: auto;
// 		// left: 20px;
// 		// bottom: 45px;
// 		border-radius: 5px;
// 		font-size: 12px;
// 		background: $white;
// 		background: $tooltip-background;
// 		color: $tooltip-color;
// 		padding: 10px;
// 		box-shadow: 4px 4px 15px $shadow-a;
// 		z-index: 100;
// 		pointer-events: none;
// 		white-space: pre-wrap;
// 		opacity: 0;
// 		transition: opacity 500ms ease;
// 	}
// 	&:hover {
// 		&::after {
// 			animation: showTooltip 500ms 1;
// 			opacity: 1;
// 		}
// 	}
// }

// .tabs {
// 	display: flex;
// 	flex-direction: row;
// 	align-items: stretch;
// 	align-content: center;
// 	margin-bottom: 0;
// 	min-height: 40px;
// 	// border-bottom: 8px solid $primary-color;

// 	margin-bottom: 0;
// 	@media #{$mobile_portrait_landscape} {
// 		border-bottom: 0;
// 	}
// 	.separator {
// 		flex: 1;
// 	}
// 	.tab {
// 		padding: 15px 20px;
// 		margin-right: 5px;
// 		display: flex;
// 		flex-direction: row;
// 		align-items: center;
// 		color: $grayC;
// 		cursor: pointer;
// 		font-size: 12px;
// 		font-weight: bold;
// 		text-transform: uppercase;

// 		border-radius: 4px;
// 		background: $white;
// 		background: transparent;
// 		transition: transform 300ms ease, box-shadow 300ms ease-out;
// 		// box-shadow: inset 0 -10px 10px desaturate(lighten($primary-color, 10%), 70%);
// 		transform-origin: bottom left;

// 		@media #{$mobile_portrait_landscape} {
// 			margin-right: 1px;
// 			font-size: 10px;
// 		}
// 		&.active {
// 			background: $white;
// 			color: $primary-color;
// 			box-shadow: 5px 5px 10px $shadow-a;
// 			// transform: translate3d(-2px, -2px, -2px);
// 			transform: translate3d(0, 0, 0);
// 			border-left: 10px solid $primary-color;
// 			border-bottom-left-radius: 0;
// 			border-bottom-right-radius: 0;
// 			// border-bottom: 10px solid $primary-color;
// 			&:hover {
// 				background: $grayO;
// 			}
// 		}
// 		&:hover {
// 			background: $grayO;
// 		}
// 		svg {
// 			margin-right: 10px;
// 		}
// 		.number {
// 			background: $white;
// 			color: $grayC;
// 			margin-left: 10px;
// 			border-radius: 3px;
// 			font-size: 18px;
// 			padding: 0 3px;
// 		}
// 	}
// 	.tab-button {
// 		background: $primary-color;
// 		color: $white;

// 		padding: 0 10px;
// 		display: flex;
// 		align-items: center;
// 		align-content: center;
// 		justify-content: center;
// 		border-radius: 4px;
// 		margin-top: 0;
// 		margin-right: 5px;
// 		margin-bottom: 5px;
// 		cursor: pointer;
// 		transition: box-shadow 400ms ease, transform 400ms ease;
// 		font-size: 12px;
// 		text-transform: uppercase;
// 		&:hover {
// 			box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
// 			transform: translate3d(-2px, -2px, -2px);
// 		}
// 		svg {
// 			margin-right: 10px;
// 			font-size: 16px;
// 		}
// 		&.disabled {
// 			pointer-events: none;
// 		}
// 		&.delete-button {
// 			background: $red;
// 		}
// 	}
// }

// .tab-content {
// 	flex: 1;
// 	margin-top: 20px;
// 	margin-top: 0;
// 	display: none;
// 	background: $white;
// 	padding: 15px;
// 	border-radius: 4px;
// 	box-shadow: 5px 10px 10px $shadow-a;
// 	position: relative;
// 	z-index: 100;
// 	max-height: 80vh;
// 	overflow-y: scroll;
// 	&.active {
// 		display: block;
// 	}
// }
// .floating-menu {
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	display: none;
// 	bottom: 0;
// 	z-index: 10000000000;
// 	background: rgba(255, 255, 255, 0);
// 	// backdrop-filter: blur(3px);
// 	pointer-events: all;
// 	pointer-events: none;
// 	&.visible {
// 		display: block;
// 	}
// 	.content {
// 		position: fixed;
// 		top: 100px;
// 		left: 50%;
// 		right: auto;
// 		bottom: auto;
// 		padding: 0;
// 		min-width: 200px;
// 		max-width: 300px;
// 		height: auto;
// 		max-height: 80vh;
// 		background: $floating-menu-background;
// 		box-shadow: 0 0 5px $shadow-b, 14px 14px 50px darken($shadow-b, 10%);
// 		border-radius: 4px;
// 		z-index: 10000000000;
// 		.items {
// 			display: flex;
// 			flex-direction: column;
// 			align-items: stretch;
// 			align-content: flex-start;
// 			.item {
// 				display: flex;
// 				flex-direction: row;
// 				align-items: center;
// 				align-content: flex-start;
// 				padding: 5px 10px;
// 				font-size: 14px;
// 				// word-break: break-all;
// 				word-break: normal;
// 				color: $grayE;
// 				// border-bottom: 1px solid $grayN;
// 				pointer-events: all;
// 				cursor: default;
// 				&:hover {
// 					background: $floating-menu-item-hover-background;
// 					.icon {
// 						color: $floating-menu-color;
// 					}
// 				}
// 				.name {
// 					flex: 1;
// 					margin-right: 10px;
// 					color: $floating-menu-color;
// 				}
// 				.icon {
// 					width: 30px;
// 					height: 30px;
// 					border-radius: 15px;
// 					display: flex;
// 					align-items: center;
// 					align-content: center;
// 					justify-content: center;
// 					font-size: 18px;
// 					color: $floating-menu-color;
// 					&.left-icon {
// 						margin-right: 10px;
// 					}
// 				}
// 			}
// 		}
// 	}
// }
// .local-loader {
// 	display: flex;
// 	align-items: center;
// 	align-content: center;
// 	justify-content: center;
// 	padding: 20px 0;
// 	animation: showLoadingContainer 1 linear forwards;
// 	svg {
// 		font-size: 20px;
// 		color: $greenB;
// 		animation: loading 500ms infinite linear;
// 	}
// }
// .local-message {
// 	font-size: 14px;
// 	margin: 10px;
// 	padding: 10px;
// 	background: $blueE;
// 	box-shadow: 5px 5px 10px $shadow-a;
// 	color: $blue7;
// 	border-radius: 4px;
// }
// content.trash {
// 	.files {
// 		padding: 15px;
// 	}
// }
// .background-tasks {
// 	position: fixed;
// 	top: auto;
// 	left: auto;
// 	right: 10px;
// 	bottom: 10px;
// 	background: $white;
// 	box-shadow: 4px 4px 20px $shadow-b;
// 	max-height: 200px;
// 	overflow-y: scroll;
// 	padding-top: 30px;
// 	min-width: 130px;
// 	z-index: 10000000;
// 	&.tasks-minimized {
// 		.task {
// 			display: none;
// 		}
// 		.header {
// 			.toggle-button {
// 				transform: rotate3d(0, 0, 1, -90deg);
// 			}
// 		}
// 	}
// 	.header {
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		right: 0;
// 		height: 30px;
// 		box-sizing: border-box;
// 		padding: 0;
// 		font-size: 12px;
// 		display: flex;
// 		align-items: center;
// 		& > span {
// 			flex: 1;
// 			padding: 5px;
// 		}
// 		.toggle-button {
// 			transform: rotate3d(0, 0, 1, 90deg);
// 			width: 20px;
// 			height: 20px;
// 			display: flex;
// 			align-items: center;
// 			align-content: center;
// 			justify-content: center;
// 			cursor: pointer;
// 			border-radius: 3px;
// 			&:hover {
// 				background: $grayM;
// 			}
// 		}
// 	}
// 	.task {
// 		padding: 10px;
// 		display: flex;
// 		align-items: center;
// 		border-bottom: 1px solid $grayM;
// 		.task-name {
// 			margin-right: 10px;
// 			font-size: 12px;
// 		}
// 		.task-loader {
// 			svg {
// 				animation: loading 300ms infinite linear;
// 			}
// 		}
// 	}
// }
// .MuiAutocomplete-popper {
// 	z-index: 10000000 !important;
// 	box-shadow: 5px 5px 16px $shadow-a;
// }
// .input-required-message {
// 	padding: 5px;
// 	font-size: 12px;
// 	background: $grayN;
// 	padding-top: 10px;
// 	margin-top: -20px;
// 	border-radius: 3px;
// }

// #auth-container {
// 	display: flex;
// 	flex-direction: row;
// 	section {
// 		position: relative;
// 		display: flex;
// 		align-items: center;
// 		padding: 35px;
// 		width: 500px;
// 		padding-left: 235px;
// 		margin-left: -200px;
// 		background: $white;
// 		height: 100vh;
// 		box-sizing: border-box;
// 		box-shadow: 0 0 10px $shadow-a;
// 		z-index: 100;
// 		transition: transform 400ms ease, box-shadow 400ms ease;
// 		&:hover {
// 			transform: translate3d(20px, 0, 0);
// 			box-shadow: 0 0 30px $shadow-b;
// 		}
// 		& > div:first-child {
// 			display: flex;
// 			flex-direction: column;
// 			.auth-button {
// 				background: $auth-button-background;
// 				color: $auth-button-color;
// 				align-self: flex-end;
// 			}
// 			.form-control-input {
// 				.icon {
// 					background: $auth-input-icon-background;
// 				}
// 				label {
// 					color: $auth-input-icon-color;
// 				}
// 				input {
// 					border-bottom: 0;
// 					position: relative;
// 					background: $grayM;
// 					background: $grayA;
// 					color: $white;
// 					border-radius: 25px;
// 					font-size: 20px;
// 					&:placeholder-shown ~ label {
// 						font-size: 20px;
// 						opacity: 1;
// 					}
// 					&:focus ~ label {
// 						transform: translate3d(0, 0, 0);
// 						font-size: 10px;
// 						opacity: 0;
// 						font-weight: 700;
// 					}
// 				}
// 			}
// 		}
// 		.logo {
// 			position: relative;
// 			margin: 0 -35px;
// 			display: flex;
// 			align-items: center;
// 			align-content: center;
// 			justify-content: center;
// 			margin-bottom: 15px;
// 			&.custom-logo {
// 				display: flex;
// 				align-items: center;
// 				align-content: center;
// 				justify-content: center;
// 				img {
// 					height: 200px !important;
// 					width: auto !important;
// 				}
// 			}
// 			img {
// 				width: 200px;
// 			}
// 		}
// 		.error {
// 			margin: 10px 0;
// 			padding: 10px;
// 			border-radius: 5px;
// 			background: $grayM;
// 			color: $grayF;
// 			font-size: 12px;
// 			font-weight: bold;
// 		}
// 	}
// }
// #right-container {
// 	background-size: cover;
// 	flex: 1;
// }
// .octadesk-octachat-app.octachatAppClose {
// 	left: 2px !important;
// 	bottom: 2px !important;
// }
// .MuiFormControl-root {
// 	.MuiSelect-select,
// 	.MuiSelect-selectMenu,
// 	.MuiInput-input,
// 	label.MuiInputLabel-shrink,
// 	fieldset,
// 	label,
// 	input {
// 		color: $black;
// 	}
// }
// .MuiTypography-root {
// 	color: $black;

// 	.MuiTypography-colorTextSecondary {
// 		color: darken($black, 20%);
// 	}
// }
// .table-controls {
// 	display: flex;
// 	align-items: center;
// 	align-content: center;
// 	margin-bottom: 20px;
// 	.prev,
// 	.next {
// 		background: $grayC;
// 		color: $grayK;
// 		padding: 0 5px;
// 		border-radius: 4px;
// 		cursor: pointer;
// 	}
// 	.prev {
// 		margin-right: 10px;
// 	}
// 	.next {
// 		margin-left: 10px;
// 		margin-right: 20px;
// 	}
// }
// .MuiTooltip-popper {
// 	z-index: 100000000 !important;
// 	.MuiTooltip-tooltip {
// 		border-radius: 5px;
// 		font-size: 12px;
// 		background: $tooltip-background;
// 		color: $tooltip-color;
// 		padding: 15px;
// 		box-shadow: 4px 4px 15px $shadow-a;
// 		pointer-events: none;
// 	}
// }
// .central-full-logo {
// 	display: flex;
// 	align-items: center;
// 	align-content: center;
// 	justify-content: center;
// 	top: 0;
// 	left: 0;
// 	width: 100vw;
// 	height: 100vh;
// 	position: fixed;
// 	background: $background;
// 	opacity: 0;
// 	animation: showLoadingContainer 2s 1 linear forwards;
// 	z-index: 1000000000000;
// 	&.not-spinning {
// 		z-index: 10001;
// 	}
// 	.dokia-logo {
// 		background: $primary-color;
// 		padding: 35px;
// 		max-width: 400px;
// 		max-height: 400px;
// 		width: 30vh;
// 		height: 30vh;
// 		border-radius: 50%;
// 		img {
// 			max-width: 400px;
// 			width: 30vh;
// 			height: auto;
// 		}
// 		z-index: 10000000000000;
// 	}
// 	.loading.icon {
// 		// position: absolute;
// 		position: relative;
// 		margin-left: -308px;
// 		max-width: 316px;
// 		max-height: 316px;
// 		width: 50vh;
// 		height: 50vh;
// 		z-index: 1;
// 		svg {
// 			max-width: 316px;
// 			max-height: 316px;
// 			width: 50vh;
// 			height: 50vh;
// 			animation: loading 1000ms infinite linear;
// 			color: $black;
// 		}
// 	}
// 	.content-container {
// 		max-width: 50vw;
// 		margin-left: 15px;
// 	}
// }
// .info-message {
// 	background: $info-message-background;
// 	color: $info-message-color;
// 	padding: 10px;
// 	border: 1px solid $info-message-color;
// 	margin-bottom: 10px;
// }
