.ShareExecutionFlow {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;

	&-warning {
		color: red;
	}
}
