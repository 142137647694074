.user-card {
	font-size: 12px;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 5px;
	box-shadow: 5px 5px 10px $shadow-a;
	margin-bottom: 10px;
	background: $white;
	cursor: default;
	transition: transform 300ms ease;
	border: 2px solid transparent;
	&.clickable {
		cursor: pointer;
	}
	&.inactive {
		color: $grayE;
		background: $grayN;
		box-shadow: none;
		// box-shadow: inset 5px 5px 10px $shadow-a;
	}
	&.selected {
		border: 2px solid $blueA;
		& > .header {
			.avatar {
				background: $blueA;
				color: $blueF;
			}
		}
	}
	&:hover {
		transform: translate3d(-3px, -3px, -3px);
	}
	& > .header {
		display: flex;
		align-items: center;
		align-content: flex-start;
		.avatar {
			height: 40px;
			width: 40px;
			background: $grayM;
			border-radius: 20px;
			margin-right: 10px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
		.user-info {
			flex: 1;
		}
	}
	h1,
	h2,
	h3,
	h4,
	h5 {
		margin-top: 10px;
		margin-bottom: 10px;
	}
}
