#modalFormManagementProtocol {
	background-color: #0000002d;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 999;

	display: flex;
	justify-content: center;
	align-items: self-start;
	padding: 1rem;
}

#modalFormManagementProtocol .contentModalForm {
	background-color: #fff;
	padding: 1rem;
	border-radius: 1rem;
}
#modalFormManagementProtocol .headerModalForm {
	display: flex;
	align-items: start;
	justify-content: space-between;
}
#modalFormManagementProtocol .headerModalForm button {
	background-color: transparent;
	border: none;
	outline: none;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 2px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}
#modalFormManagementProtocol .headerModalForm button:hover {
	background-color: red;
	color: #fff;
}

#areaButtonConfirmModalForm {
	display: flex;
	justify-content: end;
}
#areaButtonConfirmModalForm button {
	border: none;
	outline: none;
	color: #fff;
	background-color: rgb(8, 168, 128);
	padding: 0.3rem 1rem;
	border-radius: 4px;
	text-transform: uppercase;
	cursor: pointer;
}
#areaButtonConfirmModalForm button:hover {
	background-color: rgb(6, 133, 101);
}
