.areaButtons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	width: 100%;
}

@media screen and (max-width: 500px) {
	.areaButtons {
		flex-direction: column;
	}
	.areaButtons button {
		width: 100%;
	}
}
