.container * {
	margin: 0;
}
.content {
	padding: 1rem;

	max-width: 800px;
	width: 100%;
	margin: 0 auto;

	display: flex;
	flex-direction: column;
	gap: 1rem;
}
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.header h1 {
	font-size: 2rem;
}
.content img {
	width: 100%;
	max-height: 500px;
	object-fit: cover;
	border-radius: 1rem;
	margin: 0 auto;
	box-shadow: 0 0 8px #0000002a;
}
@media screen and (max-width: 768px) {
	.header {
		flex-direction: column;
		align-items: start;
	}
}
