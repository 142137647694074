.zero-collapsible {
	margin-bottom: 5px;
	border-bottom: 1px solid $grayM;
	.collapsible-header {
		padding: 4px;
		background: $white;
		color: $grayC;
		font-size: 12px;
		display: flex;
		align-items: center;
		align-content: flex-start;
		@include disable-selection();
		&.pointer {
			cursor: pointer;
			&.tertiary,
			.collapsible-text {
				padding-bottom: 0;
			}
		}
		&:not(.pointer) {
			padding-top: 0;
			&.tertiary,
			.collapsible-text {
				padding-top: 0;
			}
		}
		.collapsible-text {
			flex: 1;
			.primary {
				font-size: 16px;
			}
		}
		.icon {
			width: 40px;
			height: 40px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
		}
	}
	.collapsible-content {
		//
	}
}
