@import '../base/animations.scss';
@import '../utils/mixins';
@import '../utils/breakpoints';
@import '../utils/colors';
@import '../utils/themeApp';

main[data-route-key='auth'] {
	margin-top: 0;
	border-top-left-radius: 0;
}

$mobile-breakpoint: '(max-width : 600px)';

@mixin show-item-animation {
	animation: showItem 400ms 1;
}

.Auth {
	@extend .themeApp;

	position: relative;
	z-index: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: var(--secondary-color);

	@media #{$mobile-portrait-landscape} {
		background-color: #fff;
		overflow-y: auto;
	}

	@media #{$mobile-breakpoint} {
		background-color: #fff;
		overflow-y: auto;
	}

	&-left {
		position: relative;
		z-index: 10;
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
		flex: 1 1;
		background-color: var(--primary-color);

		@media #{$mobile-portrait-landscape} {
			width: 0;
		}

		@media #{$mobile-breakpoint} {
			width: 0;
		}

		&-header,
		&-footer {
			position: relative;
			z-index: 2;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			width: 100%;

			&-logo {
				width: 260px;

				&[data-portal='true'] {
					margin: 20px 60px;
				}

				@media #{$mobile-portrait-landscape} {
					width: 200px;
				}

				@media #{$mobile-breakpoint} {
					margin-left: -18px;
					width: 200px;
				}
			}

			&-links {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 15px;
				width: 100%;
				margin-bottom: 30px;

				li,
				a {
					color: #ffffff;
					font-size: 17px;
				}

				li {
					display: inline;
				}

				a {
					text-decoration: underline;

					&:hover {
						text-decoration: none;
					}
				}
			}

			@media #{$mobile-portrait-landscape} {
				position: absolute;
				width: 100vw;
			}

			@media #{$mobile-breakpoint} {
				position: absolute;
				width: 100vw;
			}
		}

		&-footer {
			@media #{$mobile-portrait-landscape} {
				display: none;
			}

			@media #{$mobile-breakpoint} {
				display: none;
			}
		}

		&-cover {
			position: absolute;
			height: 100%;
			width: 100%;
			left: 0%;
			z-index: 1;
			@include show-item-animation;
			@include disable-selection;
			@include disable-drag;

			@media #{$mobile-portrait-landscape} {
				display: none;
			}

			@media #{$mobile-breakpoint} {
				display: none;
			}
		}
	}

	&-right {
		position: relative;
		z-index: 2;
		width: 35%;
		height: 100%;
		background-color: var(--secondary-color);
		display: flex;
		align-items: center;
		justify-content: space-around;
		padding-top: 25px;

		@media #{$tablets-portrait-landscape} {
			width: 40%;
		}

		@media #{$mobile-portrait-landscape} {
			width: 100%;
			margin-top: 16%;
		}

		@media #{$mobile-breakpoint} {
			width: 100%;
			margin-top: 30%;
		}

		&-checkbox {
			a {
				color: var(--text-color);
				text-decoration: none;
				font-size: 14px;
				margin-left: -2px;

				cursor: pointer;
				@include show-item-animation;

				&:hover {
					text-decoration: underline;
					text-decoration-color: var(--text-color);
				}
			}
		}

		.MuiCheckbox-colorPrimary.Mui-checked {
			color: var(--input-color);
		}

		&-form {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			max-width: 73%;
			max-height: 90%;
			overflow-y: auto;
			overflow-x: hidden;
			padding: 0 10px;

			@media #{$mobile-portrait-landscape} {
				max-width: 50%;
			}

			@media #{$mobile-breakpoint} {
				max-width: 90%;
			}

			&-textBox {
				margin-top: 10px;
				margin-bottom: 15px;
				@include show-item-animation;
			}

			#formGroup-Number {
				margin-top: 20px;
				margin-bottom: -15px;
			}

			h1 {
				font-weight: 500;
				color: var(--title-color);
				font-size: 21px;
				@include show-item-animation;
			}

			p,
			label {
				word-wrap: break-word;
				word-break: break-word;
				color: var(--text-color);
				font-size: 14px;
				@include show-item-animation;
			}

			label {
				margin-right: 0;
			}

			.MuiFormGroup-root {
				align-items: center;
				justify-content: space-between;
				gap: 10px;

				.MuiTextField-root {
					width: calc(50% - 5px);
				}
			}

			.SimpleButton {
				justify-content: center;
				text-transform: none;

				svg {
					margin: 0 5px;
				}
			}

			.MuiIconButton-root {
				font-size: 1.25rem !important;
			}

			&-highlight {
				color: var(--highlight-color);
			}

			&-link {
				font-weight: 500;
				color: var(--highlight-color);
				cursor: pointer;
				@include show-item-animation;

				&:hover {
					text-decoration: underline;
					text-decoration-color: var(--highlight-color);
				}
			}

			&-centeredText {
				text-align: center;
			}

			&-error {
				color: #f92323 !important;
				font-size: 8px;
				margin-top: 0px;
			}

			&-buttons {
				position: relative;
				height: 200px;

				>div {
					opacity: 0 !important;
					position: absolute;
					top: 20px;

					iframe,
					#cantainer,
					div {
						width: 100% !important;
						max-width: 100%;
					}
				}

				&-google {
					z-index: -10;
					top: 20px;
				}

				&-linkedIn {
					top: 70px;
				}

				&-facebook {
					top: 120px;
				}

				&-govbr {
					top: 120px;
					gap: 10px;
					width: 97%;
					margin: 5px !important;
					background-color: white !important;

					span {
						color: rgb(49, 49, 49);
						text-transform: none !important;
					}

					img {
						width: 54px;
					}
				}

				.externalLoginFacebook {
					position: absolute;
					width: 100%;
					top: 125px;
					z-index: 10;
					opacity: 0;
				}

				button {
					position: absolute;
					display: flex !important;
					justify-content: center;
					align-items: center;
					height: 40px !important;

					span {
						font-size: 16px;
					}
				}
			}
		}
	}
}