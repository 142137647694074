@import '../animations';
@import '../mediaQueries';
@import '../mixins';
@import '../mimetypes';
@import '../animations';
@import '../mediaQueries';
@import '../colors';

$primary-color: #5e69df;
$title-color: $primary-color;
$shadow-a: #0a0d129f;
$shadow-b: #050b1343;
$background: #1b222b;
$menuColorA: #1d2f4f;
$menuColorA: #0e1421;

$white: #272e3c;
$black: #b8bcc2;
$grayA: #fff;
$grayB: #eee;
$grayC: #ddd;
$grayD: #ccc;
$grayE: #bbb;
$grayF: #aaa;
$grayG: #999;
$grayH: #888;
$grayI: #777;
$grayJ: #666;
$grayK: #555;
$grayL: #444;
$grayM: #333;
$grayN: #222;
$grayO: #111;

$blueIcon: #7668f7;

$shadow: 0 0 10px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1);
/***** COMPONENTS SPECIFIC CONFIGURATIONS *****/
$scroll-bar-background: #dddddd00;
$scroll-thumb-background: #153d52;

$ultra-bar-height: 40px;
$ultra-bar-items-color: #769ff2;
$ultra-bar-light-background: $yellowA;
$ultra-bar-light-color: $yellow5;
$ultra-bar-dark-background: $purple9;
$ultra-bar-dark-color: $purpleD;
$ultra-bar-background: #222732;
$ultra-bar-background-hover: #191c24;
$ultra-bar-theme-switch-background: #10141c;
$ultra-bar-badge-background-a: #0b5e8a;
$ultra-bar-badge-color-a: #fff;
$ultra-bar-badge-background-b: #0b6867;
$ultra-bar-badge-color-b: #fff;
$ultra-bar-badge-background-c: #b18b0e;
$ultra-bar-badge-color-c: #452407;
$ultra-bar-profile-button-background: #13161d;

$simple-button-default-background: $yellow9;
$simple-button-default-color: #222;

$panel-title-color: #c7c7f2;
$panel-action-color: $ultra-bar-items-color;
$panel-action-text-bakground: #b69833;
$panel-action-filtering-background: #ffb700;
$panel-section-header-color: #1da6d8;
$panel-section-header-icon-color: $ultra-bar-items-color;
$panel-folder-item-icon-color: #fdd835;

$auth-button-background: #0069c0;
$auth-button-color: $white;
$auth-input-icon-background: $grayM;
$auth-input-icon-color: $white;

$tooltip-background: #051b25;
$tooltip-color: $blue;

$floating-menu-background: #0f1215;
$floating-menu-item-hover-background: #06060e;
$floating-menu-color: #3a8bc9;
$floating-menu-border: $grayA;

$info-message-background: $yellow2;
$info-message-color: $yellow;

$chatbar-background: #222;
$chatbar-background-hover: #292929;
$chatbar-border-color: #333;
$chatbar-color: #ddd;
$chatbar-avatar-background: #333;
$chatbar-avatar-color: #aaa;
$chatbar-special-color: #0c646a;

$tab-active-background: #1b716f;
$tab-active-color: #fff;

$chip-background: #101d12;
$chip-background-item: #2d322e;
$chip-color: #acb8af;

$chip-size: 30px;
/***** [END OF] COMPONENTS SPECIFIC CONFIGURATIONS *****/
body.dark {
	color: $black;
	@import '../general';
	@import '../components/all';
	margin: 0;
	font-family: Roboto;
}
