$apps: 'collectame', 'appcenter-customer', 'dokia', 'workflux', 'workflux-portal';
$app: 'collectame';

$shadow-a: #c9d9f0;
$shadow-b: #8fa4c2;
$background: #f2f7f7;
$background: #fafaff;
$background: #f7f7f7;

$background: #f2f4f7;
$menuColorA: #626ac9;

$company: #f9bf2d;
$companyColor: #4e421e;
$subsidiary: #26a69a;
$classification: #29b6f6;
$action-background: #e3e310;
$action-bar-background: #056f82;
$action-bar-color: #eaf5ff;

$white: #fff;
$green: #38b50f;
$red: #d01245;
$orange: #fc8b00;
$yellow: #fcfc00;
$blue: #127ed0;
$teal: #205861;
$blueA: #69b2f7;
$blueB: #89c5fc;
$blueC: #a9d6ff;
$blueD: #c3e1fe;
$blueE: #daecfc;
$blueF: #eef7ff;

$grayA: #111;
$grayB: #222;
$grayC: #333;
$grayD: #444;
$grayE: #555;
$grayF: #666;
$grayG: #777;
$grayH: #888;
$grayI: #999;
$grayJ: #aaa;
$grayK: #bbb;
$grayL: #ccc;
$grayM: #ddd;
$grayN: #eee;
$grayO: #fafafa;
$blueIcon: #7668f7;

$shadow: 0 0 10px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1);

$l-red: #e74c3c;
$d-red: #c0392b;
$l-green: #1abc9c;
$d-green: #16a085;
$l-yellow: #f1c40f;
$d-yellow: #f39c12;
$l-orange: #e67e22;
$d-orange: #d35400;
$l-blue: #3498db;
$d-blue: #2980b9;
$l-purple: #9b59b6;
$d-purple: #8e44ad;
$l-black: #34495e;
$d-black: #2c3e50;
$l-gray: #95a5a6;
$d-gray: #7f8c8d;
$l-white: #ecf0f1;
$d-white: #bdc3c7;

$colors: (
	'dark': (
		'collectame': (
			primary-color: #fdf8f2,
			secondary-color: #ffffff,
			highlight-color: #f27d18,
			title-color: #605c77,
			text-color: #605e6a,
			button-color: #f27d18,
			text-button-color: #ffffff,
			input-color: #f27d18
		),
		'appcenter-customer': (
			primary-color: #00121e,
			secondary-color: #303449,
			highlight-color: #e0e0e0,
			title-color: #e0e0e0,
			text-color: #979797,
			button-color: #00121e,
			text-button-color: #e0e0e0,
			input-color: #979797
		),
		'dokia': (
			primary-color: #1b222b,
			secondary-color: #212733,
			tertiary-color: #0bbfd3,
			highlight-color: #5e69df,
			title-color: #fff,
			text-color: #979797,
			button-color: #5e69df,
			text-button-color: #fff,
			input-color: #ddd
		),
		'workflux': (
			primary-color: #f3f3f3,
			secondary-color: #fff,
			highlight-color: #1969b2,
			title-color: #5f5879,
			text-color: #9c9999,
			button-color: #1969b2,
			text-button-color: #fff,
			input-color: #1969b2
		),
		'workflux-portal': (
			primary-color: #f3f3f3,
			secondary-color: #fff,
			highlight-color: #1969b2,
			title-color: #5f5879,
			text-color: #9c9999,
			button-color: #1969b2,
			text-button-color: #fff,
			input-color: #1969b2
		)
	),
	'light': (
		'collectame': (
			primary-color: #fdf8f2,
			secondary-color: #ffffff,
			highlight-color: #f27d18,
			title-color: #605c77,
			text-color: #605e6a,
			button-color: #f27d18,
			text-button-color: #ffffff,
			input-color: #f27d18
		),
		'appcenter-customer': (
			primary-color: #00121e,
			secondary-color: #303449,
			highlight-color: #e0e0e0,
			title-color: #e0e0e0,
			text-color: #979797,
			button-color: #00121e,
			text-button-color: #e0e0e0,
			input-color: #979797
		),
		'dokia': (
			primary-color: #f3f3f3,
			secondary-color: #fff,
			tertiary-color: #9c9999,
			highlight-color: #00aedd,
			title-color: #5f5879,
			text-color: #9c9999,
			button-color: #00aedd,
			text-button-color: #fff,
			input-color: #00aedd
		),
		'workflux': (
			primary-color: #f3f3f3,
			secondary-color: #fff,
			highlight-color: #1969b2,
			title-color: #5f5879,
			text-color: #9c9999,
			button-color: #1969b2,
			text-button-color: #fff,
			input-color: #1969b2
		),
		'workflux-portal': (
			primary-color: #f3f3f3,
			secondary-color: #fff,
			highlight-color: #1969b2,
			title-color: #5f5879,
			text-color: #9c9999,
			button-color: #1969b2,
			text-button-color: #fff,
			input-color: #1969b2
		)
	)
);
