.ext-x3d-color {
	color: #2c6765;
}
.ext-x3d-background-color {
	--background-color: #2c6765;
	--color: colour-difference(#2c6765, #000000);
	background-color: #2c6765;
}
.ext-x3d-all {
	--background-color: #2c6765;
	--color: colour-difference(#2c6765, #000000);
	background-color: #2c6765;
	color: colour-difference(#2c6765, #000000);
}
.ext-3gp-color {
	color: #698602;
}
.ext-3gp-background-color {
	--background-color: #698602;
	--color: colour-difference(#698602, #000000);
	background-color: #698602;
}
.ext-3gp-all {
	--background-color: #698602;
	--color: colour-difference(#698602, #000000);
	background-color: #698602;
	color: colour-difference(#698602, #000000);
}
.ext-3g2-color {
	color: #dc9c6c;
}
.ext-3g2-background-color {
	--background-color: #dc9c6c;
	--color: colour-difference(#dc9c6c, #000000);
	background-color: #dc9c6c;
}
.ext-3g2-all {
	--background-color: #dc9c6c;
	--color: colour-difference(#dc9c6c, #000000);
	background-color: #dc9c6c;
	color: colour-difference(#dc9c6c, #000000);
}
.ext-mseq-color {
	color: #be6acb;
}
.ext-mseq-background-color {
	--background-color: #be6acb;
	--color: colour-difference(#be6acb, #000000);
	background-color: #be6acb;
}
.ext-mseq-all {
	--background-color: #be6acb;
	--color: colour-difference(#be6acb, #000000);
	background-color: #be6acb;
	color: colour-difference(#be6acb, #000000);
}
.ext-pwn-color {
	color: #af9f46;
}
.ext-pwn-background-color {
	--background-color: #af9f46;
	--color: colour-difference(#af9f46, #000000);
	background-color: #af9f46;
}
.ext-pwn-all {
	--background-color: #af9f46;
	--color: colour-difference(#af9f46, #000000);
	background-color: #af9f46;
	color: colour-difference(#af9f46, #000000);
}
.ext-plb-color {
	color: #d337d2;
}
.ext-plb-background-color {
	--background-color: #d337d2;
	--color: colour-difference(#d337d2, #000000);
	background-color: #d337d2;
}
.ext-plb-all {
	--background-color: #d337d2;
	--color: colour-difference(#d337d2, #000000);
	background-color: #d337d2;
	color: colour-difference(#d337d2, #000000);
}
.ext-psb-color {
	color: #1baa88;
}
.ext-psb-background-color {
	--background-color: #1baa88;
	--color: colour-difference(#1baa88, #000000);
	background-color: #1baa88;
}
.ext-psb-all {
	--background-color: #1baa88;
	--color: colour-difference(#1baa88, #000000);
	background-color: #1baa88;
	color: colour-difference(#1baa88, #000000);
}
.ext-pvb-color {
	color: #8f92db;
}
.ext-pvb-background-color {
	--background-color: #8f92db;
	--color: colour-difference(#8f92db, #000000);
	background-color: #8f92db;
}
.ext-pvb-all {
	--background-color: #8f92db;
	--color: colour-difference(#8f92db, #000000);
	background-color: #8f92db;
	color: colour-difference(#8f92db, #000000);
}
.ext-tcap-color {
	color: #3305bf;
}
.ext-tcap-background-color {
	--background-color: #3305bf;
	--color: colour-difference(#3305bf, #000000);
	background-color: #3305bf;
}
.ext-tcap-all {
	--background-color: #3305bf;
	--color: colour-difference(#3305bf, #000000);
	background-color: #3305bf;
	color: colour-difference(#3305bf, #000000);
}
.ext-7z-color {
	color: #9ce30f;
}
.ext-7z-background-color {
	--background-color: #9ce30f;
	--color: colour-difference(#9ce30f, #000000);
	background-color: #9ce30f;
}
.ext-7z-all {
	--background-color: #9ce30f;
	--color: colour-difference(#9ce30f, #000000);
	background-color: #9ce30f;
	color: colour-difference(#9ce30f, #000000);
}
.ext-abw-color {
	color: #b89e01;
}
.ext-abw-background-color {
	--background-color: #b89e01;
	--color: colour-difference(#b89e01, #000000);
	background-color: #b89e01;
}
.ext-abw-all {
	--background-color: #b89e01;
	--color: colour-difference(#b89e01, #000000);
	background-color: #b89e01;
	color: colour-difference(#b89e01, #000000);
}
.ext-ace-color {
	color: #47c048;
}
.ext-ace-background-color {
	--background-color: #47c048;
	--color: colour-difference(#47c048, #000000);
	background-color: #47c048;
}
.ext-ace-all {
	--background-color: #47c048;
	--color: colour-difference(#47c048, #000000);
	background-color: #47c048;
	color: colour-difference(#47c048, #000000);
}
.ext-acc-color {
	color: #1255bf;
}
.ext-acc-background-color {
	--background-color: #1255bf;
	--color: colour-difference(#1255bf, #000000);
	background-color: #1255bf;
}
.ext-acc-all {
	--background-color: #1255bf;
	--color: colour-difference(#1255bf, #000000);
	background-color: #1255bf;
	color: colour-difference(#1255bf, #000000);
}
.ext-acu-color {
	color: #9131cf;
}
.ext-acu-background-color {
	--background-color: #9131cf;
	--color: colour-difference(#9131cf, #000000);
	background-color: #9131cf;
}
.ext-acu-all {
	--background-color: #9131cf;
	--color: colour-difference(#9131cf, #000000);
	background-color: #9131cf;
	color: colour-difference(#9131cf, #000000);
}
.ext-atc-color {
	color: #3377b6;
}
.ext-atc-background-color {
	--background-color: #3377b6;
	--color: colour-difference(#3377b6, #000000);
	background-color: #3377b6;
}
.ext-atc-all {
	--background-color: #3377b6;
	--color: colour-difference(#3377b6, #000000);
	background-color: #3377b6;
	color: colour-difference(#3377b6, #000000);
}
.ext-adp-color {
	color: #b598ac;
}
.ext-adp-background-color {
	--background-color: #b598ac;
	--color: colour-difference(#b598ac, #000000);
	background-color: #b598ac;
}
.ext-adp-all {
	--background-color: #b598ac;
	--color: colour-difference(#b598ac, #000000);
	background-color: #b598ac;
	color: colour-difference(#b598ac, #000000);
}
.ext-aab-color {
	color: #da8109;
}
.ext-aab-background-color {
	--background-color: #da8109;
	--color: colour-difference(#da8109, #000000);
	background-color: #da8109;
}
.ext-aab-all {
	--background-color: #da8109;
	--color: colour-difference(#da8109, #000000);
	background-color: #da8109;
	color: colour-difference(#da8109, #000000);
}
.ext-aam-color {
	color: #51673e;
}
.ext-aam-background-color {
	--background-color: #51673e;
	--color: colour-difference(#51673e, #000000);
	background-color: #51673e;
}
.ext-aam-all {
	--background-color: #51673e;
	--color: colour-difference(#51673e, #000000);
	background-color: #51673e;
	color: colour-difference(#51673e, #000000);
}
.ext-aas-color {
	color: #f383a1;
}
.ext-aas-background-color {
	--background-color: #f383a1;
	--color: colour-difference(#f383a1, #000000);
	background-color: #f383a1;
}
.ext-aas-all {
	--background-color: #f383a1;
	--color: colour-difference(#f383a1, #000000);
	background-color: #f383a1;
	color: colour-difference(#f383a1, #000000);
}
.ext-air-color {
	color: #e3b333;
}
.ext-air-background-color {
	--background-color: #e3b333;
	--color: colour-difference(#e3b333, #000000);
	background-color: #e3b333;
}
.ext-air-all {
	--background-color: #e3b333;
	--color: colour-difference(#e3b333, #000000);
	background-color: #e3b333;
	color: colour-difference(#e3b333, #000000);
}
.ext-swf-color {
	color: #604586;
}
.ext-swf-background-color {
	--background-color: #604586;
	--color: colour-difference(#604586, #000000);
	background-color: #604586;
}
.ext-swf-all {
	--background-color: #604586;
	--color: colour-difference(#604586, #000000);
	background-color: #604586;
	color: colour-difference(#604586, #000000);
}
.ext-fxp-color {
	color: #f1dbc1;
}
.ext-fxp-background-color {
	--background-color: #f1dbc1;
	--color: colour-difference(#f1dbc1, #000000);
	background-color: #f1dbc1;
}
.ext-fxp-all {
	--background-color: #f1dbc1;
	--color: colour-difference(#f1dbc1, #000000);
	background-color: #f1dbc1;
	color: colour-difference(#f1dbc1, #000000);
}
.ext-pdf-color {
	color: #e44;
}
.ext-pdf-background-color {
	--background-color: #e44;
	--color: colour-difference(#e44, #000000);
	background-color: #e44;
}
.ext-pdf-all {
	--background-color: #e44;
	--color: colour-difference(#e44, #000000);
	background-color: #e44;
	color: colour-difference(#e44, #000000);
}
.ext-ppd-color {
	color: #ce5205;
}
.ext-ppd-background-color {
	--background-color: #ce5205;
	--color: colour-difference(#ce5205, #000000);
	background-color: #ce5205;
}
.ext-ppd-all {
	--background-color: #ce5205;
	--color: colour-difference(#ce5205, #000000);
	background-color: #ce5205;
	color: colour-difference(#ce5205, #000000);
}
.ext-dir-color {
	color: #e7b211;
}
.ext-dir-background-color {
	--background-color: #e7b211;
	--color: colour-difference(#e7b211, #000000);
	background-color: #e7b211;
}
.ext-dir-all {
	--background-color: #e7b211;
	--color: colour-difference(#e7b211, #000000);
	background-color: #e7b211;
	color: colour-difference(#e7b211, #000000);
}
.ext-xdp-color {
	color: #8e52b6;
}
.ext-xdp-background-color {
	--background-color: #8e52b6;
	--color: colour-difference(#8e52b6, #000000);
	background-color: #8e52b6;
}
.ext-xdp-all {
	--background-color: #8e52b6;
	--color: colour-difference(#8e52b6, #000000);
	background-color: #8e52b6;
	color: colour-difference(#8e52b6, #000000);
}
.ext-xfdf-color {
	color: #ca2012;
}
.ext-xfdf-background-color {
	--background-color: #ca2012;
	--color: colour-difference(#ca2012, #000000);
	background-color: #ca2012;
}
.ext-xfdf-all {
	--background-color: #ca2012;
	--color: colour-difference(#ca2012, #000000);
	background-color: #ca2012;
	color: colour-difference(#ca2012, #000000);
}
.ext-aac-color {
	color: #52ac71;
}
.ext-aac-background-color {
	--background-color: #52ac71;
	--color: colour-difference(#52ac71, #000000);
	background-color: #52ac71;
}
.ext-aac-all {
	--background-color: #52ac71;
	--color: colour-difference(#52ac71, #000000);
	background-color: #52ac71;
	color: colour-difference(#52ac71, #000000);
}
.ext-ahead-color {
	color: #b28217;
}
.ext-ahead-background-color {
	--background-color: #b28217;
	--color: colour-difference(#b28217, #000000);
	background-color: #b28217;
}
.ext-ahead-all {
	--background-color: #b28217;
	--color: colour-difference(#b28217, #000000);
	background-color: #b28217;
	color: colour-difference(#b28217, #000000);
}
.ext-azf-color {
	color: #a4ea87;
}
.ext-azf-background-color {
	--background-color: #a4ea87;
	--color: colour-difference(#a4ea87, #000000);
	background-color: #a4ea87;
}
.ext-azf-all {
	--background-color: #a4ea87;
	--color: colour-difference(#a4ea87, #000000);
	background-color: #a4ea87;
	color: colour-difference(#a4ea87, #000000);
}
.ext-azs-color {
	color: #b14c9c;
}
.ext-azs-background-color {
	--background-color: #b14c9c;
	--color: colour-difference(#b14c9c, #000000);
	background-color: #b14c9c;
}
.ext-azs-all {
	--background-color: #b14c9c;
	--color: colour-difference(#b14c9c, #000000);
	background-color: #b14c9c;
	color: colour-difference(#b14c9c, #000000);
}
.ext-azw-color {
	color: #6c2996;
}
.ext-azw-background-color {
	--background-color: #6c2996;
	--color: colour-difference(#6c2996, #000000);
	background-color: #6c2996;
}
.ext-azw-all {
	--background-color: #6c2996;
	--color: colour-difference(#6c2996, #000000);
	background-color: #6c2996;
	color: colour-difference(#6c2996, #000000);
}
.ext-ami-color {
	color: #7491df;
}
.ext-ami-background-color {
	--background-color: #7491df;
	--color: colour-difference(#7491df, #000000);
	background-color: #7491df;
}
.ext-ami-all {
	--background-color: #7491df;
	--color: colour-difference(#7491df, #000000);
	background-color: #7491df;
	color: colour-difference(#7491df, #000000);
}
.ext-apk-color {
	color: #ab13d6;
}
.ext-apk-background-color {
	--background-color: #ab13d6;
	--color: colour-difference(#ab13d6, #000000);
	background-color: #ab13d6;
}
.ext-apk-all {
	--background-color: #ab13d6;
	--color: colour-difference(#ab13d6, #000000);
	background-color: #ab13d6;
	color: colour-difference(#ab13d6, #000000);
}
.ext-cii-color {
	color: #413106;
}
.ext-cii-background-color {
	--background-color: #413106;
	--color: colour-difference(#413106, #000000);
	background-color: #413106;
}
.ext-cii-all {
	--background-color: #413106;
	--color: colour-difference(#413106, #000000);
	background-color: #413106;
	color: colour-difference(#413106, #000000);
}
.ext-fti-color {
	color: #d92ccd;
}
.ext-fti-background-color {
	--background-color: #d92ccd;
	--color: colour-difference(#d92ccd, #000000);
	background-color: #d92ccd;
}
.ext-fti-all {
	--background-color: #d92ccd;
	--color: colour-difference(#d92ccd, #000000);
	background-color: #d92ccd;
	color: colour-difference(#d92ccd, #000000);
}
.ext-atx-color {
	color: #3c163b;
}
.ext-atx-background-color {
	--background-color: #3c163b;
	--color: colour-difference(#3c163b, #000000);
	background-color: #3c163b;
}
.ext-atx-all {
	--background-color: #3c163b;
	--color: colour-difference(#3c163b, #000000);
	background-color: #3c163b;
	color: colour-difference(#3c163b, #000000);
}
.ext-dmg-color {
	color: #a9ccf9;
}
.ext-dmg-background-color {
	--background-color: #a9ccf9;
	--color: colour-difference(#a9ccf9, #000000);
	background-color: #a9ccf9;
}
.ext-dmg-all {
	--background-color: #a9ccf9;
	--color: colour-difference(#a9ccf9, #000000);
	background-color: #a9ccf9;
	color: colour-difference(#a9ccf9, #000000);
}
.ext-mpkg-color {
	color: #1fb692;
}
.ext-mpkg-background-color {
	--background-color: #1fb692;
	--color: colour-difference(#1fb692, #000000);
	background-color: #1fb692;
}
.ext-mpkg-all {
	--background-color: #1fb692;
	--color: colour-difference(#1fb692, #000000);
	background-color: #1fb692;
	color: colour-difference(#1fb692, #000000);
}
.ext-aw-color {
	color: #b70b27;
}
.ext-aw-background-color {
	--background-color: #b70b27;
	--color: colour-difference(#b70b27, #000000);
	background-color: #b70b27;
}
.ext-aw-all {
	--background-color: #b70b27;
	--color: colour-difference(#b70b27, #000000);
	background-color: #b70b27;
	color: colour-difference(#b70b27, #000000);
}
.ext-les-color {
	color: #606051;
}
.ext-les-background-color {
	--background-color: #606051;
	--color: colour-difference(#606051, #000000);
	background-color: #606051;
}
.ext-les-all {
	--background-color: #606051;
	--color: colour-difference(#606051, #000000);
	background-color: #606051;
	color: colour-difference(#606051, #000000);
}
.ext-swi-color {
	color: #28d7f3;
}
.ext-swi-background-color {
	--background-color: #28d7f3;
	--color: colour-difference(#28d7f3, #000000);
	background-color: #28d7f3;
}
.ext-swi-all {
	--background-color: #28d7f3;
	--color: colour-difference(#28d7f3, #000000);
	background-color: #28d7f3;
	color: colour-difference(#28d7f3, #000000);
}
.ext-s-color {
	color: #14d368;
}
.ext-s-background-color {
	--background-color: #14d368;
	--color: colour-difference(#14d368, #000000);
	background-color: #14d368;
}
.ext-s-all {
	--background-color: #14d368;
	--color: colour-difference(#14d368, #000000);
	background-color: #14d368;
	color: colour-difference(#14d368, #000000);
}
.ext-atomcat-color {
	color: #ac01b6;
}
.ext-atomcat-background-color {
	--background-color: #ac01b6;
	--color: colour-difference(#ac01b6, #000000);
	background-color: #ac01b6;
}
.ext-atomcat-all {
	--background-color: #ac01b6;
	--color: colour-difference(#ac01b6, #000000);
	background-color: #ac01b6;
	color: colour-difference(#ac01b6, #000000);
}
.ext-atomsvc-color {
	color: #44cdff;
}
.ext-atomsvc-background-color {
	--background-color: #44cdff;
	--color: colour-difference(#44cdff, #000000);
	background-color: #44cdff;
}
.ext-atomsvc-all {
	--background-color: #44cdff;
	--color: colour-difference(#44cdff, #000000);
	background-color: #44cdff;
	color: colour-difference(#44cdff, #000000);
}
.ext-atom,
.ext-xml-color {
	color: #d640d6;
}
.ext-atom,
.ext-xml-background-color {
	--background-color: #d640d6;
	--color: colour-difference(#d640d6, #000000);
	background-color: #d640d6;
}
.ext-atom,
.ext-xml-all {
	--background-color: #d640d6;
	--color: colour-difference(#d640d6, #000000);
	background-color: #d640d6;
	color: colour-difference(#d640d6, #000000);
}
.ext-ac-color {
	color: #26e161;
}
.ext-ac-background-color {
	--background-color: #26e161;
	--color: colour-difference(#26e161, #000000);
	background-color: #26e161;
}
.ext-ac-all {
	--background-color: #26e161;
	--color: colour-difference(#26e161, #000000);
	background-color: #26e161;
	color: colour-difference(#26e161, #000000);
}
.ext-aif-color {
	color: #3548db;
}
.ext-aif-background-color {
	--background-color: #3548db;
	--color: colour-difference(#3548db, #000000);
	background-color: #3548db;
}
.ext-aif-all {
	--background-color: #3548db;
	--color: colour-difference(#3548db, #000000);
	background-color: #3548db;
	color: colour-difference(#3548db, #000000);
}
.ext-avi-color {
	color: #caa5d2;
}
.ext-avi-background-color {
	--background-color: #caa5d2;
	--color: colour-difference(#caa5d2, #000000);
	background-color: #caa5d2;
}
.ext-avi-all {
	--background-color: #caa5d2;
	--color: colour-difference(#caa5d2, #000000);
	background-color: #caa5d2;
	color: colour-difference(#caa5d2, #000000);
}
.ext-aep-color {
	color: #e64705;
}
.ext-aep-background-color {
	--background-color: #e64705;
	--color: colour-difference(#e64705, #000000);
	background-color: #e64705;
}
.ext-aep-all {
	--background-color: #e64705;
	--color: colour-difference(#e64705, #000000);
	background-color: #e64705;
	color: colour-difference(#e64705, #000000);
}
.ext-dxf-color {
	color: #7f0280;
}
.ext-dxf-background-color {
	--background-color: #7f0280;
	--color: colour-difference(#7f0280, #000000);
	background-color: #7f0280;
}
.ext-dxf-all {
	--background-color: #7f0280;
	--color: colour-difference(#7f0280, #000000);
	background-color: #7f0280;
	color: colour-difference(#7f0280, #000000);
}
.ext-dwf-color {
	color: #e1a322;
}
.ext-dwf-background-color {
	--background-color: #e1a322;
	--color: colour-difference(#e1a322, #000000);
	background-color: #e1a322;
}
.ext-dwf-all {
	--background-color: #e1a322;
	--color: colour-difference(#e1a322, #000000);
	background-color: #e1a322;
	color: colour-difference(#e1a322, #000000);
}
.ext-par-color {
	color: #2794ed;
}
.ext-par-background-color {
	--background-color: #2794ed;
	--color: colour-difference(#2794ed, #000000);
	background-color: #2794ed;
}
.ext-par-all {
	--background-color: #2794ed;
	--color: colour-difference(#2794ed, #000000);
	background-color: #2794ed;
	color: colour-difference(#2794ed, #000000);
}
.ext-bcpio-color {
	color: #210dbd;
}
.ext-bcpio-background-color {
	--background-color: #210dbd;
	--color: colour-difference(#210dbd, #000000);
	background-color: #210dbd;
}
.ext-bcpio-all {
	--background-color: #210dbd;
	--color: colour-difference(#210dbd, #000000);
	background-color: #210dbd;
	color: colour-difference(#210dbd, #000000);
}
.ext-bin-color {
	color: #b11e8a;
}
.ext-bin-background-color {
	--background-color: #b11e8a;
	--color: colour-difference(#b11e8a, #000000);
	background-color: #b11e8a;
}
.ext-bin-all {
	--background-color: #b11e8a;
	--color: colour-difference(#b11e8a, #000000);
	background-color: #b11e8a;
	color: colour-difference(#b11e8a, #000000);
}
.ext-bmp-color {
	color: #10c186;
}
.ext-bmp-background-color {
	--background-color: #10c186;
	--color: colour-difference(#10c186, #000000);
	background-color: #10c186;
}
.ext-bmp-all {
	--background-color: #10c186;
	--color: colour-difference(#10c186, #000000);
	background-color: #10c186;
	color: colour-difference(#10c186, #000000);
}
.ext-torrent-color {
	color: #bcbdeb;
}
.ext-torrent-background-color {
	--background-color: #bcbdeb;
	--color: colour-difference(#bcbdeb, #000000);
	background-color: #bcbdeb;
}
.ext-torrent-all {
	--background-color: #bcbdeb;
	--color: colour-difference(#bcbdeb, #000000);
	background-color: #bcbdeb;
	color: colour-difference(#bcbdeb, #000000);
}
.ext-cod-color {
	color: #2dbc82;
}
.ext-cod-background-color {
	--background-color: #2dbc82;
	--color: colour-difference(#2dbc82, #000000);
	background-color: #2dbc82;
}
.ext-cod-all {
	--background-color: #2dbc82;
	--color: colour-difference(#2dbc82, #000000);
	background-color: #2dbc82;
	color: colour-difference(#2dbc82, #000000);
}
.ext-mpm-color {
	color: #646955;
}
.ext-mpm-background-color {
	--background-color: #646955;
	--color: colour-difference(#646955, #000000);
	background-color: #646955;
}
.ext-mpm-all {
	--background-color: #646955;
	--color: colour-difference(#646955, #000000);
	background-color: #646955;
	color: colour-difference(#646955, #000000);
}
.ext-bmi-color {
	color: #74a592;
}
.ext-bmi-background-color {
	--background-color: #74a592;
	--color: colour-difference(#74a592, #000000);
	background-color: #74a592;
}
.ext-bmi-all {
	--background-color: #74a592;
	--color: colour-difference(#74a592, #000000);
	background-color: #74a592;
	color: colour-difference(#74a592, #000000);
}
.ext-sh-color {
	color: #b11614;
}
.ext-sh-background-color {
	--background-color: #b11614;
	--color: colour-difference(#b11614, #000000);
	background-color: #b11614;
}
.ext-sh-all {
	--background-color: #b11614;
	--color: colour-difference(#b11614, #000000);
	background-color: #b11614;
	color: colour-difference(#b11614, #000000);
}
.ext-btif-color {
	color: #8ff7ee;
}
.ext-btif-background-color {
	--background-color: #8ff7ee;
	--color: colour-difference(#8ff7ee, #000000);
	background-color: #8ff7ee;
}
.ext-btif-all {
	--background-color: #8ff7ee;
	--color: colour-difference(#8ff7ee, #000000);
	background-color: #8ff7ee;
	color: colour-difference(#8ff7ee, #000000);
}
.ext-rep-color {
	color: #eba3a8;
}
.ext-rep-background-color {
	--background-color: #eba3a8;
	--color: colour-difference(#eba3a8, #000000);
	background-color: #eba3a8;
}
.ext-rep-all {
	--background-color: #eba3a8;
	--color: colour-difference(#eba3a8, #000000);
	background-color: #eba3a8;
	color: colour-difference(#eba3a8, #000000);
}
.ext-bz-color {
	color: #89230d;
}
.ext-bz-background-color {
	--background-color: #89230d;
	--color: colour-difference(#89230d, #000000);
	background-color: #89230d;
}
.ext-bz-all {
	--background-color: #89230d;
	--color: colour-difference(#89230d, #000000);
	background-color: #89230d;
	color: colour-difference(#89230d, #000000);
}
.ext-bz2-color {
	color: #965d05;
}
.ext-bz2-background-color {
	--background-color: #965d05;
	--color: colour-difference(#965d05, #000000);
	background-color: #965d05;
}
.ext-bz2-all {
	--background-color: #965d05;
	--color: colour-difference(#965d05, #000000);
	background-color: #965d05;
	color: colour-difference(#965d05, #000000);
}
.ext-csh-color {
	color: #aadc24;
}
.ext-csh-background-color {
	--background-color: #aadc24;
	--color: colour-difference(#aadc24, #000000);
	background-color: #aadc24;
}
.ext-csh-all {
	--background-color: #aadc24;
	--color: colour-difference(#aadc24, #000000);
	background-color: #aadc24;
	color: colour-difference(#aadc24, #000000);
}
.ext-c-color {
	color: #1816f2;
}
.ext-c-background-color {
	--background-color: #1816f2;
	--color: colour-difference(#1816f2, #000000);
	background-color: #1816f2;
}
.ext-c-all {
	--background-color: #1816f2;
	--color: colour-difference(#1816f2, #000000);
	background-color: #1816f2;
	color: colour-difference(#1816f2, #000000);
}
.ext-cdxml-color {
	color: #a25a2d;
}
.ext-cdxml-background-color {
	--background-color: #a25a2d;
	--color: colour-difference(#a25a2d, #000000);
	background-color: #a25a2d;
}
.ext-cdxml-all {
	--background-color: #a25a2d;
	--color: colour-difference(#a25a2d, #000000);
	background-color: #a25a2d;
	color: colour-difference(#a25a2d, #000000);
}
.ext-css-color {
	color: #50d6b1;
}
.ext-css-background-color {
	--background-color: #50d6b1;
	--color: colour-difference(#50d6b1, #000000);
	background-color: #50d6b1;
}
.ext-css-all {
	--background-color: #50d6b1;
	--color: colour-difference(#50d6b1, #000000);
	background-color: #50d6b1;
	color: colour-difference(#50d6b1, #000000);
}
.ext-cdx-color {
	color: #1f7fc9;
}
.ext-cdx-background-color {
	--background-color: #1f7fc9;
	--color: colour-difference(#1f7fc9, #000000);
	background-color: #1f7fc9;
}
.ext-cdx-all {
	--background-color: #1f7fc9;
	--color: colour-difference(#1f7fc9, #000000);
	background-color: #1f7fc9;
	color: colour-difference(#1f7fc9, #000000);
}
.ext-cml-color {
	color: #6faecd;
}
.ext-cml-background-color {
	--background-color: #6faecd;
	--color: colour-difference(#6faecd, #000000);
	background-color: #6faecd;
}
.ext-cml-all {
	--background-color: #6faecd;
	--color: colour-difference(#6faecd, #000000);
	background-color: #6faecd;
	color: colour-difference(#6faecd, #000000);
}
.ext-csml-color {
	color: #33462f;
}
.ext-csml-background-color {
	--background-color: #33462f;
	--color: colour-difference(#33462f, #000000);
	background-color: #33462f;
}
.ext-csml-all {
	--background-color: #33462f;
	--color: colour-difference(#33462f, #000000);
	background-color: #33462f;
	color: colour-difference(#33462f, #000000);
}
.ext-cdbcmsg-color {
	color: #360720;
}
.ext-cdbcmsg-background-color {
	--background-color: #360720;
	--color: colour-difference(#360720, #000000);
	background-color: #360720;
}
.ext-cdbcmsg-all {
	--background-color: #360720;
	--color: colour-difference(#360720, #000000);
	background-color: #360720;
	color: colour-difference(#360720, #000000);
}
.ext-cla-color {
	color: #2e96ea;
}
.ext-cla-background-color {
	--background-color: #2e96ea;
	--color: colour-difference(#2e96ea, #000000);
	background-color: #2e96ea;
}
.ext-cla-all {
	--background-color: #2e96ea;
	--color: colour-difference(#2e96ea, #000000);
	background-color: #2e96ea;
	color: colour-difference(#2e96ea, #000000);
}
.ext-c4g-color {
	color: #aad3a2;
}
.ext-c4g-background-color {
	--background-color: #aad3a2;
	--color: colour-difference(#aad3a2, #000000);
	background-color: #aad3a2;
}
.ext-c4g-all {
	--background-color: #aad3a2;
	--color: colour-difference(#aad3a2, #000000);
	background-color: #aad3a2;
	color: colour-difference(#aad3a2, #000000);
}
.ext-sub-color {
	color: #ca3dfd;
}
.ext-sub-background-color {
	--background-color: #ca3dfd;
	--color: colour-difference(#ca3dfd, #000000);
	background-color: #ca3dfd;
}
.ext-sub-all {
	--background-color: #ca3dfd;
	--color: colour-difference(#ca3dfd, #000000);
	background-color: #ca3dfd;
	color: colour-difference(#ca3dfd, #000000);
}
.ext-cdmia-color {
	color: #df09ac;
}
.ext-cdmia-background-color {
	--background-color: #df09ac;
	--color: colour-difference(#df09ac, #000000);
	background-color: #df09ac;
}
.ext-cdmia-all {
	--background-color: #df09ac;
	--color: colour-difference(#df09ac, #000000);
	background-color: #df09ac;
	color: colour-difference(#df09ac, #000000);
}
.ext-cdmic-color {
	color: #3d320a;
}
.ext-cdmic-background-color {
	--background-color: #3d320a;
	--color: colour-difference(#3d320a, #000000);
	background-color: #3d320a;
}
.ext-cdmic-all {
	--background-color: #3d320a;
	--color: colour-difference(#3d320a, #000000);
	background-color: #3d320a;
	color: colour-difference(#3d320a, #000000);
}
.ext-cdmid-color {
	color: #1b718b;
}
.ext-cdmid-background-color {
	--background-color: #1b718b;
	--color: colour-difference(#1b718b, #000000);
	background-color: #1b718b;
}
.ext-cdmid-all {
	--background-color: #1b718b;
	--color: colour-difference(#1b718b, #000000);
	background-color: #1b718b;
	color: colour-difference(#1b718b, #000000);
}
.ext-cdmio-color {
	color: #29dccf;
}
.ext-cdmio-background-color {
	--background-color: #29dccf;
	--color: colour-difference(#29dccf, #000000);
	background-color: #29dccf;
}
.ext-cdmio-all {
	--background-color: #29dccf;
	--color: colour-difference(#29dccf, #000000);
	background-color: #29dccf;
	color: colour-difference(#29dccf, #000000);
}
.ext-cdmiq-color {
	color: #4f1862;
}
.ext-cdmiq-background-color {
	--background-color: #4f1862;
	--color: colour-difference(#4f1862, #000000);
	background-color: #4f1862;
}
.ext-cdmiq-all {
	--background-color: #4f1862;
	--color: colour-difference(#4f1862, #000000);
	background-color: #4f1862;
	color: colour-difference(#4f1862, #000000);
}
.ext-c11amc-color {
	color: #41147d;
}
.ext-c11amc-background-color {
	--background-color: #41147d;
	--color: colour-difference(#41147d, #000000);
	background-color: #41147d;
}
.ext-c11amc-all {
	--background-color: #41147d;
	--color: colour-difference(#41147d, #000000);
	background-color: #41147d;
	color: colour-difference(#41147d, #000000);
}
.ext-c11amz-color {
	color: #88aa62;
}
.ext-c11amz-background-color {
	--background-color: #88aa62;
	--color: colour-difference(#88aa62, #000000);
	background-color: #88aa62;
}
.ext-c11amz-all {
	--background-color: #88aa62;
	--color: colour-difference(#88aa62, #000000);
	background-color: #88aa62;
	color: colour-difference(#88aa62, #000000);
}
.ext-ras-color {
	color: #632540;
}
.ext-ras-background-color {
	--background-color: #632540;
	--color: colour-difference(#632540, #000000);
	background-color: #632540;
}
.ext-ras-all {
	--background-color: #632540;
	--color: colour-difference(#632540, #000000);
	background-color: #632540;
	color: colour-difference(#632540, #000000);
}
.ext-dae-color {
	color: #d7921d;
}
.ext-dae-background-color {
	--background-color: #d7921d;
	--color: colour-difference(#d7921d, #000000);
	background-color: #d7921d;
}
.ext-dae-all {
	--background-color: #d7921d;
	--color: colour-difference(#d7921d, #000000);
	background-color: #d7921d;
	color: colour-difference(#d7921d, #000000);
}
.ext-csv-color {
	color: #3e6fa6;
}
.ext-csv-background-color {
	--background-color: #3e6fa6;
	--color: colour-difference(#3e6fa6, #000000);
	background-color: #3e6fa6;
}
.ext-csv-all {
	--background-color: #3e6fa6;
	--color: colour-difference(#3e6fa6, #000000);
	background-color: #3e6fa6;
	color: colour-difference(#3e6fa6, #000000);
}
.ext-cpt-color {
	color: #1cff35;
}
.ext-cpt-background-color {
	--background-color: #1cff35;
	--color: colour-difference(#1cff35, #000000);
	background-color: #1cff35;
}
.ext-cpt-all {
	--background-color: #1cff35;
	--color: colour-difference(#1cff35, #000000);
	background-color: #1cff35;
	color: colour-difference(#1cff35, #000000);
}
.ext-wmlc-color {
	color: #ad15d2;
}
.ext-wmlc-background-color {
	--background-color: #ad15d2;
	--color: colour-difference(#ad15d2, #000000);
	background-color: #ad15d2;
}
.ext-wmlc-all {
	--background-color: #ad15d2;
	--color: colour-difference(#ad15d2, #000000);
	background-color: #ad15d2;
	color: colour-difference(#ad15d2, #000000);
}
.ext-cgm-color {
	color: #9baf25;
}
.ext-cgm-background-color {
	--background-color: #9baf25;
	--color: colour-difference(#9baf25, #000000);
	background-color: #9baf25;
}
.ext-cgm-all {
	--background-color: #9baf25;
	--color: colour-difference(#9baf25, #000000);
	background-color: #9baf25;
	color: colour-difference(#9baf25, #000000);
}
.ext-ice-color {
	color: #207bce;
}
.ext-ice-background-color {
	--background-color: #207bce;
	--color: colour-difference(#207bce, #000000);
	background-color: #207bce;
}
.ext-ice-all {
	--background-color: #207bce;
	--color: colour-difference(#207bce, #000000);
	background-color: #207bce;
	color: colour-difference(#207bce, #000000);
}
.ext-cmx-color {
	color: #cc0352;
}
.ext-cmx-background-color {
	--background-color: #cc0352;
	--color: colour-difference(#cc0352, #000000);
	background-color: #cc0352;
}
.ext-cmx-all {
	--background-color: #cc0352;
	--color: colour-difference(#cc0352, #000000);
	background-color: #cc0352;
	color: colour-difference(#cc0352, #000000);
}
.ext-xar-color {
	color: #bb9773;
}
.ext-xar-background-color {
	--background-color: #bb9773;
	--color: colour-difference(#bb9773, #000000);
	background-color: #bb9773;
}
.ext-xar-all {
	--background-color: #bb9773;
	--color: colour-difference(#bb9773, #000000);
	background-color: #bb9773;
	color: colour-difference(#bb9773, #000000);
}
.ext-cmc-color {
	color: #f76962;
}
.ext-cmc-background-color {
	--background-color: #f76962;
	--color: colour-difference(#f76962, #000000);
	background-color: #f76962;
}
.ext-cmc-all {
	--background-color: #f76962;
	--color: colour-difference(#f76962, #000000);
	background-color: #f76962;
	color: colour-difference(#f76962, #000000);
}
.ext-cpio-color {
	color: #758cc5;
}
.ext-cpio-background-color {
	--background-color: #758cc5;
	--color: colour-difference(#758cc5, #000000);
	background-color: #758cc5;
}
.ext-cpio-all {
	--background-color: #758cc5;
	--color: colour-difference(#758cc5, #000000);
	background-color: #758cc5;
	color: colour-difference(#758cc5, #000000);
}
.ext-clkx-color {
	color: #e7f8f1;
}
.ext-clkx-background-color {
	--background-color: #e7f8f1;
	--color: colour-difference(#e7f8f1, #000000);
	background-color: #e7f8f1;
}
.ext-clkx-all {
	--background-color: #e7f8f1;
	--color: colour-difference(#e7f8f1, #000000);
	background-color: #e7f8f1;
	color: colour-difference(#e7f8f1, #000000);
}
.ext-clkk-color {
	color: #a4e76b;
}
.ext-clkk-background-color {
	--background-color: #a4e76b;
	--color: colour-difference(#a4e76b, #000000);
	background-color: #a4e76b;
}
.ext-clkk-all {
	--background-color: #a4e76b;
	--color: colour-difference(#a4e76b, #000000);
	background-color: #a4e76b;
	color: colour-difference(#a4e76b, #000000);
}
.ext-clkp-color {
	color: #ba3cd5;
}
.ext-clkp-background-color {
	--background-color: #ba3cd5;
	--color: colour-difference(#ba3cd5, #000000);
	background-color: #ba3cd5;
}
.ext-clkp-all {
	--background-color: #ba3cd5;
	--color: colour-difference(#ba3cd5, #000000);
	background-color: #ba3cd5;
	color: colour-difference(#ba3cd5, #000000);
}
.ext-clkt-color {
	color: #adfe06;
}
.ext-clkt-background-color {
	--background-color: #adfe06;
	--color: colour-difference(#adfe06, #000000);
	background-color: #adfe06;
}
.ext-clkt-all {
	--background-color: #adfe06;
	--color: colour-difference(#adfe06, #000000);
	background-color: #adfe06;
	color: colour-difference(#adfe06, #000000);
}
.ext-clkw-color {
	color: #8332f8;
}
.ext-clkw-background-color {
	--background-color: #8332f8;
	--color: colour-difference(#8332f8, #000000);
	background-color: #8332f8;
}
.ext-clkw-all {
	--background-color: #8332f8;
	--color: colour-difference(#8332f8, #000000);
	background-color: #8332f8;
	color: colour-difference(#8332f8, #000000);
}
.ext-wbs-color {
	color: #17af40;
}
.ext-wbs-background-color {
	--background-color: #17af40;
	--color: colour-difference(#17af40, #000000);
	background-color: #17af40;
}
.ext-wbs-all {
	--background-color: #17af40;
	--color: colour-difference(#17af40, #000000);
	background-color: #17af40;
	color: colour-difference(#17af40, #000000);
}
.ext-cryptonote-color {
	color: #e4e2c3;
}
.ext-cryptonote-background-color {
	--background-color: #e4e2c3;
	--color: colour-difference(#e4e2c3, #000000);
	background-color: #e4e2c3;
}
.ext-cryptonote-all {
	--background-color: #e4e2c3;
	--color: colour-difference(#e4e2c3, #000000);
	background-color: #e4e2c3;
	color: colour-difference(#e4e2c3, #000000);
}
.ext-cif-color {
	color: #877f9f;
}
.ext-cif-background-color {
	--background-color: #877f9f;
	--color: colour-difference(#877f9f, #000000);
	background-color: #877f9f;
}
.ext-cif-all {
	--background-color: #877f9f;
	--color: colour-difference(#877f9f, #000000);
	background-color: #877f9f;
	color: colour-difference(#877f9f, #000000);
}
.ext-cmdf-color {
	color: #8572d0;
}
.ext-cmdf-background-color {
	--background-color: #8572d0;
	--color: colour-difference(#8572d0, #000000);
	background-color: #8572d0;
}
.ext-cmdf-all {
	--background-color: #8572d0;
	--color: colour-difference(#8572d0, #000000);
	background-color: #8572d0;
	color: colour-difference(#8572d0, #000000);
}
.ext-cu-color {
	color: #32238b;
}
.ext-cu-background-color {
	--background-color: #32238b;
	--color: colour-difference(#32238b, #000000);
	background-color: #32238b;
}
.ext-cu-all {
	--background-color: #32238b;
	--color: colour-difference(#32238b, #000000);
	background-color: #32238b;
	color: colour-difference(#32238b, #000000);
}
.ext-cww-color {
	color: #8fc38b;
}
.ext-cww-background-color {
	--background-color: #8fc38b;
	--color: colour-difference(#8fc38b, #000000);
	background-color: #8fc38b;
}
.ext-cww-all {
	--background-color: #8fc38b;
	--color: colour-difference(#8fc38b, #000000);
	background-color: #8fc38b;
	color: colour-difference(#8fc38b, #000000);
}
.ext-curl-color {
	color: #426ab7;
}
.ext-curl-background-color {
	--background-color: #426ab7;
	--color: colour-difference(#426ab7, #000000);
	background-color: #426ab7;
}
.ext-curl-all {
	--background-color: #426ab7;
	--color: colour-difference(#426ab7, #000000);
	background-color: #426ab7;
	color: colour-difference(#426ab7, #000000);
}
.ext-dcurl-color {
	color: #7a44fa;
}
.ext-dcurl-background-color {
	--background-color: #7a44fa;
	--color: colour-difference(#7a44fa, #000000);
	background-color: #7a44fa;
}
.ext-dcurl-all {
	--background-color: #7a44fa;
	--color: colour-difference(#7a44fa, #000000);
	background-color: #7a44fa;
	color: colour-difference(#7a44fa, #000000);
}
.ext-mcurl-color {
	color: #efab20;
}
.ext-mcurl-background-color {
	--background-color: #efab20;
	--color: colour-difference(#efab20, #000000);
	background-color: #efab20;
}
.ext-mcurl-all {
	--background-color: #efab20;
	--color: colour-difference(#efab20, #000000);
	background-color: #efab20;
	color: colour-difference(#efab20, #000000);
}
.ext-scurl-color {
	color: #9edbdf;
}
.ext-scurl-background-color {
	--background-color: #9edbdf;
	--color: colour-difference(#9edbdf, #000000);
	background-color: #9edbdf;
}
.ext-scurl-all {
	--background-color: #9edbdf;
	--color: colour-difference(#9edbdf, #000000);
	background-color: #9edbdf;
	color: colour-difference(#9edbdf, #000000);
}
.ext-car-color {
	color: #a03c0d;
}
.ext-car-background-color {
	--background-color: #a03c0d;
	--color: colour-difference(#a03c0d, #000000);
	background-color: #a03c0d;
}
.ext-car-all {
	--background-color: #a03c0d;
	--color: colour-difference(#a03c0d, #000000);
	background-color: #a03c0d;
	color: colour-difference(#a03c0d, #000000);
}
.ext-pcurl-color {
	color: #3cf74b;
}
.ext-pcurl-background-color {
	--background-color: #3cf74b;
	--color: colour-difference(#3cf74b, #000000);
	background-color: #3cf74b;
}
.ext-pcurl-all {
	--background-color: #3cf74b;
	--color: colour-difference(#3cf74b, #000000);
	background-color: #3cf74b;
	color: colour-difference(#3cf74b, #000000);
}
.ext-cmp-color {
	color: #d579ff;
}
.ext-cmp-background-color {
	--background-color: #d579ff;
	--color: colour-difference(#d579ff, #000000);
	background-color: #d579ff;
}
.ext-cmp-all {
	--background-color: #d579ff;
	--color: colour-difference(#d579ff, #000000);
	background-color: #d579ff;
	color: colour-difference(#d579ff, #000000);
}
.ext-dssc-color {
	color: #be81aa;
}
.ext-dssc-background-color {
	--background-color: #be81aa;
	--color: colour-difference(#be81aa, #000000);
	background-color: #be81aa;
}
.ext-dssc-all {
	--background-color: #be81aa;
	--color: colour-difference(#be81aa, #000000);
	background-color: #be81aa;
	color: colour-difference(#be81aa, #000000);
}
.ext-xdssc-color {
	color: #c1c296;
}
.ext-xdssc-background-color {
	--background-color: #c1c296;
	--color: colour-difference(#c1c296, #000000);
	background-color: #c1c296;
}
.ext-xdssc-all {
	--background-color: #c1c296;
	--color: colour-difference(#c1c296, #000000);
	background-color: #c1c296;
	color: colour-difference(#c1c296, #000000);
}
.ext-deb-color {
	color: #ac4f10;
}
.ext-deb-background-color {
	--background-color: #ac4f10;
	--color: colour-difference(#ac4f10, #000000);
	background-color: #ac4f10;
}
.ext-deb-all {
	--background-color: #ac4f10;
	--color: colour-difference(#ac4f10, #000000);
	background-color: #ac4f10;
	color: colour-difference(#ac4f10, #000000);
}
.ext-uva-color {
	color: #376a10;
}
.ext-uva-background-color {
	--background-color: #376a10;
	--color: colour-difference(#376a10, #000000);
	background-color: #376a10;
}
.ext-uva-all {
	--background-color: #376a10;
	--color: colour-difference(#376a10, #000000);
	background-color: #376a10;
	color: colour-difference(#376a10, #000000);
}
.ext-uvi-color {
	color: #28831f;
}
.ext-uvi-background-color {
	--background-color: #28831f;
	--color: colour-difference(#28831f, #000000);
	background-color: #28831f;
}
.ext-uvi-all {
	--background-color: #28831f;
	--color: colour-difference(#28831f, #000000);
	background-color: #28831f;
	color: colour-difference(#28831f, #000000);
}
.ext-uvh-color {
	color: #3039af;
}
.ext-uvh-background-color {
	--background-color: #3039af;
	--color: colour-difference(#3039af, #000000);
	background-color: #3039af;
}
.ext-uvh-all {
	--background-color: #3039af;
	--color: colour-difference(#3039af, #000000);
	background-color: #3039af;
	color: colour-difference(#3039af, #000000);
}
.ext-uvm-color {
	color: #fa3a8c;
}
.ext-uvm-background-color {
	--background-color: #fa3a8c;
	--color: colour-difference(#fa3a8c, #000000);
	background-color: #fa3a8c;
}
.ext-uvm-all {
	--background-color: #fa3a8c;
	--color: colour-difference(#fa3a8c, #000000);
	background-color: #fa3a8c;
	color: colour-difference(#fa3a8c, #000000);
}
.ext-uvu-color {
	color: #384cb3;
}
.ext-uvu-background-color {
	--background-color: #384cb3;
	--color: colour-difference(#384cb3, #000000);
	background-color: #384cb3;
}
.ext-uvu-all {
	--background-color: #384cb3;
	--color: colour-difference(#384cb3, #000000);
	background-color: #384cb3;
	color: colour-difference(#384cb3, #000000);
}
.ext-uvp-color {
	color: #27e689;
}
.ext-uvp-background-color {
	--background-color: #27e689;
	--color: colour-difference(#27e689, #000000);
	background-color: #27e689;
}
.ext-uvp-all {
	--background-color: #27e689;
	--color: colour-difference(#27e689, #000000);
	background-color: #27e689;
	color: colour-difference(#27e689, #000000);
}
.ext-uvs-color {
	color: #de5b8e;
}
.ext-uvs-background-color {
	--background-color: #de5b8e;
	--color: colour-difference(#de5b8e, #000000);
	background-color: #de5b8e;
}
.ext-uvs-all {
	--background-color: #de5b8e;
	--color: colour-difference(#de5b8e, #000000);
	background-color: #de5b8e;
	color: colour-difference(#de5b8e, #000000);
}
.ext-uvv-color {
	color: #8fecff;
}
.ext-uvv-background-color {
	--background-color: #8fecff;
	--color: colour-difference(#8fecff, #000000);
	background-color: #8fecff;
}
.ext-uvv-all {
	--background-color: #8fecff;
	--color: colour-difference(#8fecff, #000000);
	background-color: #8fecff;
	color: colour-difference(#8fecff, #000000);
}
.ext-dvi-color {
	color: #237ef1;
}
.ext-dvi-background-color {
	--background-color: #237ef1;
	--color: colour-difference(#237ef1, #000000);
	background-color: #237ef1;
}
.ext-dvi-all {
	--background-color: #237ef1;
	--color: colour-difference(#237ef1, #000000);
	background-color: #237ef1;
	color: colour-difference(#237ef1, #000000);
}
.ext-seed-color {
	color: #261da9;
}
.ext-seed-background-color {
	--background-color: #261da9;
	--color: colour-difference(#261da9, #000000);
	background-color: #261da9;
}
.ext-seed-all {
	--background-color: #261da9;
	--color: colour-difference(#261da9, #000000);
	background-color: #261da9;
	color: colour-difference(#261da9, #000000);
}
.ext-dtb-color {
	color: #943064;
}
.ext-dtb-background-color {
	--background-color: #943064;
	--color: colour-difference(#943064, #000000);
	background-color: #943064;
}
.ext-dtb-all {
	--background-color: #943064;
	--color: colour-difference(#943064, #000000);
	background-color: #943064;
	color: colour-difference(#943064, #000000);
}
.ext-res-color {
	color: #760665;
}
.ext-res-background-color {
	--background-color: #760665;
	--color: colour-difference(#760665, #000000);
	background-color: #760665;
}
.ext-res-all {
	--background-color: #760665;
	--color: colour-difference(#760665, #000000);
	background-color: #760665;
	color: colour-difference(#760665, #000000);
}
.ext-ait-color {
	color: #118b19;
}
.ext-ait-background-color {
	--background-color: #118b19;
	--color: colour-difference(#118b19, #000000);
	background-color: #118b19;
}
.ext-ait-all {
	--background-color: #118b19;
	--color: colour-difference(#118b19, #000000);
	background-color: #118b19;
	color: colour-difference(#118b19, #000000);
}
.ext-svc-color {
	color: #7695db;
}
.ext-svc-background-color {
	--background-color: #7695db;
	--color: colour-difference(#7695db, #000000);
	background-color: #7695db;
}
.ext-svc-all {
	--background-color: #7695db;
	--color: colour-difference(#7695db, #000000);
	background-color: #7695db;
	color: colour-difference(#7695db, #000000);
}
.ext-eol-color {
	color: #9e37e3;
}
.ext-eol-background-color {
	--background-color: #9e37e3;
	--color: colour-difference(#9e37e3, #000000);
	background-color: #9e37e3;
}
.ext-eol-all {
	--background-color: #9e37e3;
	--color: colour-difference(#9e37e3, #000000);
	background-color: #9e37e3;
	color: colour-difference(#9e37e3, #000000);
}
.ext-djvu-color {
	color: #c88957;
}
.ext-djvu-background-color {
	--background-color: #c88957;
	--color: colour-difference(#c88957, #000000);
	background-color: #c88957;
}
.ext-djvu-all {
	--background-color: #c88957;
	--color: colour-difference(#c88957, #000000);
	background-color: #c88957;
	color: colour-difference(#c88957, #000000);
}
.ext-dtd-color {
	color: #716709;
}
.ext-dtd-background-color {
	--background-color: #716709;
	--color: colour-difference(#716709, #000000);
	background-color: #716709;
}
.ext-dtd-all {
	--background-color: #716709;
	--color: colour-difference(#716709, #000000);
	background-color: #716709;
	color: colour-difference(#716709, #000000);
}
.ext-mlp-color {
	color: #431c9f;
}
.ext-mlp-background-color {
	--background-color: #431c9f;
	--color: colour-difference(#431c9f, #000000);
	background-color: #431c9f;
}
.ext-mlp-all {
	--background-color: #431c9f;
	--color: colour-difference(#431c9f, #000000);
	background-color: #431c9f;
	color: colour-difference(#431c9f, #000000);
}
.ext-wad-color {
	color: #aca0f0;
}
.ext-wad-background-color {
	--background-color: #aca0f0;
	--color: colour-difference(#aca0f0, #000000);
	background-color: #aca0f0;
}
.ext-wad-all {
	--background-color: #aca0f0;
	--color: colour-difference(#aca0f0, #000000);
	background-color: #aca0f0;
	color: colour-difference(#aca0f0, #000000);
}
.ext-dpg-color {
	color: #91ec24;
}
.ext-dpg-background-color {
	--background-color: #91ec24;
	--color: colour-difference(#91ec24, #000000);
	background-color: #91ec24;
}
.ext-dpg-all {
	--background-color: #91ec24;
	--color: colour-difference(#91ec24, #000000);
	background-color: #91ec24;
	color: colour-difference(#91ec24, #000000);
}
.ext-dra-color {
	color: #879c9e;
}
.ext-dra-background-color {
	--background-color: #879c9e;
	--color: colour-difference(#879c9e, #000000);
	background-color: #879c9e;
}
.ext-dra-all {
	--background-color: #879c9e;
	--color: colour-difference(#879c9e, #000000);
	background-color: #879c9e;
	color: colour-difference(#879c9e, #000000);
}
.ext-dfac-color {
	color: #453d2e;
}
.ext-dfac-background-color {
	--background-color: #453d2e;
	--color: colour-difference(#453d2e, #000000);
	background-color: #453d2e;
}
.ext-dfac-all {
	--background-color: #453d2e;
	--color: colour-difference(#453d2e, #000000);
	background-color: #453d2e;
	color: colour-difference(#453d2e, #000000);
}
.ext-dts-color {
	color: #3252db;
}
.ext-dts-background-color {
	--background-color: #3252db;
	--color: colour-difference(#3252db, #000000);
	background-color: #3252db;
}
.ext-dts-all {
	--background-color: #3252db;
	--color: colour-difference(#3252db, #000000);
	background-color: #3252db;
	color: colour-difference(#3252db, #000000);
}
.ext-dtshd-color {
	color: #9405bf;
}
.ext-dtshd-background-color {
	--background-color: #9405bf;
	--color: colour-difference(#9405bf, #000000);
	background-color: #9405bf;
}
.ext-dtshd-all {
	--background-color: #9405bf;
	--color: colour-difference(#9405bf, #000000);
	background-color: #9405bf;
	color: colour-difference(#9405bf, #000000);
}
.ext-dwg-color {
	color: #df3ff0;
}
.ext-dwg-background-color {
	--background-color: #df3ff0;
	--color: colour-difference(#df3ff0, #000000);
	background-color: #df3ff0;
}
.ext-dwg-all {
	--background-color: #df3ff0;
	--color: colour-difference(#df3ff0, #000000);
	background-color: #df3ff0;
	color: colour-difference(#df3ff0, #000000);
}
.ext-geo-color {
	color: #a44285;
}
.ext-geo-background-color {
	--background-color: #a44285;
	--color: colour-difference(#a44285, #000000);
	background-color: #a44285;
}
.ext-geo-all {
	--background-color: #a44285;
	--color: colour-difference(#a44285, #000000);
	background-color: #a44285;
	color: colour-difference(#a44285, #000000);
}
.ext-es-color {
	color: #44765e;
}
.ext-es-background-color {
	--background-color: #44765e;
	--color: colour-difference(#44765e, #000000);
	background-color: #44765e;
}
.ext-es-all {
	--background-color: #44765e;
	--color: colour-difference(#44765e, #000000);
	background-color: #44765e;
	color: colour-difference(#44765e, #000000);
}
.ext-mag-color {
	color: #653272;
}
.ext-mag-background-color {
	--background-color: #653272;
	--color: colour-difference(#653272, #000000);
	background-color: #653272;
}
.ext-mag-all {
	--background-color: #653272;
	--color: colour-difference(#653272, #000000);
	background-color: #653272;
	color: colour-difference(#653272, #000000);
}
.ext-mmr-color {
	color: #2d6c8d;
}
.ext-mmr-background-color {
	--background-color: #2d6c8d;
	--color: colour-difference(#2d6c8d, #000000);
	background-color: #2d6c8d;
}
.ext-mmr-all {
	--background-color: #2d6c8d;
	--color: colour-difference(#2d6c8d, #000000);
	background-color: #2d6c8d;
	color: colour-difference(#2d6c8d, #000000);
}
.ext-rlc-color {
	color: #44deed;
}
.ext-rlc-background-color {
	--background-color: #44deed;
	--color: colour-difference(#44deed, #000000);
	background-color: #44deed;
}
.ext-rlc-all {
	--background-color: #44deed;
	--color: colour-difference(#44deed, #000000);
	background-color: #44deed;
	color: colour-difference(#44deed, #000000);
}
.ext-exi-color {
	color: #b9273e;
}
.ext-exi-background-color {
	--background-color: #b9273e;
	--color: colour-difference(#b9273e, #000000);
	background-color: #b9273e;
}
.ext-exi-all {
	--background-color: #b9273e;
	--color: colour-difference(#b9273e, #000000);
	background-color: #b9273e;
	color: colour-difference(#b9273e, #000000);
}
.ext-mgz-color {
	color: #c7af8d;
}
.ext-mgz-background-color {
	--background-color: #c7af8d;
	--color: colour-difference(#c7af8d, #000000);
	background-color: #c7af8d;
}
.ext-mgz-all {
	--background-color: #c7af8d;
	--color: colour-difference(#c7af8d, #000000);
	background-color: #c7af8d;
	color: colour-difference(#c7af8d, #000000);
}
.ext-epub-color {
	color: #f7acdc;
}
.ext-epub-background-color {
	--background-color: #f7acdc;
	--color: colour-difference(#f7acdc, #000000);
	background-color: #f7acdc;
}
.ext-epub-all {
	--background-color: #f7acdc;
	--color: colour-difference(#f7acdc, #000000);
	background-color: #f7acdc;
	color: colour-difference(#f7acdc, #000000);
}
.ext-eml-color {
	color: #4f0034;
}
.ext-eml-background-color {
	--background-color: #4f0034;
	--color: colour-difference(#4f0034, #000000);
	background-color: #4f0034;
}
.ext-eml-all {
	--background-color: #4f0034;
	--color: colour-difference(#4f0034, #000000);
	background-color: #4f0034;
	color: colour-difference(#4f0034, #000000);
}
.ext-nml-color {
	color: #1251b5;
}
.ext-nml-background-color {
	--background-color: #1251b5;
	--color: colour-difference(#1251b5, #000000);
	background-color: #1251b5;
}
.ext-nml-all {
	--background-color: #1251b5;
	--color: colour-difference(#1251b5, #000000);
	background-color: #1251b5;
	color: colour-difference(#1251b5, #000000);
}
.ext-xpr-color {
	color: #d072a6;
}
.ext-xpr-background-color {
	--background-color: #d072a6;
	--color: colour-difference(#d072a6, #000000);
	background-color: #d072a6;
}
.ext-xpr-all {
	--background-color: #d072a6;
	--color: colour-difference(#d072a6, #000000);
	background-color: #d072a6;
	color: colour-difference(#d072a6, #000000);
}
.ext-xif-color {
	color: #3541f5;
}
.ext-xif-background-color {
	--background-color: #3541f5;
	--color: colour-difference(#3541f5, #000000);
	background-color: #3541f5;
}
.ext-xif-all {
	--background-color: #3541f5;
	--color: colour-difference(#3541f5, #000000);
	background-color: #3541f5;
	color: colour-difference(#3541f5, #000000);
}
.ext-xfdl-color {
	color: #cf0647;
}
.ext-xfdl-background-color {
	--background-color: #cf0647;
	--color: colour-difference(#cf0647, #000000);
	background-color: #cf0647;
}
.ext-xfdl-all {
	--background-color: #cf0647;
	--color: colour-difference(#cf0647, #000000);
	background-color: #cf0647;
	color: colour-difference(#cf0647, #000000);
}
.ext-emma-color {
	color: #23ffcd;
}
.ext-emma-background-color {
	--background-color: #23ffcd;
	--color: colour-difference(#23ffcd, #000000);
	background-color: #23ffcd;
}
.ext-emma-all {
	--background-color: #23ffcd;
	--color: colour-difference(#23ffcd, #000000);
	background-color: #23ffcd;
	color: colour-difference(#23ffcd, #000000);
}
.ext-ez2-color {
	color: #b354bf;
}
.ext-ez2-background-color {
	--background-color: #b354bf;
	--color: colour-difference(#b354bf, #000000);
	background-color: #b354bf;
}
.ext-ez2-all {
	--background-color: #b354bf;
	--color: colour-difference(#b354bf, #000000);
	background-color: #b354bf;
	color: colour-difference(#b354bf, #000000);
}
.ext-ez3-color {
	color: #569ec8;
}
.ext-ez3-background-color {
	--background-color: #569ec8;
	--color: colour-difference(#569ec8, #000000);
	background-color: #569ec8;
}
.ext-ez3-all {
	--background-color: #569ec8;
	--color: colour-difference(#569ec8, #000000);
	background-color: #569ec8;
	color: colour-difference(#569ec8, #000000);
}
.ext-fst-color {
	color: #1e70aa;
}
.ext-fst-background-color {
	--background-color: #1e70aa;
	--color: colour-difference(#1e70aa, #000000);
	background-color: #1e70aa;
}
.ext-fst-all {
	--background-color: #1e70aa;
	--color: colour-difference(#1e70aa, #000000);
	background-color: #1e70aa;
	color: colour-difference(#1e70aa, #000000);
}
.ext-fvt-color {
	color: #58eecb;
}
.ext-fvt-background-color {
	--background-color: #58eecb;
	--color: colour-difference(#58eecb, #000000);
	background-color: #58eecb;
}
.ext-fvt-all {
	--background-color: #58eecb;
	--color: colour-difference(#58eecb, #000000);
	background-color: #58eecb;
	color: colour-difference(#58eecb, #000000);
}
.ext-fbs-color {
	color: #1a8aef;
}
.ext-fbs-background-color {
	--background-color: #1a8aef;
	--color: colour-difference(#1a8aef, #000000);
	background-color: #1a8aef;
}
.ext-fbs-all {
	--background-color: #1a8aef;
	--color: colour-difference(#1a8aef, #000000);
	background-color: #1a8aef;
	color: colour-difference(#1a8aef, #000000);
}
.ext-fe_launch-color {
	color: #bebc39;
}
.ext-fe_launch-background-color {
	--background-color: #bebc39;
	--color: colour-difference(#bebc39, #000000);
	background-color: #bebc39;
}
.ext-fe_launch-all {
	--background-color: #bebc39;
	--color: colour-difference(#bebc39, #000000);
	background-color: #bebc39;
	color: colour-difference(#bebc39, #000000);
}
.ext-f4v-color {
	color: #828169;
}
.ext-f4v-background-color {
	--background-color: #828169;
	--color: colour-difference(#828169, #000000);
	background-color: #828169;
}
.ext-f4v-all {
	--background-color: #828169;
	--color: colour-difference(#828169, #000000);
	background-color: #828169;
	color: colour-difference(#828169, #000000);
}
.ext-flv-color {
	color: #56e821;
}
.ext-flv-background-color {
	--background-color: #56e821;
	--color: colour-difference(#56e821, #000000);
	background-color: #56e821;
}
.ext-flv-all {
	--background-color: #56e821;
	--color: colour-difference(#56e821, #000000);
	background-color: #56e821;
	color: colour-difference(#56e821, #000000);
}
.ext-fpx-color {
	color: #793467;
}
.ext-fpx-background-color {
	--background-color: #793467;
	--color: colour-difference(#793467, #000000);
	background-color: #793467;
}
.ext-fpx-all {
	--background-color: #793467;
	--color: colour-difference(#793467, #000000);
	background-color: #793467;
	color: colour-difference(#793467, #000000);
}
.ext-npx-color {
	color: #391ef9;
}
.ext-npx-background-color {
	--background-color: #391ef9;
	--color: colour-difference(#391ef9, #000000);
	background-color: #391ef9;
}
.ext-npx-all {
	--background-color: #391ef9;
	--color: colour-difference(#391ef9, #000000);
	background-color: #391ef9;
	color: colour-difference(#391ef9, #000000);
}
.ext-flx-color {
	color: #2281de;
}
.ext-flx-background-color {
	--background-color: #2281de;
	--color: colour-difference(#2281de, #000000);
	background-color: #2281de;
}
.ext-flx-all {
	--background-color: #2281de;
	--color: colour-difference(#2281de, #000000);
	background-color: #2281de;
	color: colour-difference(#2281de, #000000);
}
.ext-fli-color {
	color: #c73fd0;
}
.ext-fli-background-color {
	--background-color: #c73fd0;
	--color: colour-difference(#c73fd0, #000000);
	background-color: #c73fd0;
}
.ext-fli-all {
	--background-color: #c73fd0;
	--color: colour-difference(#c73fd0, #000000);
	background-color: #c73fd0;
	color: colour-difference(#c73fd0, #000000);
}
.ext-ftc-color {
	color: #813cf8;
}
.ext-ftc-background-color {
	--background-color: #813cf8;
	--color: colour-difference(#813cf8, #000000);
	background-color: #813cf8;
}
.ext-ftc-all {
	--background-color: #813cf8;
	--color: colour-difference(#813cf8, #000000);
	background-color: #813cf8;
	color: colour-difference(#813cf8, #000000);
}
.ext-fdf-color {
	color: #352b7c;
}
.ext-fdf-background-color {
	--background-color: #352b7c;
	--color: colour-difference(#352b7c, #000000);
	background-color: #352b7c;
}
.ext-fdf-all {
	--background-color: #352b7c;
	--color: colour-difference(#352b7c, #000000);
	background-color: #352b7c;
	color: colour-difference(#352b7c, #000000);
}
.ext-f-color {
	color: #a691c7;
}
.ext-f-background-color {
	--background-color: #a691c7;
	--color: colour-difference(#a691c7, #000000);
	background-color: #a691c7;
}
.ext-f-all {
	--background-color: #a691c7;
	--color: colour-difference(#a691c7, #000000);
	background-color: #a691c7;
	color: colour-difference(#a691c7, #000000);
}
.ext-mif-color {
	color: #277d29;
}
.ext-mif-background-color {
	--background-color: #277d29;
	--color: colour-difference(#277d29, #000000);
	background-color: #277d29;
}
.ext-mif-all {
	--background-color: #277d29;
	--color: colour-difference(#277d29, #000000);
	background-color: #277d29;
	color: colour-difference(#277d29, #000000);
}
.ext-fm-color {
	color: #860a69;
}
.ext-fm-background-color {
	--background-color: #860a69;
	--color: colour-difference(#860a69, #000000);
	background-color: #860a69;
}
.ext-fm-all {
	--background-color: #860a69;
	--color: colour-difference(#860a69, #000000);
	background-color: #860a69;
	color: colour-difference(#860a69, #000000);
}
.ext-fh-color {
	color: #87e03d;
}
.ext-fh-background-color {
	--background-color: #87e03d;
	--color: colour-difference(#87e03d, #000000);
	background-color: #87e03d;
}
.ext-fh-all {
	--background-color: #87e03d;
	--color: colour-difference(#87e03d, #000000);
	background-color: #87e03d;
	color: colour-difference(#87e03d, #000000);
}
.ext-fsc-color {
	color: #6ec210;
}
.ext-fsc-background-color {
	--background-color: #6ec210;
	--color: colour-difference(#6ec210, #000000);
	background-color: #6ec210;
}
.ext-fsc-all {
	--background-color: #6ec210;
	--color: colour-difference(#6ec210, #000000);
	background-color: #6ec210;
	color: colour-difference(#6ec210, #000000);
}
.ext-fnc-color {
	color: #28deb7;
}
.ext-fnc-background-color {
	--background-color: #28deb7;
	--color: colour-difference(#28deb7, #000000);
	background-color: #28deb7;
}
.ext-fnc-all {
	--background-color: #28deb7;
	--color: colour-difference(#28deb7, #000000);
	background-color: #28deb7;
	color: colour-difference(#28deb7, #000000);
}
.ext-ltf-color {
	color: #c06aec;
}
.ext-ltf-background-color {
	--background-color: #c06aec;
	--color: colour-difference(#c06aec, #000000);
	background-color: #c06aec;
}
.ext-ltf-all {
	--background-color: #c06aec;
	--color: colour-difference(#c06aec, #000000);
	background-color: #c06aec;
	color: colour-difference(#c06aec, #000000);
}
.ext-ddd-color {
	color: #8e7bce;
}
.ext-ddd-background-color {
	--background-color: #8e7bce;
	--color: colour-difference(#8e7bce, #000000);
	background-color: #8e7bce;
}
.ext-ddd-all {
	--background-color: #8e7bce;
	--color: colour-difference(#8e7bce, #000000);
	background-color: #8e7bce;
	color: colour-difference(#8e7bce, #000000);
}
.ext-xdw-color {
	color: #24d60e;
}
.ext-xdw-background-color {
	--background-color: #24d60e;
	--color: colour-difference(#24d60e, #000000);
	background-color: #24d60e;
}
.ext-xdw-all {
	--background-color: #24d60e;
	--color: colour-difference(#24d60e, #000000);
	background-color: #24d60e;
	color: colour-difference(#24d60e, #000000);
}
.ext-xbd-color {
	color: #126fef;
}
.ext-xbd-background-color {
	--background-color: #126fef;
	--color: colour-difference(#126fef, #000000);
	background-color: #126fef;
}
.ext-xbd-all {
	--background-color: #126fef;
	--color: colour-difference(#126fef, #000000);
	background-color: #126fef;
	color: colour-difference(#126fef, #000000);
}
.ext-oas-color {
	color: #36dec7;
}
.ext-oas-background-color {
	--background-color: #36dec7;
	--color: colour-difference(#36dec7, #000000);
	background-color: #36dec7;
}
.ext-oas-all {
	--background-color: #36dec7;
	--color: colour-difference(#36dec7, #000000);
	background-color: #36dec7;
	color: colour-difference(#36dec7, #000000);
}
.ext-oa2-color {
	color: #df1c2d;
}
.ext-oa2-background-color {
	--background-color: #df1c2d;
	--color: colour-difference(#df1c2d, #000000);
	background-color: #df1c2d;
}
.ext-oa2-all {
	--background-color: #df1c2d;
	--color: colour-difference(#df1c2d, #000000);
	background-color: #df1c2d;
	color: colour-difference(#df1c2d, #000000);
}
.ext-oa3-color {
	color: #928cf6;
}
.ext-oa3-background-color {
	--background-color: #928cf6;
	--color: colour-difference(#928cf6, #000000);
	background-color: #928cf6;
}
.ext-oa3-all {
	--background-color: #928cf6;
	--color: colour-difference(#928cf6, #000000);
	background-color: #928cf6;
	color: colour-difference(#928cf6, #000000);
}
.ext-fg5-color {
	color: #f54f66;
}
.ext-fg5-background-color {
	--background-color: #f54f66;
	--color: colour-difference(#f54f66, #000000);
	background-color: #f54f66;
}
.ext-fg5-all {
	--background-color: #f54f66;
	--color: colour-difference(#f54f66, #000000);
	background-color: #f54f66;
	color: colour-difference(#f54f66, #000000);
}
.ext-bh2-color {
	color: #ecb8a4;
}
.ext-bh2-background-color {
	--background-color: #ecb8a4;
	--color: colour-difference(#ecb8a4, #000000);
	background-color: #ecb8a4;
}
.ext-bh2-all {
	--background-color: #ecb8a4;
	--color: colour-difference(#ecb8a4, #000000);
	background-color: #ecb8a4;
	color: colour-difference(#ecb8a4, #000000);
}
.ext-spl-color {
	color: #3a92ef;
}
.ext-spl-background-color {
	--background-color: #3a92ef;
	--color: colour-difference(#3a92ef, #000000);
	background-color: #3a92ef;
}
.ext-spl-all {
	--background-color: #3a92ef;
	--color: colour-difference(#3a92ef, #000000);
	background-color: #3a92ef;
	color: colour-difference(#3a92ef, #000000);
}
.ext-fzs-color {
	color: #fdba93;
}
.ext-fzs-background-color {
	--background-color: #fdba93;
	--color: colour-difference(#fdba93, #000000);
	background-color: #fdba93;
}
.ext-fzs-all {
	--background-color: #fdba93;
	--color: colour-difference(#fdba93, #000000);
	background-color: #fdba93;
	color: colour-difference(#fdba93, #000000);
}
.ext-g3-color {
	color: #cd0b95;
}
.ext-g3-background-color {
	--background-color: #cd0b95;
	--color: colour-difference(#cd0b95, #000000);
	background-color: #cd0b95;
}
.ext-g3-all {
	--background-color: #cd0b95;
	--color: colour-difference(#cd0b95, #000000);
	background-color: #cd0b95;
	color: colour-difference(#cd0b95, #000000);
}
.ext-gmx-color {
	color: #b09958;
}
.ext-gmx-background-color {
	--background-color: #b09958;
	--color: colour-difference(#b09958, #000000);
	background-color: #b09958;
}
.ext-gmx-all {
	--background-color: #b09958;
	--color: colour-difference(#b09958, #000000);
	background-color: #b09958;
	color: colour-difference(#b09958, #000000);
}
.ext-gtw-color {
	color: #7f0929;
}
.ext-gtw-background-color {
	--background-color: #7f0929;
	--color: colour-difference(#7f0929, #000000);
	background-color: #7f0929;
}
.ext-gtw-all {
	--background-color: #7f0929;
	--color: colour-difference(#7f0929, #000000);
	background-color: #7f0929;
	color: colour-difference(#7f0929, #000000);
}
.ext-txd-color {
	color: #40e9df;
}
.ext-txd-background-color {
	--background-color: #40e9df;
	--color: colour-difference(#40e9df, #000000);
	background-color: #40e9df;
}
.ext-txd-all {
	--background-color: #40e9df;
	--color: colour-difference(#40e9df, #000000);
	background-color: #40e9df;
	color: colour-difference(#40e9df, #000000);
}
.ext-ggb-color {
	color: #d25e8e;
}
.ext-ggb-background-color {
	--background-color: #d25e8e;
	--color: colour-difference(#d25e8e, #000000);
	background-color: #d25e8e;
}
.ext-ggb-all {
	--background-color: #d25e8e;
	--color: colour-difference(#d25e8e, #000000);
	background-color: #d25e8e;
	color: colour-difference(#d25e8e, #000000);
}
.ext-ggt-color {
	color: #e89591;
}
.ext-ggt-background-color {
	--background-color: #e89591;
	--color: colour-difference(#e89591, #000000);
	background-color: #e89591;
}
.ext-ggt-all {
	--background-color: #e89591;
	--color: colour-difference(#e89591, #000000);
	background-color: #e89591;
	color: colour-difference(#e89591, #000000);
}
.ext-gdl-color {
	color: #45a500;
}
.ext-gdl-background-color {
	--background-color: #45a500;
	--color: colour-difference(#45a500, #000000);
	background-color: #45a500;
}
.ext-gdl-all {
	--background-color: #45a500;
	--color: colour-difference(#45a500, #000000);
	background-color: #45a500;
	color: colour-difference(#45a500, #000000);
}
.ext-gex-color {
	color: #79168d;
}
.ext-gex-background-color {
	--background-color: #79168d;
	--color: colour-difference(#79168d, #000000);
	background-color: #79168d;
}
.ext-gex-all {
	--background-color: #79168d;
	--color: colour-difference(#79168d, #000000);
	background-color: #79168d;
	color: colour-difference(#79168d, #000000);
}
.ext-gxt-color {
	color: #db064e;
}
.ext-gxt-background-color {
	--background-color: #db064e;
	--color: colour-difference(#db064e, #000000);
	background-color: #db064e;
}
.ext-gxt-all {
	--background-color: #db064e;
	--color: colour-difference(#db064e, #000000);
	background-color: #db064e;
	color: colour-difference(#db064e, #000000);
}
.ext-g2w-color {
	color: #79e099;
}
.ext-g2w-background-color {
	--background-color: #79e099;
	--color: colour-difference(#79e099, #000000);
	background-color: #79e099;
}
.ext-g2w-all {
	--background-color: #79e099;
	--color: colour-difference(#79e099, #000000);
	background-color: #79e099;
	color: colour-difference(#79e099, #000000);
}
.ext-g3w-color {
	color: #1d5c87;
}
.ext-g3w-background-color {
	--background-color: #1d5c87;
	--color: colour-difference(#1d5c87, #000000);
	background-color: #1d5c87;
}
.ext-g3w-all {
	--background-color: #1d5c87;
	--color: colour-difference(#1d5c87, #000000);
	background-color: #1d5c87;
	color: colour-difference(#1d5c87, #000000);
}
.ext-gsf-color {
	color: #234bd2;
}
.ext-gsf-background-color {
	--background-color: #234bd2;
	--color: colour-difference(#234bd2, #000000);
	background-color: #234bd2;
}
.ext-gsf-all {
	--background-color: #234bd2;
	--color: colour-difference(#234bd2, #000000);
	background-color: #234bd2;
	color: colour-difference(#234bd2, #000000);
}
.ext-bdf-color {
	color: #28eef2;
}
.ext-bdf-background-color {
	--background-color: #28eef2;
	--color: colour-difference(#28eef2, #000000);
	background-color: #28eef2;
}
.ext-bdf-all {
	--background-color: #28eef2;
	--color: colour-difference(#28eef2, #000000);
	background-color: #28eef2;
	color: colour-difference(#28eef2, #000000);
}
.ext-gtar-color {
	color: #a283a2;
}
.ext-gtar-background-color {
	--background-color: #a283a2;
	--color: colour-difference(#a283a2, #000000);
	background-color: #a283a2;
}
.ext-gtar-all {
	--background-color: #a283a2;
	--color: colour-difference(#a283a2, #000000);
	background-color: #a283a2;
	color: colour-difference(#a283a2, #000000);
}
.ext-texinfo-color {
	color: #c664c5;
}
.ext-texinfo-background-color {
	--background-color: #c664c5;
	--color: colour-difference(#c664c5, #000000);
	background-color: #c664c5;
}
.ext-texinfo-all {
	--background-color: #c664c5;
	--color: colour-difference(#c664c5, #000000);
	background-color: #c664c5;
	color: colour-difference(#c664c5, #000000);
}
.ext-gnumeric-color {
	color: #ac6438;
}
.ext-gnumeric-background-color {
	--background-color: #ac6438;
	--color: colour-difference(#ac6438, #000000);
	background-color: #ac6438;
}
.ext-gnumeric-all {
	--background-color: #ac6438;
	--color: colour-difference(#ac6438, #000000);
	background-color: #ac6438;
	color: colour-difference(#ac6438, #000000);
}
.ext-kml-color {
	color: #df7e38;
}
.ext-kml-background-color {
	--background-color: #df7e38;
	--color: colour-difference(#df7e38, #000000);
	background-color: #df7e38;
}
.ext-kml-all {
	--background-color: #df7e38;
	--color: colour-difference(#df7e38, #000000);
	background-color: #df7e38;
	color: colour-difference(#df7e38, #000000);
}
.ext-kmz-color {
	color: #cad472;
}
.ext-kmz-background-color {
	--background-color: #cad472;
	--color: colour-difference(#cad472, #000000);
	background-color: #cad472;
}
.ext-kmz-all {
	--background-color: #cad472;
	--color: colour-difference(#cad472, #000000);
	background-color: #cad472;
	color: colour-difference(#cad472, #000000);
}
.ext-gqf-color {
	color: #3e7388;
}
.ext-gqf-background-color {
	--background-color: #3e7388;
	--color: colour-difference(#3e7388, #000000);
	background-color: #3e7388;
}
.ext-gqf-all {
	--background-color: #3e7388;
	--color: colour-difference(#3e7388, #000000);
	background-color: #3e7388;
	color: colour-difference(#3e7388, #000000);
}
.ext-gif-color {
	color: #8e7fec;
}
.ext-gif-background-color {
	--background-color: #8e7fec;
	--color: colour-difference(#8e7fec, #000000);
	background-color: #8e7fec;
}
.ext-gif-all {
	--background-color: #8e7fec;
	--color: colour-difference(#8e7fec, #000000);
	background-color: #8e7fec;
	color: colour-difference(#8e7fec, #000000);
}
.ext-gv-color {
	color: #5e8a05;
}
.ext-gv-background-color {
	--background-color: #5e8a05;
	--color: colour-difference(#5e8a05, #000000);
	background-color: #5e8a05;
}
.ext-gv-all {
	--background-color: #5e8a05;
	--color: colour-difference(#5e8a05, #000000);
	background-color: #5e8a05;
	color: colour-difference(#5e8a05, #000000);
}
.ext-gac-color {
	color: #ffbdc3;
}
.ext-gac-background-color {
	--background-color: #ffbdc3;
	--color: colour-difference(#ffbdc3, #000000);
	background-color: #ffbdc3;
}
.ext-gac-all {
	--background-color: #ffbdc3;
	--color: colour-difference(#ffbdc3, #000000);
	background-color: #ffbdc3;
	color: colour-difference(#ffbdc3, #000000);
}
.ext-ghf-color {
	color: #a0801b;
}
.ext-ghf-background-color {
	--background-color: #a0801b;
	--color: colour-difference(#a0801b, #000000);
	background-color: #a0801b;
}
.ext-ghf-all {
	--background-color: #a0801b;
	--color: colour-difference(#a0801b, #000000);
	background-color: #a0801b;
	color: colour-difference(#a0801b, #000000);
}
.ext-gim-color {
	color: #11d846;
}
.ext-gim-background-color {
	--background-color: #11d846;
	--color: colour-difference(#11d846, #000000);
	background-color: #11d846;
}
.ext-gim-all {
	--background-color: #11d846;
	--color: colour-difference(#11d846, #000000);
	background-color: #11d846;
	color: colour-difference(#11d846, #000000);
}
.ext-grv-color {
	color: #309efa;
}
.ext-grv-background-color {
	--background-color: #309efa;
	--color: colour-difference(#309efa, #000000);
	background-color: #309efa;
}
.ext-grv-all {
	--background-color: #309efa;
	--color: colour-difference(#309efa, #000000);
	background-color: #309efa;
	color: colour-difference(#309efa, #000000);
}
.ext-gtm-color {
	color: #ecbb6f;
}
.ext-gtm-background-color {
	--background-color: #ecbb6f;
	--color: colour-difference(#ecbb6f, #000000);
	background-color: #ecbb6f;
}
.ext-gtm-all {
	--background-color: #ecbb6f;
	--color: colour-difference(#ecbb6f, #000000);
	background-color: #ecbb6f;
	color: colour-difference(#ecbb6f, #000000);
}
.ext-tpl-color {
	color: #fd591a;
}
.ext-tpl-background-color {
	--background-color: #fd591a;
	--color: colour-difference(#fd591a, #000000);
	background-color: #fd591a;
}
.ext-tpl-all {
	--background-color: #fd591a;
	--color: colour-difference(#fd591a, #000000);
	background-color: #fd591a;
	color: colour-difference(#fd591a, #000000);
}
.ext-vcg-color {
	color: #f56e2d;
}
.ext-vcg-background-color {
	--background-color: #f56e2d;
	--color: colour-difference(#f56e2d, #000000);
	background-color: #f56e2d;
}
.ext-vcg-all {
	--background-color: #f56e2d;
	--color: colour-difference(#f56e2d, #000000);
	background-color: #f56e2d;
	color: colour-difference(#f56e2d, #000000);
}
.ext-h261-color {
	color: #72972c;
}
.ext-h261-background-color {
	--background-color: #72972c;
	--color: colour-difference(#72972c, #000000);
	background-color: #72972c;
}
.ext-h261-all {
	--background-color: #72972c;
	--color: colour-difference(#72972c, #000000);
	background-color: #72972c;
	color: colour-difference(#72972c, #000000);
}
.ext-h263-color {
	color: #6e3085;
}
.ext-h263-background-color {
	--background-color: #6e3085;
	--color: colour-difference(#6e3085, #000000);
	background-color: #6e3085;
}
.ext-h263-all {
	--background-color: #6e3085;
	--color: colour-difference(#6e3085, #000000);
	background-color: #6e3085;
	color: colour-difference(#6e3085, #000000);
}
.ext-h264-color {
	color: #eefab4;
}
.ext-h264-background-color {
	--background-color: #eefab4;
	--color: colour-difference(#eefab4, #000000);
	background-color: #eefab4;
}
.ext-h264-all {
	--background-color: #eefab4;
	--color: colour-difference(#eefab4, #000000);
	background-color: #eefab4;
	color: colour-difference(#eefab4, #000000);
}
.ext-hpid-color {
	color: #ff3c6a;
}
.ext-hpid-background-color {
	--background-color: #ff3c6a;
	--color: colour-difference(#ff3c6a, #000000);
	background-color: #ff3c6a;
}
.ext-hpid-all {
	--background-color: #ff3c6a;
	--color: colour-difference(#ff3c6a, #000000);
	background-color: #ff3c6a;
	color: colour-difference(#ff3c6a, #000000);
}
.ext-hps-color {
	color: #ada6e2;
}
.ext-hps-background-color {
	--background-color: #ada6e2;
	--color: colour-difference(#ada6e2, #000000);
	background-color: #ada6e2;
}
.ext-hps-all {
	--background-color: #ada6e2;
	--color: colour-difference(#ada6e2, #000000);
	background-color: #ada6e2;
	color: colour-difference(#ada6e2, #000000);
}
.ext-hdf-color {
	color: #23962a;
}
.ext-hdf-background-color {
	--background-color: #23962a;
	--color: colour-difference(#23962a, #000000);
	background-color: #23962a;
}
.ext-hdf-all {
	--background-color: #23962a;
	--color: colour-difference(#23962a, #000000);
	background-color: #23962a;
	color: colour-difference(#23962a, #000000);
}
.ext-rip-color {
	color: #744597;
}
.ext-rip-background-color {
	--background-color: #744597;
	--color: colour-difference(#744597, #000000);
	background-color: #744597;
}
.ext-rip-all {
	--background-color: #744597;
	--color: colour-difference(#744597, #000000);
	background-color: #744597;
	color: colour-difference(#744597, #000000);
}
.ext-hbci-color {
	color: #a2614f;
}
.ext-hbci-background-color {
	--background-color: #a2614f;
	--color: colour-difference(#a2614f, #000000);
	background-color: #a2614f;
}
.ext-hbci-all {
	--background-color: #a2614f;
	--color: colour-difference(#a2614f, #000000);
	background-color: #a2614f;
	color: colour-difference(#a2614f, #000000);
}
.ext-jlt-color {
	color: #281353;
}
.ext-jlt-background-color {
	--background-color: #281353;
	--color: colour-difference(#281353, #000000);
	background-color: #281353;
}
.ext-jlt-all {
	--background-color: #281353;
	--color: colour-difference(#281353, #000000);
	background-color: #281353;
	color: colour-difference(#281353, #000000);
}
.ext-pcl-color {
	color: #4bd583;
}
.ext-pcl-background-color {
	--background-color: #4bd583;
	--color: colour-difference(#4bd583, #000000);
	background-color: #4bd583;
}
.ext-pcl-all {
	--background-color: #4bd583;
	--color: colour-difference(#4bd583, #000000);
	background-color: #4bd583;
	color: colour-difference(#4bd583, #000000);
}
.ext-hpgl-color {
	color: #773c87;
}
.ext-hpgl-background-color {
	--background-color: #773c87;
	--color: colour-difference(#773c87, #000000);
	background-color: #773c87;
}
.ext-hpgl-all {
	--background-color: #773c87;
	--color: colour-difference(#773c87, #000000);
	background-color: #773c87;
	color: colour-difference(#773c87, #000000);
}
.ext-hvs-color {
	color: #a043c6;
}
.ext-hvs-background-color {
	--background-color: #a043c6;
	--color: colour-difference(#a043c6, #000000);
	background-color: #a043c6;
}
.ext-hvs-all {
	--background-color: #a043c6;
	--color: colour-difference(#a043c6, #000000);
	background-color: #a043c6;
	color: colour-difference(#a043c6, #000000);
}
.ext-hvd-color {
	color: #59893b;
}
.ext-hvd-background-color {
	--background-color: #59893b;
	--color: colour-difference(#59893b, #000000);
	background-color: #59893b;
}
.ext-hvd-all {
	--background-color: #59893b;
	--color: colour-difference(#59893b, #000000);
	background-color: #59893b;
	color: colour-difference(#59893b, #000000);
}
.ext-hvp-color {
	color: #d770e5;
}
.ext-hvp-background-color {
	--background-color: #d770e5;
	--color: colour-difference(#d770e5, #000000);
	background-color: #d770e5;
}
.ext-hvp-all {
	--background-color: #d770e5;
	--color: colour-difference(#d770e5, #000000);
	background-color: #d770e5;
	color: colour-difference(#d770e5, #000000);
}
.ext-sfd-hdstx-color {
	color: #c049ed;
}
.ext-sfd-hdstx-background-color {
	--background-color: #c049ed;
	--color: colour-difference(#c049ed, #000000);
	background-color: #c049ed;
}
.ext-sfd-hdstx-all {
	--background-color: #c049ed;
	--color: colour-difference(#c049ed, #000000);
	background-color: #c049ed;
	color: colour-difference(#c049ed, #000000);
}
.ext-stk-color {
	color: #d4f79e;
}
.ext-stk-background-color {
	--background-color: #d4f79e;
	--color: colour-difference(#d4f79e, #000000);
	background-color: #d4f79e;
}
.ext-stk-all {
	--background-color: #d4f79e;
	--color: colour-difference(#d4f79e, #000000);
	background-color: #d4f79e;
	color: colour-difference(#d4f79e, #000000);
}
.ext-hal-color {
	color: #f82e7a;
}
.ext-hal-background-color {
	--background-color: #f82e7a;
	--color: colour-difference(#f82e7a, #000000);
	background-color: #f82e7a;
}
.ext-hal-all {
	--background-color: #f82e7a;
	--color: colour-difference(#f82e7a, #000000);
	background-color: #f82e7a;
	color: colour-difference(#f82e7a, #000000);
}
.ext-html-color {
	color: #47e;
}
.ext-html-background-color {
	--background-color: #47e;
	--color: colour-difference(#47e, #000000);
	background-color: #47e;
}
.ext-html-all {
	--background-color: #47e;
	--color: colour-difference(#47e, #000000);
	background-color: #47e;
	color: colour-difference(#47e, #000000);
}
.ext-irm-color {
	color: #d1d3f1;
}
.ext-irm-background-color {
	--background-color: #d1d3f1;
	--color: colour-difference(#d1d3f1, #000000);
	background-color: #d1d3f1;
}
.ext-irm-all {
	--background-color: #d1d3f1;
	--color: colour-difference(#d1d3f1, #000000);
	background-color: #d1d3f1;
	color: colour-difference(#d1d3f1, #000000);
}
.ext-sc-color {
	color: #9fbe79;
}
.ext-sc-background-color {
	--background-color: #9fbe79;
	--color: colour-difference(#9fbe79, #000000);
	background-color: #9fbe79;
}
.ext-sc-all {
	--background-color: #9fbe79;
	--color: colour-difference(#9fbe79, #000000);
	background-color: #9fbe79;
	color: colour-difference(#9fbe79, #000000);
}
.ext-ics-color {
	color: #274c2f;
}
.ext-ics-background-color {
	--background-color: #274c2f;
	--color: colour-difference(#274c2f, #000000);
	background-color: #274c2f;
}
.ext-ics-all {
	--background-color: #274c2f;
	--color: colour-difference(#274c2f, #000000);
	background-color: #274c2f;
	color: colour-difference(#274c2f, #000000);
}
.ext-icc-color {
	color: #63a4b9;
}
.ext-icc-background-color {
	--background-color: #63a4b9;
	--color: colour-difference(#63a4b9, #000000);
	background-color: #63a4b9;
}
.ext-icc-all {
	--background-color: #63a4b9;
	--color: colour-difference(#63a4b9, #000000);
	background-color: #63a4b9;
	color: colour-difference(#63a4b9, #000000);
}
.ext-ico-color {
	color: #93d71b;
}
.ext-ico-background-color {
	--background-color: #93d71b;
	--color: colour-difference(#93d71b, #000000);
	background-color: #93d71b;
}
.ext-ico-all {
	--background-color: #93d71b;
	--color: colour-difference(#93d71b, #000000);
	background-color: #93d71b;
	color: colour-difference(#93d71b, #000000);
}
.ext-igl-color {
	color: #4d05a7;
}
.ext-igl-background-color {
	--background-color: #4d05a7;
	--color: colour-difference(#4d05a7, #000000);
	background-color: #4d05a7;
}
.ext-igl-all {
	--background-color: #4d05a7;
	--color: colour-difference(#4d05a7, #000000);
	background-color: #4d05a7;
	color: colour-difference(#4d05a7, #000000);
}
.ext-ief-color {
	color: #45c472;
}
.ext-ief-background-color {
	--background-color: #45c472;
	--color: colour-difference(#45c472, #000000);
	background-color: #45c472;
}
.ext-ief-all {
	--background-color: #45c472;
	--color: colour-difference(#45c472, #000000);
	background-color: #45c472;
	color: colour-difference(#45c472, #000000);
}
.ext-ivp-color {
	color: #58f88c;
}
.ext-ivp-background-color {
	--background-color: #58f88c;
	--color: colour-difference(#58f88c, #000000);
	background-color: #58f88c;
}
.ext-ivp-all {
	--background-color: #58f88c;
	--color: colour-difference(#58f88c, #000000);
	background-color: #58f88c;
	color: colour-difference(#58f88c, #000000);
}
.ext-ivu-color {
	color: #bcfb1a;
}
.ext-ivu-background-color {
	--background-color: #bcfb1a;
	--color: colour-difference(#bcfb1a, #000000);
	background-color: #bcfb1a;
}
.ext-ivu-all {
	--background-color: #bcfb1a;
	--color: colour-difference(#bcfb1a, #000000);
	background-color: #bcfb1a;
	color: colour-difference(#bcfb1a, #000000);
}
.ext-rif-color {
	color: #70d442;
}
.ext-rif-background-color {
	--background-color: #70d442;
	--color: colour-difference(#70d442, #000000);
	background-color: #70d442;
}
.ext-rif-all {
	--background-color: #70d442;
	--color: colour-difference(#70d442, #000000);
	background-color: #70d442;
	color: colour-difference(#70d442, #000000);
}
.ext-3dml-color {
	color: #a3a3e2;
}
.ext-3dml-background-color {
	--background-color: #a3a3e2;
	--color: colour-difference(#a3a3e2, #000000);
	background-color: #a3a3e2;
}
.ext-3dml-all {
	--background-color: #a3a3e2;
	--color: colour-difference(#a3a3e2, #000000);
	background-color: #a3a3e2;
	color: colour-difference(#a3a3e2, #000000);
}
.ext-spot-color {
	color: #561a16;
}
.ext-spot-background-color {
	--background-color: #561a16;
	--color: colour-difference(#561a16, #000000);
	background-color: #561a16;
}
.ext-spot-all {
	--background-color: #561a16;
	--color: colour-difference(#561a16, #000000);
	background-color: #561a16;
	color: colour-difference(#561a16, #000000);
}
.ext-igs-color {
	color: #73cd48;
}
.ext-igs-background-color {
	--background-color: #73cd48;
	--color: colour-difference(#73cd48, #000000);
	background-color: #73cd48;
}
.ext-igs-all {
	--background-color: #73cd48;
	--color: colour-difference(#73cd48, #000000);
	background-color: #73cd48;
	color: colour-difference(#73cd48, #000000);
}
.ext-i2g-color {
	color: #5eccc0;
}
.ext-i2g-background-color {
	--background-color: #5eccc0;
	--color: colour-difference(#5eccc0, #000000);
	background-color: #5eccc0;
}
.ext-i2g-all {
	--background-color: #5eccc0;
	--color: colour-difference(#5eccc0, #000000);
	background-color: #5eccc0;
	color: colour-difference(#5eccc0, #000000);
}
.ext-cdy-color {
	color: #2b6cd5;
}
.ext-cdy-background-color {
	--background-color: #2b6cd5;
	--color: colour-difference(#2b6cd5, #000000);
	background-color: #2b6cd5;
}
.ext-cdy-all {
	--background-color: #2b6cd5;
	--color: colour-difference(#2b6cd5, #000000);
	background-color: #2b6cd5;
	color: colour-difference(#2b6cd5, #000000);
}
.ext-xpw-color {
	color: #b27c7a;
}
.ext-xpw-background-color {
	--background-color: #b27c7a;
	--color: colour-difference(#b27c7a, #000000);
	background-color: #b27c7a;
}
.ext-xpw-all {
	--background-color: #b27c7a;
	--color: colour-difference(#b27c7a, #000000);
	background-color: #b27c7a;
	color: colour-difference(#b27c7a, #000000);
}
.ext-fcs-color {
	color: #24ebf9;
}
.ext-fcs-background-color {
	--background-color: #24ebf9;
	--color: colour-difference(#24ebf9, #000000);
	background-color: #24ebf9;
}
.ext-fcs-all {
	--background-color: #24ebf9;
	--color: colour-difference(#24ebf9, #000000);
	background-color: #24ebf9;
	color: colour-difference(#24ebf9, #000000);
}
.ext-ipfix-color {
	color: #f18653;
}
.ext-ipfix-background-color {
	--background-color: #f18653;
	--color: colour-difference(#f18653, #000000);
	background-color: #f18653;
}
.ext-ipfix-all {
	--background-color: #f18653;
	--color: colour-difference(#f18653, #000000);
	background-color: #f18653;
	color: colour-difference(#f18653, #000000);
}
.ext-cer-color {
	color: #7ea046;
}
.ext-cer-background-color {
	--background-color: #7ea046;
	--color: colour-difference(#7ea046, #000000);
	background-color: #7ea046;
}
.ext-cer-all {
	--background-color: #7ea046;
	--color: colour-difference(#7ea046, #000000);
	background-color: #7ea046;
	color: colour-difference(#7ea046, #000000);
}
.ext-pki-color {
	color: #dfb903;
}
.ext-pki-background-color {
	--background-color: #dfb903;
	--color: colour-difference(#dfb903, #000000);
	background-color: #dfb903;
}
.ext-pki-all {
	--background-color: #dfb903;
	--color: colour-difference(#dfb903, #000000);
	background-color: #dfb903;
	color: colour-difference(#dfb903, #000000);
}
.ext-crl-color {
	color: #9bc76f;
}
.ext-crl-background-color {
	--background-color: #9bc76f;
	--color: colour-difference(#9bc76f, #000000);
	background-color: #9bc76f;
}
.ext-crl-all {
	--background-color: #9bc76f;
	--color: colour-difference(#9bc76f, #000000);
	background-color: #9bc76f;
	color: colour-difference(#9bc76f, #000000);
}
.ext-pkipath-color {
	color: #48af77;
}
.ext-pkipath-background-color {
	--background-color: #48af77;
	--color: colour-difference(#48af77, #000000);
	background-color: #48af77;
}
.ext-pkipath-all {
	--background-color: #48af77;
	--color: colour-difference(#48af77, #000000);
	background-color: #48af77;
	color: colour-difference(#48af77, #000000);
}
.ext-igm-color {
	color: #106b1f;
}
.ext-igm-background-color {
	--background-color: #106b1f;
	--color: colour-difference(#106b1f, #000000);
	background-color: #106b1f;
}
.ext-igm-all {
	--background-color: #106b1f;
	--color: colour-difference(#106b1f, #000000);
	background-color: #106b1f;
	color: colour-difference(#106b1f, #000000);
}
.ext-rcprofile-color {
	color: #830d9f;
}
.ext-rcprofile-background-color {
	--background-color: #830d9f;
	--color: colour-difference(#830d9f, #000000);
	background-color: #830d9f;
}
.ext-rcprofile-all {
	--background-color: #830d9f;
	--color: colour-difference(#830d9f, #000000);
	background-color: #830d9f;
	color: colour-difference(#830d9f, #000000);
}
.ext-irp-color {
	color: #24363b;
}
.ext-irp-background-color {
	--background-color: #24363b;
	--color: colour-difference(#24363b, #000000);
	background-color: #24363b;
}
.ext-irp-all {
	--background-color: #24363b;
	--color: colour-difference(#24363b, #000000);
	background-color: #24363b;
	color: colour-difference(#24363b, #000000);
}
.ext-jad-color {
	color: #5f9f45;
}
.ext-jad-background-color {
	--background-color: #5f9f45;
	--color: colour-difference(#5f9f45, #000000);
	background-color: #5f9f45;
}
.ext-jad-all {
	--background-color: #5f9f45;
	--color: colour-difference(#5f9f45, #000000);
	background-color: #5f9f45;
	color: colour-difference(#5f9f45, #000000);
}
.ext-jar-color {
	color: #273852;
}
.ext-jar-background-color {
	--background-color: #273852;
	--color: colour-difference(#273852, #000000);
	background-color: #273852;
}
.ext-jar-all {
	--background-color: #273852;
	--color: colour-difference(#273852, #000000);
	background-color: #273852;
	color: colour-difference(#273852, #000000);
}
.ext-class-color {
	color: #21db46;
}
.ext-class-background-color {
	--background-color: #21db46;
	--color: colour-difference(#21db46, #000000);
	background-color: #21db46;
}
.ext-class-all {
	--background-color: #21db46;
	--color: colour-difference(#21db46, #000000);
	background-color: #21db46;
	color: colour-difference(#21db46, #000000);
}
.ext-jnlp-color {
	color: #a3e29e;
}
.ext-jnlp-background-color {
	--background-color: #a3e29e;
	--color: colour-difference(#a3e29e, #000000);
	background-color: #a3e29e;
}
.ext-jnlp-all {
	--background-color: #a3e29e;
	--color: colour-difference(#a3e29e, #000000);
	background-color: #a3e29e;
	color: colour-difference(#a3e29e, #000000);
}
.ext-ser-color {
	color: #45691d;
}
.ext-ser-background-color {
	--background-color: #45691d;
	--color: colour-difference(#45691d, #000000);
	background-color: #45691d;
}
.ext-ser-all {
	--background-color: #45691d;
	--color: colour-difference(#45691d, #000000);
	background-color: #45691d;
	color: colour-difference(#45691d, #000000);
}
.ext-java-color {
	color: #4b567b;
}
.ext-java-background-color {
	--background-color: #4b567b;
	--color: colour-difference(#4b567b, #000000);
	background-color: #4b567b;
}
.ext-java-all {
	--background-color: #4b567b;
	--color: colour-difference(#4b567b, #000000);
	background-color: #4b567b;
	color: colour-difference(#4b567b, #000000);
}
.ext-js-color {
	color: #9cbc16;
}
.ext-js-background-color {
	--background-color: #9cbc16;
	--color: colour-difference(#9cbc16, #000000);
	background-color: #9cbc16;
}
.ext-js-all {
	--background-color: #9cbc16;
	--color: colour-difference(#9cbc16, #000000);
	background-color: #9cbc16;
	color: colour-difference(#9cbc16, #000000);
}
.ext-json-color {
	color: #fab691;
}
.ext-json-background-color {
	--background-color: #fab691;
	--color: colour-difference(#fab691, #000000);
	background-color: #fab691;
}
.ext-json-all {
	--background-color: #fab691;
	--color: colour-difference(#fab691, #000000);
	background-color: #fab691;
	color: colour-difference(#fab691, #000000);
}
.ext-joda-color {
	color: #6d0555;
}
.ext-joda-background-color {
	--background-color: #6d0555;
	--color: colour-difference(#6d0555, #000000);
	background-color: #6d0555;
}
.ext-joda-all {
	--background-color: #6d0555;
	--color: colour-difference(#6d0555, #000000);
	background-color: #6d0555;
	color: colour-difference(#6d0555, #000000);
}
.ext-jpm-color {
	color: #27f233;
}
.ext-jpm-background-color {
	--background-color: #27f233;
	--color: colour-difference(#27f233, #000000);
	background-color: #27f233;
}
.ext-jpm-all {
	--background-color: #27f233;
	--color: colour-difference(#27f233, #000000);
	background-color: #27f233;
	color: colour-difference(#27f233, #000000);
}

.ext-jpg-color {
	color: #b8e35b;
}

.ext-jpg-background-color {
	--background-color: #b8e35b;
	--color: colour-difference(#b8e35b, #000000);
	background-color: #b8e35b;
}

.ext-jpg-all {
	--background-color: #b8e35b;
	--color: colour-difference(#b8e35b, #000000);
	background-color: #b8e35b;
	color: colour-difference(#b8e35b, #000000);
}

.ext-jpeg-color {
	color: #b8e35b;
}

.ext-jpeg-background-color {
	--background-color: #b8e35b;
	--color: colour-difference(#b8e35b, #000000);
	background-color: #b8e35b;
}

.ext-jpeg-all {
	--background-color: #b8e35b;
	--color: colour-difference(#b8e35b, #000000);
	background-color: #b8e35b;
	color: colour-difference(#b8e35b, #000000);
}
.ext-pjpeg-color {
	color: #777723;
}
.ext-pjpeg-background-color {
	--background-color: #777723;
	--color: colour-difference(#777723, #000000);
	background-color: #777723;
}
.ext-pjpeg-all {
	--background-color: #777723;
	--color: colour-difference(#777723, #000000);
	background-color: #777723;
	color: colour-difference(#777723, #000000);
}
.ext-jpgv-color {
	color: #ecd11b;
}
.ext-jpgv-background-color {
	--background-color: #ecd11b;
	--color: colour-difference(#ecd11b, #000000);
	background-color: #ecd11b;
}
.ext-jpgv-all {
	--background-color: #ecd11b;
	--color: colour-difference(#ecd11b, #000000);
	background-color: #ecd11b;
	color: colour-difference(#ecd11b, #000000);
}
.ext-ktz-color {
	color: #23d89f;
}
.ext-ktz-background-color {
	--background-color: #23d89f;
	--color: colour-difference(#23d89f, #000000);
	background-color: #23d89f;
}
.ext-ktz-all {
	--background-color: #23d89f;
	--color: colour-difference(#23d89f, #000000);
	background-color: #23d89f;
	color: colour-difference(#23d89f, #000000);
}
.ext-mmd-color {
	color: #606c6a;
}
.ext-mmd-background-color {
	--background-color: #606c6a;
	--color: colour-difference(#606c6a, #000000);
	background-color: #606c6a;
}
.ext-mmd-all {
	--background-color: #606c6a;
	--color: colour-difference(#606c6a, #000000);
	background-color: #606c6a;
	color: colour-difference(#606c6a, #000000);
}
.ext-karbon-color {
	color: #adf2ea;
}
.ext-karbon-background-color {
	--background-color: #adf2ea;
	--color: colour-difference(#adf2ea, #000000);
	background-color: #adf2ea;
}
.ext-karbon-all {
	--background-color: #adf2ea;
	--color: colour-difference(#adf2ea, #000000);
	background-color: #adf2ea;
	color: colour-difference(#adf2ea, #000000);
}
.ext-chrt-color {
	color: #41b3d2;
}
.ext-chrt-background-color {
	--background-color: #41b3d2;
	--color: colour-difference(#41b3d2, #000000);
	background-color: #41b3d2;
}
.ext-chrt-all {
	--background-color: #41b3d2;
	--color: colour-difference(#41b3d2, #000000);
	background-color: #41b3d2;
	color: colour-difference(#41b3d2, #000000);
}
.ext-kfo-color {
	color: #8311d4;
}
.ext-kfo-background-color {
	--background-color: #8311d4;
	--color: colour-difference(#8311d4, #000000);
	background-color: #8311d4;
}
.ext-kfo-all {
	--background-color: #8311d4;
	--color: colour-difference(#8311d4, #000000);
	background-color: #8311d4;
	color: colour-difference(#8311d4, #000000);
}
.ext-flw-color {
	color: #bef25e;
}
.ext-flw-background-color {
	--background-color: #bef25e;
	--color: colour-difference(#bef25e, #000000);
	background-color: #bef25e;
}
.ext-flw-all {
	--background-color: #bef25e;
	--color: colour-difference(#bef25e, #000000);
	background-color: #bef25e;
	color: colour-difference(#bef25e, #000000);
}
.ext-kon-color {
	color: #b3cac2;
}
.ext-kon-background-color {
	--background-color: #b3cac2;
	--color: colour-difference(#b3cac2, #000000);
	background-color: #b3cac2;
}
.ext-kon-all {
	--background-color: #b3cac2;
	--color: colour-difference(#b3cac2, #000000);
	background-color: #b3cac2;
	color: colour-difference(#b3cac2, #000000);
}
.ext-kpr-color {
	color: #caa778;
}
.ext-kpr-background-color {
	--background-color: #caa778;
	--color: colour-difference(#caa778, #000000);
	background-color: #caa778;
}
.ext-kpr-all {
	--background-color: #caa778;
	--color: colour-difference(#caa778, #000000);
	background-color: #caa778;
	color: colour-difference(#caa778, #000000);
}
.ext-ksp-color {
	color: #9541d9;
}
.ext-ksp-background-color {
	--background-color: #9541d9;
	--color: colour-difference(#9541d9, #000000);
	background-color: #9541d9;
}
.ext-ksp-all {
	--background-color: #9541d9;
	--color: colour-difference(#9541d9, #000000);
	background-color: #9541d9;
	color: colour-difference(#9541d9, #000000);
}
.ext-kwd-color {
	color: #a6d469;
}
.ext-kwd-background-color {
	--background-color: #a6d469;
	--color: colour-difference(#a6d469, #000000);
	background-color: #a6d469;
}
.ext-kwd-all {
	--background-color: #a6d469;
	--color: colour-difference(#a6d469, #000000);
	background-color: #a6d469;
	color: colour-difference(#a6d469, #000000);
}
.ext-htke-color {
	color: #bda92e;
}
.ext-htke-background-color {
	--background-color: #bda92e;
	--color: colour-difference(#bda92e, #000000);
	background-color: #bda92e;
}
.ext-htke-all {
	--background-color: #bda92e;
	--color: colour-difference(#bda92e, #000000);
	background-color: #bda92e;
	color: colour-difference(#bda92e, #000000);
}
.ext-kia-color {
	color: #fc2d90;
}
.ext-kia-background-color {
	--background-color: #fc2d90;
	--color: colour-difference(#fc2d90, #000000);
	background-color: #fc2d90;
}
.ext-kia-all {
	--background-color: #fc2d90;
	--color: colour-difference(#fc2d90, #000000);
	background-color: #fc2d90;
	color: colour-difference(#fc2d90, #000000);
}
.ext-kne-color {
	color: #8b123b;
}
.ext-kne-background-color {
	--background-color: #8b123b;
	--color: colour-difference(#8b123b, #000000);
	background-color: #8b123b;
}
.ext-kne-all {
	--background-color: #8b123b;
	--color: colour-difference(#8b123b, #000000);
	background-color: #8b123b;
	color: colour-difference(#8b123b, #000000);
}
.ext-sse-color {
	color: #68dccf;
}
.ext-sse-background-color {
	--background-color: #68dccf;
	--color: colour-difference(#68dccf, #000000);
	background-color: #68dccf;
}
.ext-sse-all {
	--background-color: #68dccf;
	--color: colour-difference(#68dccf, #000000);
	background-color: #68dccf;
	color: colour-difference(#68dccf, #000000);
}
.ext-lasxml-color {
	color: #83b31f;
}
.ext-lasxml-background-color {
	--background-color: #83b31f;
	--color: colour-difference(#83b31f, #000000);
	background-color: #83b31f;
}
.ext-lasxml-all {
	--background-color: #83b31f;
	--color: colour-difference(#83b31f, #000000);
	background-color: #83b31f;
	color: colour-difference(#83b31f, #000000);
}
.ext-latex-color {
	color: #a4987a;
}
.ext-latex-background-color {
	--background-color: #a4987a;
	--color: colour-difference(#a4987a, #000000);
	background-color: #a4987a;
}
.ext-latex-all {
	--background-color: #a4987a;
	--color: colour-difference(#a4987a, #000000);
	background-color: #a4987a;
	color: colour-difference(#a4987a, #000000);
}
.ext-lbd-color {
	color: #38d41a;
}
.ext-lbd-background-color {
	--background-color: #38d41a;
	--color: colour-difference(#38d41a, #000000);
	background-color: #38d41a;
}
.ext-lbd-all {
	--background-color: #38d41a;
	--color: colour-difference(#38d41a, #000000);
	background-color: #38d41a;
	color: colour-difference(#38d41a, #000000);
}
.ext-lbe-color {
	color: #e38b22;
}
.ext-lbe-background-color {
	--background-color: #e38b22;
	--color: colour-difference(#e38b22, #000000);
	background-color: #e38b22;
}
.ext-lbe-all {
	--background-color: #e38b22;
	--color: colour-difference(#e38b22, #000000);
	background-color: #e38b22;
	color: colour-difference(#e38b22, #000000);
}
.ext-jam-color {
	color: #6ddbaa;
}
.ext-jam-background-color {
	--background-color: #6ddbaa;
	--color: colour-difference(#6ddbaa, #000000);
	background-color: #6ddbaa;
}
.ext-jam-all {
	--background-color: #6ddbaa;
	--color: colour-difference(#6ddbaa, #000000);
	background-color: #6ddbaa;
	color: colour-difference(#6ddbaa, #000000);
}
.ext-123-color {
	color: #f7cedf;
}
.ext-123-background-color {
	--background-color: #f7cedf;
	--color: colour-difference(#f7cedf, #000000);
	background-color: #f7cedf;
}
.ext-123-all {
	--background-color: #f7cedf;
	--color: colour-difference(#f7cedf, #000000);
	background-color: #f7cedf;
	color: colour-difference(#f7cedf, #000000);
}
.ext-apr-color {
	color: #c94e3c;
}
.ext-apr-background-color {
	--background-color: #c94e3c;
	--color: colour-difference(#c94e3c, #000000);
	background-color: #c94e3c;
}
.ext-apr-all {
	--background-color: #c94e3c;
	--color: colour-difference(#c94e3c, #000000);
	background-color: #c94e3c;
	color: colour-difference(#c94e3c, #000000);
}
.ext-pre-color {
	color: #76f717;
}
.ext-pre-background-color {
	--background-color: #76f717;
	--color: colour-difference(#76f717, #000000);
	background-color: #76f717;
}
.ext-pre-all {
	--background-color: #76f717;
	--color: colour-difference(#76f717, #000000);
	background-color: #76f717;
	color: colour-difference(#76f717, #000000);
}
.ext-nsf-color {
	color: #cf4320;
}
.ext-nsf-background-color {
	--background-color: #cf4320;
	--color: colour-difference(#cf4320, #000000);
	background-color: #cf4320;
}
.ext-nsf-all {
	--background-color: #cf4320;
	--color: colour-difference(#cf4320, #000000);
	background-color: #cf4320;
	color: colour-difference(#cf4320, #000000);
}
.ext-org-color {
	color: #6faee7;
}
.ext-org-background-color {
	--background-color: #6faee7;
	--color: colour-difference(#6faee7, #000000);
	background-color: #6faee7;
}
.ext-org-all {
	--background-color: #6faee7;
	--color: colour-difference(#6faee7, #000000);
	background-color: #6faee7;
	color: colour-difference(#6faee7, #000000);
}
.ext-scm-color {
	color: #b93819;
}
.ext-scm-background-color {
	--background-color: #b93819;
	--color: colour-difference(#b93819, #000000);
	background-color: #b93819;
}
.ext-scm-all {
	--background-color: #b93819;
	--color: colour-difference(#b93819, #000000);
	background-color: #b93819;
	color: colour-difference(#b93819, #000000);
}
.ext-lwp-color {
	color: #247be5;
}
.ext-lwp-background-color {
	--background-color: #247be5;
	--color: colour-difference(#247be5, #000000);
	background-color: #247be5;
}
.ext-lwp-all {
	--background-color: #247be5;
	--color: colour-difference(#247be5, #000000);
	background-color: #247be5;
	color: colour-difference(#247be5, #000000);
}
.ext-lvp-color {
	color: #acb72b;
}
.ext-lvp-background-color {
	--background-color: #acb72b;
	--color: colour-difference(#acb72b, #000000);
	background-color: #acb72b;
}
.ext-lvp-all {
	--background-color: #acb72b;
	--color: colour-difference(#acb72b, #000000);
	background-color: #acb72b;
	color: colour-difference(#acb72b, #000000);
}
.ext-m3u-color {
	color: #c14fb3;
}
.ext-m3u-background-color {
	--background-color: #c14fb3;
	--color: colour-difference(#c14fb3, #000000);
	background-color: #c14fb3;
}
.ext-m3u-all {
	--background-color: #c14fb3;
	--color: colour-difference(#c14fb3, #000000);
	background-color: #c14fb3;
	color: colour-difference(#c14fb3, #000000);
}
.ext-m4v-color {
	color: #854a73;
}
.ext-m4v-background-color {
	--background-color: #854a73;
	--color: colour-difference(#854a73, #000000);
	background-color: #854a73;
}
.ext-m4v-all {
	--background-color: #854a73;
	--color: colour-difference(#854a73, #000000);
	background-color: #854a73;
	color: colour-difference(#854a73, #000000);
}
.ext-hqx-color {
	color: #ab6697;
}
.ext-hqx-background-color {
	--background-color: #ab6697;
	--color: colour-difference(#ab6697, #000000);
	background-color: #ab6697;
}
.ext-hqx-all {
	--background-color: #ab6697;
	--color: colour-difference(#ab6697, #000000);
	background-color: #ab6697;
	color: colour-difference(#ab6697, #000000);
}
.ext-portpkg-color {
	color: #368c2f;
}
.ext-portpkg-background-color {
	--background-color: #368c2f;
	--color: colour-difference(#368c2f, #000000);
	background-color: #368c2f;
}
.ext-portpkg-all {
	--background-color: #368c2f;
	--color: colour-difference(#368c2f, #000000);
	background-color: #368c2f;
	color: colour-difference(#368c2f, #000000);
}
.ext-mgp-color {
	color: #d5a57a;
}
.ext-mgp-background-color {
	--background-color: #d5a57a;
	--color: colour-difference(#d5a57a, #000000);
	background-color: #d5a57a;
}
.ext-mgp-all {
	--background-color: #d5a57a;
	--color: colour-difference(#d5a57a, #000000);
	background-color: #d5a57a;
	color: colour-difference(#d5a57a, #000000);
}
.ext-mrc-color {
	color: #c33509;
}
.ext-mrc-background-color {
	--background-color: #c33509;
	--color: colour-difference(#c33509, #000000);
	background-color: #c33509;
}
.ext-mrc-all {
	--background-color: #c33509;
	--color: colour-difference(#c33509, #000000);
	background-color: #c33509;
	color: colour-difference(#c33509, #000000);
}
.ext-mrcx-color {
	color: #3a4852;
}
.ext-mrcx-background-color {
	--background-color: #3a4852;
	--color: colour-difference(#3a4852, #000000);
	background-color: #3a4852;
}
.ext-mrcx-all {
	--background-color: #3a4852;
	--color: colour-difference(#3a4852, #000000);
	background-color: #3a4852;
	color: colour-difference(#3a4852, #000000);
}
.ext-mxf-color {
	color: #e1d06d;
}
.ext-mxf-background-color {
	--background-color: #e1d06d;
	--color: colour-difference(#e1d06d, #000000);
	background-color: #e1d06d;
}
.ext-mxf-all {
	--background-color: #e1d06d;
	--color: colour-difference(#e1d06d, #000000);
	background-color: #e1d06d;
	color: colour-difference(#e1d06d, #000000);
}
.ext-nbp-color {
	color: #e16ad7;
}
.ext-nbp-background-color {
	--background-color: #e16ad7;
	--color: colour-difference(#e16ad7, #000000);
	background-color: #e16ad7;
}
.ext-nbp-all {
	--background-color: #e16ad7;
	--color: colour-difference(#e16ad7, #000000);
	background-color: #e16ad7;
	color: colour-difference(#e16ad7, #000000);
}
.ext-ma-color {
	color: #2ddbe6;
}
.ext-ma-background-color {
	--background-color: #2ddbe6;
	--color: colour-difference(#2ddbe6, #000000);
	background-color: #2ddbe6;
}
.ext-ma-all {
	--background-color: #2ddbe6;
	--color: colour-difference(#2ddbe6, #000000);
	background-color: #2ddbe6;
	color: colour-difference(#2ddbe6, #000000);
}
.ext-mathml-color {
	color: #c1daa1;
}
.ext-mathml-background-color {
	--background-color: #c1daa1;
	--color: colour-difference(#c1daa1, #000000);
	background-color: #c1daa1;
}
.ext-mathml-all {
	--background-color: #c1daa1;
	--color: colour-difference(#c1daa1, #000000);
	background-color: #c1daa1;
	color: colour-difference(#c1daa1, #000000);
}
.ext-mbox-color {
	color: #e4228f;
}
.ext-mbox-background-color {
	--background-color: #e4228f;
	--color: colour-difference(#e4228f, #000000);
	background-color: #e4228f;
}
.ext-mbox-all {
	--background-color: #e4228f;
	--color: colour-difference(#e4228f, #000000);
	background-color: #e4228f;
	color: colour-difference(#e4228f, #000000);
}
.ext-mc1-color {
	color: #b17d98;
}
.ext-mc1-background-color {
	--background-color: #b17d98;
	--color: colour-difference(#b17d98, #000000);
	background-color: #b17d98;
}
.ext-mc1-all {
	--background-color: #b17d98;
	--color: colour-difference(#b17d98, #000000);
	background-color: #b17d98;
	color: colour-difference(#b17d98, #000000);
}
.ext-mscml-color {
	color: #89c025;
}
.ext-mscml-background-color {
	--background-color: #89c025;
	--color: colour-difference(#89c025, #000000);
	background-color: #89c025;
}
.ext-mscml-all {
	--background-color: #89c025;
	--color: colour-difference(#89c025, #000000);
	background-color: #89c025;
	color: colour-difference(#89c025, #000000);
}
.ext-cdkey-color {
	color: #755b20;
}
.ext-cdkey-background-color {
	--background-color: #755b20;
	--color: colour-difference(#755b20, #000000);
	background-color: #755b20;
}
.ext-cdkey-all {
	--background-color: #755b20;
	--color: colour-difference(#755b20, #000000);
	background-color: #755b20;
	color: colour-difference(#755b20, #000000);
}
.ext-mwf-color {
	color: #355645;
}
.ext-mwf-background-color {
	--background-color: #355645;
	--color: colour-difference(#355645, #000000);
	background-color: #355645;
}
.ext-mwf-all {
	--background-color: #355645;
	--color: colour-difference(#355645, #000000);
	background-color: #355645;
	color: colour-difference(#355645, #000000);
}
.ext-mfm-color {
	color: #a78473;
}
.ext-mfm-background-color {
	--background-color: #a78473;
	--color: colour-difference(#a78473, #000000);
	background-color: #a78473;
}
.ext-mfm-all {
	--background-color: #a78473;
	--color: colour-difference(#a78473, #000000);
	background-color: #a78473;
	color: colour-difference(#a78473, #000000);
}
.ext-msh-color {
	color: #bc407d;
}
.ext-msh-background-color {
	--background-color: #bc407d;
	--color: colour-difference(#bc407d, #000000);
	background-color: #bc407d;
}
.ext-msh-all {
	--background-color: #bc407d;
	--color: colour-difference(#bc407d, #000000);
	background-color: #bc407d;
	color: colour-difference(#bc407d, #000000);
}
.ext-mads-color {
	color: #f3aeee;
}
.ext-mads-background-color {
	--background-color: #f3aeee;
	--color: colour-difference(#f3aeee, #000000);
	background-color: #f3aeee;
}
.ext-mads-all {
	--background-color: #f3aeee;
	--color: colour-difference(#f3aeee, #000000);
	background-color: #f3aeee;
	color: colour-difference(#f3aeee, #000000);
}
.ext-mets-color {
	color: #3eab54;
}
.ext-mets-background-color {
	--background-color: #3eab54;
	--color: colour-difference(#3eab54, #000000);
	background-color: #3eab54;
}
.ext-mets-all {
	--background-color: #3eab54;
	--color: colour-difference(#3eab54, #000000);
	background-color: #3eab54;
	color: colour-difference(#3eab54, #000000);
}
.ext-mods-color {
	color: #2a7ed5;
}
.ext-mods-background-color {
	--background-color: #2a7ed5;
	--color: colour-difference(#2a7ed5, #000000);
	background-color: #2a7ed5;
}
.ext-mods-all {
	--background-color: #2a7ed5;
	--color: colour-difference(#2a7ed5, #000000);
	background-color: #2a7ed5;
	color: colour-difference(#2a7ed5, #000000);
}
.ext-meta4-color {
	color: #42a8ff;
}
.ext-meta4-background-color {
	--background-color: #42a8ff;
	--color: colour-difference(#42a8ff, #000000);
	background-color: #42a8ff;
}
.ext-meta4-all {
	--background-color: #42a8ff;
	--color: colour-difference(#42a8ff, #000000);
	background-color: #42a8ff;
	color: colour-difference(#42a8ff, #000000);
}
.ext-mcd-color {
	color: #61b228;
}
.ext-mcd-background-color {
	--background-color: #61b228;
	--color: colour-difference(#61b228, #000000);
	background-color: #61b228;
}
.ext-mcd-all {
	--background-color: #61b228;
	--color: colour-difference(#61b228, #000000);
	background-color: #61b228;
	color: colour-difference(#61b228, #000000);
}
.ext-flo-color {
	color: #82bac1;
}
.ext-flo-background-color {
	--background-color: #82bac1;
	--color: colour-difference(#82bac1, #000000);
	background-color: #82bac1;
}
.ext-flo-all {
	--background-color: #82bac1;
	--color: colour-difference(#82bac1, #000000);
	background-color: #82bac1;
	color: colour-difference(#82bac1, #000000);
}
.ext-igx-color {
	color: #d3b8cb;
}
.ext-igx-background-color {
	--background-color: #d3b8cb;
	--color: colour-difference(#d3b8cb, #000000);
	background-color: #d3b8cb;
}
.ext-igx-all {
	--background-color: #d3b8cb;
	--color: colour-difference(#d3b8cb, #000000);
	background-color: #d3b8cb;
	color: colour-difference(#d3b8cb, #000000);
}
.ext-es3-color {
	color: #d9421c;
}
.ext-es3-background-color {
	--background-color: #d9421c;
	--color: colour-difference(#d9421c, #000000);
	background-color: #d9421c;
}
.ext-es3-all {
	--background-color: #d9421c;
	--color: colour-difference(#d9421c, #000000);
	background-color: #d9421c;
	color: colour-difference(#d9421c, #000000);
}
.ext-mdb-color {
	color: #d696f4;
}
.ext-mdb-background-color {
	--background-color: #d696f4;
	--color: colour-difference(#d696f4, #000000);
	background-color: #d696f4;
}
.ext-mdb-all {
	--background-color: #d696f4;
	--color: colour-difference(#d696f4, #000000);
	background-color: #d696f4;
	color: colour-difference(#d696f4, #000000);
}
.ext-asf-color {
	color: #de9fb4;
}
.ext-asf-background-color {
	--background-color: #de9fb4;
	--color: colour-difference(#de9fb4, #000000);
	background-color: #de9fb4;
}
.ext-asf-all {
	--background-color: #de9fb4;
	--color: colour-difference(#de9fb4, #000000);
	background-color: #de9fb4;
	color: colour-difference(#de9fb4, #000000);
}
.ext-exe-color {
	color: #c46b02;
}
.ext-exe-background-color {
	--background-color: #c46b02;
	--color: colour-difference(#c46b02, #000000);
	background-color: #c46b02;
}
.ext-exe-all {
	--background-color: #c46b02;
	--color: colour-difference(#c46b02, #000000);
	background-color: #c46b02;
	color: colour-difference(#c46b02, #000000);
}
.ext-cil-color {
	color: #b139af;
}
.ext-cil-background-color {
	--background-color: #b139af;
	--color: colour-difference(#b139af, #000000);
	background-color: #b139af;
}
.ext-cil-all {
	--background-color: #b139af;
	--color: colour-difference(#b139af, #000000);
	background-color: #b139af;
	color: colour-difference(#b139af, #000000);
}
.ext-cab-color {
	color: #a813ea;
}
.ext-cab-background-color {
	--background-color: #a813ea;
	--color: colour-difference(#a813ea, #000000);
	background-color: #a813ea;
}
.ext-cab-all {
	--background-color: #a813ea;
	--color: colour-difference(#a813ea, #000000);
	background-color: #a813ea;
	color: colour-difference(#a813ea, #000000);
}
.ext-ims-color {
	color: #813337;
}
.ext-ims-background-color {
	--background-color: #813337;
	--color: colour-difference(#813337, #000000);
	background-color: #813337;
}
.ext-ims-all {
	--background-color: #813337;
	--color: colour-difference(#813337, #000000);
	background-color: #813337;
	color: colour-difference(#813337, #000000);
}
.ext-application-color {
	color: #7d6e2f;
}
.ext-application-background-color {
	--background-color: #7d6e2f;
	--color: colour-difference(#7d6e2f, #000000);
	background-color: #7d6e2f;
}
.ext-application-all {
	--background-color: #7d6e2f;
	--color: colour-difference(#7d6e2f, #000000);
	background-color: #7d6e2f;
	color: colour-difference(#7d6e2f, #000000);
}
.ext-clp-color {
	color: #f200e1;
}
.ext-clp-background-color {
	--background-color: #f200e1;
	--color: colour-difference(#f200e1, #000000);
	background-color: #f200e1;
}
.ext-clp-all {
	--background-color: #f200e1;
	--color: colour-difference(#f200e1, #000000);
	background-color: #f200e1;
	color: colour-difference(#f200e1, #000000);
}
.ext-mdi-color {
	color: #6bf4e0;
}
.ext-mdi-background-color {
	--background-color: #6bf4e0;
	--color: colour-difference(#6bf4e0, #000000);
	background-color: #6bf4e0;
}
.ext-mdi-all {
	--background-color: #6bf4e0;
	--color: colour-difference(#6bf4e0, #000000);
	background-color: #6bf4e0;
	color: colour-difference(#6bf4e0, #000000);
}
.ext-eot-color {
	color: #d4657d;
}
.ext-eot-background-color {
	--background-color: #d4657d;
	--color: colour-difference(#d4657d, #000000);
	background-color: #d4657d;
}
.ext-eot-all {
	--background-color: #d4657d;
	--color: colour-difference(#d4657d, #000000);
	background-color: #d4657d;
	color: colour-difference(#d4657d, #000000);
}
.ext-xls-color {
	color: #507cd3;
}
.ext-xls-background-color {
	--background-color: #507cd3;
	--color: colour-difference(#507cd3, #000000);
	background-color: #507cd3;
}
.ext-xls-all {
	--background-color: #507cd3;
	--color: colour-difference(#507cd3, #000000);
	background-color: #507cd3;
	color: colour-difference(#507cd3, #000000);
}
.ext-xlam-color {
	color: #806e3f;
}
.ext-xlam-background-color {
	--background-color: #806e3f;
	--color: colour-difference(#806e3f, #000000);
	background-color: #806e3f;
}
.ext-xlam-all {
	--background-color: #806e3f;
	--color: colour-difference(#806e3f, #000000);
	background-color: #806e3f;
	color: colour-difference(#806e3f, #000000);
}
.ext-xlsb-color {
	color: #8d0ab0;
}
.ext-xlsb-background-color {
	--background-color: #8d0ab0;
	--color: colour-difference(#8d0ab0, #000000);
	background-color: #8d0ab0;
}
.ext-xlsb-all {
	--background-color: #8d0ab0;
	--color: colour-difference(#8d0ab0, #000000);
	background-color: #8d0ab0;
	color: colour-difference(#8d0ab0, #000000);
}
.ext-xltm-color {
	color: #63b44c;
}
.ext-xltm-background-color {
	--background-color: #63b44c;
	--color: colour-difference(#63b44c, #000000);
	background-color: #63b44c;
}
.ext-xltm-all {
	--background-color: #63b44c;
	--color: colour-difference(#63b44c, #000000);
	background-color: #63b44c;
	color: colour-difference(#63b44c, #000000);
}
.ext-xlsm-color {
	color: #69467a;
}
.ext-xlsm-background-color {
	--background-color: #69467a;
	--color: colour-difference(#69467a, #000000);
	background-color: #69467a;
}
.ext-xlsm-all {
	--background-color: #69467a;
	--color: colour-difference(#69467a, #000000);
	background-color: #69467a;
	color: colour-difference(#69467a, #000000);
}
.ext-chm-color {
	color: #a94c9a;
}
.ext-chm-background-color {
	--background-color: #a94c9a;
	--color: colour-difference(#a94c9a, #000000);
	background-color: #a94c9a;
}
.ext-chm-all {
	--background-color: #a94c9a;
	--color: colour-difference(#a94c9a, #000000);
	background-color: #a94c9a;
	color: colour-difference(#a94c9a, #000000);
}
.ext-crd-color {
	color: #589092;
}
.ext-crd-background-color {
	--background-color: #589092;
	--color: colour-difference(#589092, #000000);
	background-color: #589092;
}
.ext-crd-all {
	--background-color: #589092;
	--color: colour-difference(#589092, #000000);
	background-color: #589092;
	color: colour-difference(#589092, #000000);
}
.ext-lrm-color {
	color: #1f203b;
}
.ext-lrm-background-color {
	--background-color: #1f203b;
	--color: colour-difference(#1f203b, #000000);
	background-color: #1f203b;
}
.ext-lrm-all {
	--background-color: #1f203b;
	--color: colour-difference(#1f203b, #000000);
	background-color: #1f203b;
	color: colour-difference(#1f203b, #000000);
}
.ext-mvb-color {
	color: #96858f;
}
.ext-mvb-background-color {
	--background-color: #96858f;
	--color: colour-difference(#96858f, #000000);
	background-color: #96858f;
}
.ext-mvb-all {
	--background-color: #96858f;
	--color: colour-difference(#96858f, #000000);
	background-color: #96858f;
	color: colour-difference(#96858f, #000000);
}
.ext-mny-color {
	color: #16cce1;
}
.ext-mny-background-color {
	--background-color: #16cce1;
	--color: colour-difference(#16cce1, #000000);
	background-color: #16cce1;
}
.ext-mny-all {
	--background-color: #16cce1;
	--color: colour-difference(#16cce1, #000000);
	background-color: #16cce1;
	color: colour-difference(#16cce1, #000000);
}
.ext-pptx-color {
	color: #14d622;
}
.ext-pptx-background-color {
	--background-color: #14d622;
	--color: colour-difference(#14d622, #000000);
	background-color: #14d622;
}
.ext-pptx-all {
	--background-color: #14d622;
	--color: colour-difference(#14d622, #000000);
	background-color: #14d622;
	color: colour-difference(#14d622, #000000);
}
.ext-sldx-color {
	color: #c6c29e;
}
.ext-sldx-background-color {
	--background-color: #c6c29e;
	--color: colour-difference(#c6c29e, #000000);
	background-color: #c6c29e;
}
.ext-sldx-all {
	--background-color: #c6c29e;
	--color: colour-difference(#c6c29e, #000000);
	background-color: #c6c29e;
	color: colour-difference(#c6c29e, #000000);
}
.ext-ppsx-color {
	color: #cfa67f;
}
.ext-ppsx-background-color {
	--background-color: #cfa67f;
	--color: colour-difference(#cfa67f, #000000);
	background-color: #cfa67f;
}
.ext-ppsx-all {
	--background-color: #cfa67f;
	--color: colour-difference(#cfa67f, #000000);
	background-color: #cfa67f;
	color: colour-difference(#cfa67f, #000000);
}
.ext-potx-color {
	color: #807cb6;
}
.ext-potx-background-color {
	--background-color: #807cb6;
	--color: colour-difference(#807cb6, #000000);
	background-color: #807cb6;
}
.ext-potx-all {
	--background-color: #807cb6;
	--color: colour-difference(#807cb6, #000000);
	background-color: #807cb6;
	color: colour-difference(#807cb6, #000000);
}
.ext-xlsx-color {
	color: #539a0b;
}
.ext-xlsx-background-color {
	--background-color: #539a0b;
	--color: colour-difference(#539a0b, #000000);
	background-color: #539a0b;
}
.ext-xlsx-all {
	--background-color: #539a0b;
	--color: colour-difference(#539a0b, #000000);
	background-color: #539a0b;
	color: colour-difference(#539a0b, #000000);
}
.ext-xltx-color {
	color: #cfc51f;
}
.ext-xltx-background-color {
	--background-color: #cfc51f;
	--color: colour-difference(#cfc51f, #000000);
	background-color: #cfc51f;
}
.ext-xltx-all {
	--background-color: #cfc51f;
	--color: colour-difference(#cfc51f, #000000);
	background-color: #cfc51f;
	color: colour-difference(#cfc51f, #000000);
}
.ext-docx-color {
	color: #5e2609;
}
.ext-docx-background-color {
	--background-color: #5e2609;
	--color: colour-difference(#5e2609, #000000);
	background-color: #5e2609;
}
.ext-docx-all {
	--background-color: #5e2609;
	--color: colour-difference(#5e2609, #000000);
	background-color: #5e2609;
	color: colour-difference(#5e2609, #000000);
}
.ext-dotx-color {
	color: #3192b8;
}
.ext-dotx-background-color {
	--background-color: #3192b8;
	--color: colour-difference(#3192b8, #000000);
	background-color: #3192b8;
}
.ext-dotx-all {
	--background-color: #3192b8;
	--color: colour-difference(#3192b8, #000000);
	background-color: #3192b8;
	color: colour-difference(#3192b8, #000000);
}
.ext-obd-color {
	color: #f52162;
}
.ext-obd-background-color {
	--background-color: #f52162;
	--color: colour-difference(#f52162, #000000);
	background-color: #f52162;
}
.ext-obd-all {
	--background-color: #f52162;
	--color: colour-difference(#f52162, #000000);
	background-color: #f52162;
	color: colour-difference(#f52162, #000000);
}
.ext-thmx-color {
	color: #ca6b1b;
}
.ext-thmx-background-color {
	--background-color: #ca6b1b;
	--color: colour-difference(#ca6b1b, #000000);
	background-color: #ca6b1b;
}
.ext-thmx-all {
	--background-color: #ca6b1b;
	--color: colour-difference(#ca6b1b, #000000);
	background-color: #ca6b1b;
	color: colour-difference(#ca6b1b, #000000);
}
.ext-onetoc-color {
	color: #91cb2e;
}
.ext-onetoc-background-color {
	--background-color: #91cb2e;
	--color: colour-difference(#91cb2e, #000000);
	background-color: #91cb2e;
}
.ext-onetoc-all {
	--background-color: #91cb2e;
	--color: colour-difference(#91cb2e, #000000);
	background-color: #91cb2e;
	color: colour-difference(#91cb2e, #000000);
}
.ext-pya-color {
	color: #c6b1dd;
}
.ext-pya-background-color {
	--background-color: #c6b1dd;
	--color: colour-difference(#c6b1dd, #000000);
	background-color: #c6b1dd;
}
.ext-pya-all {
	--background-color: #c6b1dd;
	--color: colour-difference(#c6b1dd, #000000);
	background-color: #c6b1dd;
	color: colour-difference(#c6b1dd, #000000);
}
.ext-pyv-color {
	color: #6e43d8;
}
.ext-pyv-background-color {
	--background-color: #6e43d8;
	--color: colour-difference(#6e43d8, #000000);
	background-color: #6e43d8;
}
.ext-pyv-all {
	--background-color: #6e43d8;
	--color: colour-difference(#6e43d8, #000000);
	background-color: #6e43d8;
	color: colour-difference(#6e43d8, #000000);
}
.ext-ppt-color {
	color: #66e189;
}
.ext-ppt-background-color {
	--background-color: #66e189;
	--color: colour-difference(#66e189, #000000);
	background-color: #66e189;
}
.ext-ppt-all {
	--background-color: #66e189;
	--color: colour-difference(#66e189, #000000);
	background-color: #66e189;
	color: colour-difference(#66e189, #000000);
}
.ext-ppam-color {
	color: #964efe;
}
.ext-ppam-background-color {
	--background-color: #964efe;
	--color: colour-difference(#964efe, #000000);
	background-color: #964efe;
}
.ext-ppam-all {
	--background-color: #964efe;
	--color: colour-difference(#964efe, #000000);
	background-color: #964efe;
	color: colour-difference(#964efe, #000000);
}
.ext-sldm-color {
	color: #cbee53;
}
.ext-sldm-background-color {
	--background-color: #cbee53;
	--color: colour-difference(#cbee53, #000000);
	background-color: #cbee53;
}
.ext-sldm-all {
	--background-color: #cbee53;
	--color: colour-difference(#cbee53, #000000);
	background-color: #cbee53;
	color: colour-difference(#cbee53, #000000);
}
.ext-pptm-color {
	color: #88e495;
}
.ext-pptm-background-color {
	--background-color: #88e495;
	--color: colour-difference(#88e495, #000000);
	background-color: #88e495;
}
.ext-pptm-all {
	--background-color: #88e495;
	--color: colour-difference(#88e495, #000000);
	background-color: #88e495;
	color: colour-difference(#88e495, #000000);
}
.ext-ppsm-color {
	color: #1f7326;
}
.ext-ppsm-background-color {
	--background-color: #1f7326;
	--color: colour-difference(#1f7326, #000000);
	background-color: #1f7326;
}
.ext-ppsm-all {
	--background-color: #1f7326;
	--color: colour-difference(#1f7326, #000000);
	background-color: #1f7326;
	color: colour-difference(#1f7326, #000000);
}
.ext-potm-color {
	color: #38397d;
}
.ext-potm-background-color {
	--background-color: #38397d;
	--color: colour-difference(#38397d, #000000);
	background-color: #38397d;
}
.ext-potm-all {
	--background-color: #38397d;
	--color: colour-difference(#38397d, #000000);
	background-color: #38397d;
	color: colour-difference(#38397d, #000000);
}
.ext-mpp-color {
	color: #e53936;
}
.ext-mpp-background-color {
	--background-color: #e53936;
	--color: colour-difference(#e53936, #000000);
	background-color: #e53936;
}
.ext-mpp-all {
	--background-color: #e53936;
	--color: colour-difference(#e53936, #000000);
	background-color: #e53936;
	color: colour-difference(#e53936, #000000);
}
.ext-pub-color {
	color: #ec7e9f;
}
.ext-pub-background-color {
	--background-color: #ec7e9f;
	--color: colour-difference(#ec7e9f, #000000);
	background-color: #ec7e9f;
}
.ext-pub-all {
	--background-color: #ec7e9f;
	--color: colour-difference(#ec7e9f, #000000);
	background-color: #ec7e9f;
	color: colour-difference(#ec7e9f, #000000);
}
.ext-scd-color {
	color: #8d8ee2;
}
.ext-scd-background-color {
	--background-color: #8d8ee2;
	--color: colour-difference(#8d8ee2, #000000);
	background-color: #8d8ee2;
}
.ext-scd-all {
	--background-color: #8d8ee2;
	--color: colour-difference(#8d8ee2, #000000);
	background-color: #8d8ee2;
	color: colour-difference(#8d8ee2, #000000);
}
.ext-xap-color {
	color: #b46076;
}
.ext-xap-background-color {
	--background-color: #b46076;
	--color: colour-difference(#b46076, #000000);
	background-color: #b46076;
}
.ext-xap-all {
	--background-color: #b46076;
	--color: colour-difference(#b46076, #000000);
	background-color: #b46076;
	color: colour-difference(#b46076, #000000);
}
.ext-stl-color {
	color: #54d7ac;
}
.ext-stl-background-color {
	--background-color: #54d7ac;
	--color: colour-difference(#54d7ac, #000000);
	background-color: #54d7ac;
}
.ext-stl-all {
	--background-color: #54d7ac;
	--color: colour-difference(#54d7ac, #000000);
	background-color: #54d7ac;
	color: colour-difference(#54d7ac, #000000);
}
.ext-cat-color {
	color: #184bb1;
}
.ext-cat-background-color {
	--background-color: #184bb1;
	--color: colour-difference(#184bb1, #000000);
	background-color: #184bb1;
}
.ext-cat-all {
	--background-color: #184bb1;
	--color: colour-difference(#184bb1, #000000);
	background-color: #184bb1;
	color: colour-difference(#184bb1, #000000);
}
.ext-vsd-color {
	color: #a48e6b;
}
.ext-vsd-background-color {
	--background-color: #a48e6b;
	--color: colour-difference(#a48e6b, #000000);
	background-color: #a48e6b;
}
.ext-vsd-all {
	--background-color: #a48e6b;
	--color: colour-difference(#a48e6b, #000000);
	background-color: #a48e6b;
	color: colour-difference(#a48e6b, #000000);
}
.ext-vsdx-color {
	color: #4d8bd9;
}
.ext-vsdx-background-color {
	--background-color: #4d8bd9;
	--color: colour-difference(#4d8bd9, #000000);
	background-color: #4d8bd9;
}
.ext-vsdx-all {
	--background-color: #4d8bd9;
	--color: colour-difference(#4d8bd9, #000000);
	background-color: #4d8bd9;
	color: colour-difference(#4d8bd9, #000000);
}
.ext-wm-color {
	color: #dc0757;
}
.ext-wm-background-color {
	--background-color: #dc0757;
	--color: colour-difference(#dc0757, #000000);
	background-color: #dc0757;
}
.ext-wm-all {
	--background-color: #dc0757;
	--color: colour-difference(#dc0757, #000000);
	background-color: #dc0757;
	color: colour-difference(#dc0757, #000000);
}
.ext-wma-color {
	color: #3481ff;
}
.ext-wma-background-color {
	--background-color: #3481ff;
	--color: colour-difference(#3481ff, #000000);
	background-color: #3481ff;
}
.ext-wma-all {
	--background-color: #3481ff;
	--color: colour-difference(#3481ff, #000000);
	background-color: #3481ff;
	color: colour-difference(#3481ff, #000000);
}
.ext-wax-color {
	color: #1cb21f;
}
.ext-wax-background-color {
	--background-color: #1cb21f;
	--color: colour-difference(#1cb21f, #000000);
	background-color: #1cb21f;
}
.ext-wax-all {
	--background-color: #1cb21f;
	--color: colour-difference(#1cb21f, #000000);
	background-color: #1cb21f;
	color: colour-difference(#1cb21f, #000000);
}
.ext-wmx-color {
	color: #46aea2;
}
.ext-wmx-background-color {
	--background-color: #46aea2;
	--color: colour-difference(#46aea2, #000000);
	background-color: #46aea2;
}
.ext-wmx-all {
	--background-color: #46aea2;
	--color: colour-difference(#46aea2, #000000);
	background-color: #46aea2;
	color: colour-difference(#46aea2, #000000);
}
.ext-wmd-color {
	color: #475613;
}
.ext-wmd-background-color {
	--background-color: #475613;
	--color: colour-difference(#475613, #000000);
	background-color: #475613;
}
.ext-wmd-all {
	--background-color: #475613;
	--color: colour-difference(#475613, #000000);
	background-color: #475613;
	color: colour-difference(#475613, #000000);
}
.ext-wpl-color {
	color: #3bb8e4;
}
.ext-wpl-background-color {
	--background-color: #3bb8e4;
	--color: colour-difference(#3bb8e4, #000000);
	background-color: #3bb8e4;
}
.ext-wpl-all {
	--background-color: #3bb8e4;
	--color: colour-difference(#3bb8e4, #000000);
	background-color: #3bb8e4;
	color: colour-difference(#3bb8e4, #000000);
}
.ext-wmz-color {
	color: #e4bb48;
}
.ext-wmz-background-color {
	--background-color: #e4bb48;
	--color: colour-difference(#e4bb48, #000000);
	background-color: #e4bb48;
}
.ext-wmz-all {
	--background-color: #e4bb48;
	--color: colour-difference(#e4bb48, #000000);
	background-color: #e4bb48;
	color: colour-difference(#e4bb48, #000000);
}
.ext-wmv-color {
	color: #123081;
}
.ext-wmv-background-color {
	--background-color: #123081;
	--color: colour-difference(#123081, #000000);
	background-color: #123081;
}
.ext-wmv-all {
	--background-color: #123081;
	--color: colour-difference(#123081, #000000);
	background-color: #123081;
	color: colour-difference(#123081, #000000);
}
.ext-wvx-color {
	color: #5930dd;
}
.ext-wvx-background-color {
	--background-color: #5930dd;
	--color: colour-difference(#5930dd, #000000);
	background-color: #5930dd;
}
.ext-wvx-all {
	--background-color: #5930dd;
	--color: colour-difference(#5930dd, #000000);
	background-color: #5930dd;
	color: colour-difference(#5930dd, #000000);
}
.ext-wmf-color {
	color: #b269db;
}
.ext-wmf-background-color {
	--background-color: #b269db;
	--color: colour-difference(#b269db, #000000);
	background-color: #b269db;
}
.ext-wmf-all {
	--background-color: #b269db;
	--color: colour-difference(#b269db, #000000);
	background-color: #b269db;
	color: colour-difference(#b269db, #000000);
}
.ext-trm-color {
	color: #78d6ce;
}
.ext-trm-background-color {
	--background-color: #78d6ce;
	--color: colour-difference(#78d6ce, #000000);
	background-color: #78d6ce;
}
.ext-trm-all {
	--background-color: #78d6ce;
	--color: colour-difference(#78d6ce, #000000);
	background-color: #78d6ce;
	color: colour-difference(#78d6ce, #000000);
}
.ext-doc-color {
	color: #2f53d3;
}
.ext-doc-background-color {
	--background-color: #2f53d3;
	--color: colour-difference(#2f53d3, #000000);
	background-color: #2f53d3;
}
.ext-doc-all {
	--background-color: #2f53d3;
	--color: colour-difference(#2f53d3, #000000);
	background-color: #2f53d3;
	color: colour-difference(#2f53d3, #000000);
}
.ext-docm-color {
	color: #6257da;
}
.ext-docm-background-color {
	--background-color: #6257da;
	--color: colour-difference(#6257da, #000000);
	background-color: #6257da;
}
.ext-docm-all {
	--background-color: #6257da;
	--color: colour-difference(#6257da, #000000);
	background-color: #6257da;
	color: colour-difference(#6257da, #000000);
}
.ext-dotm-color {
	color: #a1c5d5;
}
.ext-dotm-background-color {
	--background-color: #a1c5d5;
	--color: colour-difference(#a1c5d5, #000000);
	background-color: #a1c5d5;
}
.ext-dotm-all {
	--background-color: #a1c5d5;
	--color: colour-difference(#a1c5d5, #000000);
	background-color: #a1c5d5;
	color: colour-difference(#a1c5d5, #000000);
}
.ext-wri-color {
	color: #d1cd7f;
}
.ext-wri-background-color {
	--background-color: #d1cd7f;
	--color: colour-difference(#d1cd7f, #000000);
	background-color: #d1cd7f;
}
.ext-wri-all {
	--background-color: #d1cd7f;
	--color: colour-difference(#d1cd7f, #000000);
	background-color: #d1cd7f;
	color: colour-difference(#d1cd7f, #000000);
}
.ext-wps-color {
	color: #384586;
}
.ext-wps-background-color {
	--background-color: #384586;
	--color: colour-difference(#384586, #000000);
	background-color: #384586;
}
.ext-wps-all {
	--background-color: #384586;
	--color: colour-difference(#384586, #000000);
	background-color: #384586;
	color: colour-difference(#384586, #000000);
}
.ext-xbap-color {
	color: #f88ed4;
}
.ext-xbap-background-color {
	--background-color: #f88ed4;
	--color: colour-difference(#f88ed4, #000000);
	background-color: #f88ed4;
}
.ext-xbap-all {
	--background-color: #f88ed4;
	--color: colour-difference(#f88ed4, #000000);
	background-color: #f88ed4;
	color: colour-difference(#f88ed4, #000000);
}
.ext-xps-color {
	color: #8fd61e;
}
.ext-xps-background-color {
	--background-color: #8fd61e;
	--color: colour-difference(#8fd61e, #000000);
	background-color: #8fd61e;
}
.ext-xps-all {
	--background-color: #8fd61e;
	--color: colour-difference(#8fd61e, #000000);
	background-color: #8fd61e;
	color: colour-difference(#8fd61e, #000000);
}
.ext-mid-color {
	color: #65b761;
}
.ext-mid-background-color {
	--background-color: #65b761;
	--color: colour-difference(#65b761, #000000);
	background-color: #65b761;
}
.ext-mid-all {
	--background-color: #65b761;
	--color: colour-difference(#65b761, #000000);
	background-color: #65b761;
	color: colour-difference(#65b761, #000000);
}
.ext-mpy-color {
	color: #2cdfbf;
}
.ext-mpy-background-color {
	--background-color: #2cdfbf;
	--color: colour-difference(#2cdfbf, #000000);
	background-color: #2cdfbf;
}
.ext-mpy-all {
	--background-color: #2cdfbf;
	--color: colour-difference(#2cdfbf, #000000);
	background-color: #2cdfbf;
	color: colour-difference(#2cdfbf, #000000);
}
.ext-afp-color {
	color: #9f356f;
}
.ext-afp-background-color {
	--background-color: #9f356f;
	--color: colour-difference(#9f356f, #000000);
	background-color: #9f356f;
}
.ext-afp-all {
	--background-color: #9f356f;
	--color: colour-difference(#9f356f, #000000);
	background-color: #9f356f;
	color: colour-difference(#9f356f, #000000);
}
.ext-rms-color {
	color: #8b6b37;
}
.ext-rms-background-color {
	--background-color: #8b6b37;
	--color: colour-difference(#8b6b37, #000000);
	background-color: #8b6b37;
}
.ext-rms-all {
	--background-color: #8b6b37;
	--color: colour-difference(#8b6b37, #000000);
	background-color: #8b6b37;
	color: colour-difference(#8b6b37, #000000);
}
.ext-tmo-color {
	color: #ee6a29;
}
.ext-tmo-background-color {
	--background-color: #ee6a29;
	--color: colour-difference(#ee6a29, #000000);
	background-color: #ee6a29;
}
.ext-tmo-all {
	--background-color: #ee6a29;
	--color: colour-difference(#ee6a29, #000000);
	background-color: #ee6a29;
	color: colour-difference(#ee6a29, #000000);
}
.ext-prc-color {
	color: #7a502b;
}
.ext-prc-background-color {
	--background-color: #7a502b;
	--color: colour-difference(#7a502b, #000000);
	background-color: #7a502b;
}
.ext-prc-all {
	--background-color: #7a502b;
	--color: colour-difference(#7a502b, #000000);
	background-color: #7a502b;
	color: colour-difference(#7a502b, #000000);
}
.ext-mbk-color {
	color: #e3cc3f;
}
.ext-mbk-background-color {
	--background-color: #e3cc3f;
	--color: colour-difference(#e3cc3f, #000000);
	background-color: #e3cc3f;
}
.ext-mbk-all {
	--background-color: #e3cc3f;
	--color: colour-difference(#e3cc3f, #000000);
	background-color: #e3cc3f;
	color: colour-difference(#e3cc3f, #000000);
}
.ext-dis-color {
	color: #cf0700;
}
.ext-dis-background-color {
	--background-color: #cf0700;
	--color: colour-difference(#cf0700, #000000);
	background-color: #cf0700;
}
.ext-dis-all {
	--background-color: #cf0700;
	--color: colour-difference(#cf0700, #000000);
	background-color: #cf0700;
	color: colour-difference(#cf0700, #000000);
}
.ext-plc-color {
	color: #6307a0;
}
.ext-plc-background-color {
	--background-color: #6307a0;
	--color: colour-difference(#6307a0, #000000);
	background-color: #6307a0;
}
.ext-plc-all {
	--background-color: #6307a0;
	--color: colour-difference(#6307a0, #000000);
	background-color: #6307a0;
	color: colour-difference(#6307a0, #000000);
}
.ext-mqy-color {
	color: #1f701f;
}
.ext-mqy-background-color {
	--background-color: #1f701f;
	--color: colour-difference(#1f701f, #000000);
	background-color: #1f701f;
}
.ext-mqy-all {
	--background-color: #1f701f;
	--color: colour-difference(#1f701f, #000000);
	background-color: #1f701f;
	color: colour-difference(#1f701f, #000000);
}
.ext-msl-color {
	color: #27c3ae;
}
.ext-msl-background-color {
	--background-color: #27c3ae;
	--color: colour-difference(#27c3ae, #000000);
	background-color: #27c3ae;
}
.ext-msl-all {
	--background-color: #27c3ae;
	--color: colour-difference(#27c3ae, #000000);
	background-color: #27c3ae;
	color: colour-difference(#27c3ae, #000000);
}
.ext-txf-color {
	color: #d46aff;
}
.ext-txf-background-color {
	--background-color: #d46aff;
	--color: colour-difference(#d46aff, #000000);
	background-color: #d46aff;
}
.ext-txf-all {
	--background-color: #d46aff;
	--color: colour-difference(#d46aff, #000000);
	background-color: #d46aff;
	color: colour-difference(#d46aff, #000000);
}
.ext-daf-color {
	color: #dbe067;
}
.ext-daf-background-color {
	--background-color: #dbe067;
	--color: colour-difference(#dbe067, #000000);
	background-color: #dbe067;
}
.ext-daf-all {
	--background-color: #dbe067;
	--color: colour-difference(#dbe067, #000000);
	background-color: #dbe067;
	color: colour-difference(#dbe067, #000000);
}
.ext-fly-color {
	color: #a62610;
}
.ext-fly-background-color {
	--background-color: #a62610;
	--color: colour-difference(#a62610, #000000);
	background-color: #a62610;
}
.ext-fly-all {
	--background-color: #a62610;
	--color: colour-difference(#a62610, #000000);
	background-color: #a62610;
	color: colour-difference(#a62610, #000000);
}
.ext-mpc-color {
	color: #5b78b5;
}
.ext-mpc-background-color {
	--background-color: #5b78b5;
	--color: colour-difference(#5b78b5, #000000);
	background-color: #5b78b5;
}
.ext-mpc-all {
	--background-color: #5b78b5;
	--color: colour-difference(#5b78b5, #000000);
	background-color: #5b78b5;
	color: colour-difference(#5b78b5, #000000);
}
.ext-mpn-color {
	color: #ee6b55;
}
.ext-mpn-background-color {
	--background-color: #ee6b55;
	--color: colour-difference(#ee6b55, #000000);
	background-color: #ee6b55;
}
.ext-mpn-all {
	--background-color: #ee6b55;
	--color: colour-difference(#ee6b55, #000000);
	background-color: #ee6b55;
	color: colour-difference(#ee6b55, #000000);
}
.ext-mj2-color {
	color: #930597;
}
.ext-mj2-background-color {
	--background-color: #930597;
	--color: colour-difference(#930597, #000000);
	background-color: #930597;
}
.ext-mj2-all {
	--background-color: #930597;
	--color: colour-difference(#930597, #000000);
	background-color: #930597;
	color: colour-difference(#930597, #000000);
}
.ext-mpga-color {
	color: #30db10;
}
.ext-mpga-background-color {
	--background-color: #30db10;
	--color: colour-difference(#30db10, #000000);
	background-color: #30db10;
}
.ext-mpga-all {
	--background-color: #30db10;
	--color: colour-difference(#30db10, #000000);
	background-color: #30db10;
	color: colour-difference(#30db10, #000000);
}
.ext-mxu-color {
	color: #c829b4;
}
.ext-mxu-background-color {
	--background-color: #c829b4;
	--color: colour-difference(#c829b4, #000000);
	background-color: #c829b4;
}
.ext-mxu-all {
	--background-color: #c829b4;
	--color: colour-difference(#c829b4, #000000);
	background-color: #c829b4;
	color: colour-difference(#c829b4, #000000);
}
.ext-mpeg-color {
	color: #8aa062;
}
.ext-mpeg-background-color {
	--background-color: #8aa062;
	--color: colour-difference(#8aa062, #000000);
	background-color: #8aa062;
}
.ext-mpeg-all {
	--background-color: #8aa062;
	--color: colour-difference(#8aa062, #000000);
	background-color: #8aa062;
	color: colour-difference(#8aa062, #000000);
}
.ext-m21-color {
	color: #e516b8;
}
.ext-m21-background-color {
	--background-color: #e516b8;
	--color: colour-difference(#e516b8, #000000);
	background-color: #e516b8;
}
.ext-m21-all {
	--background-color: #e516b8;
	--color: colour-difference(#e516b8, #000000);
	background-color: #e516b8;
	color: colour-difference(#e516b8, #000000);
}
.ext-mp4a-color {
	color: #962d1d;
}
.ext-mp4a-background-color {
	--background-color: #962d1d;
	--color: colour-difference(#962d1d, #000000);
	background-color: #962d1d;
}
.ext-mp4a-all {
	--background-color: #962d1d;
	--color: colour-difference(#962d1d, #000000);
	background-color: #962d1d;
	color: colour-difference(#962d1d, #000000);
}
.ext-mp4-color {
	color: #77e53f;
}
.ext-mp4-background-color {
	--background-color: #77e53f;
	--color: colour-difference(#77e53f, #000000);
	background-color: #77e53f;
}
.ext-mp4-all {
	--background-color: #77e53f;
	--color: colour-difference(#77e53f, #000000);
	background-color: #77e53f;
	color: colour-difference(#77e53f, #000000);
}
.ext-m3u8-color {
	color: #57e769;
}
.ext-m3u8-background-color {
	--background-color: #57e769;
	--color: colour-difference(#57e769, #000000);
	background-color: #57e769;
}
.ext-m3u8-all {
	--background-color: #57e769;
	--color: colour-difference(#57e769, #000000);
	background-color: #57e769;
	color: colour-difference(#57e769, #000000);
}
.ext-mus-color {
	color: #9febd0;
}
.ext-mus-background-color {
	--background-color: #9febd0;
	--color: colour-difference(#9febd0, #000000);
	background-color: #9febd0;
}
.ext-mus-all {
	--background-color: #9febd0;
	--color: colour-difference(#9febd0, #000000);
	background-color: #9febd0;
	color: colour-difference(#9febd0, #000000);
}
.ext-msty-color {
	color: #b53cc6;
}
.ext-msty-background-color {
	--background-color: #b53cc6;
	--color: colour-difference(#b53cc6, #000000);
	background-color: #b53cc6;
}
.ext-msty-all {
	--background-color: #b53cc6;
	--color: colour-difference(#b53cc6, #000000);
	background-color: #b53cc6;
	color: colour-difference(#b53cc6, #000000);
}
.ext-mxml-color {
	color: #282f57;
}
.ext-mxml-background-color {
	--background-color: #282f57;
	--color: colour-difference(#282f57, #000000);
	background-color: #282f57;
}
.ext-mxml-all {
	--background-color: #282f57;
	--color: colour-difference(#282f57, #000000);
	background-color: #282f57;
	color: colour-difference(#282f57, #000000);
}
.ext-ngdat-color {
	color: #aa6223;
}
.ext-ngdat-background-color {
	--background-color: #aa6223;
	--color: colour-difference(#aa6223, #000000);
	background-color: #aa6223;
}
.ext-ngdat-all {
	--background-color: #aa6223;
	--color: colour-difference(#aa6223, #000000);
	background-color: #aa6223;
	color: colour-difference(#aa6223, #000000);
}
.ext-n-gage-color {
	color: #ff8b8d;
}
.ext-n-gage-background-color {
	--background-color: #ff8b8d;
	--color: colour-difference(#ff8b8d, #000000);
	background-color: #ff8b8d;
}
.ext-n-gage-all {
	--background-color: #ff8b8d;
	--color: colour-difference(#ff8b8d, #000000);
	background-color: #ff8b8d;
	color: colour-difference(#ff8b8d, #000000);
}
.ext-ncx-color {
	color: #9bfb61;
}
.ext-ncx-background-color {
	--background-color: #9bfb61;
	--color: colour-difference(#9bfb61, #000000);
	background-color: #9bfb61;
}
.ext-ncx-all {
	--background-color: #9bfb61;
	--color: colour-difference(#9bfb61, #000000);
	background-color: #9bfb61;
	color: colour-difference(#9bfb61, #000000);
}
.ext-nc-color {
	color: #6539c1;
}
.ext-nc-background-color {
	--background-color: #6539c1;
	--color: colour-difference(#6539c1, #000000);
	background-color: #6539c1;
}
.ext-nc-all {
	--background-color: #6539c1;
	--color: colour-difference(#6539c1, #000000);
	background-color: #6539c1;
	color: colour-difference(#6539c1, #000000);
}
.ext-nlu-color {
	color: #545cc2;
}
.ext-nlu-background-color {
	--background-color: #545cc2;
	--color: colour-difference(#545cc2, #000000);
	background-color: #545cc2;
}
.ext-nlu-all {
	--background-color: #545cc2;
	--color: colour-difference(#545cc2, #000000);
	background-color: #545cc2;
	color: colour-difference(#545cc2, #000000);
}
.ext-dna-color {
	color: #60cb76;
}
.ext-dna-background-color {
	--background-color: #60cb76;
	--color: colour-difference(#60cb76, #000000);
	background-color: #60cb76;
}
.ext-dna-all {
	--background-color: #60cb76;
	--color: colour-difference(#60cb76, #000000);
	background-color: #60cb76;
	color: colour-difference(#60cb76, #000000);
}
.ext-nnd-color {
	color: #bf2410;
}
.ext-nnd-background-color {
	--background-color: #bf2410;
	--color: colour-difference(#bf2410, #000000);
	background-color: #bf2410;
}
.ext-nnd-all {
	--background-color: #bf2410;
	--color: colour-difference(#bf2410, #000000);
	background-color: #bf2410;
	color: colour-difference(#bf2410, #000000);
}
.ext-nns-color {
	color: #3c6fc3;
}
.ext-nns-background-color {
	--background-color: #3c6fc3;
	--color: colour-difference(#3c6fc3, #000000);
	background-color: #3c6fc3;
}
.ext-nns-all {
	--background-color: #3c6fc3;
	--color: colour-difference(#3c6fc3, #000000);
	background-color: #3c6fc3;
	color: colour-difference(#3c6fc3, #000000);
}
.ext-nnw-color {
	color: #8e41ff;
}
.ext-nnw-background-color {
	--background-color: #8e41ff;
	--color: colour-difference(#8e41ff, #000000);
	background-color: #8e41ff;
}
.ext-nnw-all {
	--background-color: #8e41ff;
	--color: colour-difference(#8e41ff, #000000);
	background-color: #8e41ff;
	color: colour-difference(#8e41ff, #000000);
}
.ext-rpst-color {
	color: #9371a0;
}
.ext-rpst-background-color {
	--background-color: #9371a0;
	--color: colour-difference(#9371a0, #000000);
	background-color: #9371a0;
}
.ext-rpst-all {
	--background-color: #9371a0;
	--color: colour-difference(#9371a0, #000000);
	background-color: #9371a0;
	color: colour-difference(#9371a0, #000000);
}
.ext-rpss-color {
	color: #fa8e91;
}
.ext-rpss-background-color {
	--background-color: #fa8e91;
	--color: colour-difference(#fa8e91, #000000);
	background-color: #fa8e91;
}
.ext-rpss-all {
	--background-color: #fa8e91;
	--color: colour-difference(#fa8e91, #000000);
	background-color: #fa8e91;
	color: colour-difference(#fa8e91, #000000);
}
.ext-n3-color {
	color: #21e280;
}
.ext-n3-background-color {
	--background-color: #21e280;
	--color: colour-difference(#21e280, #000000);
	background-color: #21e280;
}
.ext-n3-all {
	--background-color: #21e280;
	--color: colour-difference(#21e280, #000000);
	background-color: #21e280;
	color: colour-difference(#21e280, #000000);
}
.ext-edm-color {
	color: #f9282b;
}
.ext-edm-background-color {
	--background-color: #f9282b;
	--color: colour-difference(#f9282b, #000000);
	background-color: #f9282b;
}
.ext-edm-all {
	--background-color: #f9282b;
	--color: colour-difference(#f9282b, #000000);
	background-color: #f9282b;
	color: colour-difference(#f9282b, #000000);
}
.ext-edx-color {
	color: #9a15f1;
}
.ext-edx-background-color {
	--background-color: #9a15f1;
	--color: colour-difference(#9a15f1, #000000);
	background-color: #9a15f1;
}
.ext-edx-all {
	--background-color: #9a15f1;
	--color: colour-difference(#9a15f1, #000000);
	background-color: #9a15f1;
	color: colour-difference(#9a15f1, #000000);
}
.ext-ext-color {
	color: #65ceef;
}
.ext-ext-background-color {
	--background-color: #65ceef;
	--color: colour-difference(#65ceef, #000000);
	background-color: #65ceef;
}
.ext-ext-all {
	--background-color: #65ceef;
	--color: colour-difference(#65ceef, #000000);
	background-color: #65ceef;
	color: colour-difference(#65ceef, #000000);
}
.ext-gph-color {
	color: #d159a5;
}
.ext-gph-background-color {
	--background-color: #d159a5;
	--color: colour-difference(#d159a5, #000000);
	background-color: #d159a5;
}
.ext-gph-all {
	--background-color: #d159a5;
	--color: colour-difference(#d159a5, #000000);
	background-color: #d159a5;
	color: colour-difference(#d159a5, #000000);
}
.ext-ecelp4800-color {
	color: #28c9db;
}
.ext-ecelp4800-background-color {
	--background-color: #28c9db;
	--color: colour-difference(#28c9db, #000000);
	background-color: #28c9db;
}
.ext-ecelp4800-all {
	--background-color: #28c9db;
	--color: colour-difference(#28c9db, #000000);
	background-color: #28c9db;
	color: colour-difference(#28c9db, #000000);
}
.ext-ecelp7470-color {
	color: #10145f;
}
.ext-ecelp7470-background-color {
	--background-color: #10145f;
	--color: colour-difference(#10145f, #000000);
	background-color: #10145f;
}
.ext-ecelp7470-all {
	--background-color: #10145f;
	--color: colour-difference(#10145f, #000000);
	background-color: #10145f;
	color: colour-difference(#10145f, #000000);
}
.ext-ecelp9600-color {
	color: #1b51e4;
}
.ext-ecelp9600-background-color {
	--background-color: #1b51e4;
	--color: colour-difference(#1b51e4, #000000);
	background-color: #1b51e4;
}
.ext-ecelp9600-all {
	--background-color: #1b51e4;
	--color: colour-difference(#1b51e4, #000000);
	background-color: #1b51e4;
	color: colour-difference(#1b51e4, #000000);
}
.ext-oda-color {
	color: #6ebc6c;
}
.ext-oda-background-color {
	--background-color: #6ebc6c;
	--color: colour-difference(#6ebc6c, #000000);
	background-color: #6ebc6c;
}
.ext-oda-all {
	--background-color: #6ebc6c;
	--color: colour-difference(#6ebc6c, #000000);
	background-color: #6ebc6c;
	color: colour-difference(#6ebc6c, #000000);
}
.ext-ogx-color {
	color: #c766c8;
}
.ext-ogx-background-color {
	--background-color: #c766c8;
	--color: colour-difference(#c766c8, #000000);
	background-color: #c766c8;
}
.ext-ogx-all {
	--background-color: #c766c8;
	--color: colour-difference(#c766c8, #000000);
	background-color: #c766c8;
	color: colour-difference(#c766c8, #000000);
}
.ext-oga-color {
	color: #31931c;
}
.ext-oga-background-color {
	--background-color: #31931c;
	--color: colour-difference(#31931c, #000000);
	background-color: #31931c;
}
.ext-oga-all {
	--background-color: #31931c;
	--color: colour-difference(#31931c, #000000);
	background-color: #31931c;
	color: colour-difference(#31931c, #000000);
}
.ext-ogv-color {
	color: #718692;
}
.ext-ogv-background-color {
	--background-color: #718692;
	--color: colour-difference(#718692, #000000);
	background-color: #718692;
}
.ext-ogv-all {
	--background-color: #718692;
	--color: colour-difference(#718692, #000000);
	background-color: #718692;
	color: colour-difference(#718692, #000000);
}
.ext-dd2-color {
	color: #4b7ba5;
}
.ext-dd2-background-color {
	--background-color: #4b7ba5;
	--color: colour-difference(#4b7ba5, #000000);
	background-color: #4b7ba5;
}
.ext-dd2-all {
	--background-color: #4b7ba5;
	--color: colour-difference(#4b7ba5, #000000);
	background-color: #4b7ba5;
	color: colour-difference(#4b7ba5, #000000);
}
.ext-oth-color {
	color: #e65874;
}
.ext-oth-background-color {
	--background-color: #e65874;
	--color: colour-difference(#e65874, #000000);
	background-color: #e65874;
}
.ext-oth-all {
	--background-color: #e65874;
	--color: colour-difference(#e65874, #000000);
	background-color: #e65874;
	color: colour-difference(#e65874, #000000);
}
.ext-opf-color {
	color: #74821f;
}
.ext-opf-background-color {
	--background-color: #74821f;
	--color: colour-difference(#74821f, #000000);
	background-color: #74821f;
}
.ext-opf-all {
	--background-color: #74821f;
	--color: colour-difference(#74821f, #000000);
	background-color: #74821f;
	color: colour-difference(#74821f, #000000);
}
.ext-qbo-color {
	color: #2f3979;
}
.ext-qbo-background-color {
	--background-color: #2f3979;
	--color: colour-difference(#2f3979, #000000);
	background-color: #2f3979;
}
.ext-qbo-all {
	--background-color: #2f3979;
	--color: colour-difference(#2f3979, #000000);
	background-color: #2f3979;
	color: colour-difference(#2f3979, #000000);
}
.ext-oxt-color {
	color: #c405a2;
}
.ext-oxt-background-color {
	--background-color: #c405a2;
	--color: colour-difference(#c405a2, #000000);
	background-color: #c405a2;
}
.ext-oxt-all {
	--background-color: #c405a2;
	--color: colour-difference(#c405a2, #000000);
	background-color: #c405a2;
	color: colour-difference(#c405a2, #000000);
}
.ext-osf-color {
	color: #40b453;
}
.ext-osf-background-color {
	--background-color: #40b453;
	--color: colour-difference(#40b453, #000000);
	background-color: #40b453;
}
.ext-osf-all {
	--background-color: #40b453;
	--color: colour-difference(#40b453, #000000);
	background-color: #40b453;
	color: colour-difference(#40b453, #000000);
}
.ext-weba-color {
	color: #fc3ee3;
}
.ext-weba-background-color {
	--background-color: #fc3ee3;
	--color: colour-difference(#fc3ee3, #000000);
	background-color: #fc3ee3;
}
.ext-weba-all {
	--background-color: #fc3ee3;
	--color: colour-difference(#fc3ee3, #000000);
	background-color: #fc3ee3;
	color: colour-difference(#fc3ee3, #000000);
}
.ext-webm-color {
	color: #a59c27;
}
.ext-webm-background-color {
	--background-color: #a59c27;
	--color: colour-difference(#a59c27, #000000);
	background-color: #a59c27;
}
.ext-webm-all {
	--background-color: #a59c27;
	--color: colour-difference(#a59c27, #000000);
	background-color: #a59c27;
	color: colour-difference(#a59c27, #000000);
}
.ext-odc-color {
	color: #3c7abc;
}
.ext-odc-background-color {
	--background-color: #3c7abc;
	--color: colour-difference(#3c7abc, #000000);
	background-color: #3c7abc;
}
.ext-odc-all {
	--background-color: #3c7abc;
	--color: colour-difference(#3c7abc, #000000);
	background-color: #3c7abc;
	color: colour-difference(#3c7abc, #000000);
}
.ext-otc-color {
	color: #653e53;
}
.ext-otc-background-color {
	--background-color: #653e53;
	--color: colour-difference(#653e53, #000000);
	background-color: #653e53;
}
.ext-otc-all {
	--background-color: #653e53;
	--color: colour-difference(#653e53, #000000);
	background-color: #653e53;
	color: colour-difference(#653e53, #000000);
}
.ext-odb-color {
	color: #1d2fcf;
}
.ext-odb-background-color {
	--background-color: #1d2fcf;
	--color: colour-difference(#1d2fcf, #000000);
	background-color: #1d2fcf;
}
.ext-odb-all {
	--background-color: #1d2fcf;
	--color: colour-difference(#1d2fcf, #000000);
	background-color: #1d2fcf;
	color: colour-difference(#1d2fcf, #000000);
}
.ext-odf-color {
	color: #866a79;
}
.ext-odf-background-color {
	--background-color: #866a79;
	--color: colour-difference(#866a79, #000000);
	background-color: #866a79;
}
.ext-odf-all {
	--background-color: #866a79;
	--color: colour-difference(#866a79, #000000);
	background-color: #866a79;
	color: colour-difference(#866a79, #000000);
}
.ext-odft-color {
	color: #85dd2f;
}
.ext-odft-background-color {
	--background-color: #85dd2f;
	--color: colour-difference(#85dd2f, #000000);
	background-color: #85dd2f;
}
.ext-odft-all {
	--background-color: #85dd2f;
	--color: colour-difference(#85dd2f, #000000);
	background-color: #85dd2f;
	color: colour-difference(#85dd2f, #000000);
}
.ext-odg-color {
	color: #478372;
}
.ext-odg-background-color {
	--background-color: #478372;
	--color: colour-difference(#478372, #000000);
	background-color: #478372;
}
.ext-odg-all {
	--background-color: #478372;
	--color: colour-difference(#478372, #000000);
	background-color: #478372;
	color: colour-difference(#478372, #000000);
}
.ext-otg-color {
	color: #66952c;
}
.ext-otg-background-color {
	--background-color: #66952c;
	--color: colour-difference(#66952c, #000000);
	background-color: #66952c;
}
.ext-otg-all {
	--background-color: #66952c;
	--color: colour-difference(#66952c, #000000);
	background-color: #66952c;
	color: colour-difference(#66952c, #000000);
}
.ext-odi-color {
	color: #17fcca;
}
.ext-odi-background-color {
	--background-color: #17fcca;
	--color: colour-difference(#17fcca, #000000);
	background-color: #17fcca;
}
.ext-odi-all {
	--background-color: #17fcca;
	--color: colour-difference(#17fcca, #000000);
	background-color: #17fcca;
	color: colour-difference(#17fcca, #000000);
}
.ext-oti-color {
	color: #55d627;
}
.ext-oti-background-color {
	--background-color: #55d627;
	--color: colour-difference(#55d627, #000000);
	background-color: #55d627;
}
.ext-oti-all {
	--background-color: #55d627;
	--color: colour-difference(#55d627, #000000);
	background-color: #55d627;
	color: colour-difference(#55d627, #000000);
}
.ext-odp-color {
	color: #b27f3d;
}
.ext-odp-background-color {
	--background-color: #b27f3d;
	--color: colour-difference(#b27f3d, #000000);
	background-color: #b27f3d;
}
.ext-odp-all {
	--background-color: #b27f3d;
	--color: colour-difference(#b27f3d, #000000);
	background-color: #b27f3d;
	color: colour-difference(#b27f3d, #000000);
}
.ext-otp-color {
	color: #a25d9b;
}
.ext-otp-background-color {
	--background-color: #a25d9b;
	--color: colour-difference(#a25d9b, #000000);
	background-color: #a25d9b;
}
.ext-otp-all {
	--background-color: #a25d9b;
	--color: colour-difference(#a25d9b, #000000);
	background-color: #a25d9b;
	color: colour-difference(#a25d9b, #000000);
}
.ext-ods-color {
	color: #2ee60a;
}
.ext-ods-background-color {
	--background-color: #2ee60a;
	--color: colour-difference(#2ee60a, #000000);
	background-color: #2ee60a;
}
.ext-ods-all {
	--background-color: #2ee60a;
	--color: colour-difference(#2ee60a, #000000);
	background-color: #2ee60a;
	color: colour-difference(#2ee60a, #000000);
}
.ext-ots-color {
	color: #87db1f;
}
.ext-ots-background-color {
	--background-color: #87db1f;
	--color: colour-difference(#87db1f, #000000);
	background-color: #87db1f;
}
.ext-ots-all {
	--background-color: #87db1f;
	--color: colour-difference(#87db1f, #000000);
	background-color: #87db1f;
	color: colour-difference(#87db1f, #000000);
}
.ext-odt-color {
	color: #8e8d7b;
}
.ext-odt-background-color {
	--background-color: #8e8d7b;
	--color: colour-difference(#8e8d7b, #000000);
	background-color: #8e8d7b;
}
.ext-odt-all {
	--background-color: #8e8d7b;
	--color: colour-difference(#8e8d7b, #000000);
	background-color: #8e8d7b;
	color: colour-difference(#8e8d7b, #000000);
}
.ext-odm-color {
	color: #9625b0;
}
.ext-odm-background-color {
	--background-color: #9625b0;
	--color: colour-difference(#9625b0, #000000);
	background-color: #9625b0;
}
.ext-odm-all {
	--background-color: #9625b0;
	--color: colour-difference(#9625b0, #000000);
	background-color: #9625b0;
	color: colour-difference(#9625b0, #000000);
}
.ext-ott-color {
	color: #3ce2dc;
}
.ext-ott-background-color {
	--background-color: #3ce2dc;
	--color: colour-difference(#3ce2dc, #000000);
	background-color: #3ce2dc;
}
.ext-ott-all {
	--background-color: #3ce2dc;
	--color: colour-difference(#3ce2dc, #000000);
	background-color: #3ce2dc;
	color: colour-difference(#3ce2dc, #000000);
}
.ext-ktx-color {
	color: #6a5611;
}
.ext-ktx-background-color {
	--background-color: #6a5611;
	--color: colour-difference(#6a5611, #000000);
	background-color: #6a5611;
}
.ext-ktx-all {
	--background-color: #6a5611;
	--color: colour-difference(#6a5611, #000000);
	background-color: #6a5611;
	color: colour-difference(#6a5611, #000000);
}
.ext-sxc-color {
	color: #499c31;
}
.ext-sxc-background-color {
	--background-color: #499c31;
	--color: colour-difference(#499c31, #000000);
	background-color: #499c31;
}
.ext-sxc-all {
	--background-color: #499c31;
	--color: colour-difference(#499c31, #000000);
	background-color: #499c31;
	color: colour-difference(#499c31, #000000);
}
.ext-stc-color {
	color: #b3e99a;
}
.ext-stc-background-color {
	--background-color: #b3e99a;
	--color: colour-difference(#b3e99a, #000000);
	background-color: #b3e99a;
}
.ext-stc-all {
	--background-color: #b3e99a;
	--color: colour-difference(#b3e99a, #000000);
	background-color: #b3e99a;
	color: colour-difference(#b3e99a, #000000);
}
.ext-sxd-color {
	color: #a30418;
}
.ext-sxd-background-color {
	--background-color: #a30418;
	--color: colour-difference(#a30418, #000000);
	background-color: #a30418;
}
.ext-sxd-all {
	--background-color: #a30418;
	--color: colour-difference(#a30418, #000000);
	background-color: #a30418;
	color: colour-difference(#a30418, #000000);
}
.ext-std-color {
	color: #c11eff;
}
.ext-std-background-color {
	--background-color: #c11eff;
	--color: colour-difference(#c11eff, #000000);
	background-color: #c11eff;
}
.ext-std-all {
	--background-color: #c11eff;
	--color: colour-difference(#c11eff, #000000);
	background-color: #c11eff;
	color: colour-difference(#c11eff, #000000);
}
.ext-sxi-color {
	color: #56f7f1;
}
.ext-sxi-background-color {
	--background-color: #56f7f1;
	--color: colour-difference(#56f7f1, #000000);
	background-color: #56f7f1;
}
.ext-sxi-all {
	--background-color: #56f7f1;
	--color: colour-difference(#56f7f1, #000000);
	background-color: #56f7f1;
	color: colour-difference(#56f7f1, #000000);
}
.ext-sti-color {
	color: #dc0f8f;
}
.ext-sti-background-color {
	--background-color: #dc0f8f;
	--color: colour-difference(#dc0f8f, #000000);
	background-color: #dc0f8f;
}
.ext-sti-all {
	--background-color: #dc0f8f;
	--color: colour-difference(#dc0f8f, #000000);
	background-color: #dc0f8f;
	color: colour-difference(#dc0f8f, #000000);
}
.ext-sxm-color {
	color: #6b348f;
}
.ext-sxm-background-color {
	--background-color: #6b348f;
	--color: colour-difference(#6b348f, #000000);
	background-color: #6b348f;
}
.ext-sxm-all {
	--background-color: #6b348f;
	--color: colour-difference(#6b348f, #000000);
	background-color: #6b348f;
	color: colour-difference(#6b348f, #000000);
}
.ext-sxw-color {
	color: #313dec;
}
.ext-sxw-background-color {
	--background-color: #313dec;
	--color: colour-difference(#313dec, #000000);
	background-color: #313dec;
}
.ext-sxw-all {
	--background-color: #313dec;
	--color: colour-difference(#313dec, #000000);
	background-color: #313dec;
	color: colour-difference(#313dec, #000000);
}
.ext-sxg-color {
	color: #43c01d;
}
.ext-sxg-background-color {
	--background-color: #43c01d;
	--color: colour-difference(#43c01d, #000000);
	background-color: #43c01d;
}
.ext-sxg-all {
	--background-color: #43c01d;
	--color: colour-difference(#43c01d, #000000);
	background-color: #43c01d;
	color: colour-difference(#43c01d, #000000);
}
.ext-stw-color {
	color: #5c73cc;
}
.ext-stw-background-color {
	--background-color: #5c73cc;
	--color: colour-difference(#5c73cc, #000000);
	background-color: #5c73cc;
}
.ext-stw-all {
	--background-color: #5c73cc;
	--color: colour-difference(#5c73cc, #000000);
	background-color: #5c73cc;
	color: colour-difference(#5c73cc, #000000);
}
.ext-otf-color {
	color: #283e84;
}
.ext-otf-background-color {
	--background-color: #283e84;
	--color: colour-difference(#283e84, #000000);
	background-color: #283e84;
}
.ext-otf-all {
	--background-color: #283e84;
	--color: colour-difference(#283e84, #000000);
	background-color: #283e84;
	color: colour-difference(#283e84, #000000);
}
.ext-osfpvg-color {
	color: #dbd1ba;
}
.ext-osfpvg-background-color {
	--background-color: #dbd1ba;
	--color: colour-difference(#dbd1ba, #000000);
	background-color: #dbd1ba;
}
.ext-osfpvg-all {
	--background-color: #dbd1ba;
	--color: colour-difference(#dbd1ba, #000000);
	background-color: #dbd1ba;
	color: colour-difference(#dbd1ba, #000000);
}
.ext-dp-color {
	color: #5757dc;
}
.ext-dp-background-color {
	--background-color: #5757dc;
	--color: colour-difference(#5757dc, #000000);
	background-color: #5757dc;
}
.ext-dp-all {
	--background-color: #5757dc;
	--color: colour-difference(#5757dc, #000000);
	background-color: #5757dc;
	color: colour-difference(#5757dc, #000000);
}
.ext-pdb-color {
	color: #153f99;
}
.ext-pdb-background-color {
	--background-color: #153f99;
	--color: colour-difference(#153f99, #000000);
	background-color: #153f99;
}
.ext-pdb-all {
	--background-color: #153f99;
	--color: colour-difference(#153f99, #000000);
	background-color: #153f99;
	color: colour-difference(#153f99, #000000);
}
.ext-p-color {
	color: #7efda5;
}
.ext-p-background-color {
	--background-color: #7efda5;
	--color: colour-difference(#7efda5, #000000);
	background-color: #7efda5;
}
.ext-p-all {
	--background-color: #7efda5;
	--color: colour-difference(#7efda5, #000000);
	background-color: #7efda5;
	color: colour-difference(#7efda5, #000000);
}
.ext-paw-color {
	color: #eaac68;
}
.ext-paw-background-color {
	--background-color: #eaac68;
	--color: colour-difference(#eaac68, #000000);
	background-color: #eaac68;
}
.ext-paw-all {
	--background-color: #eaac68;
	--color: colour-difference(#eaac68, #000000);
	background-color: #eaac68;
	color: colour-difference(#eaac68, #000000);
}
.ext-pclxl-color {
	color: #c15bb1;
}
.ext-pclxl-background-color {
	--background-color: #c15bb1;
	--color: colour-difference(#c15bb1, #000000);
	background-color: #c15bb1;
}
.ext-pclxl-all {
	--background-color: #c15bb1;
	--color: colour-difference(#c15bb1, #000000);
	background-color: #c15bb1;
	color: colour-difference(#c15bb1, #000000);
}
.ext-efif-color {
	color: #651ef4;
}
.ext-efif-background-color {
	--background-color: #651ef4;
	--color: colour-difference(#651ef4, #000000);
	background-color: #651ef4;
}
.ext-efif-all {
	--background-color: #651ef4;
	--color: colour-difference(#651ef4, #000000);
	background-color: #651ef4;
	color: colour-difference(#651ef4, #000000);
}
.ext-pcx-color {
	color: #f47903;
}
.ext-pcx-background-color {
	--background-color: #f47903;
	--color: colour-difference(#f47903, #000000);
	background-color: #f47903;
}
.ext-pcx-all {
	--background-color: #f47903;
	--color: colour-difference(#f47903, #000000);
	background-color: #f47903;
	color: colour-difference(#f47903, #000000);
}
.ext-psd-color {
	color: #47c8e2;
}
.ext-psd-background-color {
	--background-color: #47c8e2;
	--color: colour-difference(#47c8e2, #000000);
	background-color: #47c8e2;
}
.ext-psd-all {
	--background-color: #47c8e2;
	--color: colour-difference(#47c8e2, #000000);
	background-color: #47c8e2;
	color: colour-difference(#47c8e2, #000000);
}
.ext-prf-color {
	color: #7ab144;
}
.ext-prf-background-color {
	--background-color: #7ab144;
	--color: colour-difference(#7ab144, #000000);
	background-color: #7ab144;
}
.ext-prf-all {
	--background-color: #7ab144;
	--color: colour-difference(#7ab144, #000000);
	background-color: #7ab144;
	color: colour-difference(#7ab144, #000000);
}
.ext-pic-color {
	color: #7df730;
}
.ext-pic-background-color {
	--background-color: #7df730;
	--color: colour-difference(#7df730, #000000);
	background-color: #7df730;
}
.ext-pic-all {
	--background-color: #7df730;
	--color: colour-difference(#7df730, #000000);
	background-color: #7df730;
	color: colour-difference(#7df730, #000000);
}
.ext-chat-color {
	color: #5e5478;
}
.ext-chat-background-color {
	--background-color: #5e5478;
	--color: colour-difference(#5e5478, #000000);
	background-color: #5e5478;
}
.ext-chat-all {
	--background-color: #5e5478;
	--color: colour-difference(#5e5478, #000000);
	background-color: #5e5478;
	color: colour-difference(#5e5478, #000000);
}
.ext-p10-color {
	color: #6c8c2c;
}
.ext-p10-background-color {
	--background-color: #6c8c2c;
	--color: colour-difference(#6c8c2c, #000000);
	background-color: #6c8c2c;
}
.ext-p10-all {
	--background-color: #6c8c2c;
	--color: colour-difference(#6c8c2c, #000000);
	background-color: #6c8c2c;
	color: colour-difference(#6c8c2c, #000000);
}
.ext-p12-color {
	color: #f1c64f;
}
.ext-p12-background-color {
	--background-color: #f1c64f;
	--color: colour-difference(#f1c64f, #000000);
	background-color: #f1c64f;
}
.ext-p12-all {
	--background-color: #f1c64f;
	--color: colour-difference(#f1c64f, #000000);
	background-color: #f1c64f;
	color: colour-difference(#f1c64f, #000000);
}
.ext-p7m-color {
	color: #534c22;
}
.ext-p7m-background-color {
	--background-color: #534c22;
	--color: colour-difference(#534c22, #000000);
	background-color: #534c22;
}
.ext-p7m-all {
	--background-color: #534c22;
	--color: colour-difference(#534c22, #000000);
	background-color: #534c22;
	color: colour-difference(#534c22, #000000);
}
.ext-p7s-color {
	color: #846e97;
}
.ext-p7s-background-color {
	--background-color: #846e97;
	--color: colour-difference(#846e97, #000000);
	background-color: #846e97;
}
.ext-p7s-all {
	--background-color: #846e97;
	--color: colour-difference(#846e97, #000000);
	background-color: #846e97;
	color: colour-difference(#846e97, #000000);
}
.ext-p7r-color {
	color: #c659af;
}
.ext-p7r-background-color {
	--background-color: #c659af;
	--color: colour-difference(#c659af, #000000);
	background-color: #c659af;
}
.ext-p7r-all {
	--background-color: #c659af;
	--color: colour-difference(#c659af, #000000);
	background-color: #c659af;
	color: colour-difference(#c659af, #000000);
}
.ext-p7b-color {
	color: #e5638e;
}
.ext-p7b-background-color {
	--background-color: #e5638e;
	--color: colour-difference(#e5638e, #000000);
	background-color: #e5638e;
}
.ext-p7b-all {
	--background-color: #e5638e;
	--color: colour-difference(#e5638e, #000000);
	background-color: #e5638e;
	color: colour-difference(#e5638e, #000000);
}
.ext-p8-color {
	color: #6b698a;
}
.ext-p8-background-color {
	--background-color: #6b698a;
	--color: colour-difference(#6b698a, #000000);
	background-color: #6b698a;
}
.ext-p8-all {
	--background-color: #6b698a;
	--color: colour-difference(#6b698a, #000000);
	background-color: #6b698a;
	color: colour-difference(#6b698a, #000000);
}
.ext-plf-color {
	color: #ae11ad;
}
.ext-plf-background-color {
	--background-color: #ae11ad;
	--color: colour-difference(#ae11ad, #000000);
	background-color: #ae11ad;
}
.ext-plf-all {
	--background-color: #ae11ad;
	--color: colour-difference(#ae11ad, #000000);
	background-color: #ae11ad;
	color: colour-difference(#ae11ad, #000000);
}
.ext-pnm-color {
	color: #88a12a;
}
.ext-pnm-background-color {
	--background-color: #88a12a;
	--color: colour-difference(#88a12a, #000000);
	background-color: #88a12a;
}
.ext-pnm-all {
	--background-color: #88a12a;
	--color: colour-difference(#88a12a, #000000);
	background-color: #88a12a;
	color: colour-difference(#88a12a, #000000);
}
.ext-pbm-color {
	color: #4d885a;
}
.ext-pbm-background-color {
	--background-color: #4d885a;
	--color: colour-difference(#4d885a, #000000);
	background-color: #4d885a;
}
.ext-pbm-all {
	--background-color: #4d885a;
	--color: colour-difference(#4d885a, #000000);
	background-color: #4d885a;
	color: colour-difference(#4d885a, #000000);
}
.ext-pcf-color {
	color: #9dd50a;
}
.ext-pcf-background-color {
	--background-color: #9dd50a;
	--color: colour-difference(#9dd50a, #000000);
	background-color: #9dd50a;
}
.ext-pcf-all {
	--background-color: #9dd50a;
	--color: colour-difference(#9dd50a, #000000);
	background-color: #9dd50a;
	color: colour-difference(#9dd50a, #000000);
}
.ext-pfr-color {
	color: #64a97f;
}
.ext-pfr-background-color {
	--background-color: #64a97f;
	--color: colour-difference(#64a97f, #000000);
	background-color: #64a97f;
}
.ext-pfr-all {
	--background-color: #64a97f;
	--color: colour-difference(#64a97f, #000000);
	background-color: #64a97f;
	color: colour-difference(#64a97f, #000000);
}
.ext-pgn-color {
	color: #21f041;
}
.ext-pgn-background-color {
	--background-color: #21f041;
	--color: colour-difference(#21f041, #000000);
	background-color: #21f041;
}
.ext-pgn-all {
	--background-color: #21f041;
	--color: colour-difference(#21f041, #000000);
	background-color: #21f041;
	color: colour-difference(#21f041, #000000);
}
.ext-pgm-color {
	color: #3943b0;
}
.ext-pgm-background-color {
	--background-color: #3943b0;
	--color: colour-difference(#3943b0, #000000);
	background-color: #3943b0;
}
.ext-pgm-all {
	--background-color: #3943b0;
	--color: colour-difference(#3943b0, #000000);
	background-color: #3943b0;
	color: colour-difference(#3943b0, #000000);
}
.ext-png-color {
	color: #fef28f;
}
.ext-png-background-color {
	--background-color: #fef28f;
	--color: colour-difference(#fef28f, #000000);
	background-color: #fef28f;
}
.ext-png-all {
	--background-color: #fef28f;
	--color: colour-difference(#fef28f, #000000);
	background-color: #fef28f;
	color: colour-difference(#fef28f, #000000);
}
.ext-ppm-color {
	color: #c90997;
}
.ext-ppm-background-color {
	--background-color: #c90997;
	--color: colour-difference(#c90997, #000000);
	background-color: #c90997;
}
.ext-ppm-all {
	--background-color: #c90997;
	--color: colour-difference(#c90997, #000000);
	background-color: #c90997;
	color: colour-difference(#c90997, #000000);
}
.ext-pskcxml-color {
	color: #2e48cb;
}
.ext-pskcxml-background-color {
	--background-color: #2e48cb;
	--color: colour-difference(#2e48cb, #000000);
	background-color: #2e48cb;
}
.ext-pskcxml-all {
	--background-color: #2e48cb;
	--color: colour-difference(#2e48cb, #000000);
	background-color: #2e48cb;
	color: colour-difference(#2e48cb, #000000);
}
.ext-pml-color {
	color: #c6bf93;
}
.ext-pml-background-color {
	--background-color: #c6bf93;
	--color: colour-difference(#c6bf93, #000000);
	background-color: #c6bf93;
}
.ext-pml-all {
	--background-color: #c6bf93;
	--color: colour-difference(#c6bf93, #000000);
	background-color: #c6bf93;
	color: colour-difference(#c6bf93, #000000);
}
.ext-ai-color {
	color: #1059c3;
}
.ext-ai-background-color {
	--background-color: #1059c3;
	--color: colour-difference(#1059c3, #000000);
	background-color: #1059c3;
}
.ext-ai-all {
	--background-color: #1059c3;
	--color: colour-difference(#1059c3, #000000);
	background-color: #1059c3;
	color: colour-difference(#1059c3, #000000);
}
.ext-pfa-color {
	color: #c9a525;
}
.ext-pfa-background-color {
	--background-color: #c9a525;
	--color: colour-difference(#c9a525, #000000);
	background-color: #c9a525;
}
.ext-pfa-all {
	--background-color: #c9a525;
	--color: colour-difference(#c9a525, #000000);
	background-color: #c9a525;
	color: colour-difference(#c9a525, #000000);
}
.ext-pbd-color {
	color: #2c5e5f;
}
.ext-pbd-background-color {
	--background-color: #2c5e5f;
	--color: colour-difference(#2c5e5f, #000000);
	background-color: #2c5e5f;
}
.ext-pbd-all {
	--background-color: #2c5e5f;
	--color: colour-difference(#2c5e5f, #000000);
	background-color: #2c5e5f;
	color: colour-difference(#2c5e5f, #000000);
}
.ext-pgp-color {
	color: #e91ac3;
}
.ext-pgp-background-color {
	--background-color: #e91ac3;
	--color: colour-difference(#e91ac3, #000000);
	background-color: #e91ac3;
}
.ext-pgp-all {
	--background-color: #e91ac3;
	--color: colour-difference(#e91ac3, #000000);
	background-color: #e91ac3;
	color: colour-difference(#e91ac3, #000000);
}
.ext-box-color {
	color: #8f10e2;
}
.ext-box-background-color {
	--background-color: #8f10e2;
	--color: colour-difference(#8f10e2, #000000);
	background-color: #8f10e2;
}
.ext-box-all {
	--background-color: #8f10e2;
	--color: colour-difference(#8f10e2, #000000);
	background-color: #8f10e2;
	color: colour-difference(#8f10e2, #000000);
}
.ext-ptid-color {
	color: #8c79b7;
}
.ext-ptid-background-color {
	--background-color: #8c79b7;
	--color: colour-difference(#8c79b7, #000000);
	background-color: #8c79b7;
}
.ext-ptid-all {
	--background-color: #8c79b7;
	--color: colour-difference(#8c79b7, #000000);
	background-color: #8c79b7;
	color: colour-difference(#8c79b7, #000000);
}
.ext-pls-color {
	color: #2a1f2e;
}
.ext-pls-background-color {
	--background-color: #2a1f2e;
	--color: colour-difference(#2a1f2e, #000000);
	background-color: #2a1f2e;
}
.ext-pls-all {
	--background-color: #2a1f2e;
	--color: colour-difference(#2a1f2e, #000000);
	background-color: #2a1f2e;
	color: colour-difference(#2a1f2e, #000000);
}
.ext-str-color {
	color: #9cb2d7;
}
.ext-str-background-color {
	--background-color: #9cb2d7;
	--color: colour-difference(#9cb2d7, #000000);
	background-color: #9cb2d7;
}
.ext-str-all {
	--background-color: #9cb2d7;
	--color: colour-difference(#9cb2d7, #000000);
	background-color: #9cb2d7;
	color: colour-difference(#9cb2d7, #000000);
}
.ext-ei6-color {
	color: #6ada8b;
}
.ext-ei6-background-color {
	--background-color: #6ada8b;
	--color: colour-difference(#6ada8b, #000000);
	background-color: #6ada8b;
}
.ext-ei6-all {
	--background-color: #6ada8b;
	--color: colour-difference(#6ada8b, #000000);
	background-color: #6ada8b;
	color: colour-difference(#6ada8b, #000000);
}
.ext-dsc-color {
	color: #528b34;
}
.ext-dsc-background-color {
	--background-color: #528b34;
	--color: colour-difference(#528b34, #000000);
	background-color: #528b34;
}
.ext-dsc-all {
	--background-color: #528b34;
	--color: colour-difference(#528b34, #000000);
	background-color: #528b34;
	color: colour-difference(#528b34, #000000);
}
.ext-psf-color {
	color: #3228cf;
}
.ext-psf-background-color {
	--background-color: #3228cf;
	--color: colour-difference(#3228cf, #000000);
	background-color: #3228cf;
}
.ext-psf-all {
	--background-color: #3228cf;
	--color: colour-difference(#3228cf, #000000);
	background-color: #3228cf;
	color: colour-difference(#3228cf, #000000);
}
.ext-qps-color {
	color: #8f9e49;
}
.ext-qps-background-color {
	--background-color: #8f9e49;
	--color: colour-difference(#8f9e49, #000000);
	background-color: #8f9e49;
}
.ext-qps-all {
	--background-color: #8f9e49;
	--color: colour-difference(#8f9e49, #000000);
	background-color: #8f9e49;
	color: colour-difference(#8f9e49, #000000);
}
.ext-wg-color {
	color: #5f6011;
}
.ext-wg-background-color {
	--background-color: #5f6011;
	--color: colour-difference(#5f6011, #000000);
	background-color: #5f6011;
}
.ext-wg-all {
	--background-color: #5f6011;
	--color: colour-difference(#5f6011, #000000);
	background-color: #5f6011;
	color: colour-difference(#5f6011, #000000);
}
.ext-qxd-color {
	color: #b23764;
}
.ext-qxd-background-color {
	--background-color: #b23764;
	--color: colour-difference(#b23764, #000000);
	background-color: #b23764;
}
.ext-qxd-all {
	--background-color: #b23764;
	--color: colour-difference(#b23764, #000000);
	background-color: #b23764;
	color: colour-difference(#b23764, #000000);
}
.ext-esf-color {
	color: #a4604f;
}
.ext-esf-background-color {
	--background-color: #a4604f;
	--color: colour-difference(#a4604f, #000000);
	background-color: #a4604f;
}
.ext-esf-all {
	--background-color: #a4604f;
	--color: colour-difference(#a4604f, #000000);
	background-color: #a4604f;
	color: colour-difference(#a4604f, #000000);
}
.ext-msf-color {
	color: #fa5ca6;
}
.ext-msf-background-color {
	--background-color: #fa5ca6;
	--color: colour-difference(#fa5ca6, #000000);
	background-color: #fa5ca6;
}
.ext-msf-all {
	--background-color: #fa5ca6;
	--color: colour-difference(#fa5ca6, #000000);
	background-color: #fa5ca6;
	color: colour-difference(#fa5ca6, #000000);
}
.ext-ssf-color {
	color: #bbbf5d;
}
.ext-ssf-background-color {
	--background-color: #bbbf5d;
	--color: colour-difference(#bbbf5d, #000000);
	background-color: #bbbf5d;
}
.ext-ssf-all {
	--background-color: #bbbf5d;
	--color: colour-difference(#bbbf5d, #000000);
	background-color: #bbbf5d;
	color: colour-difference(#bbbf5d, #000000);
}
.ext-qam-color {
	color: #ef7f1e;
}
.ext-qam-background-color {
	--background-color: #ef7f1e;
	--color: colour-difference(#ef7f1e, #000000);
	background-color: #ef7f1e;
}
.ext-qam-all {
	--background-color: #ef7f1e;
	--color: colour-difference(#ef7f1e, #000000);
	background-color: #ef7f1e;
	color: colour-difference(#ef7f1e, #000000);
}
.ext-qfx-color {
	color: #650a37;
}
.ext-qfx-background-color {
	--background-color: #650a37;
	--color: colour-difference(#650a37, #000000);
	background-color: #650a37;
}
.ext-qfx-all {
	--background-color: #650a37;
	--color: colour-difference(#650a37, #000000);
	background-color: #650a37;
	color: colour-difference(#650a37, #000000);
}
.ext-qt-color {
	color: #368377;
}
.ext-qt-background-color {
	--background-color: #368377;
	--color: colour-difference(#368377, #000000);
	background-color: #368377;
}
.ext-qt-all {
	--background-color: #368377;
	--color: colour-difference(#368377, #000000);
	background-color: #368377;
	color: colour-difference(#368377, #000000);
}
.ext-rar-color {
	color: #c5bc78;
}
.ext-rar-background-color {
	--background-color: #c5bc78;
	--color: colour-difference(#c5bc78, #000000);
	background-color: #c5bc78;
}
.ext-rar-all {
	--background-color: #c5bc78;
	--color: colour-difference(#c5bc78, #000000);
	background-color: #c5bc78;
	color: colour-difference(#c5bc78, #000000);
}
.ext-ram-color {
	color: #bbd78b;
}
.ext-ram-background-color {
	--background-color: #bbd78b;
	--color: colour-difference(#bbd78b, #000000);
	background-color: #bbd78b;
}
.ext-ram-all {
	--background-color: #bbd78b;
	--color: colour-difference(#bbd78b, #000000);
	background-color: #bbd78b;
	color: colour-difference(#bbd78b, #000000);
}
.ext-rmp-color {
	color: #7b8d75;
}
.ext-rmp-background-color {
	--background-color: #7b8d75;
	--color: colour-difference(#7b8d75, #000000);
	background-color: #7b8d75;
}
.ext-rmp-all {
	--background-color: #7b8d75;
	--color: colour-difference(#7b8d75, #000000);
	background-color: #7b8d75;
	color: colour-difference(#7b8d75, #000000);
}
.ext-rsd-color {
	color: #219d59;
}
.ext-rsd-background-color {
	--background-color: #219d59;
	--color: colour-difference(#219d59, #000000);
	background-color: #219d59;
}
.ext-rsd-all {
	--background-color: #219d59;
	--color: colour-difference(#219d59, #000000);
	background-color: #219d59;
	color: colour-difference(#219d59, #000000);
}
.ext-rm-color {
	color: #450ea3;
}
.ext-rm-background-color {
	--background-color: #450ea3;
	--color: colour-difference(#450ea3, #000000);
	background-color: #450ea3;
}
.ext-rm-all {
	--background-color: #450ea3;
	--color: colour-difference(#450ea3, #000000);
	background-color: #450ea3;
	color: colour-difference(#450ea3, #000000);
}
.ext-bed-color {
	color: #993f17;
}
.ext-bed-background-color {
	--background-color: #993f17;
	--color: colour-difference(#993f17, #000000);
	background-color: #993f17;
}
.ext-bed-all {
	--background-color: #993f17;
	--color: colour-difference(#993f17, #000000);
	background-color: #993f17;
	color: colour-difference(#993f17, #000000);
}
.ext-mxl-color {
	color: #72f57e;
}
.ext-mxl-background-color {
	--background-color: #72f57e;
	--color: colour-difference(#72f57e, #000000);
	background-color: #72f57e;
}
.ext-mxl-all {
	--background-color: #72f57e;
	--color: colour-difference(#72f57e, #000000);
	background-color: #72f57e;
	color: colour-difference(#72f57e, #000000);
}
.ext-musicxml-color {
	color: #21c25f;
}
.ext-musicxml-background-color {
	--background-color: #21c25f;
	--color: colour-difference(#21c25f, #000000);
	background-color: #21c25f;
}
.ext-musicxml-all {
	--background-color: #21c25f;
	--color: colour-difference(#21c25f, #000000);
	background-color: #21c25f;
	color: colour-difference(#21c25f, #000000);
}
.ext-rnc-color {
	color: #7c6033;
}
.ext-rnc-background-color {
	--background-color: #7c6033;
	--color: colour-difference(#7c6033, #000000);
	background-color: #7c6033;
}
.ext-rnc-all {
	--background-color: #7c6033;
	--color: colour-difference(#7c6033, #000000);
	background-color: #7c6033;
	color: colour-difference(#7c6033, #000000);
}
.ext-rdz-color {
	color: #ed5cbc;
}
.ext-rdz-background-color {
	--background-color: #ed5cbc;
	--color: colour-difference(#ed5cbc, #000000);
	background-color: #ed5cbc;
}
.ext-rdz-all {
	--background-color: #ed5cbc;
	--color: colour-difference(#ed5cbc, #000000);
	background-color: #ed5cbc;
	color: colour-difference(#ed5cbc, #000000);
}
.ext-rdf-color {
	color: #764135;
}
.ext-rdf-background-color {
	--background-color: #764135;
	--color: colour-difference(#764135, #000000);
	background-color: #764135;
}
.ext-rdf-all {
	--background-color: #764135;
	--color: colour-difference(#764135, #000000);
	background-color: #764135;
	color: colour-difference(#764135, #000000);
}
.ext-rp9-color {
	color: #cfa25f;
}
.ext-rp9-background-color {
	--background-color: #cfa25f;
	--color: colour-difference(#cfa25f, #000000);
	background-color: #cfa25f;
}
.ext-rp9-all {
	--background-color: #cfa25f;
	--color: colour-difference(#cfa25f, #000000);
	background-color: #cfa25f;
	color: colour-difference(#cfa25f, #000000);
}
.ext-jisp-color {
	color: #3c57bc;
}
.ext-jisp-background-color {
	--background-color: #3c57bc;
	--color: colour-difference(#3c57bc, #000000);
	background-color: #3c57bc;
}
.ext-jisp-all {
	--background-color: #3c57bc;
	--color: colour-difference(#3c57bc, #000000);
	background-color: #3c57bc;
	color: colour-difference(#3c57bc, #000000);
}
.ext-rtf-color {
	color: #6f5216;
}
.ext-rtf-background-color {
	--background-color: #6f5216;
	--color: colour-difference(#6f5216, #000000);
	background-color: #6f5216;
}
.ext-rtf-all {
	--background-color: #6f5216;
	--color: colour-difference(#6f5216, #000000);
	background-color: #6f5216;
	color: colour-difference(#6f5216, #000000);
}
.ext-rtx-color {
	color: #736cab;
}
.ext-rtx-background-color {
	--background-color: #736cab;
	--color: colour-difference(#736cab, #000000);
	background-color: #736cab;
}
.ext-rtx-all {
	--background-color: #736cab;
	--color: colour-difference(#736cab, #000000);
	background-color: #736cab;
	color: colour-difference(#736cab, #000000);
}
.ext-link66-color {
	color: #6417e2;
}
.ext-link66-background-color {
	--background-color: #6417e2;
	--color: colour-difference(#6417e2, #000000);
	background-color: #6417e2;
}
.ext-link66-all {
	--background-color: #6417e2;
	--color: colour-difference(#6417e2, #000000);
	background-color: #6417e2;
	color: colour-difference(#6417e2, #000000);
}

.ext-xml-color {
	color: #64a44f;
}

.ext-xml-background-color {
	--background-color: #64a44f;
	--color: colour-difference(#64a44f, #000000);
	background-color: #64a44f;
}

.ext-xml-all {
	--background-color: #64a44f;
	--color: colour-difference(#64a44f, #000000);
	background-color: #64a44f;
	color: colour-difference(#64a44f, #000000);
}

.ext-rss-color {
	color: #64a44f;
}

.ext-rss-background-color {
	--background-color: #64a44f;
	--color: colour-difference(#64a44f, #000000);
	background-color: #64a44f;
}

.ext-rss-all {
	--background-color: #64a44f;
	--color: colour-difference(#64a44f, #000000);
	background-color: #64a44f;
	color: colour-difference(#64a44f, #000000);
}

.ext-shf-color {
	color: #2df2f6;
}
.ext-shf-background-color {
	--background-color: #2df2f6;
	--color: colour-difference(#2df2f6, #000000);
	background-color: #2df2f6;
}
.ext-shf-all {
	--background-color: #2df2f6;
	--color: colour-difference(#2df2f6, #000000);
	background-color: #2df2f6;
	color: colour-difference(#2df2f6, #000000);
}
.ext-st-color {
	color: #9f04c2;
}
.ext-st-background-color {
	--background-color: #9f04c2;
	--color: colour-difference(#9f04c2, #000000);
	background-color: #9f04c2;
}
.ext-st-all {
	--background-color: #9f04c2;
	--color: colour-difference(#9f04c2, #000000);
	background-color: #9f04c2;
	color: colour-difference(#9f04c2, #000000);
}
.ext-svg-color {
	color: #3b8a9a;
}
.ext-svg-background-color {
	--background-color: #3b8a9a;
	--color: colour-difference(#3b8a9a, #000000);
	background-color: #3b8a9a;
}
.ext-svg-all {
	--background-color: #3b8a9a;
	--color: colour-difference(#3b8a9a, #000000);
	background-color: #3b8a9a;
	color: colour-difference(#3b8a9a, #000000);
}
.ext-sus-color {
	color: #9de005;
}
.ext-sus-background-color {
	--background-color: #9de005;
	--color: colour-difference(#9de005, #000000);
	background-color: #9de005;
}
.ext-sus-all {
	--background-color: #9de005;
	--color: colour-difference(#9de005, #000000);
	background-color: #9de005;
	color: colour-difference(#9de005, #000000);
}
.ext-sru-color {
	color: #434aa9;
}
.ext-sru-background-color {
	--background-color: #434aa9;
	--color: colour-difference(#434aa9, #000000);
	background-color: #434aa9;
}
.ext-sru-all {
	--background-color: #434aa9;
	--color: colour-difference(#434aa9, #000000);
	background-color: #434aa9;
	color: colour-difference(#434aa9, #000000);
}
.ext-setpay-color {
	color: #94b5d5;
}
.ext-setpay-background-color {
	--background-color: #94b5d5;
	--color: colour-difference(#94b5d5, #000000);
	background-color: #94b5d5;
}
.ext-setpay-all {
	--background-color: #94b5d5;
	--color: colour-difference(#94b5d5, #000000);
	background-color: #94b5d5;
	color: colour-difference(#94b5d5, #000000);
}
.ext-setreg-color {
	color: #696bc5;
}
.ext-setreg-background-color {
	--background-color: #696bc5;
	--color: colour-difference(#696bc5, #000000);
	background-color: #696bc5;
}
.ext-setreg-all {
	--background-color: #696bc5;
	--color: colour-difference(#696bc5, #000000);
	background-color: #696bc5;
	color: colour-difference(#696bc5, #000000);
}
.ext-sema-color {
	color: #7a25cc;
}
.ext-sema-background-color {
	--background-color: #7a25cc;
	--color: colour-difference(#7a25cc, #000000);
	background-color: #7a25cc;
}
.ext-sema-all {
	--background-color: #7a25cc;
	--color: colour-difference(#7a25cc, #000000);
	background-color: #7a25cc;
	color: colour-difference(#7a25cc, #000000);
}
.ext-semd-color {
	color: #f00864;
}
.ext-semd-background-color {
	--background-color: #f00864;
	--color: colour-difference(#f00864, #000000);
	background-color: #f00864;
}
.ext-semd-all {
	--background-color: #f00864;
	--color: colour-difference(#f00864, #000000);
	background-color: #f00864;
	color: colour-difference(#f00864, #000000);
}
.ext-semf-color {
	color: #f4adde;
}
.ext-semf-background-color {
	--background-color: #f4adde;
	--color: colour-difference(#f4adde, #000000);
	background-color: #f4adde;
}
.ext-semf-all {
	--background-color: #f4adde;
	--color: colour-difference(#f4adde, #000000);
	background-color: #f4adde;
	color: colour-difference(#f4adde, #000000);
}
.ext-see-color {
	color: #358e80;
}
.ext-see-background-color {
	--background-color: #358e80;
	--color: colour-difference(#358e80, #000000);
	background-color: #358e80;
}
.ext-see-all {
	--background-color: #358e80;
	--color: colour-difference(#358e80, #000000);
	background-color: #358e80;
	color: colour-difference(#358e80, #000000);
}
.ext-snf-color {
	color: #a7d4a4;
}
.ext-snf-background-color {
	--background-color: #a7d4a4;
	--color: colour-difference(#a7d4a4, #000000);
	background-color: #a7d4a4;
}
.ext-snf-all {
	--background-color: #a7d4a4;
	--color: colour-difference(#a7d4a4, #000000);
	background-color: #a7d4a4;
	color: colour-difference(#a7d4a4, #000000);
}
.ext-spq-color {
	color: #985aef;
}
.ext-spq-background-color {
	--background-color: #985aef;
	--color: colour-difference(#985aef, #000000);
	background-color: #985aef;
}
.ext-spq-all {
	--background-color: #985aef;
	--color: colour-difference(#985aef, #000000);
	background-color: #985aef;
	color: colour-difference(#985aef, #000000);
}
.ext-spp-color {
	color: #19a1cf;
}
.ext-spp-background-color {
	--background-color: #19a1cf;
	--color: colour-difference(#19a1cf, #000000);
	background-color: #19a1cf;
}
.ext-spp-all {
	--background-color: #19a1cf;
	--color: colour-difference(#19a1cf, #000000);
	background-color: #19a1cf;
	color: colour-difference(#19a1cf, #000000);
}
.ext-scq-color {
	color: #64204b;
}
.ext-scq-background-color {
	--background-color: #64204b;
	--color: colour-difference(#64204b, #000000);
	background-color: #64204b;
}
.ext-scq-all {
	--background-color: #64204b;
	--color: colour-difference(#64204b, #000000);
	background-color: #64204b;
	color: colour-difference(#64204b, #000000);
}
.ext-scs-color {
	color: #1a0340;
}
.ext-scs-background-color {
	--background-color: #1a0340;
	--color: colour-difference(#1a0340, #000000);
	background-color: #1a0340;
}
.ext-scs-all {
	--background-color: #1a0340;
	--color: colour-difference(#1a0340, #000000);
	background-color: #1a0340;
	color: colour-difference(#1a0340, #000000);
}
.ext-sdp-color {
	color: #6a9795;
}
.ext-sdp-background-color {
	--background-color: #6a9795;
	--color: colour-difference(#6a9795, #000000);
	background-color: #6a9795;
}
.ext-sdp-all {
	--background-color: #6a9795;
	--color: colour-difference(#6a9795, #000000);
	background-color: #6a9795;
	color: colour-difference(#6a9795, #000000);
}
.ext-etx-color {
	color: #4b637a;
}
.ext-etx-background-color {
	--background-color: #4b637a;
	--color: colour-difference(#4b637a, #000000);
	background-color: #4b637a;
}
.ext-etx-all {
	--background-color: #4b637a;
	--color: colour-difference(#4b637a, #000000);
	background-color: #4b637a;
	color: colour-difference(#4b637a, #000000);
}
.ext-movie-color {
	color: #7c7408;
}
.ext-movie-background-color {
	--background-color: #7c7408;
	--color: colour-difference(#7c7408, #000000);
	background-color: #7c7408;
}
.ext-movie-all {
	--background-color: #7c7408;
	--color: colour-difference(#7c7408, #000000);
	background-color: #7c7408;
	color: colour-difference(#7c7408, #000000);
}
.ext-ifm-color {
	color: #666ade;
}
.ext-ifm-background-color {
	--background-color: #666ade;
	--color: colour-difference(#666ade, #000000);
	background-color: #666ade;
}
.ext-ifm-all {
	--background-color: #666ade;
	--color: colour-difference(#666ade, #000000);
	background-color: #666ade;
	color: colour-difference(#666ade, #000000);
}
.ext-itp-color {
	color: #6dcfc0;
}
.ext-itp-background-color {
	--background-color: #6dcfc0;
	--color: colour-difference(#6dcfc0, #000000);
	background-color: #6dcfc0;
}
.ext-itp-all {
	--background-color: #6dcfc0;
	--color: colour-difference(#6dcfc0, #000000);
	background-color: #6dcfc0;
	color: colour-difference(#6dcfc0, #000000);
}
.ext-iif-color {
	color: #5ee82e;
}
.ext-iif-background-color {
	--background-color: #5ee82e;
	--color: colour-difference(#5ee82e, #000000);
	background-color: #5ee82e;
}
.ext-iif-all {
	--background-color: #5ee82e;
	--color: colour-difference(#5ee82e, #000000);
	background-color: #5ee82e;
	color: colour-difference(#5ee82e, #000000);
}
.ext-ipk-color {
	color: #714d2c;
}
.ext-ipk-background-color {
	--background-color: #714d2c;
	--color: colour-difference(#714d2c, #000000);
	background-color: #714d2c;
}
.ext-ipk-all {
	--background-color: #714d2c;
	--color: colour-difference(#714d2c, #000000);
	background-color: #714d2c;
	color: colour-difference(#714d2c, #000000);
}
.ext-tfi-color {
	color: #30a726;
}
.ext-tfi-background-color {
	--background-color: #30a726;
	--color: colour-difference(#30a726, #000000);
	background-color: #30a726;
}
.ext-tfi-all {
	--background-color: #30a726;
	--color: colour-difference(#30a726, #000000);
	background-color: #30a726;
	color: colour-difference(#30a726, #000000);
}
.ext-shar-color {
	color: #33c4f6;
}
.ext-shar-background-color {
	--background-color: #33c4f6;
	--color: colour-difference(#33c4f6, #000000);
	background-color: #33c4f6;
}
.ext-shar-all {
	--background-color: #33c4f6;
	--color: colour-difference(#33c4f6, #000000);
	background-color: #33c4f6;
	color: colour-difference(#33c4f6, #000000);
}
.ext-rgb-color {
	color: #8a1d78;
}
.ext-rgb-background-color {
	--background-color: #8a1d78;
	--color: colour-difference(#8a1d78, #000000);
	background-color: #8a1d78;
}
.ext-rgb-all {
	--background-color: #8a1d78;
	--color: colour-difference(#8a1d78, #000000);
	background-color: #8a1d78;
	color: colour-difference(#8a1d78, #000000);
}
.ext-slt-color {
	color: #c45286;
}
.ext-slt-background-color {
	--background-color: #c45286;
	--color: colour-difference(#c45286, #000000);
	background-color: #c45286;
}
.ext-slt-all {
	--background-color: #c45286;
	--color: colour-difference(#c45286, #000000);
	background-color: #c45286;
	color: colour-difference(#c45286, #000000);
}
.ext-aso-color {
	color: #672149;
}
.ext-aso-background-color {
	--background-color: #672149;
	--color: colour-difference(#672149, #000000);
	background-color: #672149;
}
.ext-aso-all {
	--background-color: #672149;
	--color: colour-difference(#672149, #000000);
	background-color: #672149;
	color: colour-difference(#672149, #000000);
}
.ext-imp-color {
	color: #c18b1f;
}
.ext-imp-background-color {
	--background-color: #c18b1f;
	--color: colour-difference(#c18b1f, #000000);
	background-color: #c18b1f;
}
.ext-imp-all {
	--background-color: #c18b1f;
	--color: colour-difference(#c18b1f, #000000);
	background-color: #c18b1f;
	color: colour-difference(#c18b1f, #000000);
}
.ext-twd-color {
	color: #370802;
}
.ext-twd-background-color {
	--background-color: #370802;
	--color: colour-difference(#370802, #000000);
	background-color: #370802;
}
.ext-twd-all {
	--background-color: #370802;
	--color: colour-difference(#370802, #000000);
	background-color: #370802;
	color: colour-difference(#370802, #000000);
}
.ext-csp-color {
	color: #7b5196;
}
.ext-csp-background-color {
	--background-color: #7b5196;
	--color: colour-difference(#7b5196, #000000);
	background-color: #7b5196;
}
.ext-csp-all {
	--background-color: #7b5196;
	--color: colour-difference(#7b5196, #000000);
	background-color: #7b5196;
	color: colour-difference(#7b5196, #000000);
}
.ext-saf-color {
	color: #18a376;
}
.ext-saf-background-color {
	--background-color: #18a376;
	--color: colour-difference(#18a376, #000000);
	background-color: #18a376;
}
.ext-saf-all {
	--background-color: #18a376;
	--color: colour-difference(#18a376, #000000);
	background-color: #18a376;
	color: colour-difference(#18a376, #000000);
}
.ext-mmf-color {
	color: #4d276f;
}
.ext-mmf-background-color {
	--background-color: #4d276f;
	--color: colour-difference(#4d276f, #000000);
	background-color: #4d276f;
}
.ext-mmf-all {
	--background-color: #4d276f;
	--color: colour-difference(#4d276f, #000000);
	background-color: #4d276f;
	color: colour-difference(#4d276f, #000000);
}
.ext-spf-color {
	color: #42cff4;
}
.ext-spf-background-color {
	--background-color: #42cff4;
	--color: colour-difference(#42cff4, #000000);
	background-color: #42cff4;
}
.ext-spf-all {
	--background-color: #42cff4;
	--color: colour-difference(#42cff4, #000000);
	background-color: #42cff4;
	color: colour-difference(#42cff4, #000000);
}
.ext-teacher-color {
	color: #ed1d98;
}
.ext-teacher-background-color {
	--background-color: #ed1d98;
	--color: colour-difference(#ed1d98, #000000);
	background-color: #ed1d98;
}
.ext-teacher-all {
	--background-color: #ed1d98;
	--color: colour-difference(#ed1d98, #000000);
	background-color: #ed1d98;
	color: colour-difference(#ed1d98, #000000);
}
.ext-svd-color {
	color: #6ecc85;
}
.ext-svd-background-color {
	--background-color: #6ecc85;
	--color: colour-difference(#6ecc85, #000000);
	background-color: #6ecc85;
}
.ext-svd-all {
	--background-color: #6ecc85;
	--color: colour-difference(#6ecc85, #000000);
	background-color: #6ecc85;
	color: colour-difference(#6ecc85, #000000);
}
.ext-rq-color {
	color: #6c57c3;
}
.ext-rq-background-color {
	--background-color: #6c57c3;
	--color: colour-difference(#6c57c3, #000000);
	background-color: #6c57c3;
}
.ext-rq-all {
	--background-color: #6c57c3;
	--color: colour-difference(#6c57c3, #000000);
	background-color: #6c57c3;
	color: colour-difference(#6c57c3, #000000);
}
.ext-srx-color {
	color: #6bf5dc;
}
.ext-srx-background-color {
	--background-color: #6bf5dc;
	--color: colour-difference(#6bf5dc, #000000);
	background-color: #6bf5dc;
}
.ext-srx-all {
	--background-color: #6bf5dc;
	--color: colour-difference(#6bf5dc, #000000);
	background-color: #6bf5dc;
	color: colour-difference(#6bf5dc, #000000);
}
.ext-gram-color {
	color: #fd46d2;
}
.ext-gram-background-color {
	--background-color: #fd46d2;
	--color: colour-difference(#fd46d2, #000000);
	background-color: #fd46d2;
}
.ext-gram-all {
	--background-color: #fd46d2;
	--color: colour-difference(#fd46d2, #000000);
	background-color: #fd46d2;
	color: colour-difference(#fd46d2, #000000);
}
.ext-grxml-color {
	color: #c0a319;
}
.ext-grxml-background-color {
	--background-color: #c0a319;
	--color: colour-difference(#c0a319, #000000);
	background-color: #c0a319;
}
.ext-grxml-all {
	--background-color: #c0a319;
	--color: colour-difference(#c0a319, #000000);
	background-color: #c0a319;
	color: colour-difference(#c0a319, #000000);
}
.ext-ssml-color {
	color: #d0e143;
}
.ext-ssml-background-color {
	--background-color: #d0e143;
	--color: colour-difference(#d0e143, #000000);
	background-color: #d0e143;
}
.ext-ssml-all {
	--background-color: #d0e143;
	--color: colour-difference(#d0e143, #000000);
	background-color: #d0e143;
	color: colour-difference(#d0e143, #000000);
}
.ext-skp-color {
	color: #ad22aa;
}
.ext-skp-background-color {
	--background-color: #ad22aa;
	--color: colour-difference(#ad22aa, #000000);
	background-color: #ad22aa;
}
.ext-skp-all {
	--background-color: #ad22aa;
	--color: colour-difference(#ad22aa, #000000);
	background-color: #ad22aa;
	color: colour-difference(#ad22aa, #000000);
}
.ext-sgml-color {
	color: #9e5ff7;
}
.ext-sgml-background-color {
	--background-color: #9e5ff7;
	--color: colour-difference(#9e5ff7, #000000);
	background-color: #9e5ff7;
}
.ext-sgml-all {
	--background-color: #9e5ff7;
	--color: colour-difference(#9e5ff7, #000000);
	background-color: #9e5ff7;
	color: colour-difference(#9e5ff7, #000000);
}
.ext-sdc-color {
	color: #9ea668;
}
.ext-sdc-background-color {
	--background-color: #9ea668;
	--color: colour-difference(#9ea668, #000000);
	background-color: #9ea668;
}
.ext-sdc-all {
	--background-color: #9ea668;
	--color: colour-difference(#9ea668, #000000);
	background-color: #9ea668;
	color: colour-difference(#9ea668, #000000);
}
.ext-sda-color {
	color: #e2be2e;
}
.ext-sda-background-color {
	--background-color: #e2be2e;
	--color: colour-difference(#e2be2e, #000000);
	background-color: #e2be2e;
}
.ext-sda-all {
	--background-color: #e2be2e;
	--color: colour-difference(#e2be2e, #000000);
	background-color: #e2be2e;
	color: colour-difference(#e2be2e, #000000);
}
.ext-sdd-color {
	color: #1ed7ca;
}
.ext-sdd-background-color {
	--background-color: #1ed7ca;
	--color: colour-difference(#1ed7ca, #000000);
	background-color: #1ed7ca;
}
.ext-sdd-all {
	--background-color: #1ed7ca;
	--color: colour-difference(#1ed7ca, #000000);
	background-color: #1ed7ca;
	color: colour-difference(#1ed7ca, #000000);
}
.ext-smf-color {
	color: #32a0ed;
}
.ext-smf-background-color {
	--background-color: #32a0ed;
	--color: colour-difference(#32a0ed, #000000);
	background-color: #32a0ed;
}
.ext-smf-all {
	--background-color: #32a0ed;
	--color: colour-difference(#32a0ed, #000000);
	background-color: #32a0ed;
	color: colour-difference(#32a0ed, #000000);
}
.ext-sdw-color {
	color: #c577b3;
}
.ext-sdw-background-color {
	--background-color: #c577b3;
	--color: colour-difference(#c577b3, #000000);
	background-color: #c577b3;
}
.ext-sdw-all {
	--background-color: #c577b3;
	--color: colour-difference(#c577b3, #000000);
	background-color: #c577b3;
	color: colour-difference(#c577b3, #000000);
}
.ext-sgl-color {
	color: #a2a3c2;
}
.ext-sgl-background-color {
	--background-color: #a2a3c2;
	--color: colour-difference(#a2a3c2, #000000);
	background-color: #a2a3c2;
}
.ext-sgl-all {
	--background-color: #a2a3c2;
	--color: colour-difference(#a2a3c2, #000000);
	background-color: #a2a3c2;
	color: colour-difference(#a2a3c2, #000000);
}
.ext-sm-color {
	color: #9d2231;
}
.ext-sm-background-color {
	--background-color: #9d2231;
	--color: colour-difference(#9d2231, #000000);
	background-color: #9d2231;
}
.ext-sm-all {
	--background-color: #9d2231;
	--color: colour-difference(#9d2231, #000000);
	background-color: #9d2231;
	color: colour-difference(#9d2231, #000000);
}
.ext-sit-color {
	color: #53e190;
}
.ext-sit-background-color {
	--background-color: #53e190;
	--color: colour-difference(#53e190, #000000);
	background-color: #53e190;
}
.ext-sit-all {
	--background-color: #53e190;
	--color: colour-difference(#53e190, #000000);
	background-color: #53e190;
	color: colour-difference(#53e190, #000000);
}
.ext-sitx-color {
	color: #7e45ec;
}
.ext-sitx-background-color {
	--background-color: #7e45ec;
	--color: colour-difference(#7e45ec, #000000);
	background-color: #7e45ec;
}
.ext-sitx-all {
	--background-color: #7e45ec;
	--color: colour-difference(#7e45ec, #000000);
	background-color: #7e45ec;
	color: colour-difference(#7e45ec, #000000);
}
.ext-sdkm-color {
	color: #602d5c;
}
.ext-sdkm-background-color {
	--background-color: #602d5c;
	--color: colour-difference(#602d5c, #000000);
	background-color: #602d5c;
}
.ext-sdkm-all {
	--background-color: #602d5c;
	--color: colour-difference(#602d5c, #000000);
	background-color: #602d5c;
	color: colour-difference(#602d5c, #000000);
}
.ext-xo-color {
	color: #1e9cbb;
}
.ext-xo-background-color {
	--background-color: #1e9cbb;
	--color: colour-difference(#1e9cbb, #000000);
	background-color: #1e9cbb;
}
.ext-xo-all {
	--background-color: #1e9cbb;
	--color: colour-difference(#1e9cbb, #000000);
	background-color: #1e9cbb;
	color: colour-difference(#1e9cbb, #000000);
}
.ext-au-color {
	color: #764ecb;
}
.ext-au-background-color {
	--background-color: #764ecb;
	--color: colour-difference(#764ecb, #000000);
	background-color: #764ecb;
}
.ext-au-all {
	--background-color: #764ecb;
	--color: colour-difference(#764ecb, #000000);
	background-color: #764ecb;
	color: colour-difference(#764ecb, #000000);
}
.ext-wqd-color {
	color: #8e9d55;
}
.ext-wqd-background-color {
	--background-color: #8e9d55;
	--color: colour-difference(#8e9d55, #000000);
	background-color: #8e9d55;
}
.ext-wqd-all {
	--background-color: #8e9d55;
	--color: colour-difference(#8e9d55, #000000);
	background-color: #8e9d55;
	color: colour-difference(#8e9d55, #000000);
}
.ext-sis-color {
	color: #b70927;
}
.ext-sis-background-color {
	--background-color: #b70927;
	--color: colour-difference(#b70927, #000000);
	background-color: #b70927;
}
.ext-sis-all {
	--background-color: #b70927;
	--color: colour-difference(#b70927, #000000);
	background-color: #b70927;
	color: colour-difference(#b70927, #000000);
}
.ext-smi-color {
	color: #a17a74;
}
.ext-smi-background-color {
	--background-color: #a17a74;
	--color: colour-difference(#a17a74, #000000);
	background-color: #a17a74;
}
.ext-smi-all {
	--background-color: #a17a74;
	--color: colour-difference(#a17a74, #000000);
	background-color: #a17a74;
	color: colour-difference(#a17a74, #000000);
}
.ext-xsm-color {
	color: #2fec2e;
}
.ext-xsm-background-color {
	--background-color: #2fec2e;
	--color: colour-difference(#2fec2e, #000000);
	background-color: #2fec2e;
}
.ext-xsm-all {
	--background-color: #2fec2e;
	--color: colour-difference(#2fec2e, #000000);
	background-color: #2fec2e;
	color: colour-difference(#2fec2e, #000000);
}
.ext-bdm-color {
	color: #e87b4f;
}
.ext-bdm-background-color {
	--background-color: #e87b4f;
	--color: colour-difference(#e87b4f, #000000);
	background-color: #e87b4f;
}
.ext-bdm-all {
	--background-color: #e87b4f;
	--color: colour-difference(#e87b4f, #000000);
	background-color: #e87b4f;
	color: colour-difference(#e87b4f, #000000);
}
.ext-xdm-color {
	color: #dbabdd;
}
.ext-xdm-background-color {
	--background-color: #dbabdd;
	--color: colour-difference(#dbabdd, #000000);
	background-color: #dbabdd;
}
.ext-xdm-all {
	--background-color: #dbabdd;
	--color: colour-difference(#dbabdd, #000000);
	background-color: #dbabdd;
	color: colour-difference(#dbabdd, #000000);
}
.ext-sv4cpio-color {
	color: #10b293;
}
.ext-sv4cpio-background-color {
	--background-color: #10b293;
	--color: colour-difference(#10b293, #000000);
	background-color: #10b293;
}
.ext-sv4cpio-all {
	--background-color: #10b293;
	--color: colour-difference(#10b293, #000000);
	background-color: #10b293;
	color: colour-difference(#10b293, #000000);
}
.ext-sv4crc-color {
	color: #3f7483;
}
.ext-sv4crc-background-color {
	--background-color: #3f7483;
	--color: colour-difference(#3f7483, #000000);
	background-color: #3f7483;
}
.ext-sv4crc-all {
	--background-color: #3f7483;
	--color: colour-difference(#3f7483, #000000);
	background-color: #3f7483;
	color: colour-difference(#3f7483, #000000);
}
.ext-sbml-color {
	color: #d1900d;
}
.ext-sbml-background-color {
	--background-color: #d1900d;
	--color: colour-difference(#d1900d, #000000);
	background-color: #d1900d;
}
.ext-sbml-all {
	--background-color: #d1900d;
	--color: colour-difference(#d1900d, #000000);
	background-color: #d1900d;
	color: colour-difference(#d1900d, #000000);
}
.ext-tsv-color {
	color: #e3418d;
}
.ext-tsv-background-color {
	--background-color: #e3418d;
	--color: colour-difference(#e3418d, #000000);
	background-color: #e3418d;
}
.ext-tsv-all {
	--background-color: #e3418d;
	--color: colour-difference(#e3418d, #000000);
	background-color: #e3418d;
	color: colour-difference(#e3418d, #000000);
}
.ext-tiff-color {
	color: #eab87a;
}
.ext-tiff-background-color {
	--background-color: #eab87a;
	--color: colour-difference(#eab87a, #000000);
	background-color: #eab87a;
}
.ext-tiff-all {
	--background-color: #eab87a;
	--color: colour-difference(#eab87a, #000000);
	background-color: #eab87a;
	color: colour-difference(#eab87a, #000000);
}
.ext-tao-color {
	color: #9ae532;
}
.ext-tao-background-color {
	--background-color: #9ae532;
	--color: colour-difference(#9ae532, #000000);
	background-color: #9ae532;
}
.ext-tao-all {
	--background-color: #9ae532;
	--color: colour-difference(#9ae532, #000000);
	background-color: #9ae532;
	color: colour-difference(#9ae532, #000000);
}
.ext-tar-color {
	color: #764cd1;
}
.ext-tar-background-color {
	--background-color: #764cd1;
	--color: colour-difference(#764cd1, #000000);
	background-color: #764cd1;
}
.ext-tar-all {
	--background-color: #764cd1;
	--color: colour-difference(#764cd1, #000000);
	background-color: #764cd1;
	color: colour-difference(#764cd1, #000000);
}
.ext-tcl-color {
	color: #791d34;
}
.ext-tcl-background-color {
	--background-color: #791d34;
	--color: colour-difference(#791d34, #000000);
	background-color: #791d34;
}
.ext-tcl-all {
	--background-color: #791d34;
	--color: colour-difference(#791d34, #000000);
	background-color: #791d34;
	color: colour-difference(#791d34, #000000);
}
.ext-tex-color {
	color: #37665d;
}
.ext-tex-background-color {
	--background-color: #37665d;
	--color: colour-difference(#37665d, #000000);
	background-color: #37665d;
}
.ext-tex-all {
	--background-color: #37665d;
	--color: colour-difference(#37665d, #000000);
	background-color: #37665d;
	color: colour-difference(#37665d, #000000);
}
.ext-tfm-color {
	color: #ea312f;
}
.ext-tfm-background-color {
	--background-color: #ea312f;
	--color: colour-difference(#ea312f, #000000);
	background-color: #ea312f;
}
.ext-tfm-all {
	--background-color: #ea312f;
	--color: colour-difference(#ea312f, #000000);
	background-color: #ea312f;
	color: colour-difference(#ea312f, #000000);
}
.ext-tei-color {
	color: #94586c;
}
.ext-tei-background-color {
	--background-color: #94586c;
	--color: colour-difference(#94586c, #000000);
	background-color: #94586c;
}
.ext-tei-all {
	--background-color: #94586c;
	--color: colour-difference(#94586c, #000000);
	background-color: #94586c;
	color: colour-difference(#94586c, #000000);
}
.ext-txt-color {
	color: #ed4fb0;
}
.ext-txt-background-color {
	--background-color: #ed4fb0;
	--color: colour-difference(#ed4fb0, #000000);
	background-color: #ed4fb0;
}
.ext-txt-all {
	--background-color: #ed4fb0;
	--color: colour-difference(#ed4fb0, #000000);
	background-color: #ed4fb0;
	color: colour-difference(#ed4fb0, #000000);
}
.ext-dxp-color {
	color: #b852af;
}
.ext-dxp-background-color {
	--background-color: #b852af;
	--color: colour-difference(#b852af, #000000);
	background-color: #b852af;
}
.ext-dxp-all {
	--background-color: #b852af;
	--color: colour-difference(#b852af, #000000);
	background-color: #b852af;
	color: colour-difference(#b852af, #000000);
}
.ext-sfs-color {
	color: #ff521d;
}
.ext-sfs-background-color {
	--background-color: #ff521d;
	--color: colour-difference(#ff521d, #000000);
	background-color: #ff521d;
}
.ext-sfs-all {
	--background-color: #ff521d;
	--color: colour-difference(#ff521d, #000000);
	background-color: #ff521d;
	color: colour-difference(#ff521d, #000000);
}
.ext-tsd-color {
	color: #45bed1;
}
.ext-tsd-background-color {
	--background-color: #45bed1;
	--color: colour-difference(#45bed1, #000000);
	background-color: #45bed1;
}
.ext-tsd-all {
	--background-color: #45bed1;
	--color: colour-difference(#45bed1, #000000);
	background-color: #45bed1;
	color: colour-difference(#45bed1, #000000);
}
.ext-tpt-color {
	color: #30026c;
}
.ext-tpt-background-color {
	--background-color: #30026c;
	--color: colour-difference(#30026c, #000000);
	background-color: #30026c;
}
.ext-tpt-all {
	--background-color: #30026c;
	--color: colour-difference(#30026c, #000000);
	background-color: #30026c;
	color: colour-difference(#30026c, #000000);
}
.ext-mxs-color {
	color: #3e3042;
}
.ext-mxs-background-color {
	--background-color: #3e3042;
	--color: colour-difference(#3e3042, #000000);
	background-color: #3e3042;
}
.ext-mxs-all {
	--background-color: #3e3042;
	--color: colour-difference(#3e3042, #000000);
	background-color: #3e3042;
	color: colour-difference(#3e3042, #000000);
}
.ext-t-color {
	color: #6e4ebe;
}
.ext-t-background-color {
	--background-color: #6e4ebe;
	--color: colour-difference(#6e4ebe, #000000);
	background-color: #6e4ebe;
}
.ext-t-all {
	--background-color: #6e4ebe;
	--color: colour-difference(#6e4ebe, #000000);
	background-color: #6e4ebe;
	color: colour-difference(#6e4ebe, #000000);
}
.ext-tra-color {
	color: #d5a34c;
}
.ext-tra-background-color {
	--background-color: #d5a34c;
	--color: colour-difference(#d5a34c, #000000);
	background-color: #d5a34c;
}
.ext-tra-all {
	--background-color: #d5a34c;
	--color: colour-difference(#d5a34c, #000000);
	background-color: #d5a34c;
	color: colour-difference(#d5a34c, #000000);
}
.ext-ttf-color {
	color: #e69249;
}
.ext-ttf-background-color {
	--background-color: #e69249;
	--color: colour-difference(#e69249, #000000);
	background-color: #e69249;
}
.ext-ttf-all {
	--background-color: #e69249;
	--color: colour-difference(#e69249, #000000);
	background-color: #e69249;
	color: colour-difference(#e69249, #000000);
}
.ext-ttl-color {
	color: #78156e;
}
.ext-ttl-background-color {
	--background-color: #78156e;
	--color: colour-difference(#78156e, #000000);
	background-color: #78156e;
}
.ext-ttl-all {
	--background-color: #78156e;
	--color: colour-difference(#78156e, #000000);
	background-color: #78156e;
	color: colour-difference(#78156e, #000000);
}
.ext-umj-color {
	color: #f58b8d;
}
.ext-umj-background-color {
	--background-color: #f58b8d;
	--color: colour-difference(#f58b8d, #000000);
	background-color: #f58b8d;
}
.ext-umj-all {
	--background-color: #f58b8d;
	--color: colour-difference(#f58b8d, #000000);
	background-color: #f58b8d;
	color: colour-difference(#f58b8d, #000000);
}
.ext-uoml-color {
	color: #e541ad;
}
.ext-uoml-background-color {
	--background-color: #e541ad;
	--color: colour-difference(#e541ad, #000000);
	background-color: #e541ad;
}
.ext-uoml-all {
	--background-color: #e541ad;
	--color: colour-difference(#e541ad, #000000);
	background-color: #e541ad;
	color: colour-difference(#e541ad, #000000);
}
.ext-unityweb-color {
	color: #4e33e6;
}
.ext-unityweb-background-color {
	--background-color: #4e33e6;
	--color: colour-difference(#4e33e6, #000000);
	background-color: #4e33e6;
}
.ext-unityweb-all {
	--background-color: #4e33e6;
	--color: colour-difference(#4e33e6, #000000);
	background-color: #4e33e6;
	color: colour-difference(#4e33e6, #000000);
}
.ext-ufd-color {
	color: #b8df55;
}
.ext-ufd-background-color {
	--background-color: #b8df55;
	--color: colour-difference(#b8df55, #000000);
	background-color: #b8df55;
}
.ext-ufd-all {
	--background-color: #b8df55;
	--color: colour-difference(#b8df55, #000000);
	background-color: #b8df55;
	color: colour-difference(#b8df55, #000000);
}
.ext-uri-color {
	color: #a94194;
}
.ext-uri-background-color {
	--background-color: #a94194;
	--color: colour-difference(#a94194, #000000);
	background-color: #a94194;
}
.ext-uri-all {
	--background-color: #a94194;
	--color: colour-difference(#a94194, #000000);
	background-color: #a94194;
	color: colour-difference(#a94194, #000000);
}
.ext-utz-color {
	color: #8c45f2;
}
.ext-utz-background-color {
	--background-color: #8c45f2;
	--color: colour-difference(#8c45f2, #000000);
	background-color: #8c45f2;
}
.ext-utz-all {
	--background-color: #8c45f2;
	--color: colour-difference(#8c45f2, #000000);
	background-color: #8c45f2;
	color: colour-difference(#8c45f2, #000000);
}
.ext-ustar-color {
	color: #2129a3;
}
.ext-ustar-background-color {
	--background-color: #2129a3;
	--color: colour-difference(#2129a3, #000000);
	background-color: #2129a3;
}
.ext-ustar-all {
	--background-color: #2129a3;
	--color: colour-difference(#2129a3, #000000);
	background-color: #2129a3;
	color: colour-difference(#2129a3, #000000);
}
.ext-uu-color {
	color: #aa5a3d;
}
.ext-uu-background-color {
	--background-color: #aa5a3d;
	--color: colour-difference(#aa5a3d, #000000);
	background-color: #aa5a3d;
}
.ext-uu-all {
	--background-color: #aa5a3d;
	--color: colour-difference(#aa5a3d, #000000);
	background-color: #aa5a3d;
	color: colour-difference(#aa5a3d, #000000);
}
.ext-vcs-color {
	color: #5fca93;
}
.ext-vcs-background-color {
	--background-color: #5fca93;
	--color: colour-difference(#5fca93, #000000);
	background-color: #5fca93;
}
.ext-vcs-all {
	--background-color: #5fca93;
	--color: colour-difference(#5fca93, #000000);
	background-color: #5fca93;
	color: colour-difference(#5fca93, #000000);
}
.ext-vcf-color {
	color: #bc4067;
}
.ext-vcf-background-color {
	--background-color: #bc4067;
	--color: colour-difference(#bc4067, #000000);
	background-color: #bc4067;
}
.ext-vcf-all {
	--background-color: #bc4067;
	--color: colour-difference(#bc4067, #000000);
	background-color: #bc4067;
	color: colour-difference(#bc4067, #000000);
}
.ext-vcd-color {
	color: #f9abff;
}
.ext-vcd-background-color {
	--background-color: #f9abff;
	--color: colour-difference(#f9abff, #000000);
	background-color: #f9abff;
}
.ext-vcd-all {
	--background-color: #f9abff;
	--color: colour-difference(#f9abff, #000000);
	background-color: #f9abff;
	color: colour-difference(#f9abff, #000000);
}
.ext-vsf-color {
	color: #2c4a4f;
}
.ext-vsf-background-color {
	--background-color: #2c4a4f;
	--color: colour-difference(#2c4a4f, #000000);
	background-color: #2c4a4f;
}
.ext-vsf-all {
	--background-color: #2c4a4f;
	--color: colour-difference(#2c4a4f, #000000);
	background-color: #2c4a4f;
	color: colour-difference(#2c4a4f, #000000);
}
.ext-wrl-color {
	color: #ee5da9;
}
.ext-wrl-background-color {
	--background-color: #ee5da9;
	--color: colour-difference(#ee5da9, #000000);
	background-color: #ee5da9;
}
.ext-wrl-all {
	--background-color: #ee5da9;
	--color: colour-difference(#ee5da9, #000000);
	background-color: #ee5da9;
	color: colour-difference(#ee5da9, #000000);
}
.ext-vcx-color {
	color: #a71ce3;
}
.ext-vcx-background-color {
	--background-color: #a71ce3;
	--color: colour-difference(#a71ce3, #000000);
	background-color: #a71ce3;
}
.ext-vcx-all {
	--background-color: #a71ce3;
	--color: colour-difference(#a71ce3, #000000);
	background-color: #a71ce3;
	color: colour-difference(#a71ce3, #000000);
}
.ext-mts-color {
	color: #281fd4;
}
.ext-mts-background-color {
	--background-color: #281fd4;
	--color: colour-difference(#281fd4, #000000);
	background-color: #281fd4;
}
.ext-mts-all {
	--background-color: #281fd4;
	--color: colour-difference(#281fd4, #000000);
	background-color: #281fd4;
	color: colour-difference(#281fd4, #000000);
}
.ext-vtu-color {
	color: #b43c97;
}
.ext-vtu-background-color {
	--background-color: #b43c97;
	--color: colour-difference(#b43c97, #000000);
	background-color: #b43c97;
}
.ext-vtu-all {
	--background-color: #b43c97;
	--color: colour-difference(#b43c97, #000000);
	background-color: #b43c97;
	color: colour-difference(#b43c97, #000000);
}
.ext-vis-color {
	color: #f99721;
}
.ext-vis-background-color {
	--background-color: #f99721;
	--color: colour-difference(#f99721, #000000);
	background-color: #f99721;
}
.ext-vis-all {
	--background-color: #f99721;
	--color: colour-difference(#f99721, #000000);
	background-color: #f99721;
	color: colour-difference(#f99721, #000000);
}
.ext-viv-color {
	color: #686421;
}
.ext-viv-background-color {
	--background-color: #686421;
	--color: colour-difference(#686421, #000000);
	background-color: #686421;
}
.ext-viv-all {
	--background-color: #686421;
	--color: colour-difference(#686421, #000000);
	background-color: #686421;
	color: colour-difference(#686421, #000000);
}
.ext-ccxml-color {
	color: #c2acc9;
}
.ext-ccxml-background-color {
	--background-color: #c2acc9;
	--color: colour-difference(#c2acc9, #000000);
	background-color: #c2acc9;
}
.ext-ccxml-all {
	--background-color: #c2acc9;
	--color: colour-difference(#c2acc9, #000000);
	background-color: #c2acc9;
	color: colour-difference(#c2acc9, #000000);
}
.ext-vxml-color {
	color: #8a9075;
}
.ext-vxml-background-color {
	--background-color: #8a9075;
	--color: colour-difference(#8a9075, #000000);
	background-color: #8a9075;
}
.ext-vxml-all {
	--background-color: #8a9075;
	--color: colour-difference(#8a9075, #000000);
	background-color: #8a9075;
	color: colour-difference(#8a9075, #000000);
}
.ext-src-color {
	color: #83d029;
}
.ext-src-background-color {
	--background-color: #83d029;
	--color: colour-difference(#83d029, #000000);
	background-color: #83d029;
}
.ext-src-all {
	--background-color: #83d029;
	--color: colour-difference(#83d029, #000000);
	background-color: #83d029;
	color: colour-difference(#83d029, #000000);
}
.ext-wbxml-color {
	color: #97e230;
}
.ext-wbxml-background-color {
	--background-color: #97e230;
	--color: colour-difference(#97e230, #000000);
	background-color: #97e230;
}
.ext-wbxml-all {
	--background-color: #97e230;
	--color: colour-difference(#97e230, #000000);
	background-color: #97e230;
	color: colour-difference(#97e230, #000000);
}
.ext-wbmp-color {
	color: #12bb69;
}
.ext-wbmp-background-color {
	--background-color: #12bb69;
	--color: colour-difference(#12bb69, #000000);
	background-color: #12bb69;
}
.ext-wbmp-all {
	--background-color: #12bb69;
	--color: colour-difference(#12bb69, #000000);
	background-color: #12bb69;
	color: colour-difference(#12bb69, #000000);
}
.ext-wav-color {
	color: #9ff1bf;
}
.ext-wav-background-color {
	--background-color: #9ff1bf;
	--color: colour-difference(#9ff1bf, #000000);
	background-color: #9ff1bf;
}
.ext-wav-all {
	--background-color: #9ff1bf;
	--color: colour-difference(#9ff1bf, #000000);
	background-color: #9ff1bf;
	color: colour-difference(#9ff1bf, #000000);
}
.ext-davmount-color {
	color: #117b1b;
}
.ext-davmount-background-color {
	--background-color: #117b1b;
	--color: colour-difference(#117b1b, #000000);
	background-color: #117b1b;
}
.ext-davmount-all {
	--background-color: #117b1b;
	--color: colour-difference(#117b1b, #000000);
	background-color: #117b1b;
	color: colour-difference(#117b1b, #000000);
}
.ext-woff-color {
	color: #888dc1;
}
.ext-woff-background-color {
	--background-color: #888dc1;
	--color: colour-difference(#888dc1, #000000);
	background-color: #888dc1;
}
.ext-woff-all {
	--background-color: #888dc1;
	--color: colour-difference(#888dc1, #000000);
	background-color: #888dc1;
	color: colour-difference(#888dc1, #000000);
}
.ext-wspolicy-color {
	color: #e6dfff;
}
.ext-wspolicy-background-color {
	--background-color: #e6dfff;
	--color: colour-difference(#e6dfff, #000000);
	background-color: #e6dfff;
}
.ext-wspolicy-all {
	--background-color: #e6dfff;
	--color: colour-difference(#e6dfff, #000000);
	background-color: #e6dfff;
	color: colour-difference(#e6dfff, #000000);
}
.ext-webp-color {
	color: #ed5e1f;
}
.ext-webp-background-color {
	--background-color: #ed5e1f;
	--color: colour-difference(#ed5e1f, #000000);
	background-color: #ed5e1f;
}
.ext-webp-all {
	--background-color: #ed5e1f;
	--color: colour-difference(#ed5e1f, #000000);
	background-color: #ed5e1f;
	color: colour-difference(#ed5e1f, #000000);
}
.ext-wtb-color {
	color: #fc5b8e;
}
.ext-wtb-background-color {
	--background-color: #fc5b8e;
	--color: colour-difference(#fc5b8e, #000000);
	background-color: #fc5b8e;
}
.ext-wtb-all {
	--background-color: #fc5b8e;
	--color: colour-difference(#fc5b8e, #000000);
	background-color: #fc5b8e;
	color: colour-difference(#fc5b8e, #000000);
}
.ext-wgt-color {
	color: #9f07cc;
}
.ext-wgt-background-color {
	--background-color: #9f07cc;
	--color: colour-difference(#9f07cc, #000000);
	background-color: #9f07cc;
}
.ext-wgt-all {
	--background-color: #9f07cc;
	--color: colour-difference(#9f07cc, #000000);
	background-color: #9f07cc;
	color: colour-difference(#9f07cc, #000000);
}
.ext-hlp-color {
	color: #f73c2a;
}
.ext-hlp-background-color {
	--background-color: #f73c2a;
	--color: colour-difference(#f73c2a, #000000);
	background-color: #f73c2a;
}
.ext-hlp-all {
	--background-color: #f73c2a;
	--color: colour-difference(#f73c2a, #000000);
	background-color: #f73c2a;
	color: colour-difference(#f73c2a, #000000);
}
.ext-wml-color {
	color: #2956e0;
}
.ext-wml-background-color {
	--background-color: #2956e0;
	--color: colour-difference(#2956e0, #000000);
	background-color: #2956e0;
}
.ext-wml-all {
	--background-color: #2956e0;
	--color: colour-difference(#2956e0, #000000);
	background-color: #2956e0;
	color: colour-difference(#2956e0, #000000);
}
.ext-wmls-color {
	color: #560cf6;
}
.ext-wmls-background-color {
	--background-color: #560cf6;
	--color: colour-difference(#560cf6, #000000);
	background-color: #560cf6;
}
.ext-wmls-all {
	--background-color: #560cf6;
	--color: colour-difference(#560cf6, #000000);
	background-color: #560cf6;
	color: colour-difference(#560cf6, #000000);
}
.ext-wmlsc-color {
	color: #21541c;
}
.ext-wmlsc-background-color {
	--background-color: #21541c;
	--color: colour-difference(#21541c, #000000);
	background-color: #21541c;
}
.ext-wmlsc-all {
	--background-color: #21541c;
	--color: colour-difference(#21541c, #000000);
	background-color: #21541c;
	color: colour-difference(#21541c, #000000);
}
.ext-wpd-color {
	color: #2cf076;
}
.ext-wpd-background-color {
	--background-color: #2cf076;
	--color: colour-difference(#2cf076, #000000);
	background-color: #2cf076;
}
.ext-wpd-all {
	--background-color: #2cf076;
	--color: colour-difference(#2cf076, #000000);
	background-color: #2cf076;
	color: colour-difference(#2cf076, #000000);
}
.ext-stf-color {
	color: #538844;
}
.ext-stf-background-color {
	--background-color: #538844;
	--color: colour-difference(#538844, #000000);
	background-color: #538844;
}
.ext-stf-all {
	--background-color: #538844;
	--color: colour-difference(#538844, #000000);
	background-color: #538844;
	color: colour-difference(#538844, #000000);
}
.ext-wsdl-color {
	color: #f02122;
}
.ext-wsdl-background-color {
	--background-color: #f02122;
	--color: colour-difference(#f02122, #000000);
	background-color: #f02122;
}
.ext-wsdl-all {
	--background-color: #f02122;
	--color: colour-difference(#f02122, #000000);
	background-color: #f02122;
	color: colour-difference(#f02122, #000000);
}
.ext-xbm-color {
	color: #91f2c5;
}
.ext-xbm-background-color {
	--background-color: #91f2c5;
	--color: colour-difference(#91f2c5, #000000);
	background-color: #91f2c5;
}
.ext-xbm-all {
	--background-color: #91f2c5;
	--color: colour-difference(#91f2c5, #000000);
	background-color: #91f2c5;
	color: colour-difference(#91f2c5, #000000);
}
.ext-xpm-color {
	color: #67e33d;
}
.ext-xpm-background-color {
	--background-color: #67e33d;
	--color: colour-difference(#67e33d, #000000);
	background-color: #67e33d;
}
.ext-xpm-all {
	--background-color: #67e33d;
	--color: colour-difference(#67e33d, #000000);
	background-color: #67e33d;
	color: colour-difference(#67e33d, #000000);
}
.ext-xwd-color {
	color: #5d56e0;
}
.ext-xwd-background-color {
	--background-color: #5d56e0;
	--color: colour-difference(#5d56e0, #000000);
	background-color: #5d56e0;
}
.ext-xwd-all {
	--background-color: #5d56e0;
	--color: colour-difference(#5d56e0, #000000);
	background-color: #5d56e0;
	color: colour-difference(#5d56e0, #000000);
}
.ext-der-color {
	color: #54d12f;
}
.ext-der-background-color {
	--background-color: #54d12f;
	--color: colour-difference(#54d12f, #000000);
	background-color: #54d12f;
}
.ext-der-all {
	--background-color: #54d12f;
	--color: colour-difference(#54d12f, #000000);
	background-color: #54d12f;
	color: colour-difference(#54d12f, #000000);
}
.ext-fig-color {
	color: #769685;
}
.ext-fig-background-color {
	--background-color: #769685;
	--color: colour-difference(#769685, #000000);
	background-color: #769685;
}
.ext-fig-all {
	--background-color: #769685;
	--color: colour-difference(#769685, #000000);
	background-color: #769685;
	color: colour-difference(#769685, #000000);
}
.ext-xhtml-color {
	color: #2474c0;
}
.ext-xhtml-background-color {
	--background-color: #2474c0;
	--color: colour-difference(#2474c0, #000000);
	background-color: #2474c0;
}
.ext-xhtml-all {
	--background-color: #2474c0;
	--color: colour-difference(#2474c0, #000000);
	background-color: #2474c0;
	color: colour-difference(#2474c0, #000000);
}
.ext-xml-color {
	color: #d27ca0;
}
.ext-xml-background-color {
	--background-color: #d27ca0;
	--color: colour-difference(#d27ca0, #000000);
	background-color: #d27ca0;
}
.ext-xml-all {
	--background-color: #d27ca0;
	--color: colour-difference(#d27ca0, #000000);
	background-color: #d27ca0;
	color: colour-difference(#d27ca0, #000000);
}
.ext-xdf-color {
	color: #8c0d8a;
}
.ext-xdf-background-color {
	--background-color: #8c0d8a;
	--color: colour-difference(#8c0d8a, #000000);
	background-color: #8c0d8a;
}
.ext-xdf-all {
	--background-color: #8c0d8a;
	--color: colour-difference(#8c0d8a, #000000);
	background-color: #8c0d8a;
	color: colour-difference(#8c0d8a, #000000);
}
.ext-xenc-color {
	color: #204bd0;
}
.ext-xenc-background-color {
	--background-color: #204bd0;
	--color: colour-difference(#204bd0, #000000);
	background-color: #204bd0;
}
.ext-xenc-all {
	--background-color: #204bd0;
	--color: colour-difference(#204bd0, #000000);
	background-color: #204bd0;
	color: colour-difference(#204bd0, #000000);
}
.ext-xer-color {
	color: #e365f9;
}
.ext-xer-background-color {
	--background-color: #e365f9;
	--color: colour-difference(#e365f9, #000000);
	background-color: #e365f9;
}
.ext-xer-all {
	--background-color: #e365f9;
	--color: colour-difference(#e365f9, #000000);
	background-color: #e365f9;
	color: colour-difference(#e365f9, #000000);
}
.ext-rl-color {
	color: #620124;
}
.ext-rl-background-color {
	--background-color: #620124;
	--color: colour-difference(#620124, #000000);
	background-color: #620124;
}
.ext-rl-all {
	--background-color: #620124;
	--color: colour-difference(#620124, #000000);
	background-color: #620124;
	color: colour-difference(#620124, #000000);
}
.ext-rs-color {
	color: #adacbd;
}
.ext-rs-background-color {
	--background-color: #adacbd;
	--color: colour-difference(#adacbd, #000000);
	background-color: #adacbd;
}
.ext-rs-all {
	--background-color: #adacbd;
	--color: colour-difference(#adacbd, #000000);
	background-color: #adacbd;
	color: colour-difference(#adacbd, #000000);
}
.ext-rld-color {
	color: #420a1c;
}
.ext-rld-background-color {
	--background-color: #420a1c;
	--color: colour-difference(#420a1c, #000000);
	background-color: #420a1c;
}
.ext-rld-all {
	--background-color: #420a1c;
	--color: colour-difference(#420a1c, #000000);
	background-color: #420a1c;
	color: colour-difference(#420a1c, #000000);
}
.ext-xslt-color {
	color: #314837;
}
.ext-xslt-background-color {
	--background-color: #314837;
	--color: colour-difference(#314837, #000000);
	background-color: #314837;
}
.ext-xslt-all {
	--background-color: #314837;
	--color: colour-difference(#314837, #000000);
	background-color: #314837;
	color: colour-difference(#314837, #000000);
}
.ext-xop-color {
	color: #ffc9af;
}
.ext-xop-background-color {
	--background-color: #ffc9af;
	--color: colour-difference(#ffc9af, #000000);
	background-color: #ffc9af;
}
.ext-xop-all {
	--background-color: #ffc9af;
	--color: colour-difference(#ffc9af, #000000);
	background-color: #ffc9af;
	color: colour-difference(#ffc9af, #000000);
}
.ext-xpi-color {
	color: #d3e525;
}
.ext-xpi-background-color {
	--background-color: #d3e525;
	--color: colour-difference(#d3e525, #000000);
	background-color: #d3e525;
}
.ext-xpi-all {
	--background-color: #d3e525;
	--color: colour-difference(#d3e525, #000000);
	background-color: #d3e525;
	color: colour-difference(#d3e525, #000000);
}
.ext-xspf-color {
	color: #9cd4f0;
}
.ext-xspf-background-color {
	--background-color: #9cd4f0;
	--color: colour-difference(#9cd4f0, #000000);
	background-color: #9cd4f0;
}
.ext-xspf-all {
	--background-color: #9cd4f0;
	--color: colour-difference(#9cd4f0, #000000);
	background-color: #9cd4f0;
	color: colour-difference(#9cd4f0, #000000);
}
.ext-xul-color {
	color: #b18519;
}
.ext-xul-background-color {
	--background-color: #b18519;
	--color: colour-difference(#b18519, #000000);
	background-color: #b18519;
}
.ext-xul-all {
	--background-color: #b18519;
	--color: colour-difference(#b18519, #000000);
	background-color: #b18519;
	color: colour-difference(#b18519, #000000);
}
.ext-xyz-color {
	color: #a72f42;
}
.ext-xyz-background-color {
	--background-color: #a72f42;
	--color: colour-difference(#a72f42, #000000);
	background-color: #a72f42;
}
.ext-xyz-all {
	--background-color: #a72f42;
	--color: colour-difference(#a72f42, #000000);
	background-color: #a72f42;
	color: colour-difference(#a72f42, #000000);
}
.ext-yaml-color {
	color: #145d47;
}
.ext-yaml-background-color {
	--background-color: #145d47;
	--color: colour-difference(#145d47, #000000);
	background-color: #145d47;
}
.ext-yaml-all {
	--background-color: #145d47;
	--color: colour-difference(#145d47, #000000);
	background-color: #145d47;
	color: colour-difference(#145d47, #000000);
}
.ext-yang-color {
	color: #8e2273;
}
.ext-yang-background-color {
	--background-color: #8e2273;
	--color: colour-difference(#8e2273, #000000);
	background-color: #8e2273;
}
.ext-yang-all {
	--background-color: #8e2273;
	--color: colour-difference(#8e2273, #000000);
	background-color: #8e2273;
	color: colour-difference(#8e2273, #000000);
}
.ext-yin-color {
	color: #a02a57;
}
.ext-yin-background-color {
	--background-color: #a02a57;
	--color: colour-difference(#a02a57, #000000);
	background-color: #a02a57;
}
.ext-yin-all {
	--background-color: #a02a57;
	--color: colour-difference(#a02a57, #000000);
	background-color: #a02a57;
	color: colour-difference(#a02a57, #000000);
}
.ext-zir-color {
	color: #9bc3b6;
}
.ext-zir-background-color {
	--background-color: #9bc3b6;
	--color: colour-difference(#9bc3b6, #000000);
	background-color: #9bc3b6;
}
.ext-zir-all {
	--background-color: #9bc3b6;
	--color: colour-difference(#9bc3b6, #000000);
	background-color: #9bc3b6;
	color: colour-difference(#9bc3b6, #000000);
}
.ext-zip-color {
	color: #227dd2;
}
.ext-zip-background-color {
	--background-color: #227dd2;
	--color: colour-difference(#227dd2, #000000);
	background-color: #227dd2;
}
.ext-zip-all {
	--background-color: #227dd2;
	--color: colour-difference(#227dd2, #000000);
	background-color: #227dd2;
	color: colour-difference(#227dd2, #000000);
}
.ext-zmm-color {
	color: #e144cb;
}
.ext-zmm-background-color {
	--background-color: #e144cb;
	--color: colour-difference(#e144cb, #000000);
	background-color: #e144cb;
}
.ext-zmm-all {
	--background-color: #e144cb;
	--color: colour-difference(#e144cb, #000000);
	background-color: #e144cb;
	color: colour-difference(#e144cb, #000000);
}
.ext-zaz-color {
	color: #bd343a;
}
.ext-zaz-background-color {
	--background-color: #bd343a;
	--color: colour-difference(#bd343a, #000000);
	background-color: #bd343a;
}
.ext-zaz-all {
	--background-color: #bd343a;
	--color: colour-difference(#bd343a, #000000);
	background-color: #bd343a;
	color: colour-difference(#bd343a, #000000);
}
