@import 'animations';
@import 'mediaQueries';
#collections {
	.columns {
		flex: 1;
		padding: 0 10px;

		.file-column {
			width: 20vw;
			flex: 1;
			border-right: 1px solid #aaa;
			border-top: 1px solid #ddd;
			border-right: 0;
			border-top: 0;
			margin: 4px;
			background: #fff;
			box-shadow: 4px 4px 30px #333a993e;
			border-radius: 5px;
			position: relative;
			padding: 0 0 0 0;
			display: flex;
			flex-direction: column;
			max-height: calc(100% - 10px);
			margin-bottom: 10px;
			box-sizing: border-box;

			&:nth-child(1),
			&:nth-child(2) {
				.item {
					border-left: 5px solid #2aa75a;
				}
			}

			& > .toolbar:first-child {
				border-radius: 5px 5px 0 0;
			}

			.company-area {
				&.opened {
					.content {
						display: block;
					}
				}
				.company-area-name {
					cursor: pointer;
					padding: 10px;
					font-size: 14px;
					font-weight: 200;
					display: flex;
					align-items: center;
					align-content: flex-start;
					svg {
						font-size: 14px;
						margin-right: 10px;
					}
				}
				.content {
					display: none;
				}
			}

			.workflow-title {
				font-size: 14px;
				margin-top: 10px;
				margin-bottom: 10px;
				color: #073515;
				font-weight: bold;
				svg {
					margin-right: 10px;
					font-size: 12px;
				}
			}
			.list-items {
				overflow-y: scroll;
				max-height: calc(100% - 0px);
				flex: 1;
				box-sizing: border-box;
				padding: 10px;
				.item {
					font-size: 14px;
					font-weight: lighter;
					padding: 10px;
					border-bottom: 1px solid #f0f0f0;
					border-bottom: 0;
					border-radius: 4px;
					margin-bottom: 4px;
					cursor: pointer;
					transition: transform 400ms ease, box-shadow 400ms ease, background 500ms ease;
					&.display {
						cursor: default;
						&:hover {
							color: #333;
							background: #fafafa;
							.date {
								color: #fff;
							}
						}
					}
					&:hover {
						color: #333;
						background: #74ffa9;
						border-color: #0a6b30;
					}
					&.current {
						background: #39ca71;
						background: #c2d8cb;
						color: #fff;
						color: #000;
						border-color: #208f4b;
						box-shadow: 4px 4px 5px #18182251;
						transform: translate3d(-3px, -3px, -3px);

						&:hover {
							background: #2aa75a;
							color: #fff;
							.date {
								color: #fff;
							}
						}
						.date {
							color: #fff;
							color: #000;
						}
					}
					.date {
						font-size: 12px;
						font-weight: lighter;
						color: #888;
					}
					.field-name {
						font-weight: bold;
						margin-bottom: 10px;
					}
					.field-value {
						white-space: pre-wrap;
						border-left: 5px solid rgb(135, 185, 226);
						padding-left: 15px;
						min-height: 20px;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
