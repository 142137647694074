@import '../base/animations';
@import '../utils//colors';

.Auth,
.PrivacyPolicy,
.TermsAndConditions {
	.TextInput {
		--autofill-bg-color: #fff;
		--autofill-text-color: #000;
		margin-top: 20px !important;
		margin-bottom: 15px !important;
		animation: showItem 400ms 1;

		label {
			font-weight: 600 !important;
		}

		&--noShrink {
			label {
				transform: translate(14px, 8px) scale(1) !important;
			}
		}

		&--shrink {
			label {
				transform: translate(0px, -25px) scale(1) !important;
			}
		}

		legend {
			display: none;
		}

		input {
			padding-top: 8px;
			padding-bottom: 15px;
			color: var(--title-color) !important;
		}

		.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: var(--input-color);
		}

		.MuiInputLabel-root.Mui-focused {
			color: var(--input-color);
		}

		&--disabled {
			pointer-events: none;
			user-select: none;
			cursor: not-allowed !important;
			opacity: 0.8;
		}

		.MuiSelect-root {
			padding-top: 10px;
			padding-bottom: 16px;
		}

		& + .MuiFormGroup-root {
			transform: translateY(-16px);
		}
	}
}
