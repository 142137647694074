.chips {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	flex-wrap: wrap;
}
.chip {
	display: flex;
	align-items: center;
	align-content: center;
	background: $chip-background;
	color: $chip-color;
	height: $chip-size;
	border-radius: $chip-size/2;
	box-sizing: border-box;
	padding: 0 10px;
	margin-bottom: 3px;
	margin-right: 3px;
	cursor: default;
	&.blue {
		background: $blueA;
		color: $blueF;
		.right-icon,
		.avatar {
			background: $blue8;
			color: $blueF;
		}
		&:hover {
			background: $blueC;
			color: $blue3;
		}
	}
	&.purple {
		background: $purpleA;
		color: $purpleF;
		.right-icon,
		.avatar {
			background: $purple8;
			color: $purpleF;
		}
		&:hover {
			background: $purpleC;
			color: $purple3;
		}
	}
	&.green {
		background: $greenC;
		color: $green5;
		.right-icon,
		.avatar {
			background: $green8;
			color: $greenE;
		}
		&:hover {
			background: $greenA;
			color: $green3;
		}
	}
	&.white-green {
		background: $background;
		color: $grayC;
		.right-icon,
		.avatar {
			background: $green8;
			color: $greenE;
		}
		&:hover {
			background: $background;
			color: $grayC;
		}
	}
	&.yellow {
		background: $yellowA;
		color: $yellow4;
		.right-icon,
		.avatar {
			background: $yellow8;
			color: $yellow4;
		}
		&:hover {
			background: $yellowC;
			color: $yellow3;
		}
	}
	&.teal {
		background: $tealA;
		color: $tealF;
		.right-icon,
		.avatar {
			background: $teal8;
			color: $tealF;
		}
		&:hover {
			background: $tealC;
			color: $teal3;
		}
	}

	&.red {
		background: $redA;
		color: $redF;
		.right-icon,
		.avatar {
			background: $red8;
			color: $redF;
		}
		&:hover {
			background: $redC;
			color: $red3;
		}
	}

	&.orange {
		background: $orangeB;
		color: $orangeF;
		.right-icon,
		.avatar {
			background: $orange8;
			color: $orangeF;
		}
		&:hover {
			background: $orangeC;
			color: $orange3;
		}
	}
	&.clickable {
		&:hover {
			cursor: pointer;
		}
	}
	.avatar {
		background: $chip-background-item;
		color: $chip-color;
		font-size: $chip-size - 10px;
		display: flex;
		border-radius: $chip-size/2;
		margin-left: -10px;
		margin-right: 7px;
		align-items: center;
		align-content: center;
		justify-content: center;
		height: $chip-size;
		width: $chip-size;
	}
	.right-icon {
		color: $chip-color;
		font-size: $chip-size/2;
		display: flex;
		border-radius: $chip-size/2;
		margin-left: 10px;
		margin-right: -10px;
		align-items: center;
		align-content: center;
		justify-content: center;
		height: $chip-size;
		width: $chip-size;
		&.clickable {
			&:hover {
				cursor: pointer;
			}
		}
		&:hover {
			background: $chip-background-item;
			color: $chip-color;
		}
	}
}
