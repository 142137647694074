.zero-floating-menu {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: none;
	bottom: 0;
	z-index: 10000000000;
	background: rgba(255, 255, 255, 0);
	// backdrop-filter: blur(3px);
	pointer-events: all;
	pointer-events: none;
	&.visible {
		display: block;
	}
	.content {
		position: fixed;
		top: 100px;
		left: 50%;
		right: auto;
		bottom: auto;
		padding: 0;
		min-width: 200px;
		max-width: 300px;
		height: auto;
		max-height: 80vh;
		background: $floating-menu-background;
		box-shadow: 0 0 5px $shadow-b, 14px 14px 50px darken($shadow-b, 10%);
		border-radius: 4px;
		z-index: 10000000000;
		.items {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			align-content: flex-start;
			.item {
				display: flex;
				flex-direction: row;
				align-items: center;
				align-content: flex-start;
				padding: 5px 10px;
				font-size: 14px;
				// word-break: break-all;
				word-break: normal;
				color: $grayE;
				// border-bottom: 1px solid $grayN;
				pointer-events: all;
				cursor: default;
				&:hover {
					background: $floating-menu-item-hover-background;
					.icon {
						color: $floating-menu-color;
					}
				}
				.name {
					flex: 1;
					margin-right: 10px;
					color: $floating-menu-color;
				}
				.icon {
					width: 30px;
					height: 30px;
					border-radius: 15px;
					display: flex;
					align-items: center;
					align-content: center;
					justify-content: center;
					font-size: 18px;
					color: $floating-menu-color;
					&.left-icon {
						margin-right: 10px;
					}
				}
			}
		}
	}
}
