@import 'animations';
@import 'mediaQueries';
@import 'mixins';
$shadow-a: #8bb3c4;

.phases {
	display: flex;
	flex-direction: row;
	align-content: center;
	align-items: stretch;
	overflow-x: scroll;
	padding: 15px 15px 35px 30px;
	position: relative;

	// flex-direction: column;
	// align-content: stretch;
	// align-items: flex-start;
	// // align-items: stretch;
	// justify-content: stretch;
	// border-bottom-left-radius: 10px;
	&::before {
		content: '';
		display: block;
		position: absolute;
		top: auto;
		bottom: 0px;
		left: 0;
		// right: 0;
		// box-shadow: 0 0 20px rgba(0, 0, 0, 1);
		// background: #aaa;
		height: 2px;
		width: 2000px;
	}

	&::after {
		content: '';
		display: block;
		min-width: 30px;
	}

	.phase {
		flex: 1;
		min-width: 130px;
		max-width: 130px;
		background: #eee;
		background: #fff;
		// box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		padding: 10px;
		border-radius: 5px;
		margin-right: 10px;
		position: relative;
		transition: background-color 500ms ease, border-color 400ms ease, box-shadow 100ms ease;

		// border: 1px solid #ddd;
		margin-right: 5px;
		margin-right: 10px;
		border-radius: 0;
		border-radius: 6px;
		// max-width: none;
		// display: flex;
		// align-items: stretch;
		// margin-bottom: 10px;
		// padding: 5px;
		// // flex: 1;
		// border-left: 1px dashed #aaa;
		// border-right: 1px dashed #aaa;
		// border-bottom: 1px dashed #aaa;
		border-top: 10px solid #eef;
		background: #f8f8f8;

		// box-shadow: 8px 8px 5px #076f8233;
		&:hover {
			// box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.1);
			// border: 1px dashed #0011a7;
			// border-top: 10px solid #eee;
			background: #d9fcfa;
			border-top: 10px solid #92e8e4;
		}

		&.phase-completed {
			border-top-color: #06c606;

			.title {
				margin-top: 5px;
				padding-right: 20px;
			}
		}

		&.current {
			border-top: 10px solid #ffc400;
			background: #fff8f8;
			color: #1f5706;

			.title {
				margin-top: 5px;
			}

			&:hover {
				border-top: 10px solid #e9b406;
				background: #fff8f6;
				color: #184603;

				& > .current-icon {
					border-color: #ffa200fe;

					animation-duration: 5000ms;

					svg {
						color: #ffa200fe;
					}
				}
			}
		}

		& > .completed-icon {
			position: absolute;
			top: -22px;
			left: 50%;
			margin-left: -15px;
			// right: -4px;
			bottom: auto;
			width: 24px;
			height: 24px;
			background: #fff;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			border-radius: 20px;
			border: 4px solid #06c606;

			svg {
				color: #06c606;
				font-size: 18px;
			}
		}

		& > .current-icon {
			position: absolute;
			top: -22px;
			left: 50%;
			margin-left: -15px;
			// right: -4px;
			bottom: auto;
			width: 24px;
			height: 24px;
			background: #fff;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			border-radius: 20px;
			border: 4px solid #ffc400;
			transition: transform 300ms ease;
			animation: slide-to-sides 20000ms linear infinite;

			svg {
				color: #ffc400;
				font-size: 18px;
			}
		}

		& > .title {
			font-size: 12px;
			font-size: 14px;
			font-weight: 700;
			font-weight: normal;
			margin-bottom: 12px;
			margin-top: 0;
			// min-width: 100px;
			// max-width: 100px;
			height: 30px;
		}

		.action {
			position: relative;
			padding: 3px;
			background: #fff;
			margin-bottom: 5px;
			border-radius: 4px;
			// border: 1px solid #ddd;
			transition: background-color 500ms ease, box-shadow 500ms ease, transform 400ms ease;
			box-shadow: 0 0 0 transparent;

			margin-right: 5px;
			margin-right: 0;
			margin-bottom: 4px;
			border-radius: 0;
			border-radius: 3px;
			color: #333;

			.completed-icon {
				position: absolute;
				top: -8px;
				left: auto;
				right: -4px;
				bottom: auto;

				svg {
					color: #06c606;
					font-size: 24px;
				}
			}

			// box-shadow: 0 0 2px #555702;
			// border-right: 1px solid #aaa !important;
			// border-bottom: 1px solid #aaa !important;
			&.complementary {
				// border-bottom-right-radius: 15px;
				border-top: 3px dashed #fff;
				border-right: 3px dashed #fff;
				border-bottom: 3px dashed #fff;
				border-top-color: #fff !important;
				border-right-color: #fff !important;
				border-bottom-color: #fff !important;
			}

			&.approval {
				border-left: 10px solid #fff069;
				border-left-color: #ffeb3b !important;
			}

			&.documentInput {
				border-left: 10px solid #00bcd4;
				border-left-color: #00bcd4 !important;
			}

			&.formInput {
				border-left: 10px solid #039be5;
				border-left-color: #039be5 !important;
			}

			&.documentOutput {
				border-left: 10px solid #77d2ff;
				border-left-color: #77d2ff !important;
			}

			&.notificationEmail {
				border-left: 10px solid #04c614;
				border-left-color: #04c614 !important;
			}

			&.phaseJump {
				border-left: 10px solid #ffc507;
				border-left-color: #ffc507 !important;
			}

			&:hover {
				box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.4);
				transform: translate3d(-3px, -3px, -3px);
				z-index: 100;

				& * {
					cursor: normal !important;
				}
			}

			&.clickable {
				cursor: pointer;

				&:hover {
					& * {
						cursor: pointer !important;
					}
				}
			}

			&.status-none {
				border-color: #eee;
				background: #fafafa;
				background: #fff;
				cursor: not-allowed !important;

				header,
				.title {
					cursor: not-allowed !important;
				}
			}

			&.status-completed {
				border-color: #06c606;
				background: #06c606;
				color: #fff;
			}

			&.status-pending {
				border-color: #fff8df;
				background: #fff8df;
				color: #555500;
			}

			&.status-review {
				border-color: #ffd942;
				background: #ffd942;
				color: #220;
			}

			&.status-waiting-review {
				border-color: #5898ec;
				background: #5898ec;
				color: #fff;
			}

			header {
				// background: #666;
				color: inherit;
				border-radius: 3px;
				margin-bottom: 2px;
				padding-bottom: 2px;

				span {
					font-size: 12px;
					line-height: 12px;
					font-weight: normal;
					padding: 0;
				}

				svg {
					color: #fff;
				}
			}

			.completed-icon {
				top: 1px;
				right: 6px;

				svg {
					font-size: 12px;
				}
			}

			.title {
				// font-weight: lighter;
				font-size: 12px;
				margin: 0;
				padding: 0;
				font-weight: normal;
				padding: 4px;
				cursor: pointer;
				// word-break: break-all;
			}
		}

		.comments {
			position: absolute;
			right: -14px;
			bottom: -21px;
		}
	}
}

.executed-item-box {
	// box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	margin-bottom: 15px;
	padding-bottom: 5px;

	button {
		min-width: 30px;
	}

	&.cpf {
		.simple-list-item {
			border-left-color: #24ebda;
		}
	}

	&.approvals {
		.simple-list-item {
			border-left-color: #06b300;
		}
	}

	&.documents {
		.simple-list-item {
			border-left-color: #ffc400;
		}
	}

	&.forms {
		.simple-list-item {
			border-left-color: #d83489;
		}
	}

	&.history {
		.simple-list-item {
			// border-left-color: #2e96d7;
			border-left-color: #cee3f0;
		}
	}

	.simple-list-item {
		margin-left: 30px;
		border-left: 10px solid #ffc400;
		padding: 7px;
		margin-bottom: 4px;

		.line {
			font-size: 12px;

			&.break {
				word-break: break-all;
			}
		}

		button {
			height: 40px;
			width: 40px;
			padding: 0;
			background: #ffc400;
			border-radius: 20px;
			color: #fff;
			position: relative;

			&:hover {
				@include call-attention;
			}

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
}

.executed-item-version-box {
	background: #fafafa;
}

.document-version-descriptor {
	margin-right: 15px;
	font-size: 12px;
	font-weight: bold;
}

.discussion-content {
	margin-top: 12px;
	margin-bottom: 12px;

	.discussion {
		.name {
			color: #666;
			margin: 1px;
		}
	}
}

.info-field.form {
	background: #fff;
	// box-shadow: 3px 3px 10px $shadow-a;
	border-left: 5px solid #184e6f;
	margin-bottom: 8px;
	border-radius: 5px;
	border-radius: 2px;
	padding: 8px;

	label {
		color: #184e6f;
		font-weight: bold;
	}

	.table-sheet-view {
		border-collapse: collapse;

		th {
			text-align: left;
		}

		.col {
			padding: 3px;
			font-size: 12px;
			border: 1px solid #abcbe8;
		}
	}
}

.columns {
	&.textual-info {
		margin-top: 10px;
		border-top: 1px solid #abcbe8;
		padding-top: 10px;
		align-items: center;
	}
}

.selected-items-header {
	display: flex;
	align-items: center;
	margin: 20px 0;
	padding: 10px;
	// box-shadow: 10px 10px 30px #9dc9e685;
	border-radius: 4px;
	color: #1b6783;

	& > div {
		flex: 1;
		// display: flex;
		// align-items: center;
	}

	.selection-actions {
		display: flex;
		align-items: center;
		border-bottom: 1px solid #3a68ff;
		padding-bottom: 10px;
		margin-bottom: 10px;
	}

	.filter-actions {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;

		.spacer {
			flex: 1;
		}

		.filter-by-date {
			div {
				padding: 0.75rem 0.75rem;
				margin: 0 0 0 0.5rem;
				background: #ffc670;
				border-radius: 50%;

				svg {
					transition: 1s transform cubic-bezier(0.075, 0.82, 0.165, 1);
				}
			}

			&.oldest {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		@media #{$mobile_portrait_landscape} {
			flex-wrap: wrap;
		}
	}

	.totalizers {
		flex: none;
		margin-right: 10px;
		border-right: 1px solid #3a68ff;
		padding-right: 10px;
		display: flex;
		align-items: flex-start;
		flex-direction: column;

		.totalizer {
		}
	}

	.filter {
		display: flex;
		align-items: center;
		font-size: 12px;
		white-space: nowrap;
		cursor: pointer;
		@include disable-selection();
		background: #fff9f0;
		border-radius: 40px;
		padding: 0 0 0 10px;
		color: #333;
		margin-right: 10px;
		margin-bottom: 10px;
		box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);

		&.marked {
			background: #4d8ceb;
			color: #fff;

			.button {
				background: #2c61b0;
				color: #fff;
			}
		}

		@media #{$mobile_portrait_landscape} {
			margin-bottom: 4px;
		}

		.button {
			margin-left: 12px;
			margin-right: 0;
			background: #ffc670;
			color: #000;
		}
	}

	.action {
		display: flex;
		align-items: center;
		font-size: 12px;
		white-space: nowrap;
		cursor: pointer;
		@include disable-selection();

		.button {
			margin-left: 12px;
		}
	}

	.archived {
		display: flex;
		align-items: center;
		font-size: 12px;
		white-space: nowrap;
	}

	.filter,
	.action {
		&:hover {
			.button {
				transform: scale3d(1.2, 1.2, 1.2);
				box-shadow: 4px 4px 8px #00000054;
			}
		}
	}

	.button {
		flex: none;
		background: #1b6783;
		border-radius: 50%;
		width: 30px;
		height: 30px;
		color: #fff;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		cursor: pointer;
		margin-right: 10px;
		transition: transform 300ms ease, box-shadow 300ms ease;

		&:hover {
			transform: scale3d(1.2, 1.2, 1.2);
		}
	}

	.unselect-icon {
		flex: none;
		width: 50px;
		height: 50px;
		background: #3a68ff;
	}
}

.executionflow-list {
	.area-title {
		&.opened {
			svg {
				transform: rotate3d(0, 0, 1, 45deg) scale3d(1.2, 1.2, 1.2);
				background-color: #5a940e;
			}
		}

		svg {
			// transition: transform 200ms ease, background-color 200ms ease;
			transition: background-color 200ms ease;
		}
	}

	.simple-list-item {
		position: relative;
		padding: 10px 10px 10px 20px;
		border: 1px solid #ddd;
		margin: 2px 15px 0px -2px;
		// border-radius: 5px;
		// border-right: 10px solid transparent;
		// box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
		// box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
		box-shadow: 10px 10px 30px #6bb7da8c, 0 0 0 #6bb7da8c;
		background: #fff;
		// box-shadow: none;
		z-index: 1;
		align-items: stretch;
		border: 1px solid #eee;
		border: 0 solid #eee;
		// border-left: 10px solid #dfe4f4;
		border-left: 5px solid #ffb759;
		border-radius: 4px;
		transition: transform 200ms ease-out, box-shadow 200ms ease-out;

		&.urgent {
			border-left-color: crimson !important;
		}

		&:hover {
			z-index: 100;
			box-shadow: 20px 20px 30px #6bb7da8c, 0 0 10px #6bb7da44;

			// transform: translate3d(-3px, -3px, -3px) scale3d(0.95, 0.95, 0.95);
			// transform: translate3d(-3px, -3px, -3px);
			.left-icon {
				animation: spin 1s infinite;
				top: 30px;
			}

			&.canceled,
			&.completed {
				.left-icon {
					animation: none;
				}
			}
		}

		&.selected {
			background: #fff;
			border-left-color: #ffea00;

			.left-icon {
				// border-color: #ffea00;
				svg {
					// color: #ffea00;
				}
			}

			.right-icon {
				background-color: #ffea00;
			}
		}

		&.completed {
			border-left-color: #27a90d;

			.left-icon {
				border: 5px solid #27a90d;

				svg {
					color: #27a90d;
				}
			}
		}

		&.canceled {
			border-left-color: #d54b00;

			.left-icon {
				border: 5px solid #d54b00;

				svg {
					color: #d54b00;
				}
			}
		}

		&.archived {
			$color: #a46d67;
			border-left-color: $color;

			.left-icon {
				border: 5px solid $color;

				svg {
					color: $color;
				}
			}
		}

		&.item-selected {
			$selected: #3a68ff;
			background: #fff;
			border-left-color: $selected;
			box-shadow: 0 0 5px 4px $selected;
			z-index: 10000000;

			.left-icon {
				border-color: $selected;

				svg {
					color: $selected;
				}
			}

			.right-icon {
				background-color: $selected;
				color: #fff;
			}
		}

		&.urgent {
			border-left-color: crimson;

			.left-icon {
				border: 5px solid crimson;

				svg {
					color: crimson;
				}
			}
		}

		.left-icon {
			position: absolute;
			top: 50%;
			margin-top: -20px;
			left: -23px;
			background: #fff;
			height: 20px;
			width: 20px !important;
			padding: 5px;
			border-radius: 20px;
			border: 5px solid #ffb759;
			transition: top 200ms ease;

			svg {
				color: #ffb759;
			}
		}

		.right-icon {
			width: 50px;
			height: 50px;
			min-width: 50px;
			min-height: 50px;
			border-radius: 25px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			background: #eee;
			align-self: center;

			&:hover {
				background: #dde;
			}
		}
	}
}

#executions-list {
	margin-top: 10px;

	#form-panel {
		display: none;

		#custom-form-panel {
			.info-field {
				.info-field-header {
					display: flex !important;
					justify-content: space-between;
					align-items: center;

					button {
						background-color: rgb(243, 65, 65) !important;
						color: white !important;
						svg {
							margin-right: 5px;
						}
					}
				}
			}
		}

		&.visible {
			display: block;
		}
	}

	.search-input {
		width: 100%;
		.content {
			label {
				color: #14c3cf;
			}

			input {
				box-shadow: none;
				font-size: 18px;
				background: #fff;
				border: 2px solid #14c3cf;
				color: #0ba3ad;
				border-radius: 5px;
			}
		}
	}
}

.executionflow {
	position: relative;

	.history-toggler {
		position: absolute;
		top: 20px;
		left: 15px;
		height: 30px;
		width: 30px;
		height: 50px;
		width: 50px;
		border-radius: 20px;
		border-radius: 25px;
		background: #fff;
		border: 1px solid #ddd;
		border: 1px solid transparent;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #bbb;
		cursor: pointer;
		z-index: 1000;
		transition: transform 200ms linear;
		transform: rotate3d(0, 0, 1, 180deg);
		left: -26px;

		background: #626ac9;

		&.visible {
			transform: translate3d(360px, 0, 0) rotate3d(0, 0, 1, 0deg);

			svg {
				margin-left: -5px;
			}
		}

		&:hover {
			color: #100e9f;
		}

		svg {
			font-size: 18px;
			margin-left: -15px;
			color: #fff;
		}

		@media #{$mobile_portrait_landscape} {
			position: fixed;
			top: 100px;
			left: -26px;
			background: #2988b1;
			height: 50px;
			width: 50px;
			border-radius: 25px;

			svg {
				color: #fff;
				margin-left: -15px;
			}

			&.visible {
				transform: translate3d(92vw, 0, 0) rotate3d(0, 0, 1, 0deg);

				svg {
					margin-left: -6px;
				}
			}
		}
	}

	#micro-panel {
		border-right: 0 solid transparet;

		overflow-y: scroll;
		max-width: 0;
		position: fixed;
		transform: translate3d(-500px, 0, 0);
		top: 40px;
		bottom: 0;
		background: #fff;
		z-index: 100;
		max-height: none !important;
		box-shadow: 0 0 0 $shadow-a;

		@media #{$mobile_portrait_landscape} {
			position: fixed;
			transform: translate3d(-500px, 0, 0);
			top: 0;
			bottom: 0;
			background: #fff;
			z-index: 100;
			max-height: none !important;

			&.visible {
				transform: translate3d(0, 0, 0);
				max-width: calc(100% - 0px) !important;
				min-width: calc(100% - 0px) !important;
			}

			.info-field {
				opacity: 0;
				transition: opacity 400ms ease;
				min-width: 187px;
				max-width: calc(100% - 50px) !important;
			}
		}

		&.visible {
			padding: 10px;
			max-width: 500px;
			transform: translate3d(0, 0, 0);
			box-shadow: 0 0 40px lighten($shadow-a, 10%);

			.info-field {
				opacity: 1;
			}
		}

		.info-field {
			opacity: 0;
			transition: opacity 400ms ease;
			min-width: 300px;
			max-width: 300px;

			.simple-list-item {
				margin-left: 0;
			}
		}
	}

	#list-panel {
		min-width: 50vw;
		position: relative;
		padding: 0 10px;

		&.history-visible {
			min-width: 50vw;
		}

		&.list-view {
			padding: 10px;
		}
	}

	#form-panel {
		margin-top: 10px;
		margin-left: 0;
		box-shadow: none;
		margin-bottom: 0;

		.type-approval {
			.form-panel-header {
				h4 {
					padding-left: 10px;
					border-left: 10px solid #fff069;
				}
			}
		}

		.type-documentInput {
			.form-panel-header {
				h4 {
					padding-left: 10px;
					border-left: 10px solid #00bcd4;
				}
			}
		}

		.type-formInput {
			.form-panel-header {
				h4 {
					padding-left: 10px;
					border-left: 10px solid #039be5;
				}
			}
		}

		.form-panel-header {
			margin-bottom: 0;
			padding-bottom: 0;
			display: flex !important;

			h4 {
				font-size: 20px;
				color: #00967b;
				font-weight: lighter;
				border-radius: 5px;
				border-top: 1px dashed #ddd;
				border-right: 1px dashed #ddd;
				border-bottom: 1px dashed #ddd;
				padding: 5px;
			}

			p {
				display: none;
			}

			.header-button {
				display: none;
			}
		}
	}
}

.area-title {
	padding: 5px 10px;
	border-top: 1px solid #ddf6ff;
	font-size: 14px;
	display: flex;
	align-items: center;
	color: #00bbff;
	color: #096f94;
	cursor: pointer;
	@include disable-selection();

	.area-name {
		flex: 1;
	}

	svg {
		width: 10px !important;
		height: 10px;
		padding: 2px;
		border-radius: 0px;
		margin-right: 15px;
		margin-left: 10px;
		background: #00afee;
		color: #fff;
	}

	.area-responsible,
	.area-phase {
		border-bottom: 0;
		border-right: 1px solid #ddd;
		min-height: 20px;
		text-align: center;
		min-height: 10px;
		font-size: 16px;
		align-self: flex-end;
	}
}

.area-executionflows {
	margin-left: 30px;
	opacity: 0;
	display: none;
	transition: opacity 400ms ease;

	&.opened {
		opacity: 1;
		display: block;
	}
}

.area-flow,
.area-responsible,
.execution-tasks,
.area-phase {
	max-width: 100px;
	min-width: 100px;
	padding: 5px;
	font-size: 12px;
	display: block;
	border-bottom: 0;
	background: #fff;
	border-left: 1px solid #ddd;
	color: #096f94;
	text-align: left;
	font-size: 12px;

	@media #{$mobile_portrait_landscape} {
		display: none;
	}
}

.area-flow {
	border-right: 0;
	flex: 1;
	max-width: none;
	margin-left: 30px;
}

.area-headers {
	display: flex;
	align-items: stretch;
	align-content: flex-start;
	margin-right: 86px;
}

.finish-date,
.creation-date {
	font-size: 12px !important;
	font-weight: 400 !important;
}

.executionflow-texts {
	// max-width: 170px;
	// margin-left: 10px;
	.line {
		&.bold {
			color: #12341f;
		}
	}
}

.workflow-description {
	font-size: 14px !important;
}

.document-title {
	font-size: 14px !important;
}

.info-field {
	display: flex;
	padding: 10px 20px;
	margin-bottom: 20px;

	&-search {
		display: flex;
		width: 100%;
		gap: 5px;

		button {
			height: 50px;
			width: 120px;
			min-width: 120px;
			background-color: #00bbff;
		}

		&-dates {
			display: flex;
			flex-direction: column;
			gap: 2px;
		}

		select {
			width: 286px;
			box-shadow: none;
			height: 50px;
			font-size: 18px;
			background: #fff;
			border: 2px solid #14c3cf;
			color: #0ba3ad;
			border-radius: 5px;
		}

		@media screen and (max-width: 768px) {
			flex-direction: column-reverse;
			button {
				width: 100%;
			}
			select {
				width: 100%;
			}
		}
	}

	&-time {
		display: flex;
		height: 50px;
		gap: 2px;
		width: 100%;

		.content {
			border: 2px solid #14c3cf;
			border-radius: 5px;

			input {
				height: 46px !important;
				box-shadow: none;
				font-size: 12px;
				background: #fff;
				color: #0ba3ad;
				border-radius: 5px;
				border: none !important;
			}

			label {
				left: 5px !important;
			}
		}
	}

	h2,
	h3 {
		display: flex;
		align-items: center;

		.simple-button {
			height: 30px;
			box-shadow: none;
			background: transparent;
			transition: background 400ms ease, color 300ms;
			border: 1px dashed #aaa;
			color: #bbb;
			font-weight: normal;
			padding: 0 8px;

			&:hover {
				background: #ffc400;
				color: #fff;
				border: 1px dashed transparent;
				transform: none;
			}
		}
	}

	.content-title {
		font-size: 20px;
		color: #00967b;
		display: flex;
		align-items: stretch;

		.main {
			margin-right: 20px;
		}

		svg {
			margin-right: 10px;
			width: 22px;
		}

		@media #{$mobile_portrait_landscape} {
			flex-wrap: wrap;
		}

		&.canceled {
			background: #c9000011;

			span {
				color: #820202;
			}

			align-self: flex-start;
			padding: 5px;
			border-left: 10px solid #c50707;
			margin-bottom: 10px;
		}

		.chip-status {
			font-size: 10px;
			padding: 0 2px;
			margin-bottom: 2px;
			border: 1px dashed #aaa;
			border-radius: 4px;
			margin-right: 4px;
			display: flex;
			align-items: center;
		}

		.chip-status-none {
			background: #fafafa;
			background: #fff;
		}

		.chip-status-completed {
			background: #06c606;
			color: #fff;
		}

		.chip-status-pending {
			background: #fff8df;
			color: #555500;
		}

		.chip-status-review {
			background: #ffd942;
			color: #220;
		}

		.chip-status-waiting-review {
			background: #5898ec;
			color: #fff;
		}
	}

	.content-subtitle {
		margin-left: 33px;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.info {
			font-size: 14px;
			position: relative;
			color: #1047ae;
			margin-bottom: 5px;
			padding-left: 10px;

			&::before {
				content: '';
				display: block;
				width: 5px;
				// height: 5px;
				background: #5170d4;
				position: absolute;
				left: 0px;
				top: 0px;
				bottom: 0px;
			}

			span {
				margin-right: 10px;
				color: #aaa;
				font-weight: bold;
			}
		}
	}
}

.complementary-task-warning {
	padding: 10px;

	margin-bottom: 30px;
	border: 3px dashed #fff;
	border-radius: 5px;
	font-size: 14px;
	display: inline-block;
	background: #60c0f0;
	color: #fff;
}

.certificate-required-message {
	font-size: 12px;
	margin-top: 20px;
	background: #fff;
	border: 1px dashed #fff;
	display: inline-block;
	padding: 5px;
	margin-right: 4px;
	border-radius: 4px;
	background: #0bbfd3;
	color: #000;
	color: #fff;
}

.select-certificate-message {
	font-size: 18px;
	color: #00967b;
	margin-top: 10px;
}

.certificate-controls {
	margin-top: 10px;

	.MuiFormControl-root {
		border-bottom: 0;

		.MuiFilledInput-underline::before {
			border: 0 !important;
		}

		.MuiFilledInput-root {
			background: #fff;
			border: 1px dashed #ddd;
		}
	}
}

.task-form {
	.form-title {
	}

	.form-info {
		padding: 5px;
		display: flex;
		align-items: center;
		align-content: center;

		svg {
			width: 18px !important;
			height: 18px !important;
			padding: 10px;
			background: #00967b;
			color: #fff;
			border-radius: 20px;
			margin-right: 10px;
		}

		color: #00967b;
	}

	.agent-name {
	}

	.instructions {
	}

	.expiration-date {
	}

	.absent-panel {
	}

	.send-file-content {
		// background: #000;
		padding: 10px;
		// box-shadow: inset 1px 1px 6px rgba(0, 0, 0, 0.2), 0 0 0 rgba(0, 0, 0, 0.1),
		// 	0 0 0 rgba(0, 0, 0, 0.1);
		border-radius: 3px;
		transition: transform 400ms ease, box-shadow 400ms ease;
		border-top: 1px solid #ddd;
		border-bottom: 1px solid #ddd;
		margin-top: 30px !important;
		margin-bottom: 15px !important;

		.send-file-control {
			background: #fafaff;
			background: transparent;
			border: 1px dashed #aaf;
			border: 1px dashed transparent;
			border-radius: 5px;
			margin-bottom: 0 !important;

			input {
				font-size: 12px;
				color: #3a5a6c;
				outline: none;
				margin-bottom: 0;

				&:hover,
				&:focus {
					outline: none;
				}
			}
		}

		&:hover {
			// box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.3), 8px 8px 10px rgba(0, 0, 0, 0.1),
			// 	0 0 3px rgba(0, 0, 0, 0.1);
			// transform: translate3d(-3px, -3px, -3px);
		}

		.simple-filled-button {
		}
	}
}

.table-sheet {
	border: 1px solid #aaa;
	border: 1px solid transparent;
	border-radius: 4px;
	box-shadow: 3px 3px 10px $shadow-a;
	width: 100%;
	margin-bottom: 10px;
	border-spacing: 0;
	border-collapse: collapse;

	// &.view {
	// 	.row .col:nth-child(3) {
	// 		border-right: 0;
	// 	}
	// }
	.row {
		&:last-child {
			.col {
				border-bottom: 0;
			}
		}

		&.headers {
			.col {
				font-weight: bold;
				padding: 3px;
				color: #184e6f;
				box-sizing: border-box;
			}
		}

		.col {
			border-right: 1px solid #abcbe8;
			border-bottom: 1px solid #abcbe8;
			word-break: break-all;
			vertical-align: top;
			height: 1px;
			min-width: 60px;

			&.actions {
				flex: none;
				// border-right: 1px solid ;
				max-width: 30px;
				min-width: 30px;
				padding: 3px;
				box-sizing: border-box;
				text-align: center;

				svg {
					background: #ffea07;
					color: #000;
					background: #484311;
					color: #ffea07;
					padding: 5px;
					border-radius: 50%;
					box-shadow: 3px 3px 10px $shadow-a;
					cursor: pointer;
				}
			}

			.content-editable {
				border: 0;
				flex: 1;
				padding: 3px;
				word-break: break-all;
				height: calc(100% - 0px);
			}
		}
	}
}

.tab {
	@media screen and (max-width: 768px) {
		.text {
			display: none;
		}
	}
}

.content-editable {
	border: 1px solid #ddd;
}

.form-input-content {
	background: #fff;
	border-radius: 5px;
	margin-top: 10px;
	color: #333;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 20px 0;
	}

	.MuiInputBase-root {
		background: #fff;
		box-shadow: 3px 3px 10px $shadow-a;
		margin-bottom: 10px;
		transition: transform 300ms ease-out, box-shadow 300ms ease-out;

		&:hover::before,
		&::before {
			border-bottom: 0;
		}

		&:hover {
			box-shadow: 5px 5px 10px $shadow-a;
		}

		.MuiSelect-root {
			background: #fff;
		}
	}

	.form-control-input {
		margin-bottom: 10px;

		.content {
			label {
				color: #777;
			}

			input {
				border: 1px solid #b9c7e6;
				border: 1px solid transparent !important;
				transition: transform 300ms ease-out, box-shadow ease-out;
				box-shadow: 3px 3px 10px $shadow-a;
				background: #fff;
				color: #0c2034;

				&:hover {
					box-shadow: 5px 5px 10px $shadow-a;
				}
			}
		}
	}
}

.complementary-task-panel {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: flex-end;
	justify-content: flex-start;
	position: absolute;
	margin-left: -57px;
	top: 90px;
	box-sizing: border-box;
	z-index: 100;

	@media #{$mobile_portrait_landscape} {
		&.task-a,
		&.task-b,
		&.task-c,
		&.task-d {
			margin-left: -40px;
		}

		.simple-button,
		simple-button {
			&.task-action {
				height: 30px !important;
				width: 30px;
				border-radius: 5px !important;
				background: #187e77 !important;
				color: #fff !important;
				border: 0 !important;
			}
		}
	}

	&.task-a {
		//
	}

	&.task-b {
		margin-top: 55px;
	}

	&.task-c {
		margin-top: 110px;
	}

	&.task-d {
		margin-top: 165px;
	}

	.simple-button,
	simple-button {
		&.task-action {
			background: #87dbe8;
			background: #fff;
			color: #fff;
			color: #0d1f26;
			border: 1px dashed #000;
			border: 1px solid #ddd;
			border-radius: 30px;
			height: 45px;

			&:hover {
				background: #236478;
				color: #fff;
			}
		}
	}
}

.MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
	margin-top: 2.5rem;
}

.form-field-label {
	font-size: 14px;
	color: #666;
	margin-top: 15px;
	margin-bottom: 10px;
	display: flex;
	gap: 10px;
	align-items: center;
}

.list-number-item {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;

	.bullet {
		display: flex;
		align-items: flex-start;
		align-content: center;
		margin-top: 8px;
		margin-right: 10px;

		svg {
			font-size: 5px;
		}
	}

	.number {
		margin-right: 10px;
	}

	.value {
		color: #0f394d;
	}
}
