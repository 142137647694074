@import 'components.scss';
@import 'animations';

body {
	margin: 0;
	font-family: Roboto;
}
#root {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	height: 100vh;
	width: 100vw;
}

#container {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	align-content: stretch;
	flex: 1;
	height: 100vh;
	width: 100vw;
}
main {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	flex: 1;
	width: 0;
}
#spinner-container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	transition: backdrop-filter 1000ms ease;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	animation: showLoadingContainer 2s 1 linear forwards;
	z-index: 10000000;
	svg {
		font-size: 100px;
		animation: loading 500ms infinite linear;
		color: #20851179;
	}
}

#bot {
	width: 80px !important;
	height: 80px !important;
	background-size: contain !important;
	bottom: 5px !important;
	left: 5px !important;
	z-index: 999999999 !important;
}
#dtbot-iframe {
	margin-left: 50px !important;
}

* {
	&::-webkit-scrollbar {
		position: absolute;
		background: transparent;
		width: 6px;
		height: 6px;
		border: 0;
		transition: background-color 300ms ease-out;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
		background-color: transparent;
		background-color: #dddddd00;
		background-color: inherit;
		width: 0;
		border: 0;
	}
	&::-webkit-scrollbar-thumb {
		// background-color: transparent;
		// outline: 0 solid slategrey;
		background-color: #dddddd00;
		// border-radius: 3px;
		outline: 0 solid slategrey;
	}
	&:hover {
		&::-webkit-scrollbar {
			position: absolute;
			background: transparent;
			width: 6px;
			height: 6px;
			border: 0;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
			background-color: transparent;
			background-color: #ddd;
			background-color: inherit;
			width: 0;
			border: 0;
		}
		&::-webkit-scrollbar-thumb {
			// background-color: transparent;
			// outline: 0 solid slategrey;
			background-color: #ddd;
			// border-radius: 3px;
			outline: 0 solid slategrey;
		}
	}
}
* {
	scrollbar-width: thin; /* Also needed to disable scrollbar Firefox */
	scrollbar-color: #2196f3 transparent;
	-ms-overflow-style: none; /* Disable scrollbar IE 10+ */
	// overflow-y: scroll;
}

.octadesk-octachat-app.octachatAppClose {
	left: 2px !important;
	bottom: 2px !important;
}

.MuiAutocomplete-popper {
	z-index: 10000000000000 !important;
}
