@import 'animations';
@import 'mediaQueries';
@import 'mixins';
$shadow: 0 0 10px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1);

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: lighter;
	margin-top: 0;
	margin-bottom: 15px;
}

#page-header {
	& > h3 {
		color: #0192a2;
		font-size: 24px;
		font-weight: lighter;
		svg {
			font-size: 24px;
			margin-right: 10px;
		}
	}
}
.entity-management {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: stretch;
	align-items: stretch;
	flex: 1;

	@media #{$mobile_portrait_landscape} {
		flex-direction: column;
	}

	#list-panel {
		// flex-basis: 70%;
		flex: 1;
		overflow-y: scroll;
		@media #{$mobile_portrait_landscape} {
			max-height: 1000vh !important;
			max-width: 100vw;
			flex: none;
		}
	}
	#form-panel {
		flex: 1;
		box-sizing: border-box;
		padding: 15px;
		border-left: 1px solid #ddd;
		box-sizing: border-box;
		transition: transform 400ms ease;
		overflow-y: scroll;
		@media #{$mobile_portrait_landscape} {
			transform: translate3d(0, 120vh, 0);
			pointer-events: none;
			position: fixed;
			&.visible {
				position: fixed;
				top: 40px;
				left: 0;
				right: 0;
				bottom: 0;
				transform: translate3d(0, 0, 0);
				max-height: 1000vh !important;
				z-index: 10000;
				background: #fff;
				overflow-y: scroll;
				pointer-events: all;
				// border-top: 1px solid #ddd;
				header.form-panel-header {
					box-shadow: none;
				}
				.form-panel-header {
					display: flex !important;
					flex-direction: row;
					align-items: center;
					h1,
					h2,
					h3,
					h4,
					h5 {
						flex: 1;
					}
					.header-button {
						height: 50px;
						width: 50px;
						background: #ffeb3b;
						color: #222;
						border-radius: 30px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin-left: 15px;
						font-size: 25px;
						min-width: 50px;
						transition: transform 500ms ease, box-shadow 500ms ease;
						&.save,
						&[save] {
							background: #00897b;
							color: #fff;
						}
					}
				}
			}
		}
		.form-panel-header {
			display: none;
		}
		.fab-button {
			position: fixed;
			top: auto;
			left: auto;
			right: 20px;
			bottom: 20px;
			height: 60px;
			width: 60px;
			background: #039be5;
			color: #fff;
			border-radius: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-left: 15px;
			transition: transform 500ms ease, box-shadow 500ms ease;

			&:hover {
				box-shadow: $shadow;
			}
		}
	}
}
.form-control-input {
	display: block;
	margin-bottom: 15px;
	position: relative;
	pointer-events: none;
	&.label-from-left {
		border: 1px dashed transparent;
		margin-bottom: 0;
		cursor: pointer;
		border-radius: 30px;
		background: transparent;
		border-bottom: 1px dashed transparent;

		@media #{$mobile_portrait_landscape} {
			.content {
				label {
					top: -10px;
					position: absolute !important;
				}
				input {
					margin-top: 5px;
					margin-bottom: 0;
					height: 30px;
					margin-top: 15px;
				}
			}
		}
		&:hover {
			cursor: pointer;
			border: 1px dashed #ddd;
		}
		&.multiline {
			padding-bottom: 25px;
			.content {
				label {
					position: absolute;
					top: -10px;
				}
			}
			.message {
				top: 90px;
			}
		}
		.content {
			display: flex;
			align-content: flex-start;
			flex-direction: row-reverse;
			textarea,
			input {
				background: transparent;
				border-radius: 0;
				color: #333;
				border: 0 solid #9fa0be;
				border-bottom: 0 !important;
				font-size: 14px;
				color: #039be5;
				cursor: pointer;
			}
			textarea {
				padding: 10px 10px 10px 10px !important;
				margin-left: 55px;
				margin-top: 28px;
				// min-height: 60px;
				// height: 60px;
				line-height: 20px;
				background: #fafafa;
				margin-right: 10px;
				border-radius: 10px;
				min-height: 60px;
				max-height: 60px;
			}

			label {
				font-size: 14px;
				color: #777;
				opacity: 1;
				position: relative;
				word-wrap: none;
				white-space: nowrap;
				margin-right: 10px;
				pointer-events: all;
				&::after {
					content: ':';
				}
			}
			.icon {
				svg {
					color: #665cf5;
					border: 1px solid #665cf5;
					padding: 10px;
					border-radius: 25px;
					font-size: 20px;
					height: 20px;
					width: 20px;
				}
			}
		}
		.message {
			background: transparent;
			position: absolute;
			top: 30px;
			left: 55px;
			color: #555;
		}
	}
	&.outlined {
		.content {
			label {
				left: 10px;
			}
			textarea,
			input {
				border: 1px solid #ddd;
				border-radius: 4px;
				line-height: 20px;
				padding-left: 10px;
			}
		}
	}
	&.filled {
		label {
			left: 10px !important;
			color: #888;
		}
		textarea,
		input {
			border: 1px solid #eee !important;
			background: #fafafa;
			line-height: 20px;
			border-radius: 4px;
			padding-left: 10px !important;
			box-shadow: inset 2px 2px 5px rgba(0, 0, 0, 0.05);
			// border-bottom: 0 !important;
		}
		border-bottom: 0;
	}
	&.has-left-icon {
		.content {
			label {
				left: 60px;
			}

			textarea,
			input {
				line-height: 20px;
				padding-left: 60px;
			}
		}
	}
	&.has-right-icon {
		textarea,
		input {
			padding-right: 60px;
			line-height: 20px;
		}
	}

	.icon {
		position: absolute;
		top: 0;
		left: -1px;
		height: 50px;
		width: 50px;
		// background: #ddd;
		color: #333;
		border-radius: 25px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		z-index: 100;
		cursor: pointer;
		pointer-events: all;
		&.right-icon {
			left: auto;
			right: -1px;
			&.icon-1 {
				right: 40px;
			}
		}
		svg {
			font-size: 24px;
			padding: 0;
			margin: 0;
			width: 24px;
			height: 24px;
		}
	}

	label {
		height: 50px;
		line-height: 50px;
		position: absolute;
		transition: opacity 500ms ease, transform 400ms ease, font-size 400ms ease;
		left: 20px;
		z-index: 1;
		font-size: 10px;
		opacity: 0;
	}
	textarea,
	input {
		position: relative;
		height: 50px;
		line-height: 50px;
		box-sizing: border-box;
		padding: 0 20px;
		width: 100%;
		z-index: 0;
		pointer-events: auto;
		outline: none;
		border: 0;
		background: transparent;
		border-bottom: 1px solid #ddd;
		border-radius: 0;
		font-size: 20px;
		&:placeholder-shown ~ label {
			font-size: 16px;
			opacity: 1;
		}
		&:focus ~ label {
			transform: translate3d(0, 0, 0);
			font-size: 10px;
			opacity: 0;
			font-weight: 700;
		}
	}
	textarea {
		box-sizing: border-box;
		padding: 25px 10px 5px 10px;
		line-height: 20px;
		min-height: 100px;
		max-height: 500px;
		pointer-events: all;
		height: auto;
	}
	.message {
		padding: 5px;
		font-size: 12px;
		background: #eee;
		margin-top: -5px;
		padding-top: 10px;
		border-radius: 3px;
	}
	&.top-label {
		&.outlined {
			.content {
				textarea,
				input {
					padding-left: 10px;
				}
				&.always-shown {
					& textarea:focus ~ label,
					& textarea:placeholder-shown:focus ~ label,
					& input:focus ~ label,
					& input:placeholder-shown:focus ~ label,
					label {
						transform: translate3d(10px, -14px, 0);
					}
					& textarea:placeholder-shown ~ label,
					& input:placeholder-shown ~ label {
						transform: translate3d(10px, -14px, 0);
					}
				}

				& textarea:focus ~ label,
				& textarea:placeholder-shown:focus ~ label,
				& input:focus ~ label,
				& input:placeholder-shown:focus ~ label,
				label {
					transform: translate3d(10px, -14px, 0);
				}
				& textarea:placeholder-shown ~ label,
				& input:placeholder-shown ~ label {
					transform: translate3d(10px, 0, 0);
				}
			}
		}
		.content {
			textarea,
			input {
				// background: #ddd;
				font-size: 14px;
				padding-top: 15px;
				line-height: 14px;
				padding-left: 0px;
				border-bottom: 1px solid #333;
			}
			textarea {
				padding-top: 25px;
			}
			&.always-shown {
				& textarea:focus ~ label,
				& textarea:placeholder-shown:focus ~ label,
				& input:focus ~ label,
				& input:placeholder-shown:focus ~ label,
				label {
					transform: translate3d(0, -14px, 0);
					font-size: 12px;
					opacity: 1;
					left: 0px;
					font-weight: normal;
				}
				& textarea:placeholder-shown ~ label,
				& input:placeholder-shown ~ label {
					transform: translate3d(0, -14px, 0);
				}
			}

			& textarea:focus ~ label,
			& textarea:placeholder-shown:focus ~ label,
			& input:focus ~ label,
			& input:placeholder-shown:focus ~ label,
			label {
				transform: translate3d(0, -14px, 0);
				font-size: 12px;
				opacity: 1;
				left: 0px;
				font-weight: normal;
			}
			& textarea:placeholder-shown ~ label,
			& input:placeholder-shown ~ label {
				transform: translate3d(0, 0, 0);
			}
		}
	}
}
content {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	align-content: stretch;
	flex: 1;
	width: 100%;
	overflow-y: auto;
	height: calc(100% - 0px);
	&.relative {
		position: relative;
	}
	& > header {
		padding: 3vh;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		@media #{$mobile_portrait_landscape} {
			flex-direction: column;
			align-items: stretch;
			z-index: 10;
			box-shadow: 0 5px 5px rgba(0, 0, 0, 0.05);
			h3 {
				font-size: 25px !important;
			}
		}
		.header-buttons {
			display: flex;
			min-width: 300px;
			justify-content: flex-end;
			@media #{$mobile_portrait_landscape} {
				padding: 0 15px;
				box-sizing: border-box;
				margin-top: 15px;
			}

			.header-button {
				cursor: pointer;
				height: 50px;
				width: 50px;
				background: #504c20;
				background: #ffeb3b;
				color: #222;
				border-radius: 30px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-left: 15px;
				transition: transform 500ms ease, box-shadow 500ms ease;
				&:hover {
					box-shadow: 10px 10px 10px #0192a244;
					transform: scale3d(1.2, 1.2, 1.2);
				}
				&.save,
				&[save] {
					background: #00897b;
					color: #fff;
				}
				&.disabled,
				&[disabled] {
					background: #c9c9c9;
					color: #fff;
					pointer-events: none;
				}
				&.delete,
				&[delete] {
					background: #d26c70;
					color: #fff;
				}
				&.with-text {
					min-width: 50px;
					width: auto;
					padding: 0 15px;
					svg {
						margin-right: 5px;
					}
				}
				@media #{$mobile_portrait_landscape} {
					height: 50px;
					width: 50px;
					border-radius: 25px;
					font-size: 15px;
					// background: #fff;
				}

				svg {
					font-size: 24px;
				}
			}
		}
	}
}

.profile-button {
	background-color: rgba(0, 0, 0, 0.1);
	background-color: #039be5;
	padding: 10px;
	border-radius: 5px;
	cursor: pointer;
	box-shadow: 0 0 0 transparent;
	transition: background-color 400ms ease, box-shadow 400ms ease, transform 400ms ease;
	margin-left: 5px;
	&:hover {
		// background-color: rgba(0, 0, 0, 0.3);
		box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
		transform: translate3d(-2px, -2px, -2px);
	}
	span {
		color: #fff;
		margin-right: 15px;
		top: -1px;
		display: inline-block;
		position: relative;
	}
	span,
	div,
	p,
	svg {
		color: #fff !important;
	}
}
.zero-floating-message,
.floating-message {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	pointer-events: none;
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	z-index: 10000000;
	transition: opacity 500ms ease;
	opacity: 0;
	border-radius: 4px;
	&.visible {
		opacity: 1;
		pointer-events: all;

		.content {
			transform: translate3d(0, 0, 0);
		}
	}

	& > .content {
		background: #fff;
		padding: 0;
		border-radius: 10px;
		// box-shadow: $shadow;
		// box-shadow: 10px 10px 50px #3c4785aa;
		transform: translate3d(0, 2000px, 0);
		transition: transform 500ms ease;
		min-width: 300px;
		max-width: 50vw;
		max-height: 85vh;
		box-sizing: border-box;
		padding-bottom: 60px;

		@media #{$mobile_portrait_landscape} {
			width: 98vw;
			height: 98vh;
			max-width: 100vw;
			box-sizing: border-box;
		}
		.header {
			padding: 20px;
			height: auto;
			margin-bottom: 10px;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			align-content: center;
			background: #89d2f0;
			background: #fff;
			border-bottom: thin dashed #ddd;
			box-shadow: none;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
			.close {
				cursor: pointer;
				svg {
					font-size: 30px;
				}
				@media #{$mobile_portrait_landscape} {
					display: block;
					height: 40px;
					width: 40px;
					min-width: 40px;
					border-radius: 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 10px;
				}
			}
			.title {
				font-size: 16px;
				font-weight: normal;
				text-transform: none;
				font-family: Roboto;
				flex: 1;
				margin-right: 15px;
				margin-bottom: 0;
				color: #fff;
				color: #1a1c37;
			}
		}
		.description {
			padding: 20px;
			font-size: 16px;
			font-weight: lighter;
			margin-bottom: 15px;
			max-height: 60vh;
			overflow: auto;
			.buttons {
				position: absolute;
				top: auto;
				bottom: 10px;
			}
		}
		.buttons {
			display: flex;
			align-items: center;
			z-index: 1000;
		}
	}
}
.hidden-on-mobile {
	@media #{$mobile_portrait_landscape} {
		display: none !important;
	}
}

// No selectable element
.no-selectable {
	@include disable-selection;
}
.info-field {
	padding: 15px;
	display: flex;
	flex-direction: column;
	label {
		font-size: 14px;
		color: #666;
		margin-bottom: 5px;
	}
}

.columns {
	display: flex;
	flex-direction: row;
	align-items: stretch;
	align-content: center;
	.column {
		flex: 1;
	}

	@media screen and (max-width: 650px) {
		flex-direction: column;
	}
}

.simple-filled-button {
	background-color: #eee !important;
	&:hover {
		background-color: #ddd !important;
	}
}
.simple-red-button {
	background-color: #ee5500 !important;
	color: #fff !important;
	&:hover {
		background-color: #dd5500 !important;
		color: #fff !important;
	}
}
.simple-green-button {
	background-color: #00ee55 !important;
	color: #fff !important;
	&:hover {
		background-color: #00dd55 !important;
		color: #fff !important;
	}
}

.MuiTooltip-popper {
	z-index: 1000000000000 !important;
}

simple-list-item,
.simple-list-item {
	padding: 10px;
	border-bottom: 1px solid #ddd;
	color: #555;
	&.selected {
		background: #ffeb3b;
	}
	&.clickable {
		cursor: pointer;
	}
	&.with-columns {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 16px !important;
		margin-top: 1rem;

		@media screen and (max-width: 514px) {
			flex-direction: column;
		}
	}
	.icon {
		&.green {
			color: #4d0;
		}
		&.red {
			color: rgb(245, 70, 126);
		}
		svg {
			font-size: 20px;
		}
	}
	.right-icon {
		margin-left: 10px;
	}
	.left-icon {
		margin-right: 10px;
	}
	.line {
		font-size: 14px;
		padding: 2px 0;
		&.bold {
			font-weight: bolder;
		}
		&.small {
			font-size: 12px;
		}
		&.title {
			font-size: 18px;
		}
		&.gray {
			color: #666;
		}
	}
}

.MuiPopover-root {
	z-index: 10000000000 !important;
}

.simple-button,
simple-button {
	display: flex;
	align-items: center;
	align-content: center;
	padding: 10px 15px;
	height: 40px;
	box-sizing: border-box;
	margin-top: 3px;
	border-radius: 4px;
	background: #1e6485;
	color: #fff;
	background: #ffeb3b;
	color: #333;
	text-transform: uppercase;
	font-size: 14px;
	font-weight: bold;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	cursor: pointer;
	margin-right: 10px;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
	transition: box-shadow 400ms ease, transform 400ms ease;
	transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
	position: relative;
	animation: showItem 400ms 1;
	@include disable-selection;
	@media #{$mobile_portrait_landscape} {
		font-size: 12px;
		height: auto;
	}
	&:hover {
		box-shadow: 8px 8px 8px rgba(0, 0, 0, 0.2);
		transform: translate3d(-3px, -3px, 0) scale3d(1, 1, 1);
	}
	&[save] {
		background: #009688;
		color: #fff;
	}
	&[clear] {
		background: #ff7043;
		color: #fff;
	}
	&[iconed] {
		width: 45px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		svg {
			margin-right: 0;
		}
	}
	&[tooltip] {
		&::after {
			top: auto;
			bottom: calc(100% + 5px);
		}
	}

	&.mouse-down {
		&::after {
			content: '';
			display: block;
			background: inherit;
			border-radius: inherit;
			position: absolute;
			// width: inherit;
			// height: inherit;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			animation: callAttention 500ms 1;
		}
	}
	svg {
		margin-right: 8px;
	}
}
#container {
	background-color: #626ac9;
	background-image: linear-gradient(-30deg, #5e5e5e, #626ac9);
	background-image: linear-gradient(-40deg, #5e5e5e, #626ac9 60%);
}
main {
	background: #fff;
	border-top-left-radius: 10px;
}
#app-bar {
	background: #fff;
	background: transparent;
	box-shadow: inset 0 -10px 20px rgba(0, 0, 0, 0.03);
	transition: transform 400ms ease, left 50ms ease;
	left: 66px;
	right: 0;
	width: auto;
	z-index: 100;
	&.drawer-opened {
		left: 250px;
	}
	@media #{$mobile_portrait_landscape} {
		left: 0;
	}
	.MuiPopover-paper {
		top: 20px !important;
	}
}
#app-bar-toolbar {
	background: #fff;
	border-top-left-radius: 10px;
	box-shadow: inset 0 -10px 20px rgba(0, 0, 0, 0.03);
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	h7,
	span,
	div {
		color: #333;
	}
	#drawer-toggler-button {
		display: none;
	}
	@media #{$mobile_portrait_landscape} {
		#drawer-toggler-button {
			display: block;
		}
	}
}
#app-drawer {
	transition: width 50ms ease;
	overflow: hidden;
	border-right: 0;
	z-index: 10000000;
	display: flex;
	align-items: center;
	justify-content: center;
	.MuiListItem-root {
		padding: 0 auto;
	}
	&.closed {
		width: 65px;
		.MuiPaper-root {
			width: 65px;
		}
	}
	.language-selection {
		display: none;
		margin: 10px;
		.MuiFormControl-root {
			width: 100%;
		}
	}
	&.opened {
		width: 250px;
		.MuiPaper-root {
			width: 250px;
		}
		.language-selection {
			display: block;
		}
	}
	@media #{$mobile_portrait_landscape} {
		&.closed {
			width: 0;
			.MuiPaper-root {
				width: 0;
			}
		}
	}
	color: #fff;
	.MuiPaper-root {
		// background-color: rgb(53, 10, 122);
		// background-image: linear-gradient(45deg, #350a7a, #5914c7);
		background: transparent;
		// background-color: #626ac9;
		// background-image: linear-gradient(-40deg, #5e5e5e, #626ac9 60%);
		transition: width 50ms ease;
		border-right: 0;
		color: inherit;
		width: 250px;
		@media #{$mobile_portrait_landscape} {
			// background-color: #626ac9;
			// background-image: linear-gradient(-30deg, #5e5e5e, #626ac9);
		}
		span,
		div,
		svg,
		p {
			color: inherit;
		}
	}
	@media #{$mobile_portrait_landscape} {
		width: 0;
		position: fixed;
		z-index: 10000;
		.drawer-content {
			width: 0;
		}
	}
}
.tips {
	display: flex;
	flex-direction: column;
	&.closed,
	&.opened {
		.title {
			color: #1b67d8;
			text-decoration: underline;
			cursor: pointer;
			width: 100px;
		}
	}
	&.closed {
		.tip {
			display: none;
		}
	}
	.tip {
		font-size: 12px;
		margin-bottom: 5px;
		span {
			padding: 2px;
			background: #ddf;
			color: #005;
			border-radius: 4px;
		}
	}
}

[tooltip] {
	&::after {
		content: attr(tooltip);
		position: fixed;
		margin-top: 10px;
		// position: absolute;
		// top: auto;
		// left: 20px;
		// bottom: 45px;
		border-radius: 5px;
		font-size: 12px;
		background: #fff;
		background: #0e2835;
		color: #fff;
		padding: 10px;
		box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.25);
		z-index: 100;
		pointer-events: none;
		white-space: pre-wrap;
		opacity: 0;
		transition: opacity 500ms ease;
	}
	&:hover {
		&::after {
			animation: showTooltip 500ms 1;
			opacity: 1;
		}
	}
}
.toolbar {
	display: flex;
	align-items: center;
	align-content: flex-start;
	font-size: 18px;
	font-family: Roboto;
	font-weight: 400;
	padding: 3px;
	background: #fff;
	min-height: 40px;
	&.wrap {
		flex-wrap: wrap;
	}
	&.green,
	&[green] {
		background: #609106;
		color: #fff;
	}
	&.yellow,
	&[yellow] {
		background: #ffe100;
		color: #000;
	}
	&.teal,
	&[teal] {
		background: #10859f;
		color: #fff;
	}

	&.down {
		box-shadow: inset 0 -3px 3px rgba(0, 0, 0, 0.05);
		padding: 10px;

		&.green,
		&[green] {
			background: #517908;
			color: #fff;
		}
		&.yellow,
		&[yellow] {
			background: #ffd324;
			color: #000;
		}
		&.teal,
		&[teal] {
			background: #0d6b80;
			color: #fff;
		}
	}
	.spacer {
		flex: 1;
	}
	.text {
		padding: 7px 10px;
	}
	.display.item {
		font-size: 14px;
		margin-right: 10px;
	}
	.form-control-input {
		margin-bottom: 5px;
		input,
		textarea {
			border: 1px solid #00000033 !important;
		}
	}
	.form-control-input,
	.MuiFormControl-root {
		margin-top: 0;
		margin-right: 5px;
		min-width: 150px;

		margin-bottom: 5px;
		flex: 1;
		label {
			font-size: 12px;
		}
		.MuiInputBase-input {
			padding-bottom: 12px;
		}
	}
	simple-button,
	.simple-button {
		background: inherit;
		color: inherit;
		box-shadow: none;
		&:hover {
		}
	}

	.left-padding {
		width: 15px;
	}
}
.workflow-startup {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 8px;

	.tasks {
		& > content {
			border-top: 1px solid #ddd;
		}
	}
	.form-panel-header {
		padding: 10px 10px;
		h4 {
			font-size: 20px;
			color: #1a406f;
		}
		p {
			font-size: 16px;
			color: #555;
		}
		.header-button {
			display: none;
		}
	}
}
// .kanban {
// 	height: calc(100% - 0px);
// 	.column {
// 		box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
// 		margin: 10px;
// 		border-radius: 5px;
// 		.header {
// 			padding: 10px;
// 		}
// 	}
// }
body.dragging {
	.kanban-column {
		&.hover {
			box-shadow: 0 0 10px #0c59df;
		}
	}
}
.kanban {
	display: flex;
	align-items: stretch;
	align-content: flex-start;
	height: calc(100% - 140px);
	height: calc(100% - 110px);
	box-sizing: border-box;
	padding: 0 10px;

	.kanban-column {
		display: flex;
		flex-direction: column;
		align-items: stretch;
		height: calc(100% - 10px);
		width: 250px;
		min-width: 250px;
		max-width: 250px;
		margin-right: 5px;
		background: #f4f4f4;
		border-radius: 4px;
		box-sizing: border-box;
		box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.3);

		&.closed {
			width: 50px;
			min-width: 50px;
			max-width: 50px;
			.kanban-header {
				width: 300px;
				transform-origin: 0% 0%;
				transform: rotate3d(0, 0, 1, -90deg) translate3d(-300px, 0, 0);
			}
			& > .header-info,
			& > .items {
				display: none;
			}
		}

		.kanban-header {
			padding: 10px;
			height: 50px;
			font-size: 14px;
			border-left: 5px solid #ddd;
			border-bottom: 1px solid #eee;
			border-radius: 4px 4px 0 0;
			box-sizing: border-box;
			.kanban-title {
				font-size: 14px;
				margin: 0;
				display: flex;
				align-items: center;
				align-content: flex-start;
				.text {
					flex: 1;
				}
				.icon {
					height: 30px;
					width: 30px;
					border-radius: 15px;
					display: flex;
					align-items: center;
					align-content: center;
					justify-content: center;
					background: #ddd;
				}
			}
		}
		& > .header-info {
			padding: 10px;
			box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.1);
			font-size: 12px;
			.info-title,
			.info-totalBusinessValue,
			.info-totalCostEstimated,
			.info-totalEffortEstimated {
				display: flex;
				align-items: center;
				div {
					margin-right: 5px;
					color: #183b60;
				}
			}
			.info-totalBusinessValue,
			.info-totalCostEstimated,
			.info-totalEffortEstimated {
				cursor: pointer;
				border-radius: 3px;
				&:hover,
				&:hover div {
					background: #0b73c2;
					color: #fff;
				}
			}
		}
		& > .items {
			height: calc(100% - 0px);
			overflow-y: scroll;
		}
	}
}

.task-card {
	$borderRadius: 10px;
	position: relative;
	box-sizing: border-box;
	background: #fff;
	box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
	border-radius: $borderRadius;
	margin: 10px;
	cursor: pointer;
	transition: transform 200ms ease;
	// border: 1px solid #ddd;

	@include disable-selection;
	&.due-date {
		box-shadow: 0 0 10px #f31818;
	}
	&:hover {
		transform: translate3d(-2px, -2px, 0);
		box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
		.navigation {
			opacity: 1;
			transform: scale3d(1, 1, 1);
		}
	}

	.progress-bar {
		padding: 4px 10px 0 10px;
	}
	.due-date-task,
	.navigation {
		opacity: 0;
		transform: scale3d(0, 0, 0);
		transition: color 300ms ease, background-color 300ms ease, transform 300ms ease-out, opacity 300ms ease-out;
		position: absolute;
		top: 5px;
		left: auto;
		right: 0;
		z-index: 10;
		height: 20px;
		width: 20px;
		padding: 5px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		margin: 3px -10px 0 0;
		border-radius: 20px;
		background: #0d71e4;
		color: #fff;
		&:hover {
			transform: scale3d(1.4, 1.4, 1.4);
			background: #5eff00;
			color: #333;
		}
	}
	.due-date-task {
		$bg: #ed2727;
		$color: #ffffff;
		transform: scale3d(1, 1, 1);
		top: 40px;
		opacity: 1;
		background: $bg;
		color: $color;
		&::after,
		&::before {
			content: '';
			text-align: right;
			background: #b81200;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border-radius: 20px;
			// animation: callAttention 500ms infinite;
			z-index: 0;
		}
		&::after {
			content: '';
			background: $bg;
		}
		svg {
			position: relative;
			margin-top: -3px;
			z-index: 1;
		}
		&:hover {
			transform: scale3d(1, 1, 1);
			background: $bg;
			color: $color;
		}
	}

	&.dragging {
		visibility: hidden;
	}
	&.draggable {
		transform: translate3d(0, 0, 0);
		position: fixed;
		top: 0;
		left: 0;
		transition: none !important;
		z-index: 1000000;
		pointer-events: none !important;
		box-shadow: 10px 10px 30px #000000aa;
	}

	&.type-approval {
		header {
			border-left: 10px solid #fff069;
			border-color: #ffeb3b;
		}
	}
	&.type-documentInput {
		header {
			border-left: 10px solid #00bcd4;
			border-color: #00bcd4;
		}
	}
	&.type-formInput {
		header {
			border-left: 10px solid #039be5;
			border-color: #039be5;
		}
	}

	header {
		padding: 10px;
		border-top-left-radius: $borderRadius;
		font-size: 14px;
		color: #183b60;
		cursor: move;
		position: relative;
		z-index: 10;
	}

	.container {
		position: relative;
		z-index: 10;
		padding: 14px;
		.form-control-input {
			margin: 0;
			input {
				font-size: 12px;
				padding: 2px;
				margin: 0;
				height: 22px;
			}
		}
		.task-info {
			font-size: 12px;
			.info-column {
				.info-title,
				.estimates-title {
					font-weight: bold;
					display: flex;
					align-items: center;
					margin-bottom: 8px;
					div:first-child {
						flex: 1;
					}
				}
				.task-priority,
				.task-businessvalue,
				.task-cost,
				.task-time {
					display: flex;
					align-items: center;
					div {
						margin-right: 5px;
						color: #183b60;
					}
				}
			}
		}

		.task-email {
			font-size: 12px;
			color: #1570d7;
			word-break: break-all;
		}
		.task-responsible {
			font-size: 12px;
			color: #0b2b50;
			word-break: break-all;
			display: flex;
			align-items: center;
			align-content: flex-start;
			margin-top: 5px;
			.avatar {
				height: 25px;
				width: 25px;
				border-radius: 15px;
				background: #aaa;
				margin-right: 8px;
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				color: #fff;
				font-size: 16px;
				font-weight: bold;
			}
		}
	}

	.contacts {
		position: relative;
		z-index: 10;
		.contact {
			margin: 2px 2px 0 0;
			padding: 5px;
			background: #eef;
			color: #359;
			font-size: 10px;
			word-break: break-all;
		}
	}
	.local-spinner {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: #ffffff77;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		pointer-events: none;
		opacity: 0;
		transition: opacity 100ms ease;
		z-index: 100;
		svg {
			font-size: 20px;
			color: #555;
			animation: loading 500ms infinite linear;
		}
	}
	&.saving {
		.local-spinner {
			opacity: 1;
			pointer-events: all;
		}
	}
}
.task-discussion,
.task-checklist {
	.task-discussion-header,
	.task-checklist-header {
		display: flex;
		align-items: center;
		font-size: 12px;
		margin-bottom: 8px;
		div:first-child {
			flex: 1;
			font-weight: bold;
		}
	}

	.task-discussion-input,
	.task-checklist-input {
		display: flex;
		align-items: center;
		width: calc(100% - 0px);
		margin-top: 10px;
		margin-bottom: 10px;

		.form-control-input {
			margin: 0;
			padding: 0;
			width: 200px;
			textarea,
			input {
				font-size: 12px;
				padding: 0 5px;
				border: 1px solid #ddd;
			}
			label {
				font-size: 12px;
				height: 20px;
				line-height: 20px;
				left: 5px;
			}
		}
	}
	.add-button {
		margin-left: 4px;
	}
	.task-discussion-actions,
	.task-checklist-actions {
		margin-bottom: 10px;
	}
	.discussion-action,
	.checklist-action {
		margin: 0 0;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		padding: 3px;
		&:hover {
			background: #eee;
		}
		.MuiFormControlLabel-root {
			margin-right: 0;
			margin-left: 0;
			width: auto !important;
			flex: 1;
			.MuiButtonBase-root {
				padding: 0;
				margin-right: 5px;
				font-size: 14px;
			}
			.MuiTypography-root {
				font-size: 12px;
			}
		}
		div:first-child {
			// margin-right: 10px;
		}
		.checklist-action-name {
			flex: 1;
		}
		.checklist-remove-action {
			// padding: 4px;
			&:hover {
				color: #f00;
			}
		}
	}

	.discussion {
		margin-bottom: 10px;
		position: relative;
		.user {
			display: flex;
			align-items: center;
			.avatar {
				height: 20px;
				width: 20px;
				min-height: 20px;
				min-width: 20px;
				border-radius: 15px;
				background: #2cb4e9;
				margin-right: 8px;
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				color: #fff;
				font-size: 14px;
				font-weight: bold;
			}
			.name {
				font-size: 12px;
			}
			.email {
				font-size: 12px;
			}
			.date {
				font-size: 10px;
			}
		}
		.content {
			padding: 10px 0;
			font-size: 12px;
			border-bottom: 1px solid #ddd;
			word-wrap: break-word;
			word-break: break-all;
		}
		.trash {
			position: absolute;
			top: 0;
			left: auto;
			right: 0px;
			color: #f50;
		}
	}
}
.task-form-main {
	.task-card {
		box-shadow: none;
		margin: 10px 0;
		cursor: pointer;
		transition: transform 200ms ease;
		@include disable-selection;
		&:hover {
			transform: translate3d(0, 0, 0) !important;
			box-shadow: none;
			.navigation {
				opacity: 1;
				transform: scale3d(1, 1, 1);
			}
		}
		.navigation {
			display: none;
		}
		.progress-bar {
			padding: 10px 10px 10px 0;
		}
		.container {
			padding: 15px 0;
		}
	}
}
