@import "../utils/colors";
@import "../utils/themeApp";

main[data-route-key="privacyPolicy"] {
  margin-top: 0;
  border-top-left-radius: 0;
}

.PrivacyPolicy {
  // @each $app in $apps {
  //   &.#{$app} {
  //     --primary-color: #{map-get(map-get($colors, $app), "primary-color")};
  //     --secondary-color: #{map-get(map-get($colors, $app), "secondary-color")};
  //     --title-color: #{map-get(map-get($colors, $app), "title-color")};
  //     --text-color: #{map-get(map-get($colors, $app), "text-color")};
  //     --button-color: #{map-get(map-get($colors, $app), "button-color")};
  //   }
  // }

  @extend .themeApp;

  background-color: var(--secondary-color);
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  padding: 30px;

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    max-width: 1280px;
    margin: 0 auto;
    gap: 40px;

    &-columnLeft {
      height: 100%;

      img {
        width: 300px;
        margin-left: -30px;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        text-decoration: none;
      }

      &-title {
        font-size: 22px;
        text-align: center;
        color: var(--title-color);
        font-weight: 700;
        margin-bottom: 40px;
      }

      &-table {
        width: 300px;
        border-collapse: separate;
        border-spacing: 0 5px;

        &-title {
          text-align: start;
          background-color: #dbdada;
          color: var(--title-color);
          padding: 10px;
          padding-left: 35px;
          margin-left: 30px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }

        td {
          background-color: white;
          color: var(--text-color);
          padding: 10px;
          padding-left: 35px;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }

    &-columnRight {
      height: 60vh;
      width: 65vw;
      color: var(--text-color);
      padding-top: 165px;
      overflow-y: auto;
      scroll-behavior: smooth;

      &-title {
        color: var(--title-color);
        font-weight: 700;
        text-align: end;
        padding-right: 10px;
        margin-bottom: 55px;
      }

      table {
        border-collapse: collapse;
        width: 100%;
      }

      thead th {
        background-color: var(--secondary-color);
        font-weight: bold;
        text-align: center;
      }

      th,
      td {
        padding: 30px 10px;
        text-align: center;
        border: 2px solid #5e587c;
      }
    }
  }
}