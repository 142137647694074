.Content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	max-width: 41rem;
}

.ControlCheckbox {
	margin-bottom: 20px;
	color: white;

	*:not(.Mui-checked) {
		svg {
			color: white !important;
		}
	}
}

.Input {
	width: 100%;
	padding: 0.5rem;
	border-radius: 5px;
	background-color: #fff;
}

.PortalList {
	width: 100%;
	max-width: 41rem;
	height: 176px;

	margin: 2rem 0;
	gap: 1rem;
}

.Bullet {
	display: inline-block;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #fff;
	opacity: 0.2;
	margin: 0 4px;
}

.PortalInfo {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 80px;

	div {
		height: 80px;
		width: 80px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: white;

		svg {
			height: 40px;
			width: 40px;
			color: #00acdd;
		}
	}

	img {
		height: 80px;
		width: 80px;
		border-radius: 8px;
		object-fit: cover;
	}

	button {
		margin-top: 1rem;
		padding: 0.5rem;
		background-color: #00679d;
		color: white;
		border-radius: 8px;
		font-size: small;
		width: 80px;
		overflow: hidden;
		white-space: nowrap;
		display: block;
		text-overflow: ellipsis;
	}
}

.PortalInfoPlus {
	cursor: pointer;
	display: flex !important;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: #00679d;
	border-radius: 15px;
	height: 80px !important;
	width: 80px !important;
	border-radius: 50%;
}

.IconPlus svg {
	width: 50px;
	height: 50px;
	color: #ffffff;
}
