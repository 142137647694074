@import 'animations';
@import 'mediaQueries';

.external-action {
	display: flex;
	flex-direction: row;
	// padding: 30px 0;
	background: rgb(90, 90, 90);
	// background: #c2fdff;
	justify-content: center;
	align-items: center;
	.auth-login {
		max-width: 600px;
		background: #fff;
		box-sizing: border-box;
		padding: 35px;
		border-radius: 5px;
		.message {
			font-size: 15px;
			margin-bottom: 15px;
		}
		.buttons {
			display: flex;
			justify-content: center;
		}
		simple-button {
			text-align: center;
			width: fit-content;
		}
	}

	.space-panel {
		flex: 1;
	}
	.main-panel {
		min-width: calc(80% - 0px);
		max-width: 700px;
		background: #fff;
		height: 95%;
	}
}
