.zero-tabs {
	display: flex;
	align-items: stretch;
	max-width: 100vw;
	overflow-x: auto;
	padding: 10px;
	.tab {
		padding: 10px 15px;
		background: $white;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		min-height: 50px;
		box-sizing: border-box;
		border-left: 10px solid $white;
		transition: border-color 300ms ease;
		margin-right: 5px;
		position: relative;
		z-index: 0;
		border-radius: 5px 5px 0 0;
		cursor: pointer;
		@include disable-selection;

		@media #{$mobile_portrait_landscape} {
			padding: 10px 10px;
			flex-direction: column;
			svg ~ div {
				margin-left: 0 !important;
				margin-top: 10px !important;
			}
			.text {
				word-break: keep-all;
				font-size: 10px !important;
				text-align: center;
			}
		}

		&:hover {
			border-color: $grayM;
		}
		&.active {
			box-shadow: 0 -4px 4px $shadow-a;
			border-color: $tab-active-background;
			z-index: 101;
			.text {
				color: $tab-active-background;
			}
		}
		svg {
			font-size: 20px;
			& ~ div {
				margin-left: 10px;
			}
			color: $grayE;
		}

		.text {
			font-size: 12px;
			font-weight: bold;
			text-transform: uppercase;
			color: $grayE;
		}
	}
}

.zero-tabs-content {
	margin-top: -10px;
	background: $white;
	box-shadow: 5px 5px 20px $shadow-a;
	padding: 15px;
	position: relative;
	z-index: 100;
	margin: -10px 10px 10px 10px;
	border-radius: 0 0 5px 5px;
}
