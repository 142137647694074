#profile {
	.icon-user {
		margin-right: 10px;
		padding: 10px;
		width: 50px;
		height: 50px;
		border-radius: 25px;
		background-color: #1f95c0;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		align-content: center;
		svg {
			font-size: 24px;
			color: #fff;
			margin-left: 4px;
		}
	}
	.title {
		margin-top: -4px;
		font-size: 24px;
	}
	.subtitle {
		font-size: 20px;
		margin-bottom: 10px;
	}
	.absenses {
		margin: 10px 0;
		padding: 25px;
		border-bottom: thin solid #eee;
		.help {
			margin-left: auto;
			width: fit-content;
			color: rgb(124, 124, 124);
			font-size: 20px;
		}
		.absense {
			display: flex;
			align-items: center;
			align-content: center;
			margin-bottom: 10px;
			width: 300px;
			padding-bottom: 5px;
			&:hover {
				// box-shadow: 0px 15px 40px -10px rgba(0, 0, 0, 0.87);
				border-bottom: 2px solid #333;
				.date {
					// background: #dddddd;
					// color: black;
					cursor: default;
				}
				.divider {
					// background: #1f95c0;
					// background: #28bef5;
					// height: 5px;
				}
				.remove-button {
					background: #d02b20;
				}
			}
			.date {
				padding: 3px;
				border-radius: 3px;
				// background: rgb(114, 172, 187);
				font-weight: bold;
				// color: #fff;
				transition: background 300ms ease;
			}
			.divider {
				height: 1px;
				width: 30px;
				background: #333;
				transition: background 300ms ease, height 100ms ease;
			}
			.remove-button {
				// padding: 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				align-content: center;
				padding-top: 6px;
				background: #f44336;
				color: #fff;
				width: 30px;
				height: 30px;
				border-radius: 15px;
				box-sizing: border-box;
				cursor: pointer;
				transition: background 300ms ease;
			}
		}
	}
	.profile-content {
		padding: 25px;
		.profile-info {
			padding: 0 0 10px 0;
			font-size: 18px;
		}
	}
}
