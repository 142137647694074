@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}

@include keyframes(callAttention) {
	0% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
	100% {
		opacity: 0;
		transform: scale3d(1.5, 1.5, 1.5);
	}
}
@include keyframes(spin) {
	0% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: rotate3d(0, 0, 1, 360deg);
	}
}
@include keyframes(jump) {
	0% {
		transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
	}
	40% {
		transform: translate3d(0, 30%, 0) scale3d(0.7, 1.5, 1);
	}
	100% {
		transform: translate3d(0, 100%, 0) scale3d(1.5, 0.7, 1);
	}
}
@include keyframes(slide-to-sides) {
	0% {
		transform: translate3d(0px, 0, 0) rotate3d(0, 0, 1, 0deg);
	}
	25% {
		transform: translate3d(50px, 0, 0) rotate3d(0, 0, 1, 540deg);
	}
	50% {
		transform: translate3d(0px, 0, 0) rotate3d(0, 0, 1, 0deg);
	}
	75% {
		transform: translate3d(-50px, 0, 0) rotate3d(0, 0, 1, 540deg);
	}
	100% {
		transform: translate3d(0px, 0, 0) rotate3d(0, 0, 1, 0deg);
	}
}

@include keyframes(showLoadingContainer) {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@include keyframes(hideLoadingContainer) {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@include keyframes(loading) {
	0% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
	100% {
		transform: rotate3d(0, 0, 1, 360deg);
	}
}
@include keyframes(bounceIn) {
	0%,
	20%,
	40%,
	60%,
	80%,
	100% {
		transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}
	20% {
		transform: scale3d(1.1, 1.1, 1.1);
	}
	40% {
		transform: scale3d(0.9, 0.9, 0.9);
	}
	60% {
		opacity: 1;
		transform: scale3d(1.03, 1.03, 1.03);
	}
	80% {
		transform: scale3d(0.97, 0.97, 0.97);
	}
	100% {
		opacity: 1;
		transform: scale3d(1, 1, 1);
	}
}
@include keyframes(swing) {
	20% {
		transform: rotate3d(0, 0, 1, 15deg);
	}
	40% {
		transform: rotate3d(0, 0, 1, -10deg);
	}
	60% {
		transform: rotate3d(0, 0, 1, 5deg);
	}
	80% {
		transform: rotate3d(0, 0, 1, -5deg);
	}
	100% {
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@include keyframes(tada) {
	0% {
		transform: scale3d(1, 1, 1);
	}
	10%,
	20% {
		transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
	}
	30%,
	50%,
	70%,
	90% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
	}
	40%,
	60%,
	80% {
		transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
	}
	100% {
		transform: scale3d(1, 1, 1);
	}
}
@include keyframes(rubberBand) {
	0% {
		transform: scale3d(1, 1, 1);
	}
	30% {
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		transform: scale3d(1, 1, 1);
	}
}

@include keyframes(showTooltip) {
	0% {
		opacity: 0;
		transform: translate3d(0, 50px, 0);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}