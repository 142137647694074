@import 'animations';
@import 'mediaQueries';
@import 'mixins';
.area-list {
	padding-top: 20px;
	.area-list-item {
		position: relative;
		padding: 5px 5px 5px 22px;
		margin: 2px 25px 0px 15px;
		z-index: 1;
		display: flex;
		align-items: center;
		// border: 1px dashed transparent;
		border-radius: 5px 35px 35px 5px;
		border-radius: 5px 5px;
		transition: border-color 800ms ease, box-shadow 300ms ease, transform 300ms ease-out;
		color: #1228a3;
		border-left: 10px solid #ffeb3b;
		border-left: 10px solid #00dade;
		border-right: 10px solid #00dade;
		box-shadow: 10px 10px 30px #00dade33;
		background: #fff;
		cursor: pointer;
		&:hover {
			// border: 1px dashed #06a3ae;
			// border-left: 10px solid #31de22;
			// border-right: 10px solid #31de22;
			border-left: 10px solid #06a3ae;
			border-right: 10px solid #06a3ae;

			z-index: 100;
			box-shadow: 10px 10px 30px #00dade55;
			transform: translate3d(8px, -3px, -3px) scale3d(1.02, 1.02, 1.02);
			.left-icon {
				animation: spin 1s infinite;
				border-color: #31de22;
				border-color: #06a3ae;
				svg {
					color: #31de22;
					color: #06a3ae;
				}
				// top: 30px;
			}
			.right-icon {
				background: #31de22;
				background: #06a3ae;
				color: #fff;
				&:hover {
					background: #05868f;
				}
			}
			&.canceled,
			&.completed {
				.left-icon {
					animation: none;
				}
			}
		}
		&.selected {
			background: #fff;
			border-color: #003cff;
			border-left: 10px solid #003cff;

			transform: translate3d(0, 0, 0);

			.left-icon {
				border-color: #ffea00;
				border-color: #003cff;
				svg {
					color: #003cff;
				}
			}
			.right-icon {
				background-color: #ffea00;
				background-color: #003cff;
				animation-name: bounceIn;
				animation-name: swing;
				animation-name: tada;
				animation-name: rubberBand;
				animation-duration: 1000ms;
				animation-iteration-count: 1;
				animation-play-state: running;
				animation-fill-mode: backwards;
				// @include call-attention();
				// z-index: 1;
				// &::after {
				// 	background: #0a7ec6;
				// }
				svg {
					color: #fff;
				}
			}
		}
		&.completed {
			border-left-color: #27a90d;
			.left-icon {
				border: 5px solid #27a90d;
				svg {
					color: #27a90d;
				}
			}
		}
		&.canceled {
			border-left-color: #d54b00;
		}
		.area-name {
			font-size: 18px;
		}
		.area-description {
			font-size: 12px;
			color: #666;
		}
		.area-actions {
			min-width: 120px;
			max-width: 120px;
			margin-right: 10px;
			.add-workflow {
				padding: 10px 10px;
				min-width: 100px;
				transition: background 50ms ease;
				border-radius: 10px;
				svg {
					margin-right: 10px;
				}
				&:hover {
					background: #06a3ae;
					color: #fff;
				}
				&.disabled,
				&[disabled] {
					background: #c9c9c9;
					color: #fff;
					pointer-events: none;
				}
			}
		}

		// .left-icon {
		// 	position: absolute;
		// 	top: 50%;
		// 	margin-top: -20px;
		// 	left: -25px;
		// 	left: -25px;
		// 	background: #fff;
		// 	height: 20px;
		// 	width: 20px !important;
		// 	padding: 5px;
		// 	border-radius: 20px;
		// 	border: 5px solid #ddd;
		// 	border: 5px solid #00dade;
		// 	transition: top 400ms ease, border-color 400ms ease;
		// 	svg {
		// 		transition: color 400ms ease;
		// 		color: #ddd;
		// 		color: #00dade;
		// 		font-size: 20px;
		// 	}
		// }
		.left-icon {
			position: relative;
			left: -10px;
			margin-left: -5px;
			background: #fff;
			height: 20px;
			width: 20px !important;
			padding: 5px;
			border-radius: 20px;
			border: 5px solid #ddd;
			// border: 3px solid #00dade;
			border: 0 solid #00dade;
			// background: #00dade;
			transition: top 400ms ease, border-color 400ms ease;
			svg {
				transition: color 400ms ease;
				color: #ddd;
				color: #fff;
				color: #00dade;
				font-size: 20px;
			}
		}
		.right-icon {
			width: 30px;
			height: 30px;
			border-radius: 25px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			background: #eee;
			align-self: center;
			transition: background-color 400ms ease;
			&:hover {
				background: #dde;
			}
		}
	}
}
.area {
	#list-panel {
		position: relative;
		z-index: 2;
		overflow-x: visible;
		&.list-view {
			padding: 10px;
		}
	}
	#form-panel {
		position: relative;
		z-index: 1;
		background: transparent;
		border: 1px solid transparent;
		// border-left: 0 !important;
		// border-radius: 10px;
		margin-right: 0;
		padding: 20px 20px 20px 20px !important;
		// background: #f0f0ff;
		transition: box-shadow 400ms ease;
		// box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.05);
		// border-left: 1px solid #8fadda;
		&.editing-area {
		}
		.form-control-input {
			margin-bottom: 10px;
		}
	}
}
.workflow-list {
	margin-top: 20px;
}
.workflow-action-card,
.workflow-list-item {
	position: relative;
	padding: 10px;
	display: flex;
	align-items: center;
	border-radius: 5px;
	margin-bottom: 4px;
	width: calc(100% - 0px);
	flex: 1;
	border-left: 8px solid #ffc052;
	box-shadow: 10px 10px 30px #00dade53;
	background: #fff;
	transition: transform 400ms ease-out, box-shadow 400ms ease-out;
	z-index: 1;
	box-sizing: border-box;

	&:hover {
		box-shadow: 20px 20px 30px #00dade75;
		transform: translate3d(-3px, -3px, -3px);
	}
	.content {
		flex: 1;
		.document-title {
			font-size: 14px;
			color: #382ce0;
		}
		.document-description {
			font-size: 14px;
			color: #7e7e7e;
		}
		.problematic-mapping-message {
			font-size: 14px;
			color: #9b1616;
		}
	}
	.edit-button {
		height: 30px;
		width: 30px;
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: center;
		background: #eee;
		margin-left: 10px;
		border-radius: 15px;
		color: #4617b4;
		cursor: pointer;
		&:hover {
			background: #003cff;
			color: #fff;
		}
	}
	.execute-button {
		padding: 5px;
		display: flex;
		align-items: center;
		color: #382ce0;
		border-radius: 5px;
		font-size: 14px;
		cursor: pointer;
		margin-right: 10px;
		svg {
			margin-right: 5px;
		}
		&:hover {
			background: #382ce0;
			color: #fff;
		}
		&.disabled,
		&[disabled] {
			background: #c9c9c9;
			color: #fff;
			pointer-events: none;
		}
	}
}
#recurrence-form-content {
	min-width: 300px;
	.columns {
		display: flex;
		align-items: center;
		.MuiFormControl-root {
			max-width: 230px;
			min-width: 230px;
			margin-bottom: 0;
		}
		.form-control-input {
			margin-bottom: 0;
			&.specific {
				margin-left: 15px;
				width: 120px;
			}
		}
		.add-recurrence {
			margin-left: 20px;
			cursor: pointer;
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			background: #06a3ae;
			color: #fff;
			border-radius: 15px;
			&:hover {
				background: #057f88;
			}
		}
	}
}
.recurrence-list {
	margin-top: 20px;
	.recurrence-list-item {
		display: flex;
		align-items: center;
		padding: 4px 10px;
		border: 1px dashed #ddd;
		border-left: 8px solid #ffb700;
		margin-bottom: 2px;
		&.active {
			border-left: 8px solid #019b09;
			.recurrence-action {
				background-color: #019b09;
				&:hover {
					background-color: #06be0f;
				}
			}
		}

		.recurrence-type {
			font-size: 14px;
			margin-right: 10px;
			min-width: 200px;
			padding-right: 10px;
			border-right: 1px solid #ddd;
		}
		.recurrence-rule {
			font-size: 14px;
		}
		.recurrence-action {
			margin-left: 10px;
			cursor: pointer;
			background-color: #edbe00;
			// padding: 5px;
			border-radius: 3px;
			width: 30px;
			height: 30px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			&:hover {
				background-color: #d7ad04;
			}
			&.delete {
				background-color: #c44603;
				&:hover {
					background-color: #ffc2c2;
					svg {
						color: rgb(220, 0, 0);
					}
				}
			}
			svg {
				font-size: 16px;
				color: #fff;
			}
		}
	}
}

.message-danger {
	padding: 10px;
	color: red;
	font-weight: bold;
}
