.containerModal {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: #0000002f;

	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem 1rem 1rem 78px;

}
.content {
	background-color: #fff;
	width: 100%;
	margin: 0 auto;
	padding: 1rem;
	border-radius: 1rem;
	max-width: 650px;
	max-height: 80vh;
	overflow-y: auto;
}
.contentHeader {
	display: flex;
	justify-content: end;
	align-items: center;
}
