$ultra-bar-item-height: $ultra-bar-height - 10px;
#ultra-bar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $ultra-bar-height;
	background-color: $ultra-bar-background;
	box-shadow: 0 0 10px $shadow-a;
	display: flex;
	align-items: center;
	align-content: flex-start;
	box-sizing: border-box;
	padding: 0 10px;
	z-index: 10;
	z-index: 10000000000000;
	&:hover {
	}
	.theme-switch {
		width: 60px;
		height: $ultra-bar-item-height;
		background: $ultra-bar-theme-switch-background;
		box-shadow: inset 0 0 5px $shadow-a;
		border-radius: $ultra-bar-item-height/2;
		&.light {
			.icon {
				background: $ultra-bar-light-background;
				color: $ultra-bar-light-color;
			}
		}
		&.dark {
			.icon {
				background: $ultra-bar-dark-background;
				color: $ultra-bar-dark-color;
				transform: translate3d($ultra-bar-item-height, 0, 0);
			}
		}
		.icon {
			width: $ultra-bar-item-height;
			height: $ultra-bar-item-height;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			transition: background-color 300ms ease, color 300ms ease, transform 300ms ease;
			box-shadow: 0 0 3px $shadow-b;
			border-radius: 15px;
			cursor: pointer;
		}
	}
	// background-image: linear-gradient(90deg, $menuColorA 0%, $grayC 45%);
	.flex-spacer {
		flex: 1;
	}
	svg {
		font-size: 16px;
	}
	.indicator {
		font-size: 14px;
		line-height: 14px;
		padding: 0 5px;
		color: $ultra-bar-items-color;
		background: $background;
		height: $ultra-bar-item-height;
		display: flex;
		align-items: center;
		align-content: flex-start;
		margin-right: 5px;
		border-radius: 3px;
		svg {
			margin-right: 5px;
		}
		@media #{$mobile_portrait_landscape} {
			font-size: 12px;
		}
	}
	.app-image {
		.icon {
			img {
				height: ($ultra-bar-height - 10px);
				width: auto;
				margin-right: 10px;
			}
		}
	}
	.button {
		@include disable-selection();
		height: $ultra-bar-item-height;
		font-size: 14px;
		line-height: 14px;
		display: flex;
		align-items: center;
		align-content: flex-start;

		color: $ultra-bar-items-color;
		border-radius: 4px;
		margin-left: 10px;
		cursor: pointer;
		&:hover {
			background: darken($white, 10%);
		}
		&.left {
			margin-right: 10px;
			margin-left: 0;
		}

		&.options-button,
		&.user-button {
			border-radius: $ultra-bar-item-height/2;
			background: $background;
			&.visible {
				.options,
				.user-options {
					display: block;
				}
			}
			&.user-button {
				@media #{$mobile_portrait_landscape} {
					& > .text {
						display: none;
					}
					& > .avatar {
						margin-right: 0;
					}
				}
			}
			.options,
			.user-options {
				display: none;
				cursor: default;
				position: absolute;
				top: $ultra-bar-height + 1px;
				right: 10px;
				background-color: $ultra-bar-background;
				box-shadow: 10px 10px 50px $shadow-a;
				padding: 0;
				min-height: 80px;
				min-width: 160px;
				max-width: 300px;
				border-radius: 4px 0 4px 4px;
				box-sizing: border-box;
				.option {
					display: flex;
					align-items: center;
					align-content: flex-start;
					padding: 10px;
					cursor: pointer;

					&:hover {
						background: $background;
					}
					&.relative {
						position: relative;
					}
					&.user {
						flex-direction: column;
						align-items: center;
						align-content: center;
						border-bottom: 1px solid $grayM;
						padding-bottom: 20px;
						.avatar {
							height: 50px;
							width: 50px;
							margin-right: 0;
							margin-bottom: 10px;
							border-radius: 25px;
							svg {
								font-size: 20px;
							}
						}
						.text {
							margin-top: 3px;
						}
					}
					&.logout {
						border-top: 1px solid #ddd;
					}
					&.column {
						flex-direction: column;
						align-items: stretch;
						align-content: flex-start;
					}
					&.accepted {
						border-left: 10px solid $green9;
					}
					.flex {
						display: flex;
						align-items: center;
						align-content: flex-start;
					}
					.avatar {
						height: 20px;
						width: 20px;
						background: $background;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						box-shadow: inset 3px 3px 5px $shadow-a;
						margin-right: 10px;
						svg {
							font-size: 14px;
						}
					}
					.icon {
						margin-right: 5px;
						svg {
							font-size: 14px;
						}
					}
					.actions {
						.action {
							margin-bottom: 4px;
							cursor: pointer;
							svg {
								font-size: 20px;
							}
						}
					}
				}
			}
		}
		&.applications {
			&.visible {
				.application-options {
					display: block;
				}
			}
			.application-options {
				display: none;
				cursor: default;
				position: absolute;
				top: $ultra-bar-height + 1px;
				right: 10px;
				background-color: $ultra-bar-background;
				box-shadow: 10px 10px 50px $shadow-a;
				padding: 0;
				min-height: 80px;
				min-width: 160px;
				border-radius: 4px 0 4px 4px;
				box-sizing: border-box;
				.apps {
					display: flex;
					align-items: flex-start;
					align-content: flex-start;
					flex-wrap: wrap;
					padding: 20px;
					width: 160px;
					max-width: 240px;
					.app {
						height: 80px;
						width: 80px;
						padding: 5px;
						border-radius: 4px;
						cursor: pointer;
						margin: 0;
						box-sizing: border-box;
						display: flex;
						align-items: center;
						align-content: center;
						justify-content: center;
						&:hover {
							background: $ultra-bar-background-hover;
						}
						img {
							height: 70px;
							width: 70px;
						}
						&.lawdoc,
						&.workflux {
							img {
								height: 50px;
								width: 50px;
							}
						}
					}
				}
			}
		}
		&.chat {
			margin-right: 10px;
		}
		&.access-requests {
			// personal false"
			background: #2093ff;
			min-width: 36px;
			max-width: 110px;
			height: 36px;
			border-radius: 45px;
			align-content: center;
			justify-content: center;
			@include call-attention();
			&.active {
				background: #ff4120;
			}
			& > .icon {
				color: #fff;
				position: relative;
				top: 1px;
				min-width: 20px;
				max-width: 200px;
				text-overflow: ellipsis;
				overflow: hidden;
				display: flex;
				align-items: center;
				align-content: flex-start;

				svg {
					height: 20px;
					width: 20px;
				}
			}
			&.text {
				margin-right: 5px;
			}
			.options {
				width: 300px;
				.actions {
					.action {
						&:hover {
							background: #3c89ee;
						}
					}
				}
			}
		}

		@media #{$mobile_portrait_landscape} {
			font-size: 12px;
		}

		.icon {
			padding: 0 10px;
			&.avatar {
				padding: 0 0;
				margin-right: 10px;
				background: $background;
				border-radius: 50%;
				height: $ultra-bar-item-height;
				width: $ultra-bar-item-height;
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				box-sizing: border-box;
				box-shadow: inset 3px 3px 10px $shadow-a;
			}
			& ~ .text {
				padding-left: 0;
			}
			svg {
				height: 14px;
				width: 14px;
			}
			img {
				height: $ultra-bar-height - 20px;
				width: $ultra-bar-height - 20px;
			}
		}
		.full-flex-horizontal {
			flex: 1;
		}
		.text {
			padding: 0 10px;
		}
	}
	.icon-menu {
		height: 40px;
		width: 40px;
		margin-right: 10px;
		padding: 5px;
		box-sizing: border-box;
		position: relative;
		z-index: 100000000000;
		& > .MuiBadge-root {
			.MuiBadge-badge {
				margin-top: 5px;
				background: $ultra-bar-badge-background-a;
				color: $ultra-bar-badge-color-a;
			}
		}
		& > .MuiBadge-root .icon,
		& > .icon {
			height: 30px;
			width: 30px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			box-shadow: 1px 1px 2px 1px $shadow-a;
			border-radius: 3px;
			svg {
				font-size: 20px;
				color: $primary-color;
			}
		}
		.actions {
			position: absolute;
			top: 40px;
			// right: -320px;
			background: $white;
			box-shadow: inset 0 0 3px $shadow-a, 4px 4px 10px $shadow-a;
			height: 40px;
			display: none;
			align-items: center;
			align-content: center;
			z-index: 1000011;
			.action {
				height: 40px;
				width: 40px;
				display: flex;
				align-items: center;
				align-content: center;
				justify-content: center;
				&::before {
					z-index: 100000000001;
				}
				cursor: pointer;
				&:hover {
					svg {
						color: $primary-color;
					}
				}
				svg {
					font-size: 20px;
				}
			}
		}
		.menu {
			position: absolute;
			top: 80px;
			left: 0;
			background: $white;
			box-shadow: 10px 10px 30px $shadow-a;
			width: 250px;
			padding-top: 10px;
			max-height: 400px;
			overflow-y: auto;
			z-index: 1000001;
			display: none;
		}
		&:hover {
			overflow: visible;
			transform: translate3d(0, 0, 0);
			.actions {
				display: flex;
			}
			.menu {
				display: block;
			}
		}
	}
}
