.containerForm {
	display: flex;
	flex-direction: column;
	width: 100% !important;
	max-width: 650px;
}
.containerForm h1 {
	text-align: center;
}
.form {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	border-radius: 1rem;
}
.areaSelect {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.areaButtons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	margin-top: 1rem;
}
.ritch {
	border: 1px solid #0000007a;
	border-radius: 8px;
	padding: 0.5rem;
	margin-bottom: 1rem;
}
.ritch p {
	width: fit-content;
	margin: 0;
	padding: 0 0.3rem;
	margin-top: -20px;
	background-color: #fff;
}

@media screen and (max-width: 500px) {
	.areaSelect {
		flex-direction: column-reverse;
		gap: 1rem;
	}

	.areaButtons {
		flex-direction: column;
	}

	.areaSelect button,
	.areaButtons button {
		width: 100%;
	}
}