@import 'animations';
@import 'mediaQueries';
$shadow: 0 0 10px rgba(0, 0, 0, 0.1), 10px 10px 15px rgba(0, 0, 0, 0.1);
@include keyframes(phaseShow) {
	0% {
		opacity: 0;
		transform: translate3d(-300px, 0, 0) rotate3d(0, 0, 1, -20deg);
		margin-right: -300px;
		// box-shadow: 0 0 50px rgba(0, 0, 0, 0.3),
		// 	15px 15px 50px rgba(0, 0, 0, 0.3);
	}
	100% {
		opacity: 1;
		transform: translate3d(0, 0, 0) rotate3d(0, 0, 1, 0deg);
		// margin-right: 0px;
		// box-shadow: 0;
	}
}
body {
	&.dragging-action {
		#board {
			.phase {
				&:hover {
					transform: none !important;
					transition: background-color 400ms ease, box-shadow 500ms ease, margin 500ms ease, opacity 500ms ease !important;
				}
			}
		}
	}
	#board {
		display: flex;
		flex-direction: row;
		align-content: flex-start;
		align-items: stretch;
		flex: 1;
		padding: 10px 20px;
		// width: 100%;
		overflow-x: scroll;
		max-height: calc(100vh - 210px);
		box-sizing: border-box;
		@media #{$mobile_portrait_landscape} {
			flex-direction: column;
		}
		&::after {
			content: '';
			display: block;
			min-width: 3vh;
		}
		.add-inter-phase {
			position: relative;
			align-self: center;
			height: 30px;
			width: 30px;
			max-height: 30px;
			max-width: 30px;
			min-height: 30px;
			min-width: 30px;
			margin-left: -10px;
			margin-right: -5px;
			border-radius: 15px;
			font-size: 12px;
			display: flex;
			align-items: center;
			align-content: center;
			justify-content: center;
			background: #eee;
			background: transparent;
			color: #61785d;
			cursor: pointer;
			&:hover {
				background: #195707;
				color: #fff;
			}
			@media #{$mobile_portrait_landscape} {
				margin-left: 0;
				margin-right: 0;
				margin-bottom: 5px;
			}
		}
		.phase {
			flex: 1;
			// box-shadow: inset 0 0 9px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0);
			box-shadow: inset 0 00px rgba(0, 0, 0, 0.1), 0 0 0 rgba(0, 0, 0, 0);
			margin-right: 5px;
			border-radius: 10px;
			flex-basis: 300px;
			min-width: 300px;
			max-width: 300px;
			transition: transform 500ms ease, box-shadow 500ms ease, margin 500ms ease, opacity 500ms ease,
				background-color 500ms ease, border 500ms ease;
			background: #fafafa;
			background: #fff;
			animation: phaseShow 500ms 1;
			border: 1px dashed #aaa;
			display: flex;
			flex-direction: column;

			&.closed {
				min-width: 50px;
				max-width: 50px;
				.phase-controls {
					display: none;
				}
				.phase-content-list {
					display: none;
				}
				.phase-name {
					transform: translate3d(0, 300px, 0) rotate3d(0, 0, 1, -90deg);
					transform-origin: 0 0;
					width: 300px;
				}
				input {
					pointer-events: none;
				}
			}

			@media #{$mobile_portrait_landscape} {
				width: auto;
				max-width: 10000px;
				margin-right: 0;
				margin-bottom: 20px;
				background-color: #eee;
				box-shadow: 0 0 0 transparent;
				transition: background-color 400ms ease, transform 500ms ease, box-shadow 500ms ease, margin 500ms ease,
					opacity 500ms ease;
				animation: none;
				transform: none;
				flex-basis: 0;
				min-width: 100px;

				&.closed {
					min-width: none;
					max-width: 10000px;
					.phase-controls {
						display: none;
					}
					.phase-content-list {
						display: none;
					}
					.phase-name {
						transform: none;
						transform-origin: 0 0;
						width: auto;
					}
					input {
						pointer-events: none;
					}
					.right-icon.icon-0 {
						transform: rotate3d(0, 0, 1, 180deg) !important;
					}
				}
				.right-icon.icon-0 {
					transform: rotate3d(0, 0, 1, 270deg) !important;
				}
			}
			&:hover {
				box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 4px 4px 5px #00000033;
				// transform: translate3d(-3px, -3px, -3px);
				// z-index: 100000;
				@media #{$mobile_portrait_landscape} {
					// background-color: #fff;
				}
			}
			&.hover-droppable {
				// background-color: rgb(163, 187, 230);
				transition: background-color 400ms ease, box-shadow 500ms ease, margin 500ms ease, opacity 500ms ease !important;
				transform: none !important;
				border: 1px dashed #000;
				box-shadow: inset 0 0 0 transparent, 0 0 10px #4776dc;
			}
			&.will-delete {
				opacity: 0;
				transform: translate3d(-300px, 0, 0) rotate3d(0, 0, 1, -20deg);
				margin-right: -300px;
			}
			.phase-name {
				.form-control-input {
					margin-bottom: 0;
					input {
						font-size: 14px;
						color: #1336d3;
						font-weight: bold;
						padding-right: 80px;
					}
					.right-icon {
						border-radius: 50%;
						transition: transform 400ms ease, box-shadow 400ms ease, background 400ms ease;
						width: 30px;
						height: 30px;
						top: 10px;
						right: 10px;
						background: #ddd;
						color: #fff;

						&.icon-0 {
							&:hover {
								background: #0066ff;
							}
							svg {
								margin-left: -3px;
								width: 24px;
								height: 24px;
							}
						}
						&.icon-1 {
							right: 45px;
						}
						&:hover {
							background: #f00;
							transform: translate3d(-1px, -1px, -1px);
							box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.15);
							color: #fff;
						}
						svg {
							width: 16px;
							height: 16px;
						}
					}
				}
			}
			.phase-controls {
				// padding: 15px;

				// padding-top: 4px;
				// padding-bottom: 4px;
				padding-bottom: 4px;
				background: #dde;
				background: #fafafa;
				background: #fff;
				// box-shadow: inset 0 0px 6px #33333333;
				border-bottom: 1px solid #ddd;
				display: flex;
				align-items: center;
				align-content: flex-end;
				justify-content: flex-end;
				.phase-button {
					position: relative;
					box-shadow: none;
					margin-right: 6px;
					min-width: 50px;
					height: 40px;
					perspective: 500px;
					padding: 4px 10px;
					height: 30px;
					background: transparent;
					color: #1215a9;
					font-size: 12px;

					svg {
						font-size: 14px;
					}
					@media #{$mobile_portrait_landscape} {
						font-size: 12px;
						min-width: 20px;
						height: 30px;
						padding: 3px 10px;
					}

					&:hover {
						box-shadow: $shadow;
						background: #4776dc;
						color: #fff;
					}
					&.options-menu-visible {
						z-index: 1000;
						background: #4776dc;
						color: #fff;

						.options-menu {
							opacity: 1;
							transform: translate3d(0, 0, 0) rotate3d(1, 0, 0, 0deg);
							pointer-events: all;
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.3), 10px 10px 15px rgba(0, 0, 0, 0.3);
						}
					}
					span:first-child {
						pointer-events: none;
						background-color: inherit;
						display: flex;
						align-items: center;
						span {
							line-height: 14px;
						}
					}
					.options-menu {
						position: absolute;
						top: 38px;
						left: 0;
						background: inherit;
						box-shadow: none;
						z-index: 100000;
						opacity: 0;
						transform-origin: top bottom;
						transition: box-shadow 300ms ease, opacity 300ms ease, transform 300ms ease;
						// transform: translate3d(-50px, -100px, 0) rotate3d(1, 1, 0, -40deg)
						// 	rotate3d(0, 0, 1, -30deg);
						min-width: 200px;
						// min-height: 100px;
						padding: 10px 0;
						padding: 2px;
						border-radius: 4px;
						border-top-left-radius: 0;
						pointer-events: none;
						@media #{$mobile_portrait_landscape} {
							min-width: 200px;
							max-width: 200px;
							transform: none !important;
						}
						.option {
							text-align: left;
							white-space: nowrap;
							box-shadow: inset 0 -5px 5px rgba(0, 0, 0, 0.05);
							@media #{$mobile_portrait_landscape} {
								white-space: normal;
								padding: 20px 10px;
							}
							&:first-child {
								border-top-left-radius: 4px;
								border-top-right-radius: 4px;
							}
							&:last-child {
								border-bottom-left-radius: 4px;
								border-bottom-right-radius: 4px;
							}
							&:hover {
								&::after {
									content: '';
									display: block;
									background: rgba(0, 0, 0, 0.1);
									position: absolute;
									top: 0;
									left: 0;
									right: 0;
									bottom: 0;
								}
							}

							position: relative;
							padding: 10px;
							font-size: 14px;
							font-weight: bold;
							cursor: pointer;
						}
					}
					&.phase-green-button {
					}
					&.phase-remove-button {
						align-self: flex-end;
					}
					&:hover {
						&.phase-green-button {
							color: #fff;
							background: #1bb42f;
							&:hover {
								background: #13dd2e;
							}
						}
						&.phase-remove-button {
							color: #fff;
							background: rgb(222, 100, 100);
							&:hover {
								background: rgb(206, 72, 72);
							}
						}
					}
				}
			}
			.phase-content-list {
				flex: 1 1;
				overflow-y: scroll;
				padding: 10px 10px 10px 10px;
				// width: 101%;
				overflow-x: hidden;
				// margin-left: -13px;

				.phase-actions-list {
				}
				.phase-reactions-list {
					.reactions-title {
						font-size: 14px;
						color: #153e98;
						padding: 0 15px;
						margin: 10px 0;
						display: flex;
						align-items: center;
						.line {
							height: 1px;
							background: #153e98;
							flex: 1;
							margin-left: 10px;
						}
					}
					&::before {
						content: '';
						display: block;
						// height: 1px;
						// background: #aaa;
						margin: 0 15px 15px 15px;
					}

					.jump-phase-name {
						background: #fff;
						cursor: pointer;
						border: 1px dashed #ddd;
						padding: 5px;
						font-size: 14px;
						color: #0b138a;
						&:hover {
							background: #fff06966;
						}
						&.selected {
							background: #fff069;
						}
					}
				}
				.phase-action {
					margin: 0 15px 10px 15px;
					margin: 0 0 10px 0;
					border-radius: 4px;
					background: #ffeb3b;
					background: #fff;
					// min-height: 50px;
					// border: 1px solid #ddd;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
					border-width: 0;
					color: #666;
					border-style: solid;
					transition: box-shadow 300ms ease;
					position: relative;
					z-index: 10;

					box-shadow: none;
					border-top: 1px solid #ddd !important;
					border-left: 1px solid #ddd !important;
					border-right: 1px solid #ddd !important;
					border-bottom: 1px solid #ddd !important;
					&:hover {
						box-shadow: 0 0 4px #4776dc;
					}

					&.dragging {
						position: fixed !important;
						top: 0 !important;
						left: 0 !important;
						width: 250px;
						cursor: pointer;
						box-shadow: 20px 20px 80px rgba(0, 0, 0, 0.3);
						pointer-events: none;
						z-index: 1000000 !important;
					}
					&.selected {
						box-shadow: 0 0 3px 3px #024ff5;
					}
					&.linked {
						margin-right: 13px;
						.action-link-circle {
							width: 20px;
							height: 40px;
							position: absolute;
							top: 50%;
							margin-top: -20px;
							left: auto;
							right: -23px;
							border-radius: 0px 10px 10px 0;
							z-index: 1;
							border-top: 3px solid #fff;
							border-right: 3px solid #fff;
							border-bottom: 3px solid #fff;
							box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
							svg {
								position: relative;
								display: block;
								top: 9px;
								font-size: 20px;
								color: #fff;
								transform: rotate3d(0, 0, 1, -45deg);
							}
						}
					}

					@media #{$mobile_portrait_landscape} {
						border-top: 0;
						border-right: 0;
						border-bottom: 0;
					}
					&.hover-linked-friend {
						box-shadow: 0 0 1px 2px #24b500;
					}
					&.approval {
						header {
							border-left: 10px solid #fff069;
							border-color: #ffeb3b;
						}
					}
					&.documentInput {
						header {
							border-left: 10px solid #00bcd4;
							border-color: #00bcd4;
						}
					}
					&.formInput {
						header {
							border-left: 10px solid #039be5;
							border-color: #039be5;
						}
					}
					&.documentOutput {
						header {
							border-left: 10px solid #77d2ff;
							border-color: #77d2ff;
						}
					}
					&.notificationEmail {
						header {
							border-left: 10px solid #04c614;
							border-color: #04c614;
						}
					}
					&.phaseJump {
						header {
							border-left: 10px solid #ffc507;
							border-color: #ffc507;
						}
					}
					.action-button {
						min-width: 40px;
						color: inherit;
					}
					.phase-action-type {
						header {
							display: flex;
							flex-direction: row;
							border-radius: 3px;
							.phase-action-type-name {
								padding: 5px;
								flex: 1;
								font-size: 14px;
								color: #449;
								cursor: pointer;
								display: flex;
								align-items: flex-start;
								flex-direction: column;
								align-content: center;
								justify-content: center;
								.group-info {
									position: relative;
									&.process-responsible-group,
									&.process-creator-group {
										&::after {
											position: absolute;
											left: -15px;
											bottom: 0;
											content: '';
											display: block;
											background: #00afbb;
											height: 10px;
											width: 10px;
											border-radius: 10px;
										}
									}
									display: flex;
									align-items: center;
									font-size: 12px;
									// font-weight: bold;
									color: #777;
									border-radius: 0;
									min-height: 20px;
								}
							}
							.action-check {
								flex: 1;
								width: 70px;
								svg {
									color: #0b38ff;
									font-size: 26px;
									padding: 0;
								}
							}
							button {
								.warning {
									svg {
										color: #f00;
									}
								}
								.success{
									svg{
										color: rgb(15, 168, 15)
									}
								}
								svg {
									height: 20px;
									width: 20px;
									color: #0022bb88;
								}
							}
						}
					}
					.action-toggler {
						padding-left: 8px;
						display: flex;
						align-items: center;
						svg {
							transform: rotate3d(0, 0, 1, -90deg);
							transition: transform 300ms ease;
						}
						&.opened svg {
							transform: rotate3d(0, 0, 1, 0deg);
						}
					}
					.action-content {
						// max-height: 0;
						overflow: hidden;
						box-sizing: border-box;
						background: #fafafa;
						display: none;
						// transition: max-height 300ms ease;
						&.opened {
							// transition: max-height 300ms ease;
							// max-height: 1000px;
							display: block;
							// overflow-y: auto;
							border-top: 1px solid #ddd;
							box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.14);
						}
						.action-form {
							padding: 10px;
							.checkbox-control {
								.MuiFormControlLabel-label {
									font-size: 14px;
								}
								svg {
									color: #2bc700;
								}
								span {
									color: #077814;
								}
							}
							textarea,
							input,
							.MuiInput-input {
								font-size: 14px;
							}
						}
						.checkbox-control {
							svg {
								color: #333;
							}
						}
						.form-input-content {
							background: #fff;
							border-radius: 5px;
							margin-top: 10px;
							// padding: 10px;

							color: #333;
							.form-example-title {
								margin-bottom: 10px;
								padding: 10px 0;
								display: flex;
								align-items: center;
								& > span {
									flex: 1;
									font-size: 14px;
								}
								.action-toggler {
									padding-right: 5px;
									padding-left: 0;
									margin-left: -4px;
									&.visible {
										svg {
											transform: rotate3d(0, 0, 1, 0deg);
										}
									}
								}
							}
							.textfield-input {
								color: #333;
								margin-bottom: 5px;
								input,
								textarea {
									color: #333;
								}
							}
							.form-example-content {
								display: none;
								&.visible {
									background: #eee;
									padding: 5px;
									display: block;
									height: 150px;
									overflow: scroll;
									border-radius: 5px;
									input {
										background: #fff;
									}
								}
								.MuiFormControl-root {
									background: transparent;
									.MuiInputBase-formControl {
										background: transparent;

										.MuiSelect-select {
											background: #fff;
											margin-bottom: 10px;
											border: 1px solid #ddd;
											border-radius: 4px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.ged-17-info {
			margin-top: 20px;
			.info {
				margin-bottom: 5px;
				border: 1px dashed #ddd;
				border-left: 5px solid #4776dc;
				padding: 2px 6px;
			}
		}
	}
}
.action-type {
	&.approval {
		background: #ffeb3b;
		color: #333;
	}
	&.document-input {
		background: #00bcd4;
		color: #fff;
	}
	&.form-input {
		background: #039be5;
		color: #fff;
	}
}
#specific-group {
	padding-bottom: 10px;
	border-bottom: 2px solid #00949f;
	margin-bottom: 10px;
}
.visible-group {
	display: block !important;
}
.invisible-group {
	display: none !important;
}
#document-form, #update-form {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;

	display: flex;
	flex-direction: column;
	align-content: stretch;
	align-items: stretch;
	pointer-events: none;
	transition: background-color 500ms ease;

	&.visible {
		pointer-events: all;
		background-color: #55555544;
		.content {
			transform: translate3d(0, 0, 0);
		}
	}
	#document-close, #update-close {
		position: absolute;
		top: 15px;
		left: auto;
		right: 15px;
		font-size: 30px;
		cursor: pointer;
		color: #333;
		transition: transform 500ms ease, color 500ms ease;
		&:hover {
			color: #039be0;
			transform: scale3d(1.2, 1.2, 1.2);
		}
	}
	.content {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background: #039be0;
		background: #fff;
		padding: 20px;
		width: 400px;
		flex: 1;
		box-shadow: $shadow;
		overflow-y: scroll;
		transition: transform 500ms ease;
		transform: translate3d(-830px, 0, 0);
		@media #{$mobile_portrait_landscape} {
			width: 100vw;
			box-sizing: border-box;
		}
	}
}

.group-list {
	.group-list-item {
		cursor: pointer;
		padding: 5px 10px;
		min-height: 30px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		border-radius: 4px;
		border-left: 5px solid #00949f;
		&.process-creator-group {
			border: 1px solid #aad;
			background: #01646c;
			border-left: 5px solid #02e915;
			color: #02e915;
			&:hover {
				background: #00868f;
				border-left: 5px solid #02e915;
				color: #02e915;
			}
			svg {
				color: #fff;
			}
		}
		&:hover {
			background: #eef;
		}
		.group-list-icon {
			justify-content: flex-end;
		}
		.MuiListItemText-root {
			margin: 0;
			span {
				// white-space: nowrap;
			}
		}
	}
	.group-list-item-2 {
		cursor: pointer;
		padding: 5px 10px;
		min-height: 30px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		margin-left: 20px;
		border-radius: 4px;
		border-left: 5px solid #02e915;
		max-width: 95%;
		&.process-creator-group {
			border: 1px solid #aad;
			background: #01646c;
			border-left: 5px solid #02e915;
			color: #02e915;
			&:hover {
				background: #00868f;
				border-left: 5px solid #02e915;
				color: #02e915;
			}
			svg {
				color: #fff;
			}
		}
		&:hover {
			background: #eef;
		}
		.group-list-icon {
			justify-content: flex-end;
		}
		.MuiListItemText-root {
			margin: 0;
			span {
				font-size: 15px;
			}
		}
	}
	.group-list-item-3 {
		cursor: pointer;
		padding: 5px 10px;
		min-height: 30px;
		border-bottom: 1px solid #ddd;
		margin-bottom: 5px;
		margin-left: 40px;
		border-radius: 4px;
		border-left: 5px solid #00868f;
		max-width: 90%;
		&.process-creator-group {
			border: 1px solid #aad;
			background: #01646c;
			border-left: 5px solid #02e915;
			color: #02e915;
			&:hover {
				background: #00868f;
				border-left: 5px solid #02e915;
				color: #02e915;
			}
			svg {
				color: #fff;
			}
		}
		&:hover {
			background: #eef;
		}
		.group-list-icon {
			justify-content: flex-end;
		}
		.MuiListItemText-root {
			margin: 0;
			span {
				font-size: 15px;
			}
		}
	}
}
.cancel-flow {
	margin: 10px 15px;
}
.header-chip {
	padding: 5px 6px !important;
	background: #039be5;
	color: #fff;
	margin-right: 8px !important;
	border-radius: 4px;
	font-size: 12px;
	font-weight: bold;
	text-transform: uppercase;
	border: 0 !important;
	min-height: 30px !important;
	box-sizing: border-box;
	cursor: pointer;
	&.error {
		background: #d00;
		color: #fff;
		.chip-label {
			background: #d00;
			color: #fff;
			padding: 0 3px;
			border-radius: 3px;
		}
	}
	&.MuiFormControl-root {
		padding: 0 !important;
		height: 30px;

		.MuiInputBase-root {
			height: 30px;
			&.Mui-error {
				background: #d00;
				color: #fff;
				padding: 0 3px;
			}
		}
		.MuiSelect-root {
			height: 30px;
			padding: 5px;
			box-sizing: border-box;
			color: #fff !important;
		}
		.MuiFormLabel-root {
			transform: translate3d(0px, -15px, 0) !important;
			font-size: 12px;
			color: #333;
			border-radius: 3px;
			&.Mui-error {
				background: #d00;
				color: #fff;
				padding: 0 3px;
			}
		}
		fieldset {
			border-color: transparent;
			border: 0 !important;
			height: 30px;
		}
		svg {
			color: #fff !important;
		}
	}
	.chip-label {
		position: absolute;
		transform: translate3d(-4px, -22px, 0);
		font-size: 12px;
		font-weight: normal;
		color: #333;
	}
}
.workflow-mapping-content {
	padding-bottom: 0;
	& > header {
		padding-bottom: 10px;
		h3 {
			font-size: 20px;
			margin: 5px 0;
		}
	}
}
.phase-jump-form-config {
	margin: 10px 0;
	.form-title {
		color: #163b61;
		font-size: 16px;
		font-weight: 600;
	}
	.field-configuration {
		.field-title {
			color: #163b61;
		}
	}
}
.signatory {
	border: 1px solid #ddd;
	border-radius: 4px;
	padding: 5px;
	margin: 5px 0;
	.signatory-header {
		display: flex;
		align-items: center;
		align-content: flex-start;
		cursor: pointer;
		.caret {
			margin-right: 0;
			width: 15px;
		}
		.text {
			flex: 1;
		}
	}
	&.opened {
		& > .content {
			display: block;
		}
	}
	& > .content {
		display: none;
	}
}
.phase-action-type {
	.info {
		font-size: 14px;
		padding: 5px 10px;
		margin-top: 2px;
		border-left: 4px solid #269dd0;
		border-radius: 4px;
		background: #fff;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	}
}
.form-field-option {
	padding: 5px;
	border: 1px solid #ddd;
	border-radius: 4px;
	margin: 5px 0;
}
