@import './colors';
@import './functions';

$themes: 'light', 'dark';

@mixin theme-app-styles($theme) {
	.themeApp {
		@each $app in $apps {
			&[data-app='#{$app}'] {
				--primary-color: #{map-deep-get($colors, $theme, $app, 'primary-color')};
				--secondary-color: #{map-deep-get($colors, $theme, $app, 'secondary-color')};
				--highlight-color: #{map-deep-get($colors, $theme, $app, 'highlight-color')};
				--title-color: #{map-deep-get($colors, $theme, $app, 'title-color')};
				--text-color: #{map-deep-get($colors, $theme, $app, 'text-color')};
				--button-color: #{map-deep-get($colors, $theme, $app, 'button-color')};
				--text-button-color: #{map-deep-get($colors, $theme, $app, 'text-button-color')};
				--input-color: #{map-deep-get($colors, $theme, $app, 'input-color')};
			}
		}
	}
}

@each $theme in $themes {
	@if ($theme == 'light') {
		body.#{$theme},
		body {
			@include theme-app-styles($theme);
		}
	} @else {
		body.#{$theme} {
			@include theme-app-styles($theme);
		}
	}
}
